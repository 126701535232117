import React, { useEffect, useState } from "react";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import PropTypes from "prop-types";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
const MenuListBoxEdit = ({ setShowModal, data, setData }) => {
  const { setupData } = useAppContext();
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    const roles = setupData.roles
    const options = roles.map((role) => {
      return { value: role.name, label: role.name };
    });
    setRoleOptions(options);
  }, [setupData])

  const elements = [
    {
      name: "title",
      component: "input",
      type: "text",
      label: "Titulo",
      key: "title",
      placeholder: "Nuevo Menú",
    },
    {
      name: "pathmenu",
      component: "input",
      type: "text",
      label: "Path Menu",
      key: "pathmenu",
      disabled: true,
    },
    {
      name: "id",
      component: "input",
      type: "text",
      label: "ID",
      key: "id",
      placeholder: "nuevomenu2",
    },
    {
      name: "url",
      component: "input",
      type: "text",
      label: "URL",
      key: "url",
      placeholder: "/nuevomenu2",
    },
    {
      name: "outwindow",
      component: "input",
      type: "checkbox",
      label: "Out Window",
      key: "outwindow",
      checked: false,
    },
    {
      name: "roles",
      component: "select",
      type: "text",
      label: "Roles",
      key: "roles",
      options: roleOptions,
    },
    {
      name: "active",
      component: "input",
      type: "checkbox",
      label: "Activo",
      key: "active",
      checked: true,

    },
  ];
  const updateDataValues = (e) => {
    setShowModal(null)
    const obj = {
      ...e,
      pathmenu: `/${e.id}`
    }
    setData(obj)

  }

  return (
    <BoxFloat title="Nuevo menú" onClickExit={() => setShowModal(null)}>
      <FormGenerator
        form={{ elements: elements }}
        onSubmit={updateDataValues}
        initialValues={data}
      />
    </BoxFloat>
  );
};

export default MenuListBoxEdit;

MenuListBoxEdit.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};
