/**
 * @param {Array} data Must be an array of objects. 
 * @returns {Array} An array of objects with the structure of the menu and submenus.
 */
export const generateStructureMenu = (data) => {
    const principal = data.filter((item) => item.pathmenu.split("/").length === 2)
    const submenus = data.filter((item) => item.pathmenu.split("/").length > 2)
    principal.forEach((item) => {
        item.submenus = submenus.filter((submenu) => submenu.pathmenu.includes(item.pathmenu))
    })
    return principal
}

export const plainStructureMenu = (data) => {
    const plainedMenu = []
    for (let menu of data) {
        const copyMenu = { ...menu }
        delete copyMenu.submenus
        plainedMenu.push(copyMenu)
        for (let submenu of menu.submenus) {
            plainedMenu.push(submenu)
        }
    }
    return plainedMenu
}