import React from "react";
import PropTypes from 'prop-types'; // Añadido para la validación de props
import "./planner.css";

const Sidebar = ({ children, onCloseEditor }) => {
  return (
    <aside className="sidebar">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <i
          className="fas fa-times sidebar-close-button"
          onClick={onCloseEditor}
        />
      </div>
      {children}
    </aside>
  );
};

// Añadido para la validación de props
Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseEditor: PropTypes.func.isRequired,
};

export default Sidebar;
