import { API_URL } from "../../config.js";

export class HttpMethods {
  static async request_get(requestPath, mode = "json") {
    const options = {
      method: "GET",
    };
    return this.sendRequest(requestPath, options, mode);
  }

  static async request_post(requestPath, data, mode = "json") {
    const isJSON = mode !== "text";
    const options = {
      method: "POST",
      body: isJSON ? JSON.stringify(data) : data,
    };
    return this.sendRequest(requestPath, options, mode);
  }

  static async request_delete(requestPath, itemToDelete) {
    const options = {
      method: "DELETE",
    };
    return this.sendRequest(`${requestPath}/${itemToDelete}`, options);
  }

  static async sendRequest(requestPath, options, mode = "json") {
    const token = this.getToken();
    let headers = {}
    if (token) {
      headers = {
        Authorization: token,
      };
    }
    if (options.method === "POST" && mode === "json")
      headers["Content-Type"] = "application/json";
    try {
      const response = await fetch(`${API_URL}${requestPath}`, {
        method: options.method,
        headers,
        body: options.body,
      });

      if (response.status === 200) {
        return mode === "json" ? await response.json() : await response.text();
      } else if (response.status === 400) {
        return mode === "json" ? await response.json() : await response.text();
      } else if (response.status === 409) {
        return await response.json();
      } else {
        this.handleResponseError(response, requestPath, options);
        try {
          return response.json()
        } catch (err) {
          console.log(err);
        }
      }
    } catch (error) {
      console.error(
        `Error al hacer la petición al servidor ${API_URL}${requestPath} `
      );
      alert(
        `Error al hacer la petición al servidor ${API_URL}${requestPath}`,
        error
      );
    }
  }

  static async handleResponseError(response, requestPath, options) {
    if (response.status === 404) {
      const item = requestPath.split("/").pop();
      if (options.method === "DELETE") {
        console.error(
          `Error al eliminar el elemento ${item} no encontrado, status: ${response.status}`
        );
        alert(
          `Error al eliminar el elemento ${item} no encontrado, status: ${response.status}`
        );
      } else if (options.method === "GET") {
        console.error(
          `Error al leer el elemento ${item} no encontrado, status: ${response.status}`
        );
        alert(
          `Error al leer el elemento ${item} no encontrado, status: ${response.status}`
        );
      }
      // else {
      //     console.error(`Error elemento no encontrado status: ${response.status}`);
      //     alert(`Error elemento no encontrado status: ${response.status}`);
      // }
    } else if (response.status === 401) {
      console.error(
        `Error en la autenticación con el servidor status ${response.status}`
      );
      alert(
        `Error en la autenticación con el servidor status ${response.status}`
      );
    } else {
      console.error(
        `Error al hacer la petición al servidor ${API_URL}${requestPath} status: ${response.status}`
      );
      alert(
        `Error al hacer la petición al servidor ${API_URL}${requestPath} status: ${response.status}`
      );
    }
    return
  }

  static getToken() {
    return sessionStorage.getItem("token")
  }
}
