import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

const observersMap = new Map();
const observerCallbacksMap = new Map();

const getObserver = (root, rootMargin, threshold) => {
  const key = `${root || 'null'}|${rootMargin}|${Array.isArray(threshold) ? threshold.join(',') : threshold}`;
  if (!observersMap.has(key)) {
    const observer = new IntersectionObserver((entries, obs) => handleIntersections(obs, entries), {
      root: root || null,
      rootMargin,
      threshold,
    });
    observersMap.set(key, observer);
    observerCallbacksMap.set(observer, new Map());
  }
  return observersMap.get(key);
};

const handleIntersections = (observer, entries) => {
  const callbacks = observerCallbacksMap.get(observer);
  if (!callbacks) return;

  entries.forEach(entry => {
    const callback = callbacks.get(entry.target);
    if (callback) {
      callback(entry);
    }
  });
};

const LazyLoad = ({ children, rootMargin = "200px 0px 200px 0px", threshold = 0.1, root }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleIntersection = useCallback(
    (entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        const observer = getObserver(root, rootMargin, threshold);
        observer.unobserve(entry.target);
        const callbacks = observerCallbacksMap.get(observer);
        if (callbacks) {
          callbacks.delete(entry.target);
        }
      }
    },
    [root, rootMargin, threshold]
  );

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;

    const observer = getObserver(root, rootMargin, threshold);
    const callbacks = observerCallbacksMap.get(observer);
    if (callbacks) {
      callbacks.set(currentRef, handleIntersection);
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
        if (callbacks) {
          callbacks.delete(currentRef);
        }
      }
    };
  }, [handleIntersection, root, rootMargin, threshold]);

  return <div ref={ref}>{isVisible ? children : null}</div>;
};

LazyLoad.propTypes = {
  children: PropTypes.node.isRequired,
  rootMargin: PropTypes.string,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  root: PropTypes.object,
};

export default LazyLoad;
