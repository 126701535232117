import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'; // Importa PropTypes
import "./StatusGraphic.css";

const StatusGraphic = ({ service }) => {
  const calculateElapsedTime = (init) => {
    const initDate = new Date(init);
    const now = new Date();
    const diffInMs = now - initDate;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const seconds = diffInSeconds % 60;

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const minutes = diffInMinutes % 60;

    const diffInHours = Math.floor(diffInMinutes / 60);
    const hours = diffInHours % 24;

    const days = Math.floor(diffInHours / 24);

    // Si hay más de 1 día, mostrar el formato con días
    if (days > 0) {
      return `${days}d ${("0" + hours).slice(-2)}:${("0" + minutes).slice(
        -2
      )}:${("0" + seconds).slice(-2)}`;
    }

    // Si no hay días, mostrar solo horas, minutos y segundos
    return `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
      "0" + seconds
    ).slice(-2)}`;
  };

  const { init } = service;
  const [elapsedTime, setElapsedTime] = useState(
    init ? calculateElapsedTime(init) : "N/A"
  );

  useEffect(() => {
    if (init) {
      const intervalId = setInterval(() => {
        setElapsedTime(calculateElapsedTime(init)); // Actualiza el tiempo transcurrido cada segundo
      }, 1000);

      return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
    }
  }, [init]);

  return (
    <div className="status-service-graphic">
      <div className="status-service-graphic-label">
        <div className="status-service-graphic-label-name">
          Tiempo en ejecución
        </div>
        <div className="status-service-graphic-label-value">{elapsedTime}</div>
      </div>
      <div className="status-service-graphic-paint"></div>
    </div>
  );
};

// Validación de props
StatusGraphic.propTypes = {
  service: PropTypes.shape({
    init: PropTypes.string, // init debe ser un string (por la fecha)
  }).isRequired,
};

export default StatusGraphic;
