import removeAccents from "./removeAccents";

const searchTextHelper = (data, searchText, dataType) => {
    if (searchText.trim() !== "") {
        const dataCopy = data.filter((item, index) => {
            if (
                removeAccents(item)
                    .toLowerCase()
                    .includes(removeAccents(searchText).toLowerCase())
            ) {
                return true;
            }
            return false
        });
        return dataCopy;
    }
    return data
};

export default searchTextHelper;