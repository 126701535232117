import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Test.css";
import Button from "src/modules/components/common/Button/Button";
// import SortingTable from "../Table/SortingTable";

const Test = ({ element }) => {
  const [showVars, setShowVars] = useState(false);

  return (
    <div className="test-group">
      <p>
        <strong>Name:</strong> {element.name}
      </p>
      <p>
        <strong>Check:</strong>{" "}
        {element.check.replaceAll("'", " ").replaceAll("  ", " ").trim()}
      </p>
      <p>
        <strong>Delay:</strong> {element.delay}
      </p>
      <Button
        className="toggle-vars"
        data-state="hidden"
        onClick={() => setShowVars(!showVars)}
        content={showVars ? "Ocultar Vars" : "Mostrar Vars"}
      />
      {showVars && (
        <div className="vars">
          {/* <SortingTable columnsData={columnsData} tbodyData={element.vars} enableSorting={true}/> */}
          <table>
            <thead>
              <tr>
                <th>Variable Name</th>
                <th>Value</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {element.vars.map((varItem) => {
                return (
                  <tr key={varItem.value}>
                    <td>{varItem.name}</td>
                    <td>{varItem.value}</td>
                    <td>{varItem.type}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

Test.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
    check: PropTypes.string.isRequired,
    delay: PropTypes.string.isRequired,
    vars: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Test;
