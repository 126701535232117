export function useMustache() {
  const replaceMustache = (text, variables, params = null) => {
    if (!text || typeof text !== 'string' || !variables) return "";
    const re = /{{([^}]+)}}/g;

    const getNestedValue = (obj, path) => {
      return path.split('.').reduce((acc, key) => {
        if (acc && typeof acc === 'object') {
          // Verifica si la clave es un índice de array (e.g., "array[0]")
          const arrayMatch = key.match(/(\w+)\[(\d+)\]/);
          if (arrayMatch) {
            const arrayKey = arrayMatch[1];
            const index = parseInt(arrayMatch[2], 10);
            return acc[arrayKey] && Array.isArray(acc[arrayKey]) ? acc[arrayKey][index] : "";
          }
          return key in acc ? acc[key] : "";
        }
        return "";
      }, obj);
    };

    return text.replace(re, function (match, p1, offset, string) {
      const subvars = p1.split(".");
      if (subvars[0] === "params") {
        return params.get(subvars[1]);
      }
      return variables[p1] ?? getNestedValue(variables, p1);
    });
  };

  const editMustacheValue = (path, value, obj) => {
    const parts = path.replace(/\{|\}/g, "").split(".");
    const updatedObject = { ...obj };

    let current = updatedObject;

    for (let i = 0; i < parts.length - 1; i++) {
      const isArrayAccess = /\[(\d+)\]/.exec(parts[i]);

      if (isArrayAccess) {
        const arrayIndex = parseInt(isArrayAccess[1]);
        const arrayName = parts[i].replace(/\[\d+\]/, "");

        if (Array.isArray(current[arrayName])) {
          current = current[arrayName];
          current = current[arrayIndex];
        } else {
          // Si la parte anterior no es un array o el índice no existe, devuelve el objeto sin cambios
          return obj;
        }
      } else {
        if (parts[i] in current) {  // Reemplazado hasOwnProperty con in
          current = current[parts[i]];
        } else {
          // Si alguna parte no existe en el objeto, devuelve el objeto sin cambios
          return obj;
        }
      }
    }

    // Actualiza el valor en el último nivel del objeto
    const lastPart = parts[parts.length - 1];
    const isArrayAccess = /\[(\d+)\]/.exec(lastPart);
    const arrayName = lastPart.replace(/\[\d+\]/, "");

    if (
      isArrayAccess &&
      arrayName in current && // Reemplazado hasOwnProperty con in
      Array.isArray(current[arrayName])
    ) {
      const arrayIndex = parseInt(isArrayAccess[1]);
      current[arrayName][arrayIndex] = value;
    } else {
      current[lastPart] = value;
    }

    return updatedObject;
  };

  return { replaceMustache, editMustacheValue };
}
