import viewActionTypes from "../actionTypes/viewActionTypes";
import {
  View,
  Cartridge,
  Check,
  Error,
  Event,
  Flow,
  Movement,
  Template,
  Dossier,
  Doc,
  Resume,
  User,
} from "src/lib/api.js";

export const fetchView = async (vid, dispatch) => {
  dispatch({ type: viewActionTypes.FETCH_VIEW_REQUEST });

  try {
    const data = await View.read(vid);
    if (data.frames.length === 1) {
      setSelectedFrame(data.frames[0], dispatch);
    }
    dispatch({ type: viewActionTypes.FETCH_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_VIEW_FAILURE, payload: error.message });
  }
};

export const fetchListData = async (frame, limit, dispatch, props={}) => {
  dispatch({ type: viewActionTypes.FETCH_DATA_REQUEST });

  try {
    const api = getApiByScope(frame.scope);
    const data = await api.list(frame._id, 0, limit, props)
    dispatch({ type: viewActionTypes.FETCH_DATA_SUCCESS, payload: {frameId: frame._id, data} });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_DATA_FAILURE, payload: error.message });
  }
}

export const fetchListFlatData = async (frame, limit, dispatch) => {
  dispatch({ type: viewActionTypes.FETCH_DATA_REQUEST });

  try {
    const api = getApiByScope(frame.scope);
    const data = await api.listflat(frame._id, 0, limit)
    dispatch({ type: viewActionTypes.FETCH_DATA_SUCCESS, payload: {frameId: frame._id, data} });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_DATA_FAILURE, payload: error.message });
  }
}

export const fetchShow = async (scope, dataCode, dispatch) => {
  dispatch({ type: viewActionTypes.FETCH_SHOW_REQUEST });

  try {
    const api = getApiByScope(scope);
    const data = await api.read(dataCode);
    dispatch({ type: viewActionTypes.FETCH_SHOW_SUCCESS, payload: {frameId: dataCode, data} });
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_SHOW_FAILURE, payload: error.message });
  }
};

export const editView = async(view, dispatch) => {
  dispatch({ type: viewActionTypes.UPDATE_VIEW_REQUEST});

  try{
    await View.update(view)
    dispatch({ type: viewActionTypes.UPDATE_VIEW_SUCCESS, payload: view });
  } catch(error) {
    dispatch({ type: viewActionTypes.UPDATE_VIEW_FAILURE, payload: error.message });
  }
}

export const setSelectedFrame = (frame, dispatch) => {
  dispatch({ type: viewActionTypes.SET_SELECTED_FRAME, payload: frame });
}

export const patchDossier = async (scope, dataCode, action, dataToUpdate, dispatch) => {

  dispatch({ type: viewActionTypes.FETCH_PATCH_REQUEST });

  try {
    const api = getApiByScope(scope);

    const response = await api.modify(dataCode, action);

    console.log(response);
    if (response.status === 200) {
      dispatch({ type: viewActionTypes.FETCH_PATCH_SUCCESS, payload: dataToUpdate });
    } else {
      dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: "Error: Respuesta inesperada del servidor." });
    }
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: error.message });
  }
};

export const crupdateData = async (scope, dataToUpdate, dispatch) => {

  dispatch({ type: viewActionTypes.FETCH_PATCH_REQUEST });

  try {
    const api = getApiByScope(scope);

    const response = await api.crupdate(dataToUpdate);

    if (response.status === 200) {
      dispatch({ type: viewActionTypes.FETCH_PATCH_SUCCESS, payload: dataToUpdate });
    } else {
      dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: "Error: Respuesta inesperada del servidor." });
    }
  } catch (error) {
    dispatch({ type: viewActionTypes.FETCH_PATCH_FAILURE, payload: error.message });
  }
};

const getApiByScope = (scope) => {
  switch (scope) {
    case 'dossier': return Dossier;
    case 'cartridge': return Cartridge;
    case 'check': return Check;
    case 'error': return Error;
    case 'event': return Event;
    case 'flow': return Flow;
    case 'movement': return Movement;
    case 'template': return Template;
    case 'doc': return Doc;
    case 'resume': return Resume;
    case 'user': return User;
    case "view": return View;
    default: return true;
  }
}
