import { useCallback } from 'react';

const useFormatters = () => {

    const formatDaysAgo = useCallback((dateString) => {
        const now = new Date();
        const date = new Date(dateString);
        const diffTime = Math.abs(now - date);

        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        const years = Math.floor(diffDays / 365);
        const months = Math.floor((diffDays % 365) / 30);
        const days = diffDays % 30;

        const yearStr = years === 1 ? "año" : "años";
        const monthStr = months === 1 ? "mes" : "meses";
        const dayStr = days === 1 ? "día" : "días";
        const hourStr = diffHours === 1 ? "hora" : "horas";
        const minuteStr = diffMinutes === 1 ? "minuto" : "minutos";

        if (years > 0) {
            return `Hace más de ${years} ${yearStr}`;
        } else if (months > 0) {
            return `Hace ${months} ${monthStr} y ${days} ${dayStr}`;
        } else if (days > 0) {
            return `Hace ${days} ${dayStr}`;
        } else if (diffHours > 0) {
            return `Hace ${diffHours} ${hourStr}`;
        } else if (diffMinutes > 0) {
            return `Hace ${diffMinutes} ${minuteStr}`;
        } else {
            return "Hace menos de un minuto";
        }
    }, []);

    const formatDate = useCallback((date, options = {}) => {
        const defaultOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
        return new Date(date).toLocaleDateString('es-ES', { ...defaultOptions, ...options });
    }, []);

    const formatCurrency = useCallback((amount, currency = 'EUR') => {
        const number = parseFloat(amount) || 0;
        return new Intl.NumberFormat('es-ES', { style: 'currency', currency }).format(number);
    }, []);

    const capitalizeFirstLetter = useCallback((str) => {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }, []);

    return { formatDate, formatCurrency, capitalizeFirstLetter, formatDaysAgo };
};

export default useFormatters;
