import React, { createContext, useState, useContext, useEffect } from "react";
import { Setup } from "src/lib/api";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import PropTypes from 'prop-types'; // Añadir PropTypes

const DragDropContext = createContext();

export function useDragDrop() {
    return useContext(DragDropContext);
}

// En este context de gestión del kanBan de menus se van a tratar los menus como dos tipos: columnas e items
// Las columnas son: lo que entendemos como menus padres (pathmenu: /admin) 
// Los items son: menus hijos (pathmenu: /admin/hijo)

export const DragDropProvider = ({ children }) => {
    const { refreshSetup } = useAppContext();
    const initialColumnData =
    {
        "id": "example",
        "title": "example",
        "pathmenu": "/example",
        "roles": [
            "superadmin",
            "robot",
            "hyperuser",
            "admin",
            "user",
            "developer",
            "guest"
        ],
        "url": "/example",
        "active": true,
    };
    const [dataReady, setDataReady] = useState(false);
    const [initialData, setInitialData] = useState();
    const [originalSingleColumn, setOriginalSingleColumn] = useState();
    const [singleColumn, setSingleColumn] = useState(initialColumnData);
    const [showColumnEdit, setShowColumnEdit] = useState(false);
    const [originalSingleItem, setOriginalSingleItem] = useState();
    const [singleItem, setSingleItem] = useState();
    const [showItemEdit, setShowItemEdit] = useState(false);
    const [columns, setColumns] = useState({});
    const [draggedOver, setDraggedOver] = useState({ column: null, index: null, isOverItem: false, showPlaceholder: false });

    useEffect(() => {
        cargarDatos();
    }, []);

    // Effect que escucha initialData para ejecutar el fetch después de modificar los datos
    useEffect(() => {
        if (dataReady) {
            guardarCambios();
        }
    }, [initialData]);



    // Fetch de datos
    const cargarDatos = async () => {
        try {
            const res = await Setup.read();
            // Clasifica los elementos en las columnas adecuadas y genera las columnas dinámicamente
            const columnasDinamicas = generarColumnasDinamicas(res.menu);
            clasificarElementos(res.menu, columnasDinamicas);
            setInitialData(res); // Crea una copia de seguridad en el State
        } catch (err) {
            console.error("Error al cargar datos:", err);
        }
    };

    // Función que genera las columnas
    const generarColumnasDinamicas = (data) => {
        const columnas = {};

        data.forEach(item => {
            const parts = item.pathmenu.split("/").filter(part => part);
            if (parts.length === 1) {
                const columna = parts[0];
                columnas[columna] = []; // Inicializa la columna si aún no existe
            }
        });

        return columnas;
    };

    // Función que clasifica los objetos de menu dentro de las columnas
    const clasificarElementos = (data, columnasDinamicas) => {
        const newColumns = { ...columnasDinamicas };

        data.forEach(item => {
            const parts = item.pathmenu.split("/").filter(part => part);
            if (parts.length === 2) {
                const group = parts[0];
                if (Object.prototype.hasOwnProperty.call(newColumns, group)) {
                    newColumns[group].push(item);
                }
            }
        });

        setColumns(newColumns);
    };

    // Función que añade nuevas columnas
    const addNewColumn = () => {
        const nuevaColumnaKey = singleColumn.pathmenu.split('/')[1]; // Extrae el nombre de la columna de 'pathmenu'

        // Añade la nueva columna al estado 'columns' si no existe
        setColumns(prevColumns => {
            if (!prevColumns[nuevaColumnaKey]) {
                return {
                    ...prevColumns,
                    [nuevaColumnaKey]: []
                };
            }
            return prevColumns;
        });

        // Añade el 'singleColumn' a 'initialData.menu'
        setInitialData(prevData => {
            // Verifica si el elemento ya existe para evitar duplicados
            const existe = prevData.menu.some(item => item.id === singleColumn.id);
            if (!existe) {
                return {
                    ...prevData,
                    menu: [...prevData.menu, singleColumn]
                };
            }
            return prevData;
        });
    };

    // Fuunción que resetea singleColumn
    const resetSingleColumn = () => {
        setSingleColumn(initialColumnData)
    }

    const actualizarPathmenu = () => {
        // Separar los ítems en "padres" e "hijos"
        const itemsPadres = initialData.menu.filter(item => item.pathmenu.split("/").filter(part => part).length === 1);
        let itemsHijos = initialData.menu.filter(item => item.pathmenu.split("/").filter(part => part).length === 2);

        // Actualizar los pathmenus de los ítems "hijos" basado en el estado actual de las columnas
        itemsHijos = itemsHijos.map(hijo => {
            // Buscar a qué columna pertenece el hijo
            const columnaPadre = Object.keys(columns).find(columna =>
                columns[columna].some(itemColumna => itemColumna.id === hijo.id)
            );

            // Si se encuentra el hijo en una columna, actualizar su pathmenu
            if (columnaPadre) {
                const nuevoPathmenu = `/${columnaPadre}/${hijo.id}`;
                return { ...hijo, pathmenu: nuevoPathmenu };
            }

            // Si el hijo no se movió de columna, mantenerlo tal como está
            return hijo;
        });

        // Reconstruir el menú final combinando los ítems "padres" con los "hijos" actualizados
        const menuActualizado = [...itemsPadres, ...itemsHijos];

        // Actualizar el estado `initialData` con el nuevo menú
        setInitialData(prevData => ({
            ...prevData,
            menu: menuActualizado,
        }));

        setDataReady(true);
    };

    // Fetch que manda los datos nuevos del Setup a la DB
    const guardarCambios = async () => {
        try {
            console.log("CONTEXT",initialData);
            const response = await Setup.write(initialData);
            if (response.error) {
                throw new Error(`Error al actualizar el setup: ${response.error}: ${response.message}`);
            }
            console.log('Datos actualizados con éxito en la API:', response);
        } catch (error) {
            console.error('Error al guardar cambios:', error.message);
        } finally {
            setDataReady(false); // Resetea dataReady para futuras operaciones
            cargarDatos();
            refreshSetup();
        }
    };

    const guardarCambiosColumna = () => {
        setInitialData(prevData => {
            const newMenu = [...prevData.menu];
            // Encontrar y actualizar el elemento "padre"
            const menuIndex = newMenu.findIndex(item => item.id === originalSingleColumn.id);
            if (menuIndex !== -1) {
                newMenu[menuIndex] = { ...newMenu[menuIndex], ...singleColumn };
            }
            // Preparar el antiguo y el nuevo pathmenu para la comparación y actualización
            const oldPathmenu = originalSingleColumn.pathmenu;
            const newPathmenu = singleColumn.pathmenu;
            // Actualizar el pathmenu de todos los ítems "hijos"
            newMenu.forEach((item, index) => {
                // Verificar si el item actual es un "hijo" basándose en el pathmenu
                if (item.pathmenu.startsWith(oldPathmenu + "/")) {
                    // Construir el nuevo pathmenu del "hijo" reemplazando el antiguo pathmenu del "padre"
                    const updatedPathmenu = item.pathmenu.replace(oldPathmenu, newPathmenu);
                    // Actualizar el ítem "hijo" en el menu
                    newMenu[index] = { ...item, pathmenu: updatedPathmenu };
                }
            });

            return {
                ...prevData,
                menu: newMenu,
            };
        });
        setShowColumnEdit(false);
        setSingleColumn(initialColumnData);
        setOriginalSingleColumn(null);
        setDataReady(true);
    };

    const addItemToColumn = (columnName) => {
        const newItem = {
            id: `newItem`,
            title: "Nuevo Ítem",
            pathmenu: `/${columnName}/newItem`,
            roles: [],
            url: "/",
            active: true,
        };

        // Añadir el nuevo ítem a la columna correspondiente en el estado 'columns'
        const updatedColumns = { ...columns, [columnName]: [...(columns[columnName] || []), newItem] };
        setColumns(updatedColumns);

        // Añade el nuevo ítem a initialData para que pueda ser editado
        setInitialData(prevData => ({
            ...prevData,
            menu: [...prevData.menu, newItem],
        }));
    };

    const guardarCambiosItem = () => {
        setInitialData((prevData) => {
            // Encuentra el índice del item original en el array de menu
            const itemIndex = prevData.menu.findIndex(item => item.id === originalSingleItem.id);
            if (itemIndex !== -1) {
                const newMenu = [...prevData.menu];
                // Reemplaza el item original con el nuevo item modificado
                newMenu[itemIndex] = singleItem;
                return {
                    ...prevData,
                    menu: newMenu,
                };
            }
            // Si el item no se encuentra, simplemente retorna el estado anterior sin cambios
            return prevData;
        });

        // Resetear el estado de edición
        setShowItemEdit(false);
        setSingleItem(initialColumnData);
        setOriginalSingleItem(null);
        setDataReady(true);
    };

    const actualizarMenuConJerarquia = () => {
        let menuActualizado = [];

        // Primero, agregamos todos los ítems "padre" directamente al nuevo menú.
        const itemsPadres = initialData.menu.filter(item => item.pathmenu.split("/").filter(part => part).length === 1);
        menuActualizado.push(...itemsPadres);

        // Para cada columna (padre), agregamos sus hijos actualizando su pathmenu.
        itemsPadres.forEach(padre => {
            const columnaPadre = padre.pathmenu.split('/')[1];
            const hijos = columns[columnaPadre];

            if (hijos) {
                hijos.forEach(hijo => {
                    // Actualizamos el pathmenu del hijo para reflejar su nuevo padre (columna)
                    const nuevoHijo = { ...hijo, pathmenu: `/${columnaPadre}/${hijo.id}` };
                    menuActualizado.push(nuevoHijo);
                });
            }
        });

        // Finalmente, actualizamos initialData con el nuevo menú.
        setInitialData(prevData => ({
            ...prevData,
            menu: menuActualizado,
        }));

        setDataReady(true);
    };

    const reorderColumns = (sourceColumnName, targetColumnName) => {
        // Reordenar initialData
        const newMenuOrder = [...initialData.menu];
        const sourceIndex = newMenuOrder.findIndex(column => column.id === sourceColumnName);
        const targetIndex = newMenuOrder.findIndex(column => column.id === targetColumnName);

        if (sourceIndex < 0 || targetIndex < 0) {
            console.log("Índices inválidos para el reordenamiento. Operación cancelada.");
            return; // Verificar índices válidos
        }

        const [removed] = newMenuOrder.splice(sourceIndex, 1);
        newMenuOrder.splice(targetIndex, 0, removed);

        setInitialData(prevData => ({
            ...prevData,
            menu: newMenuOrder,
        }));

        // Reordenar columns
        const columnsKeys = Object.keys(columns);
        const columnsValues = Object.values(columns);
        const newColumns = {};

        // Encuentra los índices según el nombre de las columnas en el orden actual
        const sourceColumnIndex = columnsKeys.findIndex(key => key === sourceColumnName);
        const targetColumnIndex = columnsKeys.findIndex(key => key === targetColumnName);

        if (sourceColumnIndex < 0 || targetColumnIndex < 0) {
            console.log("Índices inválidos para el reordenamiento de columns. Operación cancelada.");
            return; // Verificar índices válidos
        }

        // Crea una copia de las keys y values para manipularlas
        const keysCopy = [...columnsKeys];
        const valuesCopy = [...columnsValues];

        // Reordena las keys y values
        keysCopy.splice(targetColumnIndex, 0, keysCopy.splice(sourceColumnIndex, 1)[0]);
        valuesCopy.splice(targetColumnIndex, 0, valuesCopy.splice(sourceColumnIndex, 1)[0]);

        // Reconstruye el objeto columns con el nuevo orden
        keysCopy.forEach((key, index) => {
            newColumns[key] = valuesCopy[index];
        });

        // Actualiza el estado con las nuevas columnas
        setColumns(newColumns);
    };

    const deleteColumnAndItems = (columnName) => {
        // Eliminar columna de 'columns'
        const updatedColumns = { ...columns };
        delete updatedColumns[columnName];
        setColumns(updatedColumns);

        // Filtrar 'initialData.menu' para eliminar ítems de la columna borrada
        const updatedMenu = initialData.menu.filter(item => !item.pathmenu.startsWith(`/${columnName}`));
        setInitialData(prevData => ({
            ...prevData,
            menu: updatedMenu,
        }));
    };

    const deleteItem = (itemId, originColumn) => {
        // Eliminar ítem de 'columns'
        const updatedColumns = { ...columns };
        updatedColumns[originColumn] = updatedColumns[originColumn].filter(item => item.id !== itemId);
        setColumns(updatedColumns);

        // Filtrar 'initialData.menu' para eliminar el ítem específico
        const updatedMenu = initialData.menu.filter(item => item.id !== itemId);
        setInitialData(prevData => ({
            ...prevData,
            menu: updatedMenu,
        }));
    };


    // ############################## Funciones para el manejo de DnD ############################## //
    const handleDragStart = (e, itemId, originColumn) => {
        const target = e.target;
        e.dataTransfer.setData("application/drag-item", itemId); // Identificador para arrastrar ítems
        e.dataTransfer.setData("application/reactflow", itemId);
        e.dataTransfer.setData("application/reactflow-column", originColumn);
        e.target.classList.add("dragging-item");
        const clone = target.cloneNode(true);
        clone.style.position = "fixed";
        clone.style.left = `${e.clientX}px`;
        clone.style.top = `${e.clientY}px`;
        clone.style.opacity = "1";
        clone.style.pointerEvents = "none";
        clone.id = "drag-clone";
        document.body.appendChild(clone);
        e.target.style.opacity = "0";
        e.dataTransfer.setDragImage(new Image(), 0, 0); // Evita la vista previa por defecto
    };

    const handleItemDragOver = (e, column, index) => {
        e.preventDefault();
        e.stopPropagation();
        setDraggedOver({
            column: column,
            index: index,
            isOverItem: true,
            showPlaceholder: false,
        });

        // Actualizar la posición del clon
        const clone = document.getElementById("drag-clone");
        if (clone) {
            clone.style.left = `${e.clientX}px`;
            clone.style.top = `${e.clientY}px`;
        }
    };

    const handleColumnDragOver = (e, targetColumn) => {
        e.preventDefault();

        const targetIndex = columns[targetColumn] ? (columns[targetColumn].length > 0 ? columns[targetColumn].length - 1 : 0) : 0;

        // Asegura que el estado se actualiza para reflejar que el drag está sobre la columna
        setDraggedOver({
            column: targetColumn,
            index: targetIndex, // Actualiza para colocar al final de la columna o al inicio si está vacía
            isOverItem: false,
            showPlaceholder: true,
        });

        // Continúa con la actualización del clon si lo necesitas
        const clone = document.getElementById("drag-clone");
        if (clone) {
            clone.style.left = `${e.clientX}px`;
            clone.style.top = `${e.clientY}px`;
        }
    };

    const handleDrop = (e, targetColumnName) => {
        e.preventDefault();
        const itemId = e.dataTransfer.getData("application/drag-item");
        const columnName = e.dataTransfer.getData("application/drag-column");
        if (itemId) {
            // Obtiene el ID del ítem arrastrado desde el evento.
            const itemId = e.dataTransfer.getData("application/reactflow");
            console.log(`Soltando ítem: ${itemId} en la columna: ${targetColumnName} en el índice: ${draggedOver.index}`);
            // Elimina el clon si existe.
            const clone = document.getElementById("drag-clone");
            if (clone) {
                document.body.removeChild(clone);
                console.log("Clon eliminado del DOM.");
            } else {
                console.log("No se encontró el clon para eliminar.");
            }

            // Encuentra el ítem y su columna original.
            const originalColumn = Object.keys(columns).find(column => columns[column].some(item => item.id === itemId));
            const itemMoved = columns[originalColumn]?.find(item => item.id === itemId);

            if (!itemMoved) {
                console.error("Ítem a mover no encontrado.");
                return;
            }
            // Prepara una nueva estructura de datos para evitar mutaciones directas del estado.
            let newColumns = JSON.parse(JSON.stringify(columns));

            // Elimina el ítem de su posición original y lo añade a la nueva posición/columna.
            newColumns[originalColumn] = newColumns[originalColumn].filter(item => item.id !== itemId);

            // Reordenamiento dentro de la misma columna o movimiento a una nueva columna.
            if (originalColumn === targetColumnName) {
                if (draggedOver.isOverItem && draggedOver.index !== null) {
                    newColumns[targetColumnName].splice(draggedOver.index, 0, itemMoved);
                } else {
                    newColumns[targetColumnName].push(itemMoved);
                }
            } else {
                const insertIndex = draggedOver.index !== null ? draggedOver.index : newColumns[targetColumnName].length;
                newColumns[targetColumnName].splice(insertIndex, 0, itemMoved);
            }
            // Actualiza el estado con las nuevas columnas.
            setColumns(newColumns);

        } else if (columnName) {
            // Solo procede si el nombre de la columna fuente y destino son diferentes
            if (columnName !== targetColumnName) {
                console.log(`Reordenando columnas: de '${columnName}' a '${targetColumnName}'`);
                reorderColumns(columnName, targetColumnName);
            } else {
                console.log("Intento de reordenar la misma columna. No se hace nada.");
            }
        }
        // Restablece el estado draggedOver para la próxima operación de arrastrar y soltar.
        setDraggedOver({ column: null, index: null, isOverItem: false, showPlaceholder: false });
    };

    const handleColumnDragStart = (e, columnName) => {
        e.dataTransfer.setData("application/drag-column", columnName);
        e.dataTransfer.effectAllowed = "move";
    };

    const handleDragEnd = (e) => {
        const clone = document.getElementById("drag-clone");
        e.target.classList.remove("dragging-item");
        if (clone) {
            document.body.removeChild(clone);
        }
        e.target.style.opacity = "1";
    };

    const value = {
        columns,
        singleItem,
        singleColumn,
        originalSingleColumn,
        showColumnEdit,
        showItemEdit,
        initialData,
        initialColumnData,
        originalSingleItem,
        draggedOver,
        handleDragEnd,
        handleDragStart,
        handleDrop,
        guardarCambios,
        actualizarPathmenu,
        addNewColumn,
        resetSingleColumn,
        setSingleItem,
        setSingleColumn,
        setOriginalSingleColumn,
        guardarCambiosColumna,
        setShowColumnEdit,
        setShowItemEdit,
        setOriginalSingleItem,
        guardarCambiosItem,
        handleItemDragOver,
        handleColumnDragOver,
        setDraggedOver,
        actualizarMenuConJerarquia,
        addItemToColumn,
        handleColumnDragStart,
        deleteColumnAndItems,
        deleteItem
    };

    return <DragDropContext.Provider value={value}>{children}</DragDropContext.Provider>;
};

DragDropProvider.propTypes = {
  children: PropTypes.node.isRequired,
};