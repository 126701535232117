/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Assistant {
    static async list() {
        const response = await HttpMethods.request_get("/v1/assistant/list");
        if (!response) alert("Error al obtener la lista de asistentes");
        return response;
    }
    static async sendEmail(assitantName, to, subject) {
        const data = { assitantName, to, subject }
        const response = await HttpMethods.request_post("/v1/assitant/sendemail", data);
        if (!response) alert("Error al enviar el email");
        return response;
    }
    static async listEmail(assitantName) {
        const response = await HttpMethods.request_get("/v1/assitant/listemail" + assitantName);
        if (!response) alert("Error al enviar un email a un usuario usando un asistente");
        return response;
    }

    static async readEmail(assitantName, messageId) {
        const response = await HttpMethods.request_get("/v1/assitant/reademail" + assitantName + "/" + messageId);
        if (!response) alert("Error al enviar un email a un usuario usando un asistente");
        return response;
    }

}
