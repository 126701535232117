import React, { useEffect, useState } from "react";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Test from "src/routes/Checks/Test/Test";
import { Check as CheckAPI, Filetype } from "src/lib/api";
import Title from "src/modules/components/common/Title/Title";
import PropTypes from "prop-types";
import "../Checks.css";
import useFormatters from "src/modules/hooks/useFormatters";
import { Editor } from "@monaco-editor/react";
import Select from "src/modules/components/common/Select/Select";

const TestCheck = ({ check }) => {
  const [scopecode, setScopecode] = useState(null);
  const [result, setResult] = useState({});
  const [passedTests, setPassedTests] = useState([]);
  const [notPassedTests, setNotPassedTests] = useState([]);
  const [usedVariables, setUsedVariables] = useState([]);
  const [testLogic, setTestLogic] = useState(check.logic);
  const [filetype, setFiletype] = useState(null)
  const [filetypeOptions, setFiletypeOptions] = useState([])
  const formatter = useFormatters();
  const testCheck = async () => {
    if (!scopecode) return alert("Introduzca un scopeCode");
    const testCheck = { ...check, logic: testLogic };
    const responseData = await CheckAPI.test(scopecode, testCheck);
    setResult(responseData.result);
    // Actualizar la segunda columna con los datos recibidos
    setPassedTests(
      responseData.result.tests.filter((test) => test.result === true)
    );
    setNotPassedTests(
      responseData.result.tests.filter((test) => {
        if (test.result === false || test.error) {
          return test;
        }
        return null;
      })
    );
    setUsedVariables(responseData.result.vars.map((variable) => variable.name));
  };

  useEffect(() => {
    const fetch = async() => {
      const result = await Filetype.list();
      const filetypes = result.map((filetype) => ({value: filetype.header.name, label: filetype.header.name}))
      setFiletypeOptions(filetypes)
    }
    setResult({});
    setPassedTests([]);
    setNotPassedTests([]);
    setUsedVariables([]);
    fetch()
  }, []);

  const calculateTotalDelay = (tests) => {
    let totalDelay = 0;

    for (const test of tests) {
      totalDelay += test.delay;
    }

    return totalDelay;
  };

  const onChangeMonaco = (value) => {
    setTestLogic(value);
  };

  useEffect(() => {
    setTestLogic(check.logic);
  }, [check]);

  return (
    <div className="group-container">
      <div>
        <Title text="Test" />
        <div className="actions-buttons">
          <Input
            name="scopecode"
            type="text"
            placeholder="ScopeCode"
            value={scopecode}
            onChange={(e) => setScopecode(e.target.value)}
            label="Código de Item"
            activeLabel={true}
          />
          <Select
            isSearchable={true}
            isClearable={true}
            name="selectActionType"
            options={filetypeOptions}
            placeholder="Type"
            onChange={(data) => setFiletype(data)}
            label="Tipo"
            value={filetype}
          />
        </div>
        <br></br>
        <div className="actions-buttons">

        <Editor
          defaultLanguage="javascript"
          value={testLogic}
          onChange={onChangeMonaco}
          height={150}
          theme="vs-dark"
        />
          <Button
            onClick={testCheck}
            icon="science"
            disabled={!scopecode}
          />
        </div>
        {result.name && (
          <div style={{ paddingTop: "20px" }}>
            <Title text="Resultados:" />
            <span>
              <strong>Name:</strong> {result.name}
            </span>
            <span>
              <strong>Check Code:</strong> {result.testcode}
            </span>
            <span>
              <strong>Delay:</strong>{" "}
              {formatter.roundToDecimals(result.delay, 3)}ms
            </span>
            <span>
              <strong>Total tests:</strong> {result.tests.length}
            </span>
            <span>
              <strong>Structure:</strong> {JSON.stringify(result.structure)}
            </span>
            <ul
              style={{
                listStyle: "inside",
                margin: "0",
                padding: "0",
                color: "white",
              }}>
              <span>
                <strong>Variables usadas:</strong>
              </span>{" "}
              {usedVariables.map((variable, index) => (
                <li
                  style={{ marginLeft: "20px" }}
                  key={index}>
                  {variable}
                </li>
              ))}
            </ul>
            <div className="test-section actions-buttons-container">
              <div className="columnCheck">
                <h3 style={{ color: "white" }}>
                  Cumplen la comprobación: {passedTests.length}
                </h3>
                {passedTests.length > 0 && (
                  <>
                    <div style={{ color: "white" }}>
                      Delay:{" "}
                      {formatter.roundToDecimals(
                        calculateTotalDelay(passedTests),
                        3
                      )}
                      ms
                    </div>
                    <br />
                    {passedTests.map((test, index) => (
                      <Test
                        element={test}
                        key={index}
                      />
                    ))}
                  </>
                )}
              </div>
              <div className="columnCheck">
                <h3 style={{ color: "white" }}>
                  No cumplen la comprobación: {notPassedTests.length}
                </h3>
                {notPassedTests.length > 0 && (
                  <>
                    <div style={{ color: "white" }}>
                      Delay:{" "}
                      {formatter.roundToDecimals(
                        calculateTotalDelay(notPassedTests),
                        3
                      )}
                      ms
                    </div>
                    <br />
                    {notPassedTests.map((test, index) => (
                      <Test
                        element={test}
                        key={index}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TestCheck.propTypes = {
  check: PropTypes.shape({
    name: PropTypes.string,
    logic: PropTypes.string,
  }),
};

export default TestCheck;
