import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Conductor as ConductorApi } from "src/lib/api";
import "./ActionPage.css";
import LoadingSpinner  from "src/modules/components/common/LoadingSpinner/LoadingSpinner";

// path '/action/:actionname?key=value&key=value&key=value&key=value'
// ejemplo: http://localhost:3000/action/testingaction?uno=1&dos=2&tres=3&cuatro=4

function ActionPage() {
  const { actionName } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const path = `${actionName}${location.search}`;
    setLoading(true);

    ConductorApi.get(path)
    // .then((response) => response.text()) // Si es HTML
      .then((html) => {
        setResponse(html);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [actionName, location]);

  return (
    <div className="action-page-container">
      <h1>Action Page</h1>
      {loading ? (
        <div className="loading-container">
          <p>Cargando </p><LoadingSpinner /> 
        </div>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: response }} />
      )}
    </div>
  );
}

export default ActionPage;
