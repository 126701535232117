export function useInternal() {
  
  const read = (key) => {
    return window.sessionStorage.getItem(key);
  };

  const write = (key, value) => {
    if (typeof value === "object") {
      value = JSON.stringify(value);
    } else value = value.toString();
    window.sessionStorage.setItem(key, value);
  };

  return {read, write};
}
