import React, { createContext, useReducer } from "react";
import PropTypes from 'prop-types';
import { viewReducer } from "../reducers/viewReducer";

const initialState = {
    view: {},
    data: {},
    selectedFrame: undefined,
    loading: false,
    error: null
};

export const ViewContext = createContext(initialState);

export const ViewContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(viewReducer, initialState);

    return (
        <ViewContext.Provider value={{ state, dispatch }}>
            {children}
        </ViewContext.Provider>
    );
};

ViewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
