import React from "react";
import { useDragDrop } from "./KanbanContext";
import "./EditColumn.css";
import Input from "src/modules/components/common/Input/Input";

export default function EditColumn() {
  const {
    singleColumn,
    setSingleColumn,
    guardarCambiosColumna,
  } = useDragDrop();

  // control de inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Para manejar diferentes tipos de inputs, como texto y checkbox
    const inputValue = type === "checkbox" ? checked : value;

    // Actualiza singleColumn con el nuevo valor para el campo correspondiente
    setSingleColumn((prevColumn) => ({
      ...prevColumn,
      [name]: inputValue,
    }));
  };

  return (
    <div className="edit-column">
      <div className="inputs-edit-column">
      <button className="inputs-edit-column-buttons" onClick={guardarCambiosColumna}><i className="fa-solid fa-xmark"></i></button>
        <Input
          name="title"
          type="text"
          value={singleColumn.title}
          onChange={handleInputChange}
          label="Titulo"
          activeLabel={true}
          placeholder="Titulo"
        />
        <Input
          name="pathmenu"
          type="text"
          value={singleColumn.pathmenu}
          onChange={handleInputChange}
          label="Path Menu"
          activeLabel={true}
          placeholder="Path Menu"
        />
        <Input
          name="id"
          type="text"
          value={singleColumn.id}
          onChange={handleInputChange}
          label="ID"
          activeLabel={true}
          placeholder="ID"
        />
        <Input
          name="url"
          type="text"
          value={singleColumn.url}
          onChange={handleInputChange}
          label="URL"
          activeLabel={true}
          placeholder="URL"
        />
        <Input
          name="out"
          type="checkbox"
          value={singleColumn.out}
          onChange={handleInputChange}
          label="Out Window"
          activeLabel={true}
        />
        <Input
          name="roles"
          type="text"
          value={singleColumn.roles}
          onChange={handleInputChange}
          label="Roles"
          activeLabel={true}
          placeholder="Roles"
        />
        <Input
          name="active"
          type="checkbox"
          value={singleColumn.active}
          onChange={handleInputChange}
          label="Activo"
          activeLabel={true}
        />

      </div>
    </div>
  );
}
