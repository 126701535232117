import React from "react";
import PropTypes from 'prop-types'; 
import { default as ReactSelect } from "react-select";
import "../Input/Input.css";

const Select = ({
  isMulti,
  isClearable = true,
  isSearchable = true,
  onChange,
  name,
  options, 
  placeholder,
  value,
  height,
  ...props
}) => {
  const styles = {
    container: (base) => ({
      ...base,
      flex: "1 1 343px",
      "@media (max-width: 768px)": {
        flex: "none",
        width: "-webkit-fill-available",
      },
    }),
    control: (base, state) => ({
      ...base,
      "--placeholderTextColor": "rgba(255, 255, 255, 0.7)",
      alignSelf: "center",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      borderWidth: state.isFocused ? "2px" : "0.5px",
      color: "rgb(255, 255, 255)",
      height: height ?? "44px",
      paddingLeft: "12px",
      width: "100%",
      fontFamily: "Ping LCG Regular",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "16px",
      letterSpacing: "0px",
      minHeight: 0,
      borderColor: state.isFocused
        ? "rgb(0, 0, 0)"
        : "rgba(255, 255, 255, 0.4)",
      boxShadow: state.isFocused
        ? "0px 0px 0px 1px rgb(255, 255, 255)"
        : "none",
      "&:hover": {
        borderColor: state.isFocused
          ? "rgb(0, 0, 0)"
          : "rgba(255, 255, 255, 0.4)",
        cursor: "pointer",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "rgba(0, 0, 0, 0.2)" : null,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    }),
    menu: (base) => ({
      ...base,
      "--placeholderTextColor": "rgba(255, 255, 255, 0.7)",
      alignSelf: "center",
      backgroundColor: "rgb(105, 105, 105)",
      borderRadius: "4px",
      borderWidth: "0.5px",
      color: "rgb(255, 255, 255)",
      fontFamily: "Ping LCG Regular",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "14px",
      letterSpacing: "0px",
      borderColor: "rgba(255, 255, 255, 0.4)",
    }),
    input: (base) => ({
      ...base,
      color: "rgb(255, 255, 255)",
      fontFamily: "Ping LCG Regular",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px",
      "&:hover": {
        cursor: "text",
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "-webkit-fill-available",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "rgb(255, 255, 255)",
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "white",
    }),
    multiValueRemove: (base) => ({
      ...base,
      "&:hover": {
        color: "white",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        cursor: "pointer",
      },
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 11,
    }),
  };

  return (
    <>
      <div className="input-container">
        {props.label && <label className="input-label">{props.label}</label>}
        <ReactSelect
          isClearable={isClearable}
          isSearchable={isSearchable}
          name={name}
          options={options}
          onChange={onChange}
          placeholder={placeholder || "Select"}
          value={value}
          styles={styles}
          isMulti={isMulti || false}
          menuPortalTarget={document.body}
          menuPlacement="bottom"
        />
      </div>
    </>
  );
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  height: PropTypes.string,
  label: PropTypes.string, 
};

export function onSelectChangeSingle(event, setValues, property) {
  const values = event?.value;
  setValues((prev) => ({
    ...prev,
    [property]: values,
  }));
}

export function onSelectChangeMulti(event, setValues, property) {
  const values = event.map((item) => item.value);
  setValues((prev) => ({
    ...prev,
    [property]: values,
  }));
}

export default Select;
