//Logica
import userActivityActionTypes from "../actionTypes/userActivityActionTypes";
import { Activity as ActivityAPI } from 'src/lib/api';

function getCurrentTime() {
    const currentDate = new Date();
    const hours = currentDate.getHours().toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
}

function secondsToHoursAndMins(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const hoursFormated = hours.toString().padStart(2, '0');
    const minutesFormated = minutes.toString().padStart(2, '0');

    return `${hoursFormated}h ${minutesFormated}min`;
}
export const setCurrentTime = (dispatch) => {
    const currentTime = getCurrentTime()
    dispatch({
        type: userActivityActionTypes.SET_CURRENT_TIME,
        payload: currentTime
    })

}

export const fetchActivity = async (dispatch) => {
    const result = await ActivityAPI.read()
    const lastActionObject = result.actions.pop()
    if (!lastActionObject) return
    const lastAction = lastActionObject.action
    const duration = result.duration //seconds
    const formatedDuration = secondsToHoursAndMins(duration)
    dispatch({
        type: userActivityActionTypes.FETCH_LAST_ACTION,
        payload: lastAction
    })
    //TODO:The duration must be updated as soon as any button is clicked 
    dispatch({
        type: userActivityActionTypes.SET_DURATION,
        payload: formatedDuration
    })
}

export const fetchAction = async (action, dispatch) => {
    try {
        await ActivityAPI.action(action)
        dispatch({
            type: userActivityActionTypes.FETCH_LAST_ACTION,
            payload: action
        })
    } catch (error) {
        dispatch({
            type: userActivityActionTypes.FETCH_ACTION_ERROR,
            payload: error
        })
    }

}
