import React, { useEffect, useState } from "react";
import "./Status.css";
import StatusGeneralInfo from "./StatusGeneralInfo";
import StatusRunning from "./StatusRunning";
import StatusGraphic from "./StatusGraphic";
import { Info } from "../../lib/api/Info";
const Status = () => {
  const [services, setServices] = useState([]);
  const [, serviceInfo] = useState({});

  useEffect(() => {
    function parseInpuInfo(inputObj) {
      const servicios = [];
      const generalInfo = {};
      for (const key in inputObj) {
        if (
          typeof inputObj[key] === "object" &&
          !Array.isArray(inputObj[key]) &&
          inputObj[key] !== null
        ) {
          const obj = inputObj[key];
          obj["name"] = key;
          servicios.push(obj);
        } else {
          generalInfo[key] = inputObj[key];
        }
      }
      return { servicios, generalInfo };
    }
    function fetch() {
      Info.status().then((response) => {
        const { servicios, generalInfo } = parseInpuInfo(response.status);
        setServices(servicios);
        serviceInfo(generalInfo);
      });
    }
    fetch();
  }, []);

  return (
    <div className="status">
      {services.map((service, index) => (
        <div className="status-service" key={`service-${index}`}>
          <StatusGeneralInfo service={service} />
          <StatusRunning service={service} />
          <StatusGraphic service={service} />
        </div>
      ))}
    </div>
  );
};

export default Status;
