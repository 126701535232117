import React, { useEffect, useState } from "react";
import "./Login.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import { useNavigate } from "react-router";
import { User } from "src/lib/api";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import { Auth as AuthAPI } from "src/lib/api";
const Login = () => {
  const { setRole, setToken, publicSetup, setName, setEmail } = useAppContext();
  const navigate = useNavigate();
  const [emailLogin, setEmailLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginServices, setLoginServices] = useState([]);

  useEffect(() => {
    setLoginServices(publicSetup.login);
  }, [publicSetup]);

  const buttonStyle = {
    minHeight: "44px",
    minWidth: "144px",
  };
  useEffect(() => {
    setEmailLogin("");
    setPassword("");
  }, []);

  function handleChange(name, value) {
    // S name === "email" ? setEmail(value) : setPassword(value)
    if (name === "email") {
      setEmailLogin(value);
    } else {
      setPassword(value);
    }
  }

  const login = async (event) => {
    event.preventDefault();
    if (!emailLogin || !password) {
      alert("Por favor, rellene todos los campos");
    }

    const response = await AuthAPI.login(emailLogin, password);

    if (!response.token) return alert("Usuario o contraseña incorrectos");

    const bearer = `Bearer ${response.token}`;
    setToken(bearer);
    const userData = await User.get();
    setRole(userData.role || "");
    setName(userData.name || "");
    setEmail(userData.email || "");
    navigate("/");
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      login(event);
    }
  };
  return (
    <div>
      <div className="content-container" onKeyDown={onKeyDown}>
        <FormContainer style={{ width: "40%", marginTop: "50px" }}>
          {/* <FormContainer style={{ width: "60%" }}> */}
          <div className="css-175oi2r">
            <Input
              name="email"
              type="text"
              placeholder="Email"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={emailLogin}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={password}
            />
          </div>
          <div className="css-175oi2c" style={{ alignItems: "center" }}>
            <Button
              content="Iniciar Sesión"
              disabled={emailLogin && password ? false : true}
              buttonStyle={buttonStyle}
              onClick={login}
              id="submit-login"
            />
            <p>¿Has olvidado tu contraseña?</p>
          </div>
          <div className="css-175oi2r line-container">
            <div className="line" />
            <div className="separator">O</div>
            <div className="line" />
          </div>
          <div className="content-container-login-buttons">
            {loginServices.map((el, index) => {
              if (el === null) return <></>;
              const iconurl = el.iconurl;
              const provider = el.provider;
              const linkurl = el.linkurl;
              return (
                <Button
                  key={`button-${index}`}
                  content={
                    <img
                      src={`${iconurl}`}
                      alt={`${provider}`}
                      className="resize_fit_center"
                      style={{ gridColumn: 1, gridRow: 1 }}
                    />
                  }
                  disabled={false}
                  onClick={(ev) => {
                    ev.preventDefault();
                    window.location.href = `${linkurl}`;
                  }}
                  buttonStyle={buttonStyle}
                />
              );
            })}
          </div>
        </FormContainer>
        <br />
        <p>
          <a href="https://valeria.moodrobotics.com/docs/app/login">
            ¿Necesitas ayuda para iniciar sesión?
          </a>
        </p>
        <br />
      </div>
    </div>
  );
};
export default Login;
