import { Structure as StructureApi } from 'src/lib/api';
import structureActionsTypes from "../actionTypes/structureActionsTypes";

// Acción para cargar los datos de la estructura
export const loadStructureData = async (filetypeName, dispatch) => {
  dispatch({ type: structureActionsTypes.SET_LOADING, payload: true });
  try {
    const fetchedData = await StructureApi.read(filetypeName);
    if (fetchedData) {
      dispatch({ type: structureActionsTypes.LOAD_STRUCTURE_DATA, payload: fetchedData });
    } else {
      showSnackbar("❌ No se encontró la estructura especificada", dispatch);
    }
  } catch (error) {
    showSnackbar("❌ Error al cargar la estructura", dispatch);
  } finally {
    dispatch({ type: structureActionsTypes.SET_LOADING, payload: false });
  }
};

// Acción para guardar los cambios en la estructura
export const saveStructureData = async (editedItems, dispatch) => {
  dispatch({ type: structureActionsTypes.SET_LOADING, payload: true });
  try {
    const response = await StructureApi.crupdate(editedItems.filetype);
    if (response && !response.error) {
      dispatch({ type: structureActionsTypes.LOAD_STRUCTURE_DATA, payload: editedItems });
      showSnackbar("✅ Estructura actualizada correctamente", dispatch);
    } else {
      showSnackbar(`❌ Error: ${response.error}: ${response.message}`, dispatch);
    }
  } catch (error) {
    showSnackbar("❌ Error en la actualización de la estructura", dispatch);
  } finally {
    dispatch({ type: structureActionsTypes.SET_LOADING, payload: false });
  }
};

// Acción para eliminar la estructura
export const deleteStructure = async (filetypeName, dispatch) => {
  dispatch({ type: structureActionsTypes.SET_LOADING, payload: true });
  try {
    const response = await StructureApi.delete(filetypeName);
    if (response && !response.error) {
      dispatch({ type: structureActionsTypes.LOAD_STRUCTURE_DATA, payload: null });
      showSnackbar("✅ Estructura eliminada correctamente", dispatch);
    } else {
      showSnackbar(`❌ Error: ${response.error}: ${response.message}`, dispatch);
    }
  } catch (error) {
    showSnackbar("❌ Error en la eliminación de la estructura", dispatch);
  } finally {
    dispatch({ type: structureActionsTypes.SET_LOADING, payload: false });
  }
};

// Acción para crear la estructura
export const createNewStructure = (newStructure, path, dispatch) => {
    dispatch({ type: structureActionsTypes.SET_LOADING, payload: true });
  
    const buildFullVarname = (path, structures) => {
      let varname = "";
      return path.reduce((acc, index) => {
        varname = structures[index].varname;
        structures = structures[index].structure || [];
        return `${acc}.${varname}`;
      }, "file");
    };
  
    dispatch({ type: structureActionsTypes.CREATE_NEW_STRUCTURE, payload: { newStructure, path, buildFullVarname } });
    
    dispatch({ type: structureActionsTypes.SET_LOADING, payload: false });
  };

// Acción para actualizar la estructura
export const updateStructure = (updatedStructure, modalPath, dispatch) => {
    dispatch({
      type: structureActionsTypes.UPDATE_STRUCTURE_ITEM,
      payload: { updatedStructure, modalPath },
    });
  };

// Acción para mostrar el snackbar
export const showSnackbar = (message, dispatch) => {
  dispatch({ type: structureActionsTypes.SHOW_SNACKBAR, payload: message });
  setTimeout(() => {
    dispatch({ type: structureActionsTypes.HIDE_SNACKBAR });
  }, 2000);
};

// Acción para ocultar el snackbar
export const hideSnackbar = (dispatch) => {
  dispatch({ type: structureActionsTypes.HIDE_SNACKBAR });
};

// Acción para cargar la lista de remotos
export const loadRemotes = async (dispatch) => {
  try {
    const fetchedData = await StructureApi.remotes();
    if (fetchedData) {
      dispatch({
        type: structureActionsTypes.SET_SELECTED_ORIGINS,
        payload: fetchedData.map((origin) => ({
          value: origin.name,
          label: origin.name,
        })),
      });
    } else {
      showSnackbar("❌ No se encontró la lista de remotos", dispatch);
    }
  } catch (error) {
    showSnackbar("❌ Error al cargar remotos", dispatch);
  }
};

// Acción para abrir/cerrar el modal
export const setModalState = (isOpen, structure = null, path = [], dispatch) => {
  dispatch({
    type: structureActionsTypes.SET_MODAL_STATE,
    payload: { isModalOpen: isOpen, structure, path },
  });
};

// Acción para seleccionar un elemento del menú
export const setSelectedMenuItem = (menuItem, dispatch) => {
  dispatch({ type: structureActionsTypes.SET_SELECTED_MENU_ITEM, payload: menuItem });
};

// Acción para actualizar la estructura localmente
export const updateStructurePositions = (pos, editedItems, dispatch) => {
  const dragPath = pos.drag.index.split(",").map(Number);
  const dropPath = pos.drop.index.split(",").map(Number);

  if (dragPath.length !== dropPath.length) {
    showSnackbar(
      "❌ La reorganización a diferentes niveles está deshabilitada.",
      dispatch
    );
    return;
  }

  if (
    dragPath.length > 1 &&
    dragPath[dragPath.length - 2] !== dropPath[dragPath.length - 2]
  ) {
    showSnackbar(
      "❌ La reorganización a diferentes niveles está deshabilitada.",
      dispatch
    );
    return;
  }

  let newItems = JSON.parse(JSON.stringify(editedItems));
  let parentDrag = newItems.filetype.structure;
  let parentDrop = newItems.filetype.structure;

  for (let i = 0; i < dragPath.length - 1; i++) {
    parentDrag = parentDrag[dragPath[i]].structure;
    parentDrop = parentDrop[dropPath[i]].structure;
  }

  if (parentDrag && parentDrop) {
    const draggedItem = parentDrag.splice(dragPath[dragPath.length - 1], 1)[0];
    parentDrop.splice(dropPath[dropPath.length - 1], 0, draggedItem);
  }

  dispatch({
    type: structureActionsTypes.UPDATE_STRUCTURE,
    payload: newItems.filetype.structure,
  });

  showSnackbar("Elementos reordenados", dispatch);
};

// Acción para rehacer la estructura (recargar desde la API)
export const remakeStructure = async (filetypeName, dispatch) => {
  dispatch({ type: structureActionsTypes.SET_LOADING, payload: true });
  try {
    const fetchedData = await StructureApi.structure(filetypeName);
    if (fetchedData) {
      dispatch({
        type: structureActionsTypes.LOAD_STRUCTURE_DATA,
        payload: fetchedData,
      });
      showSnackbar("✅ Estructura recargada correctamente", dispatch);
    } else {
      showSnackbar("❌ No se pudo recargar la estructura", dispatch);
    }
  } catch (error) {
    showSnackbar("❌ Error al recargar la estructura", dispatch);
  } finally {
    dispatch({ type: structureActionsTypes.SET_LOADING, payload: false });
  }
};
