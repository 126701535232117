import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types"; // Importar PropTypes para la validación
import "./ColumnFilter.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import searchTextHelper from "src/modules/helpers/searchTextHelper";

function ColumnFilter({
  column,
  data,
  activeFilters,
  setActiveFilters,
  selectedValues,
  setSelectedValues,
}) {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const valueCounts = data.reduce((counts, item) => {
    let value = item[removeMustache(column.variable)];
    const dataType = getDataType(data, column.variable);
    if (dataType === "fecha") {
      const month = new Date(value).getMonth();
      value = new Date(value).getFullYear().toString();
      counts[months[month]] = (counts[months[month]] || 0) + 1;
    }
    value = value === "" ? "-" : value;
    value = value === undefined ? 0 : value;
    counts[value] = (counts[value] || 0) + 1;
    return counts;
  }, {});

  const uniqueValues = Object.keys(valueCounts).sort(
    (a, b) => valueCounts[b] - valueCounts[a]
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  // Maneja el width y height del filtro
  const filterContentRef = useRef(null);
  const heightRef = useRef(null);
  const widthRef = useRef(null);
  
  useEffect(() => {
    const setFilterContentDimensions = () => {
      if (
        filterContentRef.current &&
        heightRef.current === null &&
        widthRef.current === null
      ) {
        heightRef.current = filterContentRef.current.offsetHeight;
        widthRef.current = filterContentRef.current.offsetWidth;
      }
      if (filterContentRef.current) {
        filterContentRef.current.style.minHeight = `${heightRef.current}px`;
        filterContentRef.current.style.minWidth = `${widthRef.current}px`;
      }
    };

    setFilterContentDimensions();
  }, [isFilterOpen]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleOptionClick = (value) => {
    let updatedSelectedValues = [...selectedValues];
    const index = updatedSelectedValues.findIndex(
      (item) => item.name === column.variable
    );

    if (index === -1) {
      updatedSelectedValues.push({
        name: column.variable,
        checks: [value],
      });
    } else if (updatedSelectedValues[index].checks.includes(value)) {
      updatedSelectedValues[index].checks = updatedSelectedValues[
        index
      ].checks.filter((v) => v !== value);
    } else {
      updatedSelectedValues[index].checks = [
        ...updatedSelectedValues[index].checks,
        value,
      ];
    }

    setSelectedValues(updatedSelectedValues);

    const updatedFilters = { ...activeFilters };
    updatedFilters[column.variable] = updatedSelectedValues.find(
      (item) => item.name === column.variable
    ).checks;
    setActiveFilters(updatedFilters);
  };

  const toggleFilterMenu = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const closeFilterMenu = () => {
    setIsFilterOpen(false);
  };



  const isActive = selectedValues.some(
    (item) => item.name === column.variable && item.checks.length > 0
  );

  return (
    <div className={`column-filter`}>
      <div className="filter-button-container">
        <Button
          onClick={toggleFilterMenu}
          icon="Filter Alt Outlined"
          className={`button-filter${isActive ? "--active" : ""}`}
        />
        {isFilterOpen && (
          <div className="filter-menu" onMouseLeave={closeFilterMenu}>
            {uniqueValues.length > 10 && (
              <div className="filter-searchBar">
                <Input
                  onChange={handleSearchChange}
                  value={searchText}
                  placeholder="Buscar..."
                  name="search"
                  label=""
                  type="search"
                  activeLabel={true}
                />
              </div>
            )}
            <div className="filter-content" ref={filterContentRef}>
              {searchTextHelper(uniqueValues, searchText).map((value) => (
                <div
                  key={value}
                  className="filter-option"
                  onClick={() => handleOptionClick(value)}
                >
                  <div className="filter-option-checkbox">
                    <input
                      type="checkbox"
                      checked={selectedValues.some(
                        (item) =>
                          item.name === column.variable &&
                          item.checks.includes(value.toString())
                      )}
                      readOnly
                    />
                  </div>
                  <div className="filter-option-row" title={value}>
                    {value}
                  </div>
                  <div className="filter-option-count">
                    ({valueCounts[value]})
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const getDataType = (data, prop) => {
  // Función auxiliar para verificar si un dato es de tipo fecha
  const isDate = (value) => {
    //1970-01-01T00:00:00.000Z,  1970-01-01,  1970/01/01, 01/01/1970
    const dateFormats = [
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,
      /^\d{4}-\d{2}-\d{2}$/,
      /^\d{4}\/\d{2}\/\d{2}$/,
      /^\d{2}\/\d{2}\/\d{4}$/,
    ];

    for (const format of dateFormats) {
      if (format.test(value)) {
        return true;
      }
    }

    return false;
  };

  // Si no se proporciona ningún dato, devuelve "general" por defecto
  if (!data || data.length === 0) {
    return "general";
  }
  const randomIndex = Math.floor(Math.random() * data.length);
  const randomData = data[randomIndex];

  // Comprueba el tipo de los datos
  if (isDate(randomData[removeMustache(prop)])) {
    return "fecha";
  } else {
    return "general";
  }
};

const removeMustache = (str) => {
  if (!str) return;
  return str.slice(2, -2);
};

// Agregar validación de PropTypes
ColumnFilter.propTypes = {
  column: PropTypes.shape({
    variable: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      // Asegúrate de que los tipos de datos coincidan con los datos reales
    })
  ).isRequired,
  activeFilters: PropTypes.object.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      checks: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  setSelectedValues: PropTypes.func.isRequired,
};

export default ColumnFilter;
