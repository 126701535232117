import React, { useState, useEffect } from "react";
import ElementRow from "./ElementRow";
import "./TablaVCElements.css";
import { useViewCreateContext } from "../ViewCreateContext.js";
import Button from "src/modules/components/common/Button/Button";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const TablaVCElement = () => {
  const { elementsTableData, targetFrameName, addEmptyElementToSelectedFrame } =
    useViewCreateContext();

  const { publicSetup } = useAppContext();
  const [itemStyle, setItemStyle] = useState({
    backgroundColor: "rgba(245,234,234)",
  });
  useEffect(() => {
    // Actualiza el estilo cada vez que cambia el valor de publicSetup.primarycolor
    setItemStyle({
      backgroundColor: publicSetup.primarycolor,
    });
    console.log("COLOR: " + publicSetup.primarycolor);
  }, [publicSetup.primarycolor]); // Escuchando cambios en publicSetup.primarycolor

  const addElement = () => {
    addEmptyElementToSelectedFrame();
  };

  return (
    <div className="tabla-vce" style={itemStyle}>
      <div className="row justify-between table-header-vce">
        <h2 className="tabla-vce-tittle">
          Elements de: {targetFrameName?.name}
        </h2>
        <Button
          icon="add"
          disabled={false}
          onClick={addElement}
          style={{ minWidth: "0px" }}
        />
      </div>
      <div className="tabla-content-vce">
        <div className="bg-black tabla-content-vce-tittles">
          <div className="tabla-content-vce-tittles-child">Name</div>
          <div className="tabla-content-vce-tittles-child">Var</div>
          <div className="tabla-content-vce-tittles-child">Icon</div>
          <div className="tabla-content-vce-tittles-child">Tipo</div>
          <div className="tabla-content-vce-tittles-child">Actions</div>
        </div>{" "}
        <div>
          {elementsTableData.map((element, index) => (
            <ElementRow
              key={element.name}
              element={element}
              index={index + 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TablaVCElement;
