import React, { useState, useEffect } from "react";
import FrameRow from "./FrameRow";
import "./TablaViewCreate.css";
import { useViewCreateContext } from "../ViewCreateContext.js";
import Button from "src/modules/components/common/Button/Button";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const TablaViewCreate = () => {
  const { viewData, addEmptyFrameToView } = useViewCreateContext();

  const { publicSetup } = useAppContext();
  const [itemStyle, setItemStyle] = useState({
    backgroundColor: "rgba(245,234,234)",
  });
  useEffect(() => {
    // Actualiza el estilo cada vez que cambia el valor de publicSetup.primarycolor
    setItemStyle({
      backgroundColor: publicSetup.primarycolor,
    });
    console.log("COLOR: " + publicSetup.primarycolor);
  }, [publicSetup.primarycolor]); // Escuchando cambios en publicSetup.primarycolor

  // Manejo de show inputs
  const handleCreateFrame = () => {
    // setInputsFrame(true);
    addEmptyFrameToView();
  };

  return (
    <section className="tabla-vc" style={itemStyle}>
      <div className="table-header-vc row justify-between">
        <h2 className="tabla-vc-tittle">Frames</h2>

        <Button
          icon="add"
          disabled={false}
          onClick={handleCreateFrame}
          style={{ minWidth: "0px" }}
        />
      </div>

      <div className="tabla-content-vc">
        <div className="bg-black tabla-content-vc-tittles">
          <div className="tabla-content-vc-tittles-c1">Name</div>
          <div className="tabla-content-vc-tittles-c2">Tipo</div>
          <div className="tabla-content-vc-tittles-c3">Scope</div>
          <div className="tabla-content-vc-tittles-c4">Actions</div>
        </div>
        <div className="tabla-content-vc-rows-container">
          {viewData.frames.map((frame, index) => (
            <FrameRow key={frame.name} frame={frame} index={index + 1} />
          ))}
        </div>
        <div className="table-content-vc-add"></div>
      </div>
    </section>
  );
};

export default TablaViewCreate;
