import React from 'react';
import PropTypes from 'prop-types';
import ArrayElement from './ArrayElement';
import ObjectElement from './ObjectElement';
import PrimitiveElement from './PrimitiveElement';

function ElementRenderer({ element, data, path, ...props }) {
  switch (element.type) {
    case 'array':
      return <ArrayElement element={element} data={data} path={path} {...props} />;
    case 'object':
      return <ObjectElement element={element} data={data} path={path} {...props} />;
    default:
      return <PrimitiveElement element={element} data={data} path={path} {...props} />;
  }
}

ElementRenderer.propTypes = {
  element: PropTypes.object.isRequired,
  data: PropTypes.any,
  path: PropTypes.string,
  deleteItemInArray: PropTypes.func.isRequired,
};

export default React.memo(ElementRenderer);
