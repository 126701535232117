import React, { useEffect, useState } from "react";
import "./Info.css";
import { Info as InfoAPI } from "src/lib/api"

function Info() {
  const [infoGroups, setInfoGroups] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await InfoAPI.basics(true);
      separaPorGrupos(data);
    };
    fetchData();
  }, []);

  const separaPorGrupos = (infoValues) => {
    let grupos = [];
    const keys = Object.keys(infoValues);
    keys.forEach((key) => {
      const grupo = key.split("_")[0];
      const foundIndex = grupos.findIndex((el) => {
        return el.name === grupo;
      });
      const nombre = key.split("_")[key.split("_").length - 1];
      if (foundIndex === -1) {
        grupos.push({
          name: grupo,
          elementos: [{ name: nombre, value: infoValues[key] }],
        });
      } else {
        grupos[foundIndex].elementos.push({
          name: nombre,
          value: infoValues[key],
        });
      }
    });
    setInfoGroups(grupos);
  };

  return (
    <div className="groupInfo">
      {infoGroups.length > 0 &&
        infoGroups.map((grupo) => {
          return (
            <div className="info-container" key={grupo.name}>
              <div className="info-title">{grupo.name}</div>
              {grupo.elementos.map((el, index) => {
                return (
                  <div className="info-value" key={index}>
                    {el.name}: {el.value}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

export default Info;
