// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-menu-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.edit-menu-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.edit-menu-buttons > * {
  background-color: rgb(156, 156, 156);
  border-radius: 4px;
  border: none;
  margin: 0.2vw;
  color: white;
  cursor: pointer;
  padding: 1vw;
  border: 1px solid rgba(255, 255, 255, 0);
}
.edit-menu-buttons > *:hover {
  background-color: rgb(44, 44, 44);

  border: 1px solid white;
}
.edit-menu-column-add {
  min-width: 5%;
  min-height: 20vh;
  background-color: rgba(255, 255, 255, 0.253);
  margin: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 8px;
  border-radius: 5px;
}

.edit-menu-add-column-button {
    background-color: rgb(156, 156, 156);
    border-radius: 4px;
    border: none;
    margin: 0.2vw;
    padding: 0.7vw;
    color: white;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0);
    font-size: 1.7vw;
  }
  .edit-menu-add-column-button:hover {
    background-color: rgb(44, 44, 44);
    border: 1px solid white;
  }`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/KanbanMenu/components/KanbanBoard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,wCAAwC;AAC1C;AACA;EACE,iCAAiC;;EAEjC,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,4CAA4C;EAC5C,WAAW;EACX,4CAA4C;EAC5C,YAAY;EACZ,kBAAkB;AACpB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,YAAY;IACZ,eAAe;IACf,wCAAwC;IACxC,gBAAgB;EAClB;EACA;IACE,iCAAiC;IACjC,uBAAuB;EACzB","sourcesContent":[".edit-menu-section {\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n}\r\n.edit-menu-buttons {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 100%;\r\n}\r\n.edit-menu-buttons > * {\r\n  background-color: rgb(156, 156, 156);\r\n  border-radius: 4px;\r\n  border: none;\r\n  margin: 0.2vw;\r\n  color: white;\r\n  cursor: pointer;\r\n  padding: 1vw;\r\n  border: 1px solid rgba(255, 255, 255, 0);\r\n}\r\n.edit-menu-buttons > *:hover {\r\n  background-color: rgb(44, 44, 44);\r\n\r\n  border: 1px solid white;\r\n}\r\n.edit-menu-column-add {\r\n  min-width: 5%;\r\n  min-height: 20vh;\r\n  background-color: rgba(255, 255, 255, 0.253);\r\n  margin: 8px;\r\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n  padding: 8px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.edit-menu-add-column-button {\r\n    background-color: rgb(156, 156, 156);\r\n    border-radius: 4px;\r\n    border: none;\r\n    margin: 0.2vw;\r\n    padding: 0.7vw;\r\n    color: white;\r\n    cursor: pointer;\r\n    border: 1px solid rgba(255, 255, 255, 0);\r\n    font-size: 1.7vw;\r\n  }\r\n  .edit-menu-add-column-button:hover {\r\n    background-color: rgb(44, 44, 44);\r\n    border: 1px solid white;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
