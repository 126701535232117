import React, { useEffect, useContext, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { User } from "src/lib/api.js";
import "./ShowFrame.css";
import { useMustache } from "src/modules/hooks/useMustache";
import Title from "../../common/Title/Title";
import dragAndDrop from "src/modules/helpers/dragAndDrop";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import {
  fetchShow,
  editView,
  patchDossier,
  fetchListFlatData,
  crupdateData,
  fetchListData,
} from "src/modules/actions/viewActions";
import ElementRenderer from "./ElementRenderer";
import {
  getDeepValue,
  defaultData,
  buscarElementoPorVariable,
  moveElementToPosition,
  obtenerObjetoDeElemento,
} from "./utils";

const ShowFrame2 = ({ frame, configuration = { mode: "edit" } }) => {
  const mustache = useMustache();
  const { state, dispatch } = useContext(ViewContext);
  const { view, data } = state;

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const paramDc = useMemo(() => urlParams.get("dc"), [urlParams]);

  const dc = useMemo(() => {
    return frame && frame.datacode !== "{{params.dc}}" ? frame.datacode : paramDc;
  }, [frame, paramDc]);

  useEffect(() => {
    const fetchShowData = async () => {
      if (frame && dc) {
        await fetchShow(frame.scope, dc, dispatch);
      }
    };
    fetchShowData();
  }, [frame, dc, dispatch]);

  const setView = useCallback(
    async (newView) => {
      await editView(newView, dispatch);
    },
    [dispatch]
  );

  const onChangeValue = useCallback(
    async (e, prop) => {
      let name = "";
      let value = "";
      const item = data[dc];
      if (prop) {
        name = prop;
        value = e ? e.value : null;
      } else {
        name = e.target.name;
        value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
      }
      const currentValue = getDeepValue(item, name);
      if (value === currentValue) return;
      const action = [
        {
          action: "set",
          pointer: name.replace(/\[/gi, ".").replace(/\]/gi, ""),
          value,
        },
      ];

      const result = mustache.editMustacheValue(name, value, item);
      await patchDossier("dossier", item.header.code, action, result, dispatch);
    },
    [mustache, dispatch, data, dc]
  );

  const save = useCallback(
    async (currentItem) => {
      const limit = 0;
      switch (frame.scope) {
        case "cartridge":
        case "check":
        case "template":
        case "view":
          await fetchListFlatData(frame, limit, dispatch);
          break;
        case "dossier":
          await crupdateData(frame.scope, currentItem, dispatch);
          break;
        case "resume":
          await fetchListData(frame, limit, dispatch);
          break;
        case "user":
          await User.update(currentItem.user);
          break;
        default:
          break;
      }
    },
    [frame.scope, frame, dispatch]
  );

  const buttonsStyle = useMemo(
    () => ({
      minWidth: "40px",
      minHeight: "40px",
      maxWidth: "40px",
      maxHeight: "40px",
    }),
    []
  );

  const addItemToArray = useCallback(
    (path, completePath) => {
      const item = data[dc];
      const usedPath = completePath || path;
      const partsOfUsedPath = usedPath.match(/[^.[\]]+(\[\d+\])?/g);

      const element = buscarElementoPorVariable(frame.elements, path);
      let defaultValue = "";

      if (element.type === "array") {
        defaultValue = [];
        if (element.elements && element.elements.length > 0) {
          const arrayItem = obtenerObjetoDeElemento(element.elements[0]);
          defaultValue.push(arrayItem);
        } else {
          defaultValue.push("");
        }
      } else if (element.type === "object") {
        defaultValue = obtenerObjetoDeElemento(element);
      } else {
        defaultValue = "";
      }

      const updateItem = { ...item };
      let current = updateItem;

      for (let i = 0; i < partsOfUsedPath.length - 1; i++) {
        const part = partsOfUsedPath[i];
        const arrayMatch = part.match(/(\w+)\[(\d+)\]/);
        if (arrayMatch) {
          const key = arrayMatch[1];
          const index = parseInt(arrayMatch[2], 10);
          if (!current[key]) {
            current[key] = [];
          }
          if (!current[key][index]) {
            current[key][index] = {};
          }
          current = current[key][index];
        } else {
          if (!current[part]) {
            current[part] = {};
          }
          current = current[part];
        }
      }

      const lastPart = partsOfUsedPath[partsOfUsedPath.length - 1];
      const arrayMatch = lastPart.match(/(\w+)\[(\d+)\]/);

      if (arrayMatch) {
        const key = arrayMatch[1];
        const index = parseInt(arrayMatch[2], 10);

        if (!current[key]) {
          current[key] = [];
        }

        if (!current[key][index]) {
          current[key][index] = [];
        } else if (!Array.isArray(current[key][index])) {
          current[key][index] = [];
        }

        current[key][index] = [...current[key][index], ...defaultValue];
      } else {
        if (!current[lastPart]) {
          current[lastPart] = [];
        } else if (!Array.isArray(current[lastPart])) {
          current[lastPart] = [];
        }

        current[lastPart] = [...current[lastPart], ...defaultValue];
      }

      save(updateItem);
    },
    [frame.elements, data, dc, save]
  );

  const deleteItemInArray = useCallback(
    async (path) => {
      const item = data[dc];
      const parts = path.replace(/\{|\}/g, "").split(".");
      const updatedObject = { ...item };

      let current = updatedObject;

      for (let i = 0; i < parts.length - 1; i++) {
        const isArrayAccess = /\[(\d+)\]/.exec(parts[i]);

        if (isArrayAccess) {
          const arrayIndex = parseInt(isArrayAccess[1], 10);
          const arrayName = parts[i].replace(/\[\d+\]/, "");

          if (current[arrayName] instanceof Array) {
            current = current[arrayName][arrayIndex];
          } else {
            return item;
          }
        } else if (current[parts[i]]) {
          current = current[parts[i]];
        } else {
          return item;
        }
      }

      const lastPart = parts[parts.length - 1];
      if (lastPart.includes("[") && lastPart.includes("]")) {
        const [propName, index] = lastPart.split(/\[|\]/).filter(Boolean);
        if (Array.isArray(current[propName])) {
          current[propName].splice(index, 1);
        }
      } else {
        delete current[lastPart];
      }
      await save(updatedObject);
    },
    [data, dc, save]
  );

  const setupDragAndDropCallback = useCallback(async () => {
    try {
      const pos = await dragAndDrop();
      const dragLevel = pos.drag.index.split(".").slice(0, -1).join(".");
      const dropLevel = pos.drop.index.split(".").slice(0, -1).join(".");
      if (dragLevel === dropLevel) {
        const dragElement = buscarElementoPorVariable(frame.elements, pos.drag.index);
        const dropElement = buscarElementoPorVariable(frame.elements, pos.drop.index);

        if (dragElement && dropElement) {
          const dragElementId = dragElement.variable;
          const dropElementId = dropElement.variable;
          const result = moveElementToPosition(frame.elements, dragElementId, dropElementId);
          const updatedFrame = {
            ...frame,
            datacode: dc,
            elements: result,
          };
          const frameIndex = view.frames.findIndex((el) => el._id === frame._id);
          const newView = { ...view };
          newView.frames[frameIndex] = updatedFrame;
          setView(newView);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [frame.elements, frame, dc, view, setView]);

  const renderedElements = useMemo(() => {
    return frame.elements.map((el, elIndex) => {
      let objData = getDeepValue(data[dc], el.variable);
      if (!objData) objData = defaultData(el.type);
      return (
        <ElementRenderer
          key={`element_${el.variable}_${elIndex}`}
          element={el}
          data={objData}
          path=""
          mustache={mustache}
          config={configuration}
          addItemToArray={addItemToArray}
          deleteItemInArray={deleteItemInArray}
          buttonsStyle={buttonsStyle}
          setupDragAndDrop={setupDragAndDropCallback}
          view={view}
          frame={frame}
          setView={setView}
          dc={dc}
          onChangeValue={onChangeValue}
        />
      );
    });
  }, [
    frame.elements,
    data,
    dc,
    mustache,
    configuration,
    addItemToArray,
    deleteItemInArray,
    buttonsStyle,
    setupDragAndDropCallback,
    view,
    frame,
    setView,
    onChangeValue,
  ]);

  return data[dc] ? (
    <div className="show-dossier-dashboard-container">
      <div className="show-container">
        <div className="clipper">
          <div className="top-line top-line-show"></div>
          <div className="show-content" data-droppable="true">
            {renderedElements}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Title text="Cargando datos..." />
  );
};

ShowFrame2.propTypes = {
  frame: PropTypes.object.isRequired,
  configuration: PropTypes.shape({
    mode: PropTypes.string,
  }),
};

export default ShowFrame2;
