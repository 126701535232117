import React, { useState } from "react";
import PropTypes from 'prop-types';
import Input from "../Input/Input";
import DragFileBox from "../DragFileBox/DragFileBox";

const InputWithImport = ({
  label,
  onChange,
  url,
  name,
  style,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      event.target.name = name;
      onChange(event);
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      event.target.name = name;
      onChange(event);
      setSelectedFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const boxStyle = {
    minWidth: "150px",
    maxWidth: "150px",
    minHeight: "20px",
    maxHeight: "20px",
    border: "2px dashed #ccc",
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <Input
        type="text"
        onChange={onChange}
        value={`${url}`}
        label={label || "url del archivo"}
        name={name}
      />
      <DragFileBox
        handleFileChange={handleFileChange}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        selectedFile={selectedFile}
        style={boxStyle}
      />
    </div>
  );
};

InputWithImport.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  url: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
};

export default InputWithImport;
