/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Setup {

  static async public() {
    const response = await HttpMethods.request_get("/v1/setup/public");
    if (!response) alert("Error al obtener el setup");
    return response;
  }
  static async read() {
    const response = await HttpMethods.request_get("/v1/setup/read");
    if (!response) alert("Error al obtener el setup");
    return response;
  }
  static async write(setup) {
    const response = await HttpMethods.request_post("/v1/setup/write", setup);
    if (response.error)
      alert(
        `Error al actualizar el setup.\nError: ${response.error}: ${response.message}`
      );
    return response;
  }

  static async fileSync_read() {
    const response = await HttpMethods.request_get("/v1/setup/filesync")
    if (!response) alert("Error al leer fileSync");
    return response
  }

  static async fileSync_save(data) {
    const response = await HttpMethods.request_post("/v1/setup/filesync", data, "text")
    if (response.error)
      alert(`Error al actualizar el filesync.\nError: ${response.error}: ${response.message}`);
    return response
  }
}