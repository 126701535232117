import React from "react";
import PropTypes from 'prop-types'; 
import "./MetaWindow.css";

const MetaWindow = ({ onClick, onClickError, errors }) => {

  return (
    <div className="dashboard-floating-resize">
      {errors > 0 && (
        <span className="dashboard-floating-resize-actions-errors" onClick={onClickError}>
          {errors} error{errors !== 1 ? "es" : ""}
        </span>
      )}
      <span className="dashboard-floating-resize-actions-open" onClick={onClick}>&larr;</span>
    </div>
  );
};

MetaWindow.propTypes = {
  onClick: PropTypes.func.isRequired, 
  onClickError: PropTypes.func.isRequired, 
  errors: PropTypes.number.isRequired, 
};

export default MetaWindow;
