/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Cartridge {
    static async public() {
        const response = await HttpMethods.request_get("/v1/cartridge/public");
        if (!response) alert("Error al obtener los cartridges");
        return response?.cartridges;
    }
    static async list() {
        const response = await HttpMethods.request_get("/v1/cartridge/list");
        if (!response) alert("Error al obtener los cartridges");
        return response?.cartridges;
    }
    static async listflat(first = 0, pagination = 100) {
        let response = await HttpMethods.request_get(`/v1/cartridge/list?format=flat`);
        if (!response) {
            alert("Error al obtener los cartuchos");
            response = [];
        }
        return response;
    }
    static async read(cartridgeCode) {
        const response = await HttpMethods.request_get(`/v1/cartridge/read/${cartridgeCode}`);
        if (!response) alert("Error al obtener el cartridge");
        return response?.cartridge;
    }
    static async create(cartridge) {
        const response = await HttpMethods.request_post("/v1/cartridge/create", cartridge);
        if (response.error)
            alert(
                "Error al crear el cartridge." +
                `\nError: ${response.error}: ${response.message}`
            );
        if (response.result) alert(`${response.result}`);
        return response;
    }

    static async update(cartridge) {
        const response = await HttpMethods.request_post("/v1/cartridge/update", cartridge);
        if (response.error)
            alert(
                "Error al actualizar el cartridge." +
                `\nError: ${response.error}: ${response.message}`
            );
        if (response.result) alert(`${response.result}`);
        return response;
    }

    static async crupdate(cartridge) {
        const response = await HttpMethods.request_post("/v1/cartridge/crupdate", cartridge);
        if (response.error)
            alert(
                "Error al crear el cartridge." +
                `\nError: ${response.error}: ${response.message}`
            );
        if (response.result) alert(`${response.result}`);
        return response;
    }
    static async delete(cartridgeCode) {
        const response = await HttpMethods.request_delete(
            "/v1/cartridge/delete",
            cartridgeCode
        );
        if (response.result) alert(`${response.result}`);

        return response;
    }
}