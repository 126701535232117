import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";

const StructureMenuArchives = ({
  editedItems,
  dispatch,
  originOptions,
}) => {
  const syncOptions = [
    { value: "none", label: "None" },
    { value: "sync", label: "Bidireccional" },
    { value: "synctoremote", label: "Local to Remote" },
    { value: "synctolocal", label: "Remote to Local" },
  ];

  const handleCheckBoxChange = (key, checked) => {
    dispatch({
      type: "UPDATE_HEADER",
      payload: {
        ...editedItems.filetype.header,
        [key]: checked,
      },
    });
  };

  const handleInputChange = (key, value) => {
    dispatch({
      type: "UPDATE_HEADER",
      payload: {
        ...editedItems.filetype.header,
        [key]: value,
      },
    });
  };

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Archivos</h3>
      <p>
        Configura cómo los dossiers del filetype se relacionan con los archivos.
      </p>
      <div className="structure-header__value">
        <span className="structure-header-key">Activado:</span>
        <Input
          type="checkbox"
          value={editedItems.filetype.header["archives"] || false}
          onChange={(e) => handleCheckBoxChange("archives", e.target.checked)}
          autoFocus
        />
      </div>
      <div className="structure-header__value">
        <span className="structure-header-key">
          Directorio de archivo de cada dossier:
        </span>
        <Input
          value={editedItems.filetype.header["dossierarchivedirectory"] || ""}
          onChange={(e) =>
            handleInputChange("dossierarchivedirectory", e.target.value)
          }
          autoFocus
          placeholder="/clientes/{{dossier.file.client}}/expedientes/{{dossier.file.id}}"
        />
      </div>
      <div className="structure-header__value">
        <span className="structure-header-key">Origen de los archivos:</span>
        <Select
          options={originOptions} // local, public, some remote
          value={originOptions.find(
            (option) =>
              option.value ===
              editedItems.filetype.header["dossierarchivedorigin"]
          )}
          onChange={(option) =>
            handleInputChange(
              "dossierarchivedorigin",
              option ? option.value : ""
            )
          }
          isClearable
          isSearchable
          placeholder="archives"
        />
      </div>
      <div className="structure-header__value">
        <span className="structure-header-key">Sincronización:</span>
        <Select
          options={syncOptions} // [sync, synctoremote, synctolocal, none]
          value={syncOptions.find(
            (option) =>
              option.value === editedItems.filetype.header["dossierarchivesync"]
          )}
          onChange={(option) =>
            handleInputChange("dossierarchivesync", option ? option.value : "")
          }
          isClearable
          isSearchable
          placeholder="sync"
        />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuArchives.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        archives: PropTypes.bool,
        dossierarchivedirectory: PropTypes.string,
        dossierarchivedorigin: PropTypes.string,
        dossierarchivesync: PropTypes.string,
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  originOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StructureMenuArchives;
