/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";


export class Archive {
  static async dossier(dossierCode) {
    const response = await HttpMethods.request_get(`/v1/archive/dossier/${dossierCode}`);
    return response;
  }
  static async scan(maxindex, origin, directory) {
    const response = await HttpMethods.request_get(`/v1/archive/scan/${maxindex}/${origin}/${directory}`);
    return response;
  }
  static readurl(origin, directory, file) {
    const path = encodeURIComponent(directory + file);
    const response = `/v1/archive/read/${origin}/${path}`;
    return response;
  }
  static async read(origin, fullfilename) {
    const response = await HttpMethods.request_get(`/v1/archive/read/${origin}/${fullfilename}`);
    return response;
  }
  static async remotes() {
    const response = await HttpMethods.request_get(`/v1/archive/remotes`);
    return response;
  }
}
