import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDragDrop } from "./KanbanContext";
import "./Item.css";
import Button from "src/modules/components/common/Button/Button";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

export default function Item({ content, originColumn, index }) {
  const {
    handleDragStart,
    setShowItemEdit,
    setOriginalSingleItem,
    setSingleItem,
    initialData,
    handleItemDragOver,
    handleDragEnd,
    deleteItem,
  } = useDragDrop();

  const { publicSetup } = useAppContext();
  const [itemStyle, setItemStyle] = useState({
    backgroundColor: 'rgba(245,234,234)',
  });

  const setItemToEdit = () => {
    const fullPath = content.pathmenu;
    const itemToEdit = initialData.menu.find(
      (item) => item.pathmenu === fullPath
    );

    if (itemToEdit) {
      setSingleItem(itemToEdit); // Establece el ítem para edición
      setOriginalSingleItem({ ...itemToEdit }); // Guarda una copia para tener referencia al estado original
      setShowItemEdit(true);
    } else {
      console.log(`No se encontró el ítem con pathmenu "${fullPath}"`);
    }
  };

  const handleDeleteItem = (itemId, originColumn) => {
    // Llamada a la función del contexto para borrar el ítem
    deleteItem(itemId, originColumn);
  };

  useEffect(() => {
    // Actualiza el estilo cada vez que cambia el valor de publicSetup.primarycolor
    setItemStyle({
      backgroundColor: publicSetup.primarycolor,
    });
    console.log("COLOR: " + publicSetup.primarycolor);
  }, [publicSetup.primarycolor]); // Escuchando cambios en publicSetup.primarycolor

  return (
    <div
      draggable="true"
      onDragOver={(e) => handleItemDragOver(e, originColumn, index)}
      onDragStart={(e) => handleDragStart(e, content.id, originColumn)}
      onDragEnd={handleDragEnd}
      className="edit-menu-item"
      style={itemStyle} 
    >
      <div className="edit-menu-item-tittle">{content.title}</div>
      <div className="item-container-buttons">
        <Button
          onClick={setItemToEdit}
          icon="edit"
          style={{ minWidth: "0px" }}
        />
        <Button
          onClick={() => handleDeleteItem(content.id, originColumn)}
          icon="delete"
          style={{ minWidth: "0px" }}
        />
      </div>
    </div>
  );
}

// Validación de prop-types
Item.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pathmenu: PropTypes.string,
  }).isRequired,
  originColumn: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
