// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disconnection-container{
    display: flex;
    padding: 16px;
    min-width: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
}
.disconnection-image{
    width: 100%;
    height: 100%;
    background-size: cover;
    max-width: 600px;
    max-height: 600px;
    /* object-fit: contain; */

}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Disconnection/Disconnection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;;AAE7B","sourcesContent":[".disconnection-container{\r\n    display: flex;\r\n    padding: 16px;\r\n    min-width: none;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    color: black;\r\n}\r\n.disconnection-image{\r\n    width: 100%;\r\n    height: 100%;\r\n    background-size: cover;\r\n    max-width: 600px;\r\n    max-height: 600px;\r\n    /* object-fit: contain; */\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
