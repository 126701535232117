import React, { useEffect, useState } from "react";
import "./Agentes.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import constants from "src/definitions/constants";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Icon } from "@mui/material";

const tiposProveedores = Object.entries(constants.loginProviders).map(
  ([key, value]) => ({
    value: value.value,
    label: value.label,
  })
);

const Login = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [loginTypes, setLoginTypes] = useState(setupInfo?.login || []);
  const [selectedLoginType, setSelectedLoginType] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [editedLoginType, setEditedLoginType] = useState(null);
  const [newLoginType, setNewLoginType] = useState(false);

  useEffect(() => {
    setSetupInfo((prev) => {
      return { ...prev, login: loginTypes };
    });
  }, [setLoginTypes, setSetupInfo, loginTypes]);

  const handleLoginTypeClick = (index) => {
    setSelectedLoginType(loginTypes[index]);
    setEditedLoginType({ ...loginTypes[index] });
    setOpenBox(true);
  };

  const handleEditLoginType = (e) => {
    let value = e.target.value;
    if (e.target.name === "clientID") {
      value = value.trim();
    }
    setEditedLoginType((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
  };

  const agregarElemento = () => {
    const item = {
      id: crypto.randomUUID(),
      provider: "",
      clientID: "",
      secretID: "",
      redirect_uri: "",
      scope: "https://www.googleapis.com/auth/userinfo.email",
      iconurl: "/googleicon.png",
      active: false,
    };
    setEditedLoginType(item);
    setSelectedLoginType(item);
    setNewLoginType(true);
    setOpenBox(true);
  };

  const handleSaveLoginType = () => {
    if (newLoginType) {
      setLoginTypes((prev) => {
        return [...prev, editedLoginType];
      });
    } else {
      const updatedLoginTypes = loginTypes.map((loginType) =>
        loginType === selectedLoginType ? editedLoginType : loginType
      );
      setLoginTypes(updatedLoginTypes);
    }
    setOpenBox(false);
    setSelectedLoginType(null);
    setEditedLoginType(null);
    setNewLoginType(false);
  };

  const handleDeleteLoginType = () => {
    const filteredLoginTypes = loginTypes.filter(
      (loginType) => loginType !== selectedLoginType
    );
    setLoginTypes(filteredLoginTypes);
    setOpenBox(false);
    setSelectedLoginType(null);
    setEditedLoginType(null);
  };

  const onClickExit = () => {
    setOpenBox(false);
    setEditedLoginType(null);
    setNewLoginType(false);
    setSelectedLoginType(null);
  };

  function onSelectChangeSingle(event, setValues, property) {
    const values = event?.value;
    setValues((prev) => {
      return { ...prev, [property]: values };
    });
  }

  const handleCheckboxChange = (e) => {
    setEditedLoginType((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const footerBoxFloat = (
    <div className="agente-container-buttons">
      <Button icon="save" onClick={handleSaveLoginType} />
      {!newLoginType && (
        <Button icon="delete" onClick={handleDeleteLoginType} />
      )}
    </div>
  );

  const renderInfoIconProvider = () => {
    switch (editedLoginType.provider) {
      case "google":
        return (
          <Icon
            className="info-icon"
            title="Mas información"
            onClick={() => {
              window.open(
                "https://console.cloud.google.com/apis/credentials",
                "_blank"
              );
            }}
          >
            info_icon
          </Icon>
        );
      default:
        return "";
    }
  };
  return (
    <div className="agentes-container">
      {openBox && (selectedLoginType || newLoginType) && (
        <BoxFloat
          title={selectedLoginType ? "Editar Login" : "Agregar Login"}
          onClickExit={onClickExit}
          onClose={selectedLoginType ? onClickExit : undefined}
          footer={footerBoxFloat}
        >
          <div className="agentes-container-proveedor">
            <Select
              name="provider"
              options={tiposProveedores}
              onChange={(event) =>
                onSelectChangeSingle(event, setEditedLoginType, "provider")
              }
              label="Proveedor"
              activeLabel={true}
              value={tiposProveedores.find(
                (tipo) => tipo.value === editedLoginType["provider"]
              )}
            />
            {renderInfoIconProvider()}
          </div>
          <Input
            name="clientID"
            type="text"
            placeholder=""
            onChange={handleEditLoginType}
            value={editedLoginType ? editedLoginType["clientID"] : ""}
            label="Client ID"
          />
          <Input
            name="secretID"
            type="password"
            placeholder=""
            onChange={handleEditLoginType}
            value={editedLoginType ? editedLoginType["secretID"] : ""}
            label="Secret ID"
          />
          <Input
            name="redirect_uri"
            type="text"
            placeholder="http://midominio.com/oauth"
            onChange={handleEditLoginType}
            value={editedLoginType ? editedLoginType["redirect_uri"] : ""}
            label="Redirect URI"
          />
          <Input
            name="scope"
            type="text"
            placeholder="https://www.googleapis.com/auth/userinfo.email"
            onChange={handleEditLoginType}
            value={
              editedLoginType
                ? editedLoginType["scope"]
                : "https://www.googleapis.com/auth/userinfo.email"
            }
            label="Scope"
            disabled={true}
          />
          <Input
            name="iconurl"
            type="text"
            placeholder="http://midominio.com/googleicon.png"
            onChange={handleEditLoginType}
            value={
              editedLoginType ? editedLoginType["iconurl"] : "/googleicon.png"
            }
            label="Icon URL"
          />
          <Input
            name="active"
            type="checkbox"
            value={editedLoginType ? editedLoginType["active"] : false}
            onChange={handleCheckboxChange}
            label="Activo"
            activeLabel={true}
          />
        </BoxFloat>
      )}
      <div className="display-agentes">
        {loginTypes?.map((el, index) => (
          <div
            key={index}
            className="agente-item"
            onClick={() => handleLoginTypeClick(index)}
          >
            <div className="agente-item-title">{el.provider}</div>
            <div className="agente-item-description">{el.description}</div>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <p>Agregar más</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
