import React from "react";
import PropTypes from "prop-types";
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from "react-tabs";
import "./Tabs.css";

const Tabs = ({ tabsData, vertical, saveButton }) => {
  return (
    <ReactTabs className={vertical ? "tabs" : null}>
      <TabList className="tabList" aria-orientation={vertical ? 'vertical' : 'horizontal'}>
        {tabsData.map((tab, index) => (
          <Tab tabFor={tab.id} className="tab" key={index}>
            {tab.name}
          </Tab>
        ))}
        {saveButton}
      </TabList>
      {tabsData.map((tab, index) => (
        <TabPanel tabId={tab.id} key={index}>
          {tab.component}
        </TabPanel>
      ))}
    </ReactTabs>
  );
};

Tabs.propTypes = {
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
  vertical: PropTypes.bool,
  saveButton: PropTypes.node,
};

export default Tabs;
