/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Filetype {
    static async read(filetypename) {
      const response = await HttpMethods.request_get(`/v1/filetype/read/${filetypename}`);
      if (!response) alert(`Error al obtener el filetype ${filetypename}`);
      return response;
    }
  
    static async crupdate(filetype) {
      const response = await HttpMethods.request_post(`/v1/filetype/crupdate`, filetype);
      if (!response) alert(`Error al guardar el filetype ${filetype.name}`);
      return response;
    }
  
    static async view(frametype, filetype) {
      const response = await HttpMethods.request_get(`/v1/filetype/view/${frametype}/${filetype}`);
      if (!response) alert(`Error al generar el view ${frametype} para el filetype ${filetype.name}`);
      return response;
    }
  
    static async list() {
      const response = await HttpMethods.request_get(`/v1/filetype/list`);
      if (!response) alert(`Error al listar los filetypes`);
      return response;
    }
  
    static async assign(path) {
      const response = await HttpMethods.request_get(`/v1/filetype/assign/${path}`);
      return response;
    }
  }