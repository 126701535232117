import React from "react";
import PropTypes from 'prop-types'; // Añadido para la validación de props
import Button from "../Button/Button";
import Collapsible from "../Collapsible/Collapsible";
import Input from "../Input/Input";
import Select from "../Select/Select";

const Variables = ({ variables, setVariables }) => {
  const addVariable = () => {
    const newVariable = {
      question: "",
      variable: "",
      default: "",
      datatype: "",
    };
    const newVars = [...variables, newVariable];
    setVariables(newVars);
  };

  const deleteVariable = (index) => {
    const newVars = variables.filter((variable, i) => i !== index);
    setVariables(newVars);
  };

  const onChangeD = (inputName) => (event) => {
    const [name, index] = inputName.split("_");

    const newVariables = [...variables];
    newVariables[index][name] = event.target ? event.target.type === 'checkbox' ? event.target.checked : event.target.value : event.value;
    setVariables(newVariables);
  };

  return (
    <>
      <Button
        icon="add"
        onClick={addVariable}
      />
      {variables.length > 0 &&
        variables.map((variable, index) => (
          <Variable
            key={index} // Añadido para la clave en el mapa
            variableData={variable}
            index={index}
            onChangeData={(e) => onChangeD(e)}
            deleteVariable={deleteVariable}
          />
        ))}
    </>
  );
};

const Variable = ({ variableData, index, onChangeData, deleteVariable }) => {
  const deleteVariableButtonStyle = {
    minHeight: "50px",
    minWidth: "50px",
    maxHeight: "50px",
    maxWidth: "50px",
  };

  const dataTypeOptions = [
    { value: "string", label: "Texto" },
    { value: "number", label: "Número" },
    { value: "boolean", label: "Booleano" },
    { value: "date", label: "Fecha" },
  ];

  const whoOptions = [
    { value: "owner", label: "Dueño" },
    { value: "gestor", label: "Gestor" },
    { value: "others", label: "Otros" },
  ];

  return (
    <div className="sidebar-variable-content">
      <Collapsible
        header={variableData.question}
        contentStyle={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Input
          type="text"
          name={`question_${index}`}
          value={variableData.question}
          label="Pregunta"
          onChange={onChangeData(`question_${index}`)}
          placeholder="Pregunta"
        />
        <Input
          type="text"
          name={`variable_${index}`}
          value={variableData.variable}
          label="Variable"
          onChange={onChangeData(`variable_${index}`)}
        />
        <Input
          type="text"
          name={`default_${index}`}
          value={variableData.default}
          label="Valor por defecto"
          onChange={onChangeData(`default_${index}`)}
        />
        <Select
          options={dataTypeOptions}
          name={`datatype_${index}`}
          value={dataTypeOptions.find(data => data.value === variableData.datatype)}
          placeholder="Texto, Número..."
          onChange={onChangeData(`datatype_${index}`)}
          label="Tipo de dato"
        />
        <Select
          options={whoOptions}
          name={`who_${index}`}
          value={whoOptions.find(data => data.value === variableData.who)}
          onChange={onChangeData(`who_${index}`)}
          label="Quién?"
        />
        <Input
          type="checkbox"
          name={`must_${index}`}
          checked={variableData.must} // Cambiado a 'checked' para checkbox
          label="Obligatorio"
          onChange={onChangeData(`must_${index}`)}
        />
      </Collapsible>
      <Button
        icon="delete"
        onClick={() => deleteVariable(index)}
        style={deleteVariableButtonStyle}
      />
    </div>
  );
};

// Validaciones de props añadidas
Variables.propTypes = {
  variables: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    variable: PropTypes.string,
    default: PropTypes.string,
    datatype: PropTypes.string,
    who: PropTypes.string,
    must: PropTypes.bool,
  })).isRequired,
  setVariables: PropTypes.func.isRequired,
};

Variable.propTypes = {
  variableData: PropTypes.shape({
    question: PropTypes.string,
    variable: PropTypes.string,
    default: PropTypes.string,
    datatype: PropTypes.string,
    who: PropTypes.string,
    must: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onChangeData: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
};

export default Variables;
