import React from "react";
import PropTypes from "prop-types";
import "./FormContainer.css";

/**
 * A container component for forms.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to be rendered inside the container.
 * @param {Object} props.style - The inline styles to be applied to the container.
 * @returns {JSX.Element} The rendered FormContainer component.
 */
const FormContainer = ({ children, style = {} }) => {
    return (
        <div className="form-container" style={{ ...style }}>
            {children}
        </div>
    );
};

FormContainer.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
};

export default FormContainer;
