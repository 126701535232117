// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-scroll::-webkit-scrollbar {
  width: 6px;
}

.panel-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.panel-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.panel-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.container {
  height: 95vh;
}

.panel {
  flex: 1 1;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.panel-resize-handle-vertical {
  height: 4px;
  background-color: var(--font-color);
  cursor: ns-resize;
}

.panel-resize-handle-horizontal {
  width: 4px;
  background-color: var(--font-color);
  cursor: ew-resize;
}

.view-container__loader{
  display: grid;
  place-items: center; 
  height: 90vh;
}

.view-spinner-container{
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/routes/View/View.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAO;EACP,2BAA2B;EAC3B,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,mCAAmC;EACnC,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".panel-scroll::-webkit-scrollbar {\r\n  width: 6px;\r\n}\r\n\r\n.panel-scroll::-webkit-scrollbar-thumb {\r\n  background-color: rgba(0, 0, 0, 0.2);\r\n  border-radius: 3px;\r\n}\r\n\r\n.panel-scroll::-webkit-scrollbar-thumb:hover {\r\n  background-color: rgba(0, 0, 0, 0.4);\r\n}\r\n\r\n.panel-scroll::-webkit-scrollbar-track {\r\n  background: transparent;\r\n}\r\n\r\n.container {\r\n  height: 95vh;\r\n}\r\n\r\n.panel {\r\n  flex: 1;\r\n  overflow-y: auto !important;\r\n  overflow-x: hidden !important;\r\n}\r\n\r\n.panel-resize-handle-vertical {\r\n  height: 4px;\r\n  background-color: var(--font-color);\r\n  cursor: ns-resize;\r\n}\r\n\r\n.panel-resize-handle-horizontal {\r\n  width: 4px;\r\n  background-color: var(--font-color);\r\n  cursor: ew-resize;\r\n}\r\n\r\n.view-container__loader{\r\n  display: grid;\r\n  place-items: center; \r\n  height: 90vh;\r\n}\r\n\r\n.view-spinner-container{\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
