import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Button from "src/modules/components/common/Button/Button";
import { useViewCreateContext } from "../ViewCreateContext.js";
import "./FrameRow.css";
import InputsVCFrame from "./InputsVCFrame.jsx";

const FrameRow = ({ frame, index }) => {
  const {
    removeFrameFromView,
    setTargetFrameName,
    setElementsTableData,
    setSingleFrameData,
    resetSingleFrameData,
    singleFrameData,
    onDragStartFrame,
    onDragOverFrame,
    onDropFrame,
    onDragEndFrame
  } = useViewCreateContext();

  const handleRemoveFrame = () => {
    removeFrameFromView(frame.name);
    resetSingleFrameData();
  };

  const handleEditFrame = () => {
    setTargetFrameName(frame);
    setSingleFrameData(frame);
    setElementsTableData(frame.elements);
    toggleShow();
  };

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (singleFrameData._id !== frame._id) {
      setShow(false);
    }
  }, [singleFrameData]);

  return (
    <div>
      <div
        draggable
        onDragStart={(e) => onDragStartFrame(e, frame._id)}
        onDragOver={(e) => onDragOverFrame(e)}
        onDrop={(e) => onDropFrame(e, frame._id)}
        onDragEnd={onDragEndFrame}
        className={`bg-black frame-row-tittles ${
          show ? "" : "border-bottom-light-grey"
        }`}
      >
        <div className="frame-row-c1">{frame.name}</div>
        <div className="frame-row-c2">{frame.type}</div>
        <div className="frame-row-c3">{frame.scope}</div>
        <div className="frame-row-c4">
          <Button
            icon="edit"
            disabled={false}
            onClick={handleEditFrame}
            style={{ minWidth: "0px" }}
          />
          <Button
            icon="delete"
            disabled={false}
            onClick={handleRemoveFrame}
            style={{ minWidth: "0px" }}
          />
        </div>
      </div>
      <div
        className={`expandableSection-f ${
          show ? "expanded border-bottom-light-grey" : ""
        }`}
      >
        <div className="expandableSection-inner">
          <InputsVCFrame />
        </div>
      </div>
    </div>
  );
};

// Validación de props
FrameRow.propTypes = {
  frame: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    type: PropTypes.string,
    scope: PropTypes.string,
    elements: PropTypes.array,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default FrameRow;
