// Componente diseñado para rendear vistas de manera agnostica como screens de ValerIA

import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client'; // Asegúrate de importar createRoot desde react-dom/client
import PropTypes from "prop-types";
import "./List.css";
import {
  View as Views,
  Cartridge,
  Check,
  Error,
  Event,
  Flow,
  Movement,
  Template,
  Dossier,
  Doc,
  Resume,
  User,
} from "src/lib/api.js";
import Icon from "@mui/material/Icon";
import Graph from "src/modules/components/common/Graph/Graph";
import ColumnFilter from "./ColumnFilter";
import Pagination from "./Pagination";
// Common components
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import Value from "src/modules/components/common/Value/Value";
import removeAccents from "src/modules/helpers/removeAccents";
import { useInternal } from "src/modules/hooks/useInternal";
import { EditElement } from "../EditElement";
import dragAndDrop from "src/modules/helpers/dragAndDrop";
import moveElementInArray from "src/modules/helpers/moveElementInArray";
import { useMustache } from "src/modules/hooks/useMustache";
import { useLocation, useNavigate } from "react-router-dom";

function ListFrame({
  frame,
  config = {
    searchbar: true,
    itemsPerPage: 15,
    resetFilters: true,
  },
  viewId,
}) {
  const navigate = useNavigate()
  const internal = useInternal();
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [columnWidths, setColumnWidths] = useState({});
  const [originalColumnWidths, setOriginalColumnWidths] = useState({});
  const [, setTableWidth] = useState("100%");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(config.itemsPerPage);
  const [activeFilters, setActiveFilters] = useState({});
  const [allData, setAllData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [view, set_View] = useState({});
  const [_frame, setFrame] = useState(frame);
  const mustache = useMustache()

  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const lastPartOfUrl = urlParts[urlParts.length - 1];
    if (selectedValues.length === 0 && searchText === ""){
      localStorage.removeItem(`data-${lastPartOfUrl}`)
      return
    }
    const dataToStore = {
      selectedValues,
      lastPartOfUrl,
      searchText,
    };
    localStorage.setItem(`data-${lastPartOfUrl}`, JSON.stringify(dataToStore));
  }, [selectedValues, searchText]);

  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const lastPartOfUrl = urlParts[urlParts.length - 1];
    const storedData = localStorage.getItem(`data-${lastPartOfUrl}`);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSelectedValues(parsedData.selectedValues);
      parsedData.searchText && setSearchText(parsedData.searchText);

      const resultado = {};
      parsedData.selectedValues.forEach((elemento) => {
        resultado[elemento.name] = elemento.checks;
      });

      setActiveFilters(resultado);
    }
  }, []);

  const setupDragAndDrop = async () => {
    try {
      const pos = await dragAndDrop();
      const result = moveElementInArray(_frame.elements, Number(pos.drag.index), Number(pos.drop.index));
      const updatedFrame = {..._frame, elements:result}
      const frameIndex = view.frames.map(el => el._id).indexOf(_frame._id)
      const newView = {...view}
      newView.frames[frameIndex] = updatedFrame;
      await Views.update(newView);
      setFrame(updatedFrame);
    } catch (error) {
      console.log(error);
    }
  };

  const setView = async (view) => {
    const newFrame = view.frames.find((fr) => fr._id === _frame._id);
    setFrame(newFrame);
    fetchList()
  };
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const fetchList = async () => {
    const limit = 10_000;
    let serverdata;
    // un case para cada tipo de scope
    switch (frame.scope) {
      case "cartridge":
        serverdata = await Cartridge.listflat(frame._id, 0, limit);
        break;
      case "check":
        serverdata = await Check.listflat(frame._id, 0, limit, {cartidgecode: queryParams.get("cartridgecode")?? null} );
        break;
      case "doc":
        serverdata = await Doc.listflat(frame._id, 0, limit);
        break;
      case "dossier":
        serverdata = await Dossier.list(frame._id, 0, limit);
        break;
      case "error":
        serverdata = await Error.list(frame._id, 0, limit);
        break;
      case "event":
        serverdata = await Event.list(frame.datacode, 0, limit);
        break;
      case "events":
        serverdata = await Event.list(frame.datacode, 0, limit);
        break;
      case "flow":
        serverdata = await Flow.list(frame._id, frame.datacode, 0, limit);
        break;
      case "movement":
        serverdata = await Movement.list(frame._id, frame.datacode, 0, limit);
        break;
      case "resume":
        serverdata = await Resume.list(0, limit);
        break;
      case "template":
        serverdata = await Template.listflat(frame._id, 0, limit);
        break;
      case "user":
        serverdata = await User.listflat(frame._id, 0, limit);
        break;
      case "view":
        serverdata = await Views.listflat(frame._id, 0, limit);
        break;
      case "internal":
        serverdata = JSON.parse(internal.read(frame._id, 0, limit)); //Devolver array con los frames
        break;
      case "global": {
        if (frame.datacode === "analysis") {
          serverdata = await Dossier.analysis();
        } else serverdata = [];
        break;
      }
      default:
        serverdata = [];
        break;
    }
    setAllData(serverdata);
    if (!serverdata) {
      serverdata = [];
    }
    setDisplayedData(serverdata);
    const fetchedView = await Views.read(viewId);
    set_View(fetchedView);
    const newFrame = fetchedView.frames.find((fr) => fr._id === frame._id);
    const newElements = newFrame.elements.map((el) => {
      if (!el.options) return el;
      return { ...el, options: JSON.parse(el.options) };
    });
    const parsedFrame = { ...newFrame, elements: newElements };
    setFrame(parsedFrame);
  };
  useEffect(() => {
    fetchList();
    console.log("List rendering.", _frame.elements.length);
  }, [frame]);

  const clickButton = (url) => {
    navigate(url);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const getNestedValue = (obj, path) => {
    if (!path) return obj;
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : ""),
      obj
    );
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleEdit = (rowIndex, colAccessor, newValue) => {
    if (!newValue) newValue = null;
    const updatedData = [...allData];
    updatedData[rowIndex][colAccessor] = newValue;
    setAllData(updatedData);
  };

  useEffect(() => {
    sortedData();
  }, [
    sortOrder,
    sortColumn,
    searchText,
    currentPage,
    itemsPerPage,
    allData,
    activeFilters,
  ]);

  const sortedData = () => {
    // 1. Ordenar el array allData
    let dataToShow = [...allData];
    if (sortColumn) {
      dataToShow = dataToShow.sort((a, b) => {
        const valueA =
          a[removeMustache(sortColumn)] || getNestedValue(a, sortColumn);
        const valueB =
          b[removeMustache(sortColumn)] || getNestedValue(b, sortColumn);

        // Intenta convertir las cadenas a números
        const valueANumber = Number(valueA);
        const valueBNumber = Number(valueB);

        if (!isNaN(valueANumber) && !isNaN(valueBNumber)) {
          // Si ambos valores son números, compáralos como números
          return sortOrder === "asc"
            ? valueANumber - valueBNumber
            : valueBNumber - valueANumber;
        } else {
          // Si no, compáralos como cadenas
          const normalizedValueA = removeAccents(String(valueA));
          const normalizedValueB = removeAccents(String(valueB));
          return sortOrder === "asc"
            ? normalizedValueA.localeCompare(normalizedValueB)
            : normalizedValueB.localeCompare(normalizedValueA);
        }
      });
    }

    // 2. Aplicar los filtros
    //FIXME AQUI ESTA EL PROBLEMA PARA ENCONTRAR EL FILTRO
    for (const accessor in activeFilters) {
      if (activeFilters[accessor].length > 0) {
        dataToShow = dataToShow.filter((item) => {
          const cellValue =
            item[removeMustache(accessor)] || getNestedValue(item, accessor);
          if (getDataType(dataToShow, accessor) === "fecha") {
            return activeFilters[accessor].some((filter) => {
              const filterString = filter.toString();
              const monthMatch = new Date(cellValue)
                .toLocaleString("es-ES", { month: "long" })
                .includes(filterString.toLowerCase());
              const yearMatch = new Date(cellValue)
                .getFullYear()
                .toString()
                .includes(filterString);

              return monthMatch || yearMatch;
            });
          } else {
            // Si no es una fecha, incluir en dataToShow si cumple con alguna condición
            return activeFilters[accessor]
              .map(String)
              .includes(String(cellValue));
          }
        });
      }
    }

    // 3. Aplicar el searchText al array resultante
    if (searchText.trim() !== "") {
      dataToShow = dataToShow.filter((item) => {
        for (const element of _frame.elements.filter(
          (el) => el.type !== "button"
        )) {
          const cellValue =
            item[removeMustache(element.variable)] ||
            String(getNestedValue(item, element.variable));

          if (!cellValue) return false;
          if (
            removeAccents(cellValue)
              .toLowerCase()
              .includes(removeAccents(searchText).toLowerCase())
          ) {
            return true;
          }
        }
      });
    }

    // Actualizar displayedData con los datos resultantes
    if (!dataToShow) {
      dataToShow = [];
    }
    setDisplayedData(dataToShow);
  };

  const renderSortArrow = (column) => {
    if (column === sortColumn) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return null;
  };

  const getDataType = (data, prop) => {
    // Función auxiliar para verificar si un dato es de tipo fecha
    const isDate = (value) => {
      //1970-01-01T00:00:00.000Z,  1970-01-01,  1970/01/01, 01/01/1970
      const dateFormats = [
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,
        /^\d{4}-\d{2}-\d{2}$/,
        /^\d{4}\/\d{2}\/\d{2}$/,
        /^\d{2}\/\d{2}\/\d{4}$/,
      ];

      for (const format of dateFormats) {
        if (format.test(value)) {
          return true;
        }
      }

      return false;
    };

    // Si no se proporciona ningún dato, devuelve "general" por defecto
    if (!data || data.length === 0) {
      return "general";
    }
    const randomIndex = Math.floor(Math.random() * data.length);
    const randomData = data[randomIndex];

    // Comprueba el tipo de los datos
    if (isDate(randomData[removeMustache(prop)])) {
      return "fecha";
    } else {
      return "general";
    }
  };

  const handleColumnResizeStart = (columnName) => (e) => {
    e.preventDefault();
    const initialX = e.clientX;
    const initialWidth = columnWidths[columnName] || 150;
    setOriginalColumnWidths((prevOriginalColumnWidths) => ({
      ...prevOriginalColumnWidths,
      [columnName]: initialWidth,
    }));
    const tableContainer = e.target.closest(".table-container");
    const initialTableWidth = tableContainer.offsetWidth;
    setTableWidth(initialTableWidth);

    const onMouseMove = (e) => {
      const newWidth = Math.round(initialWidth + e.clientX - initialX);
      if (newWidth > 0) {
        setColumnWidths((prevColumnWidths) => ({
          ...prevColumnWidths,
          [columnName]: newWidth,
        }));
      }
    };

    const onMouseUp = () => {
      const resizedColumnWidth = columnWidths[columnName] || 150;
      const delta =
        resizedColumnWidth - (originalColumnWidths[columnName] || 150);
      const adjacentColumns = _frame.elements
        .filter((el) => el.type !== "button")
        .filter((column) => column.variable !== columnName);
      const totalAdjacentWidth = adjacentColumns.reduce(
        (totalWidth, column) =>
          totalWidth + (columnWidths[column.variable] || 150),
        0
      );

      adjacentColumns.forEach((column) => {
        const currentWidth = columnWidths[column.variable] || 150;
        const newWidth =
          currentWidth - (delta * currentWidth) / totalAdjacentWidth;
        setColumnWidths((prevColumnWidths) => ({
          ...prevColumnWidths,
          [column.variable]: newWidth,
        }));
      });

      const newTableWidth = initialTableWidth + delta;
      setTableWidth(newTableWidth);

      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const renderCell = (rowIndex, rowData, column) => {
    const cellValue = mustache.replaceMustache(column.variable, rowData, queryParams);

    if (column.type === "checkbox") {
      return (
        <div className="cell-content">
          <Input
            type="checkbox"
            name={column.variable}
            checked={cellValue}
            onChange={(e) =>
              handleEdit(rowIndex, column.variable, e.target.checked)
            }
            disabled={true}
          />
        </div>
      );
    } else if (column.type === "select") {
      return (
        <div className="cell-content">
          <Select
            isSearchable={true}
            isClearable={true}
            name={column.variable}
            options={column.options}
            placeholder="Scope..."
            onChange={(e) =>
              handleEdit(rowIndex, column.variable, e.value || null)
            }
            value={column.options.find((option) => option.value === cellValue)}
          />
        </div>
      );
    } else if (column.type === "graph") {
      let subelements = cellValue.split("/");
      const domgraph = `listgraph${rowIndex}`;
      if (subelements[0] === "resume") {
        Resume.graph(subelements[1]).then((data) => {
          // Reemplaza el objeto domgraph por el gráfico
          const graph = document.getElementById(domgraph);
      
          // Crea una raíz y renderiza el componente
          const root = ReactDOM.createRoot(graph);
          root.render(<Graph chart={data} />);
        });
      }
      return (
        <div
          id={domgraph}
          className="cell-content">
          <Icon>pie_chart</Icon>
        </div>
      );
    } else if (column.link) {
      const link = mustache.replaceMustache(column.link, rowData, queryParams);
      return (
        <div
          className="cell-content"
          style={{ justifyContent: "flex-start" }}>
          <a
            href={link}
            target="">
            {column.icon && <Icon>{column.icon}</Icon>}
            {cellValue}
          </a>
        </div>
      );
    } else if (column.type === "link") {
      return (
        <div className="cell-content">
          <a
            href={cellValue}
            target="_blank"
            rel="noreferrer">
            {cellValue ? column.text : ""}
          </a>
        </div>
      );
    } else if (column.editable) {
      return (
        <div className="cell-content">
          <span
            contentEditable={true}
            onBlur={(e) =>
              handleEdit(rowIndex, column.variable, e.target.textContent)
            }
            dangerouslySetInnerHTML={{ __html: cellValue }}
          />
        </div>
      );
    } else {
      return (
        <Value
          type={column.type}
          content={cellValue ?? ""}
          options={column.options}
        />
      );
    }
  };

  if (!_frame?.elements) {
    return <h1>Cargando datos...</h1>;
  }

  return (
    <div className="list">
      <div className="top-line">
        {_frame.elements
          .filter((el) => el.type === "button")
          .map((element, index) => (
            <div
            key={`button-${index}`}
              className="row-element"
              style={{ width: "unset" }}>
              <EditElement
                view={view}
                setView={setView}
                element={element}
                frame={_frame}
                config={{duplicateElement: true}}
              />
              <Button
                key={`button ${index}`}
                onClick={() => clickButton(mustache.replaceMustache(element.link, allData, queryParams))}
                icon={element.icon}
                content={element.label}
              />
            </div>
          ))}
        {config.searchbar && (
          <Input
            onChange={handleSearchChange}
            value={searchText}
            placeholder="Buscar..."
            name="search"
            label=""
            type="search"
            activeLabel={true}
          />
        )}
        {config.resetFilters && (
          <Button
            icon="Filter Alt Off Icon"
            onClick={() => {
              setActiveFilters({});
              setSelectedValues([]);
              setSearchText("");
              //resetea el localstorage de los filtros
              const urlParts = window.location.pathname.split("/");
              const lastPartOfUrl = urlParts[urlParts.length - 1];
              localStorage.removeItem(`data-${lastPartOfUrl}`);
            }}
            buttonStyle={{
              minWidth: "40px",
              maxWidth: "50px",
              maxHeight: "100px",
              minHeight: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        )}
        {config.itemsPerPage && (
          <Pagination
            currentPage={currentPage}
            totalItems={displayedData.length}
            itemsPerPage={config.itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr data-droppable>
              {_frame.elements
                .filter((el) => el.type !== "button")
                .map((element, index) => (
                  <th
                    key={`th ${index}`}
                    className={`column-min-width resizable-column`}
                    style={{
                      width: columnWidths[element.variable] || "auto",
                      position: "relative",
                    }}
                    draggable
                    data-dragdrop-object="column"
                    data-dragdrop-index={_frame.elements.map(e => e._id).indexOf(element._id)}
                    onDragStart={() => setupDragAndDrop()}>
                    <div className="cell-div">
                      <EditElement
                        view={view}
                        setView={setView}
                        element={element}
                        frame={_frame}
                        align={
                          index >
                          _frame.elements.filter((el) => el.type !== "button")
                            .length /
                            2
                            ? "right"
                            : "left"
                        }
                        config={{duplicateElement: true}}
                      />
                      <div
                        className="cell-div-column-text"
                        onClick={() => handleSort(element.variable)}>
                        {element.label} {renderSortArrow(element.variable)}
                      </div>
                      {element.options?.filter && (
                        <ColumnFilter
                          column={element}
                          data={displayedData}
                          activeFilters={activeFilters} // Pasa el estado de los filtros activos
                          setActiveFilters={setActiveFilters} // Pasa la función para actualizar los filtros activos
                          selectedValues={selectedValues} // Pasa el estado de los valores seleccionados
                          setSelectedValues={setSelectedValues} // Pasa la función para actualizar los valores seleccionados
                          idFilter={`filter-${element.variable}`}
                        />
                      )}
                      {element.options?.resume && (
                        <div className="column-resume-group-container">
                          <div className="column-resume-group">
                            <div className="column-resume-group-numbers">
                              <div className="column-resume">
                                {
                                  <ColumnResume
                                    resumeType={element.options.resume}
                                    data={displayedData.map((element) =>
                                      mustache.replaceMustache(element.variable, element, queryParams)
                                    )}
                                  />
                                }
                              </div>
                              {element.options?.resume_suffix && (
                                <div className="column-resume-suffix">
                                  {element.options.resume_suffix}
                                </div>
                              )}
                            </div>
                            {element.options?.resume && (
                              <div className="column-resume-indicator">
                                {`(${element.options.resume})`}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="column-resize"
                      onMouseDown={handleColumnResizeStart(element.variable)}
                    />
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {displayedData
              .slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              .map((data, rowIndex) => (
                <tr key={`tboby-row ${rowIndex}`}>
                  {_frame.elements
                    .filter((el) => el.type !== "button")
                    .map((element, index) => (
                      <td
                        key={`tboby-cell ${index}`}
                        data-cell={element.label}
                        style={{
                          width: columnWidths[element.variable] || "auto",
                        }}>
                        {renderCell(rowIndex, data, element)}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const ColumnResume = ({ resumeType, data }) => {
  const dataNumber = data.map((element) => Number(element));
  let result = 0;
  switch (resumeType) {
    case "sum":
      result = dataNumber.reduce((a, b) => a + b, 0);
      break;
    case "avg":
      result = dataNumber.reduce((a, b) => a + b, 0) / dataNumber.length;
      break;
    case "count":
      result = dataNumber.length;
      break;
    case "max":
      result = Math.max(...dataNumber);
      break;
    case "min":
      result = Math.min(...dataNumber);
      break;
    default:
      result = 0;
  }
  //Representamos el numero con separaciones de miles y separaciones decimales
  let formattedCellValue = result;
  let numberValue = Number(result);
  if (Number.isInteger(numberValue)) {
    formattedCellValue = numberValue.toLocaleString("es-ES");
  } else {
    formattedCellValue = numberValue.toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return formattedCellValue;
};

const removeMustache = (str) => {
  if (!str) return str;
  if (str.includes("{")) return str.replace(/[{}]/g, "");
  return str;
};
// 1.Comprueba que los tipos de las props sean correctos
ListFrame.propTypes = {
  frame: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired, // Ajusta el tipo según la estructura de `config`
  viewId: PropTypes.string.isRequired, // Ajusta el tipo según el tipo de `viewId`
};

export default ListFrame;
