import React, { useEffect, useState, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import {
  View as Views,
  Cartridge,
  Check,
  Template,
  Dossier,
  Resume,
  User,
} from "src/lib/api.js";

import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "src/modules/components/common//Text Area/TextArea";
import "./ShowFrame.css";
import Value from "../../common/Value/Value";
import { useMustache } from "src/modules/hooks/useMustache";
import Collapsible from "../../common/Collapsible/Collapsible";
import Title from "../../common/Title/Title";
import { EditElement } from "../EditElement";
import dragAndDrop from "src/modules/helpers/dragAndDrop";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import MetaWindow from "../../common/MetaWindow/MetaWindow";

const DossierDashboard = lazy(() =>
  import("../../common/MetaWindow/DossierDashboard/DossierDashboard")
);

function ShowFrame({ frame, configuration = { mode: "edit" }, ogDatacode, view }) {
  const mustache = useMustache();
  const [item, setItem] = useState();
  const [config, setConfig] = useState(configuration);
  const [_frame, setFrame] = useState(frame);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [metaWindowMenuPosition, setMetaWindowMenuPosition] = useState("Flows");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClick = () => {
    setMetaWindowMenuPosition("Flows");
    setIsOpen(!isOpen);
  };

  const onClickError = () => {
    setMetaWindowMenuPosition("Errores");
    setIsOpen(!isOpen);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchItem = async () => {
      let fetchData;
      if (!frame.datacode || frame.datacode === "null") {
        setItem({});
        return;
      }
      // Un case para cada tipo de scope
      switch (frame.scope) {
        case "cartridge":
          fetchData = await Cartridge.read(frame.datacode);
          break;
        case "check":
          fetchData = await Check.read(frame.datacode);
          break;
        case "dossier":
          fetchData = await Dossier.read(frame.datacode);
          break;
        case "resume":
          fetchData = await Resume.read(frame.datacode);
          break;
        case "template":
          fetchData = await Template.read(frame.datacode);
          break;
        case "user":
          fetchData = { user: await User.read(frame.datacode) };
          break;
        case "view":
          fetchData = await Views.read(frame.datacode);
          break;
        default:
          fetchData = [];
          break;
      }
      setItem(fetchData);

      const newFrame = view.frames.find((fr) => fr._id === _frame._id);
      if (newFrame && newFrame !== _frame) {
        setFrame(newFrame);
      }
    };

    fetchItem();
  }, [frame, _frame._id, view.frames]);

  const setView = async (updatedView) => {
    const newFrame = updatedView.frames.find((fr) => fr._id === _frame._id);
    if (newFrame && newFrame !== _frame) {
      setFrame(newFrame);
    }
  };

  const onChangeValue = async (e, prop) => {
    let name = "";
    let value = "";
    if (prop) {
      name = prop;
      value = e ? e.value : null;
    } else {
      name = e.target.name;
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }
    const currentValue = getDeepValue(item, name);
    if (value === currentValue) return;
    const action = [
      {
        action: "set",
        pointer: name.replace(/\[/gi, ".").replace(/\]/gi, ""),
        value,
      },
    ];

    await Dossier.modify(item.header.code, action);
    const result = mustache.editMustacheValue(name, value, item);
    setItem(result);
  };

  const save = async () => {
    const limit = 0;
    switch (frame.scope) {
      case "cartridge":
        await Cartridge.listflat(frame._id, 0, limit);
        break;
      case "check":
        await Check.listflat(frame._id, 0, limit);
        break;
      case "dossier":
        await Dossier.crupdate(item);
        break;
      case "resume":
        await Resume.list(0, limit);
        break;
      case "template":
        await Template.listflat(frame._id, 0, limit);
        break;
      case "user":
        await User.update(item.user);
        break;
      case "view":
        await Views.listflat(frame._id, 0, limit);
        break;
      default:
        break;
    }
  };

  const buttonsStyle = {
    minWidth: "40px",
    minHeight: "40px",
    maxWidth: "40px",
    maxHeight: "40px",
  };

  function getDeepValue(object, path, defaultValue) {
    const pathTokens = path.split(".");
    let v = object;
    for (const p of pathTokens) {
      try {
        v = Object.prototype.hasOwnProperty.call(v, p) ? v[p] : defaultValue;
      } catch (catchedError) {
        return defaultValue;
      }
    }
    return v;
  }

  function buscarElementoPorVariable(array, variable) {
    for (const elemento of array) {
      if (elemento.variable === variable) {
        return elemento;
      }
      if (elemento.elements && elemento.elements.length > 0) {
        const resultadoRecursivo = buscarElementoPorVariable(
          elemento.elements,
          variable
        );
        if (resultadoRecursivo) {
          return resultadoRecursivo;
        }
      }
    }
    return null;
  }

  function obtenerObjetoDeElemento(elemento) {
    const objeto = {};
    if (elemento?.elements && elemento.elements.length > 0) {
      elemento.elements.forEach((subElemento) => {
        const defaultValue = defaultData(subElemento.type);
        objeto[subElemento.variable.split(".").pop()] = defaultValue;
      });
    }
    return objeto;
  }

  const addItemToArray = (path) => {
    const parts = path.replace(/\{|\}/g, "").split(".");
    let updatedObject = { ...item };
    let current = updatedObject;

    for (let i = 0; i < parts.length - 1; i++) {
      const isArrayAccess = /\[(\d+)\]/.exec(parts[i]);

      if (isArrayAccess) {
        const arrayIndex = parseInt(isArrayAccess[1]);
        const arrayName = parts[i].replace(/\[\d+\]/, "");

        if (!current[arrayName]) {
          current[arrayName] = [];
        }

        current = current[arrayName];

        if (!current[arrayIndex]) {
          current[arrayIndex] = {};
        }

        current = current[arrayIndex];
      } else {
        if (!current[parts[i]]) {
          current[parts[i]] = {};
        }

        current = current[parts[i]];
      }
    }

    const lastPart = parts[parts.length - 1];
    if (!current[lastPart]) {
      const element = buscarElementoPorVariable(_frame.elements, path);
      let defaultValue = "";
      if (element.type === "array") {
        if (element.elements.length > 1)
          defaultValue = [obtenerObjetoDeElemento(element)];
        else defaultValue = [""];
      } else if (element.type === "object")
        defaultValue = obtenerObjetoDeElemento(element);
      current[lastPart] = defaultValue;
    } else {
      const newStructure =
        typeof current[lastPart][0] === "object"
          ? limpiarObjeto(current[lastPart][0])
          : "";
      current[lastPart] = [...current[lastPart], newStructure];
    }

    setItem(updatedObject);
  };

  const deleteItemInArray = async (path) => {
    const action = [
      {
        action: "remove",
        pointer: path.replace(/\[/gi, ".").replace(/\]/gi, ""),
        value: getDeepValue(item, path),
      },
    ];

    await Dossier.modify(item.header.code, action);
    const parts = path.replace(/\{|\}/g, "").split(".");
    const updatedObject = { ...item };

    let current = updatedObject;

    for (let i = 0; i < parts.length - 1; i++) {
      const isArrayAccess = /\[(\d+)\]/.exec(parts[i]);

      if (isArrayAccess) {
        const arrayIndex = parseInt(isArrayAccess[1]);
        const arrayName = parts[i].replace(/\[\d+\]/, "");

        if (current[arrayName] instanceof Array) {
          current = current[arrayName];
          current = current[arrayIndex];
        } else {
          return item;
        }
      } else if (current[parts[i]]) {
        current = current[parts[i]];
      } else {
        return item;
      }
    }

    const lastPart = parts[parts.length - 1];
    if (lastPart.includes("[") && lastPart.includes("]")) {
      const [propName, index] = lastPart.split(/\[|\]/).filter(Boolean);
      if (Array.isArray(current[propName])) {
        current[propName].splice(index, 1);
      }
    } else {
      delete current[lastPart];
    }

    setItem(updatedObject);
  };

  function limpiarObjeto(objetoConValores) {
    const objetoSinValores = {};

    for (const clave in objetoConValores) {
      if (Array.isArray(objetoConValores[clave])) {
        objetoSinValores[clave] = [limpiarObjeto(objetoConValores[clave][0])];
      } else if (
        typeof objetoConValores[clave] === "object" &&
        objetoConValores[clave] !== null
      ) {
        objetoSinValores[clave] = limpiarObjeto(objetoConValores[clave]);
      } else {
        objetoSinValores[clave] = obtenerValorPorDefecto(
          objetoConValores[clave]
        );
      }
    }

    return objetoSinValores;
  }

  function obtenerValorPorDefecto(valor) {
    if (Array.isArray(valor)) {
      return [];
    } else if (typeof valor === "object" && valor !== null) {
      return {};
    } else {
      return "";
    }
  }

  function defaultData(type) {
    if (type === "array") {
      return [];
    } else if (type === "object") {
      return {};
    } else {
      return "";
    }
  }

  function findPathByVariable(arr, id, path = []) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].variable === id) {
        return path.concat(i);
      } else if (arr[i].elements && arr[i].elements.length > 0) {
        const result = findPathByVariable(
          arr[i].elements,
          id,
          path.concat(i, "elements")
        );
        if (result) return result;
      }
    }
    return null;
  }

  function getArrayByPath(arr, path) {
    return path.reduce((acc, key) => acc[key], arr);
  }

  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function reorderArray(arr, fromIndex, toIndex) {
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
  }

  function moveElementToPosition(arr, id1, id2) {
    const clonedArray = deepClone(arr);

    const path1 = findPathByVariable(clonedArray, id1);
    const path2 = findPathByVariable(clonedArray, id2);

    if (!path1 || !path2) {
      return clonedArray;
    }

    let i = 0;
    while (i < path1.length && i < path2.length && path1[i] === path2[i]) {
      i++;
    }

    const commonPath = path1.slice(0, i);
    const arrayAtLevel = getArrayByPath(clonedArray, commonPath);

    const index1 = path1[i];
    const index2 = path2[i];

    if (index1 !== undefined && index2 !== undefined) {
      reorderArray(arrayAtLevel, index1, index2);
    }

    return clonedArray;
  }

  const setupDragAndDrop = async () => {
    try {
      const pos = await dragAndDrop();
      const dragLevel = pos.drag.index.split(".").slice(0, -1).join(".");
      const dropLevel = pos.drop.index.split(".").slice(0, -1).join(".");
      if (dragLevel === dropLevel) {
        const dragElementId = buscarElementoPorVariable(
          _frame.elements,
          pos.drag.index
        ).variable;
        const dropElementId = buscarElementoPorVariable(
          _frame.elements,
          pos.drop.index
        ).variable;
        const result = moveElementToPosition(
          _frame.elements,
          dragElementId,
          dropElementId
        );
        const updatedFrame = {
          ..._frame,
          datacode: ogDatacode,
          elements: result,
        };
        const frameIndex = view.frames.map((el) => el._id).indexOf(_frame._id);
        const newView = { ...view };
        newView.frames[frameIndex] = updatedFrame;
        await Views.update(newView);
        setFrame(updatedFrame);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderRecursive = (element, data, path = "") => {
    if (!element.elements) element.elements = [];

    const link = element.link.includes("{")
      ? mustache.replaceMustache(element.link, data)
      : element.link;

    const label = element.label.includes("{")
      ? mustache.replaceMustache(element.label, data)
      : element.label;

    if (element.type === "array") {
      if (!Array.isArray(data) && data) data = [data];

      return (
        <div
          draggable
          data-dragdrop-object="item"
          data-dragdrop-index={element.variable}
          onDragStart={() => setupDragAndDrop()}
        >
          <Collapsible
            open={element.isOpen}
            headerStyle={{ padding: "2px 20px 2px 10px" }}
            textStyle={{ padding: "unset" }}
            key={`array_${element.variable}`}
            header={
              <div className="array-header">
                <div className="row-element">
                  <EditElement
                    element={element}
                    view={view}
                    frame={_frame}
                    setView={setView}
                    ogDatacode={ogDatacode}
                  />
                  {element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    <span>{`${label}: ${data ? data.length : 0}`}</span>
                  )}
                </div>
                <Button
                  icon="add-circle"
                  onClick={() => addItemToArray(element.variable)}
                  name={element.variable}
                  buttonStyle={buttonsStyle}
                />
              </div>
            }
          >
            {data?.map((dataItem, index) => {
              const currentPath = path
                ? `${path}.${element.variable.split(".").pop()}[${index}]`
                : `${element.variable}[${index}]`;

              const insideLabel = element.elements[0]?.label?.includes("{")
                ? mustache.replaceMustache(element.elements[0].label, dataItem)
                : element.elements[0]?.label || "";

              return element.elements[0]?.type === "object" ? (
                <Collapsible
                  key={`object_${currentPath}`}
                  headerStyle={{ padding: "2px 20px 2px 10px" }}
                  textStyle={{ padding: "unset" }}
                  open={element.elements[0].isOpen}
                  header={
                    <div className="array-header">
                      <div className="row-element">
                        <EditElement
                          element={element.elements[0]}
                          view={view}
                          frame={_frame}
                          setView={setView}
                          key={`edit_${currentPath}`}
                          ogDatacode={ogDatacode}
                        />
                        <span>{insideLabel}</span>
                      </div>

                      <Button
                        icon="delete"
                        onClick={() => deleteItemInArray(currentPath)}
                        name={currentPath}
                        buttonStyle={buttonsStyle}
                        key={`delete_${currentPath}`}
                      />
                    </div>
                  }
                >
                  {element.elements[0].elements.map((el) => {
                    return (
                      <>
                        {renderRecursive(
                          el,
                          dataItem[el.variable.split(".").pop()],
                          currentPath
                        )}
                      </>
                    );
                  })}
                </Collapsible>
              ) : (
                element.elements.map((el) => (
                  <>{renderRecursive(el, dataItem, currentPath)}</>
                ))
              );
            })}
          </Collapsible>
        </div>
      );
    } else if (element.type === "object") {
      const currentPath = `${path}${path ? "." : ""}${element.variable}`;
      return (
        <div
          style={{ display: "flex", gap: "10px" }}
          draggable
          data-dragdrop-object="item"
          data-dragdrop-index={element.variable}
          onDragStart={() => setupDragAndDrop()}
        >
          <EditElement
            element={element}
            view={view}
            frame={_frame}
            setView={setView}
            key={`edit_${currentPath}`}
            ogDatacode={ogDatacode}
          />
          <Collapsible
            headerStyle={{ padding: "2px 20px 2px 10px" }}
            textStyle={{ padding: "unset" }}
            open={element.isOpen}
            key={`object_${currentPath}`}
            header={
              <div className="array-header">
                {element.link ? (
                  <a href={link} target="_blank" rel="noreferrer">
                    {label}
                  </a>
                ) : (
                  <span>{`${label}`}</span>
                )}
              </div>
            }
          >
            {element.elements.map((el) => (
              <>{renderRecursive(el, data[el.variable.split(".").pop()], currentPath)}</>
            ))}
          </Collapsible>
        </div>
      );
    } else {
      if (element.required === "no" && !data) return;
      const renderedElement = renderElement(element, data, path);
      return <div className="container-value-show">{renderedElement}</div>;
    }
  };

  const renderElement = (element, data = null, path = null) => {
    let name = element.variable;
    if (path) {
      const prop = element.variable.split(".").pop();
      name = `${path}.${prop}`;
    }
    if (!element) return;

    const newElement = { ...element, variable: `{{${element.variable}}}` };
    const label = element.label.includes("{")
      ? mustache.replaceMustache(element.label, item)
      : element.label;

    const value = data ?? mustache.replaceMustache(newElement.variable, item);
    const link = element.link.includes("{")
      ? mustache.replaceMustache(newElement.link, item)
      : element.link;
    if (config.mode === "edit") {
      switch (element.type) {
        case "boolean": {
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={() => setupDragAndDrop()}
            >
              <EditElement
                element={element}
                view={view}
                frame={_frame}
                setView={setView}
                config={{ duplicateElement: true }}
                key={`edit_${name}`}
                ogDatacode={ogDatacode}
              />
              <Input
                type="checkbox"
                name={name}
                onBlur={onChangeValue}
                placeholder={element.default}
                defaultValue={value}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                key={`input_${name}`}
              />
            </div>
          );
        }
        case "enum": {
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={() => setupDragAndDrop()}
            >
              <EditElement
                element={element}
                view={view}
                frame={_frame}
                setView={setView}
                config={{ duplicateElement: true }}
                key={`edit_${name}`}
                ogDatacode={ogDatacode}
              />
              <Select
                key={`input_${name}`}
                height="34px"
                name={name}
                options={element.enum ?? []}
                placeholder={element.default}
                onChange={(e) => onChangeValue(e, name)}
                value={
                  element.enum
                    ? element.enum.find((opt) => opt.value === String(value))
                    : value
                    ? { label: String(value), value: String(value) }
                    : null
                }
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                activeLabel={true}
                isMulti={element.config?.isMulti ?? false}
              />
            </div>
          );
        }
        default: {
          let showValue = value || "";
          if (
            (element.type === "datetime-local" || element.type === "date") &&
            showValue
          ) {
            const validDate = Date.parse(showValue);
            if (isNaN(validDate)) {
              let splitChar = "";
              if (showValue.includes("/")) splitChar = "/";
              else if (showValue.includes("-")) splitChar = "-";

              const trozos = showValue.split(splitChar);
              const newDate = `${trozos[2]}/${trozos[1]}/${trozos[0]}`;
              showValue = Date.parse(newDate);
            }
          }
          if (showValue.length >= 75 && element.type !== "url") {
            return (
              <div
                className="row-element"
                draggable
                data-dragdrop-object="item"
                data-dragdrop-index={element.variable}
                onDragStart={() => setupDragAndDrop()}
              >
                <EditElement
                  element={element}
                  view={view}
                  frame={_frame}
                  setView={setView}
                  config={{ duplicateElement: true }}
                  key={`edit_${name}`}
                  ogDatacode={ogDatacode}
                />
                <TextArea
                  name={name}
                  onBlur={onChangeValue}
                  placeholder={element.default}
                  defaultValue={showValue}
                  label={
                    element.link ? (
                      <a href={link} target="_blank" rel="noreferrer">
                        {label}
                      </a>
                    ) : (
                      label
                    )
                  }
                  key={`input_${name}`}
                />
              </div>
            );
          }
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={() => setupDragAndDrop()}
            >
              <EditElement
                element={element}
                view={view}
                frame={_frame}
                setView={setView}
                config={{ duplicateElement: true }}
                key={`edit_${name}`}
                ogDatacode={ogDatacode}
              />
              <Input
                type={element.type === "string" ? "text" : element.type}
                name={name}
                onBlur={onChangeValue}
                placeholder={element.default}
                defaultValue={showValue}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                key={`input_${name}`}
                style={{ height: "30px" }}
              />
            </div>
          );
        }
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "baseline",
          }}
          draggable
          data-dragdrop-object="item"
          data-dragdrop-index={element.variable}
          onDragStart={() => setupDragAndDrop()}
        >
          <EditElement
            element={element}
            view={view}
            frame={_frame}
            setView={setView}
            config={{ duplicateElement: true }}
            key={`edit_${name}`}
            ogDatacode={ogDatacode}
          />
          <h3 style={{ margin: 0 }}>{element ? label : null}:</h3>
          <Value
            type={element.type === "string" ? "text" : element.type}
            content={value}
            options={element ? element.options : []}
            key={`show_${name}`}
          />
        </div>
      );
    }
  };

  if (!item) return <Title text="Cargando datos..." />;

  return (
    <>
      <div className="show-dossier-dashboard-container">
        <div className="show-container">
          {isOpen ? (
            isMobile ? (
              <div className="full-screen-dossier">
                <Suspense fallback={<div>Loading...</div>}>
                  <DossierDashboard
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    scope={frame.scope}
                    datacode={frame.datacode}
                    item={item}
                    menuPosition={metaWindowMenuPosition}
                  />
                </Suspense>
              </div>
            ) : (
              <PanelGroup direction="horizontal">
                <Panel>
                  <div className="clipper">
                    <div className="top-line top-line-show">
                      <Button
                        key="changemode"
                        icon={config.mode === "show" ? "edit" : "visibility"}
                        onClick={() => {
                          const newConfig = {
                            ...config,
                            mode: config.mode === "show" ? "edit" : "show",
                          };
                          setConfig(newConfig);
                        }}
                      />
                      {config.mode === "edit" && (
                        <Button key="save" icon="save" onClick={save} />
                      )}
                    </div>
                    <div className="show-content" data-droppable="true">
                      {_frame.elements.map((el) => {
                        let objData = getDeepValue(item, el.variable);
                        if (!objData) objData = defaultData(el.type);
                        return <>{renderRecursive(el, objData)}</>;
                      })}
                    </div>
                  </div>
                </Panel>
                <PanelResizeHandle className="resizer" />
                <Panel>
                  {isOpen ? (
                    <span
                      className="dashboard-floating-resize-actions-close"
                      onClick={handleCloseModal}
                    >
                      &rarr;
                    </span>
                  ) : null}
                  <Suspense fallback={<div>Loading...</div>}>
                    <DossierDashboard
                      isOpen={isOpen}
                      onClose={handleCloseModal}
                      scope={frame.scope}
                      datacode={frame.datacode}
                      item={item}
                      menuPosition={metaWindowMenuPosition}
                    />
                  </Suspense>
                </Panel>
              </PanelGroup>
            )
          ) : (
            <div className="clipper">
              <div className="top-line top-line-show">
                <Button
                  key="changemode"
                  icon={config.mode === "show" ? "edit" : "visibility"}
                  onClick={() => {
                    const newConfig = {
                      ...config,
                      mode: config.mode === "show" ? "edit" : "show",
                    };
                    setConfig(newConfig);
                  }}
                />
                {config.mode === "edit" && (
                  <Button key="save" icon="save" onClick={save} />
                )}
              </div>
              <div className="show-content" data-droppable="true">
                {_frame.elements.map((el) => {
                  let objData = getDeepValue(item, el.variable);
                  if (!objData) objData = defaultData(el.type);
                  return <>{renderRecursive(el, objData)}</>;
                })}
              </div>
              <MetaWindow
                onClick={onClick}
                onClickError={onClickError}
                errors={item.metadata ? item.metadata.nerrors : 0}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// Validación de PropTypes
ShowFrame.propTypes = {
  frame: PropTypes.object.isRequired,
  view: PropTypes.shape({
    frames: PropTypes.array.isRequired,
  }).isRequired,
  ogDatacode: PropTypes.string,
  configuration: PropTypes.shape({
    mode: PropTypes.string,
  }),
};

export default ShowFrame;
