// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groupExport {
  display: flex;
  flex-direction: column;
  gap:10px;
}

.export-container {
  -webkit-user-select: none;
          user-select: none; /* Evita la selección del texto */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap:10px
}

.export-title {
  font-size: 18px;
  font-weight: bold;
  text-transform:capitalize;
}

.export-value {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/routes/Export/Export.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB,EAAE,iCAAiC;EACpD,eAAe,EAAE,yCAAyC;EAC1D,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB;AACF;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".groupExport {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap:10px;\r\n}\r\n\r\n.export-container {\r\n  user-select: none; /* Evita la selección del texto */\r\n  cursor: pointer; /* Cambia el cursor al pasar por encima */\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  text-align: center;\r\n  gap:10px\r\n}\r\n\r\n.export-title {\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n  text-transform:capitalize;\r\n}\r\n\r\n.export-value {\r\n  text-transform: capitalize;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
