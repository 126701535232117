// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-service-generalInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 30%;
  gap: 5px;
}

.service-generalInfo-label {
  justify-content: center;
  width: auto;
  display: flex;
  align-items: center;
  background-color: red;
}

.status-service-generalInfo-name {
  font-size: x-large;
}

.status-service-generalInfo-label {
  display: flex;
  align-items: baseline;
}

.status-service-generalInfo-label-name {
  font-size: large;
}
.status-service-generalInfo-version {
  gap: 5px;
}

@media (max-width: 600px) {
  .status-service-generalInfo {
    padding: 10px;
    width: -webkit-fill-available;
  }
  .status-service-generalInfo-name {
    display: flex;
    justify-content: center;
  }
}

/* Estilos para pantallas medianas (tabletas) */
@media (min-width: 601px) and (max-width: 1024px) {
  .status-service-generalInfo {
    padding: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Status/StatusGeneralInfo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,aAAa;IACb,6BAA6B;EAC/B;EACA;IACE,aAAa;IACb,uBAAuB;EACzB;AACF;;AAEA,+CAA+C;AAC/C;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".status-service-generalInfo {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-bottom: 10px;\r\n  width: 30%;\r\n  gap: 5px;\r\n}\r\n\r\n.service-generalInfo-label {\r\n  justify-content: center;\r\n  width: auto;\r\n  display: flex;\r\n  align-items: center;\r\n  background-color: red;\r\n}\r\n\r\n.status-service-generalInfo-name {\r\n  font-size: x-large;\r\n}\r\n\r\n.status-service-generalInfo-label {\r\n  display: flex;\r\n  align-items: baseline;\r\n}\r\n\r\n.status-service-generalInfo-label-name {\r\n  font-size: large;\r\n}\r\n.status-service-generalInfo-version {\r\n  gap: 5px;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .status-service-generalInfo {\r\n    padding: 10px;\r\n    width: -webkit-fill-available;\r\n  }\r\n  .status-service-generalInfo-name {\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n}\r\n\r\n/* Estilos para pantallas medianas (tabletas) */\r\n@media (min-width: 601px) and (max-width: 1024px) {\r\n  .status-service-generalInfo {\r\n    padding: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
