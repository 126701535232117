export const ColumnResume = ({ resumeType, data }) => {
  const dataNumber = data.map((element) => Number(element));
  let result = 0;
  switch (resumeType) {
    case "sum":
      result = dataNumber.reduce((a, b) => a + b, 0);
      break;
    case "avg":
      result = dataNumber.reduce((a, b) => a + b, 0) / dataNumber.length;
      break;
    case "count":
      result = dataNumber.length;
      break;
    case "max":
      result = Math.max(...dataNumber);
      break;
    case "min":
      result = Math.min(...dataNumber);
      break;
    default:
      result = 0;
  }
  //Representamos el numero con separaciones de miles y separaciones decimales
  const numberValue = Number(result);
  if (Number.isInteger(numberValue)) {
    return numberValue.toLocaleString("es-ES");
  }
  return numberValue.toLocaleString("es-ES", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
