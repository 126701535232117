const roleSuperadmin = () => {
    return {
        id: crypto.randomUUID(),
        name: "superadmin",
        description: "Tiene acceso completo a todas las funcionalidades y recursos del sistema sin restricciones.",
        assistants: [],
        policies: ["*"],
    };
}

const roleAdmin = () => {
    return {
        id: crypto.randomUUID(),
        name: "admin",
        description: "Responsable de la gestión del sistema, con acceso a la mayoría de los recursos y funcionalidades, pero con algunas restricciones en las operaciones críticas del sistema.",
        assistants: [],
        policies: ["*"], // Acceso a todas las políticas, similar al superadmin pero puede tener limitaciones específicas según el contexto
    };
}

export const roleDeveloper = () => {
    return {
        id: crypto.randomUUID(),
        name: "developer",
        description: "Encargado de desarrollar y mantener el sistema. Tiene permisos para gestionar entornos de desarrollo, pruebas y configuración técnica. De momento tiene todas las politicas",
        assistants: [],
        policies: [
            "*"
        ], // Políticas específicas para gestión técnica
    };
}

const roleUser = () => {
    return {
        id: crypto.randomUUID(),
        name: "user",
        description: "Usuario estándar del sistema, con permisos básicos para acceder a sus propios datos y realizar tareas comunes.",
        assistants: [],
        policies: ["readdossier", "writedossier", "deletedossier", "readagent", "talkagent", "dossierarchive", "scanarchive", "readarchive", "readassistant", "sendemail", "reademail", "classifyemail", "bank", "readcartridge", "readcheck", "readcomm", "writecomm", "readcomment", "writecomment", "conductoraction", "readdoc", "writedoc", "readenum", "writeenum", "readerror", "writeerror", "readevent", "writeevent", "writefiletype", "readflow", "writeflow", "importfile", "uploadarchive", "readinfo", "listlog", "readmeasure", "writemeasure", "readmovement", "writemovement", "exportmovement", "readquestion", "writequestion", "exportquestion", "readresume", "writeresume", "exportresume", "readsetup", "readtemplate", "readuser", "readview",], // Acceso limitado a la visualización y uso de datos propios
    };
}

export const defaultRoles = () => {
    return [
        roleSuperadmin(),
        roleAdmin(),
        roleUser(),
        roleDeveloper()
    ]
}
