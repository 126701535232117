//Estas variables solo se pueden utilizar desde react, React incorpora dotenv en sus librerias
//Definir aqui todas las variables que se van a utilizar en el proyecto

import PackageJSON from '../package.json'
import setupDefault from './_publicfiles/setup.json'
//Variables de entorno
const API_URL = process.env.REACT_APP_API_URL
//Setup
let Setup = setupDefault
const fetchSetup = async () => {
    try {
        const response = await fetch('/setup.json'); // Ruta relativa al archivo en public/
        const data = await response.json();
        Setup = { ...Setup, data }
    } catch (error) {
        //FIXME: Es necesario comunicar public en el api, para que genere el manifest y setup.json, para que pueda leer estos archivos y setear los valores correctamente 
        // alert('Error al leer el archivo setup.json')
    }
}
await fetchSetup()


export { API_URL, Setup, PackageJSON }