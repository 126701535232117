import React from "react";
import PropTypes from 'prop-types'; // Importa PropTypes
import "./StatusRunning.css";
import Value from "src/modules/components/common/Value/Value";

const StatusRunning = ({ service }) => {
  const { warning, working, init } = service;
  
  return (
    <div className="status-service-running">
      <div className="status-service-running-info">
        <div className="status-service-running-label">
          <div className="status-service-running-text">Ejecutándose</div>
          <div className="status-service-running-icon">
            {working ? "✅" : "❌"}
          </div>
        </div>
        {warning && (
          <div className="status-service-running-label">
            <div className="status-service-running-text">Alerta</div>
            <div className="status-service-running-icon">⚠️</div>
          </div>
        )}
      </div>
      <div className="status-service-launchedAt">
        <div className="status-service-launchedAt-text">Inicio</div>
        <div className="status-service-launchedAt-time">
          {init ? <Value content={init} type="fulldate" /> : "N/A"}
        </div>
      </div>
    </div>
  );
};

// Validación de props
StatusRunning.propTypes = {
  service: PropTypes.shape({
    warning: PropTypes.bool,    // warning es un booleano
    working: PropTypes.bool,    // working es un booleano
    init: PropTypes.string,     // init es un string que representa una fecha
  }).isRequired,
};

export default StatusRunning;
