import React, { useEffect, useState } from "react";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { Template as TemplateAPI } from "src/lib/api";
import {
  default as Select,
  onSelectChangeSingle,
} from "src/modules/components/common/Select/Select";
import constants from "src/definitions/constants";
import { validateProperties } from "src/lib/validateProperties";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
function TemplateEdit() {
  const location = useLocation();
  const navigate = useNavigate()
  const [queryParameterExists, setQueryParameterExists] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const parametroTemplateCode = queryParams.get("templatecode");
    if (parametroTemplateCode) {
      getTemplate(parametroTemplateCode);
      setQueryParameterExists(true);
    }
  }, []);

  let [inputValues, setInputValues] = useState({
    templatecode: "",
    name: "",
    description: "",
    scope: "",
    content: "",
    design: "",
    mode: "",
    // options:{},
    // functions:{},
    // data:{}
    filter: { public: false },
    active: true,
  });
  const scopes = Object.entries(constants.scopes).map(([key, value]) => ({
    value: value.value,
    label: value.label,
  }));
  const typeContents = Object.entries(constants.contents).map(
    ([key, value]) => ({
      value: value.value,
      label: value.label,
    })
  );
  const templateModes = Object.entries(constants.templateMode).map(
    ([key, value]) => ({
      value: value.value,
      label: value.label,
    })
  );
  const getTemplate = async (templatecode) => {
    const templateRead = await TemplateAPI.read(templatecode);
    if (!templateRead) return;
    setInputValues({
      templatecode: templateRead.templatecode,
      name: templateRead.name,
      description: templateRead.description,
      scope: templateRead.scope,
      content: templateRead.content,
      design: templateRead.design,
      mode: templateRead.mode,
      filter: parseFilter(templateRead.filter),
      // options:templateRead.options||{},
      // functions:templateRead.functions||{},
      // data:templateRead.data,
      active: templateRead.active,
    });
  };
  const createTemplate = () => {
    const propertiesToValidate = {
      templatecode: inputValues.templatecode,
      name: inputValues.name,
      description: inputValues.description,
      scope: inputValues.scope,
      mode: inputValues.mode,
      active: inputValues.active,
    };
    const errorMessage = validateProperties(propertiesToValidate);
    if (errorMessage !== "") return alert(errorMessage);
    const sendValues = {
      ...inputValues,
      filter: JSON.stringify(inputValues.filter),
    };
    TemplateAPI.create(sendValues);
  };

  const updateTemplate = () => {
    const propertiesToValidate = {
      templatecode: inputValues.templatecode,
      name: inputValues.name,
      description: inputValues.description,
      scope: inputValues.scope,
      mode: inputValues.mode,
      active: inputValues.active,
    };
    const errorMessage = validateProperties(propertiesToValidate);
    if (errorMessage !== "") return alert(errorMessage);
    const sendValues = {
      ...inputValues,
      filter: JSON.stringify(inputValues.filter),
    };
    TemplateAPI.update(sendValues);
  };

  const deleteTemplate = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar el template: ${inputValues.templatecode}?`
    );

    if (confirmation) {
      TemplateAPI.delete(inputValues.templatecode);
    }
  };

  const redirectToDesign = () => {
    navigate(`/template?templatecode=${inputValues.templatecode}`);
  };

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleInputChangeFilter = (event) => {
    let { name, value } = event.target;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setInputValues({
      ...inputValues,
      filter: {
        ...inputValues.filter,
        [name]: value,
      },
    });
  };
  const redirectTemplates = () => {
    navigate("/view/dtemplates");
  };
  return (
    <FormContainer style={{ width: "60%" }}>
      <div style={{ fontSize: 30, textAlign: "center", fontWeight: "bold" }}>
        Template
      </div>
      <div className="css-175oi2r">
        <Input
          name="templatecode"
          type="text"
          placeholder="CDC0001"
          value={inputValues.templatecode}
          onChange={handleInputChange}
          label="Código:"
          activeLabel={true}
        />
        <Input
          name="name"
          type="text"
          placeholder="Nombre"
          value={inputValues.name}
          onChange={handleInputChange}
          label="Nombre:"
          activeLabel={true}
        />
        <Input
          name="description"
          type="text"
          placeholder="Description"
          value={inputValues.description}
          onChange={handleInputChange}
          label="Descripción:"
          activeLabel={true}
        />
        <Select
          isSearchable={true}
          isClearable={false}
          name="scope"
          options={scopes}
          placeholder="Scope..."
          onChange={(event) =>
            onSelectChangeSingle(event, setInputValues, "scope")
          }
          value={scopes.find((scope) => scope.value === inputValues.scope)}
          label="Scope"
          activeLabel={true}
        />
        <Select
          isSearchable={true}
          isClearable={false}
          name="content"
          options={typeContents}
          placeholder="Content..."
          onChange={(event) =>
            onSelectChangeSingle(event, setInputValues, "content")
          }
          value={typeContents.find(
            (content) => content.value === inputValues.content
          )}
          label="Content:"
          activeLabel={true}
        />
        <Select
          isSearchable={true}
          isClearable={false}
          name="mode"
          options={templateModes}
          onChange={(event) =>
            onSelectChangeSingle(event, setInputValues, "mode")
          }
          value={templateModes.find((mode) => mode.value === inputValues.mode)}
          label="Mode:"
          activeLabel={true}
        />
        {/* <Input
          name="options"
          type="text"
          placeholder="Objeto de configuracion de html to pdf"
          value={inputValues.options}
          onChange={handleInputChange}
          label="Opciones:"
          activeLabel={true}
        />
        <Input
          name="functions"
          type="text"
          placeholder="objeto funciones de ampliacion de mustache para el rendeado en runtime"
          value={inputValues.functions}
          onChange={handleInputChange}
          label="Funciones:"
          activeLabel={true}
        /> 
        <Input
          name="data"
          type="text"
          placeholder="Se rendea el objeto data, por lo tanto si se quiere introducir un dossier data."
          value={inputValues.data}
          onChange={handleInputChange}
          label="Data:"
          activeLabel={true}
        /> 
        */}
        <Input
          name="public"
          type="checkbox"
          value={inputValues.filter.public}
          onChange={handleInputChangeFilter}
          label="Publico:"
          activeLabel={true}
        />
        <Input
          name="active"
          type="checkbox"
          value={inputValues.active}
          onChange={handleInputChange}
          label="Activo:"
          activeLabel={true}
        />
      </div>
      <div className="css-175oi2c" style={{ alignItems: "center" }}>
        <Button id="templates" onClick={redirectTemplates} content="<<" />
        {!queryParameterExists && (
          <Button
            id="createTemplate"
            onClick={createTemplate}
            content="Crear"
          />
        )}
        {queryParameterExists && (
          <Button
            id="redirectToDesign"
            onClick={redirectToDesign}
            content="✏️Diseño"
          />
        )}
        {queryParameterExists && (
          <Button
            id="updateTemplate"
            onClick={updateTemplate}
            icon="save"
          />
        )}

        {queryParameterExists && (
          <Button
            id="deleteTemplate"
            onClick={deleteTemplate}
            content="Borrar"
          />
        )}
      </div>
    </FormContainer>
  );
}

const parseFilter = (value) => {
  let result = {};
  try {
    result = JSON.parse(value);
  } catch (error) {
    console.log(error);
  }
  return result;
};
export default TemplateEdit;
