import React from "react";
import "./Disconnection.css";
const logo = "/logointro.png";
function Disconnection() {
  return (
    <div className="disconnection-container">
      <div>
        <img className="disconnection-image" src={logo} alt="logointro" />
      </div>
      <div>
        <h1>
          Lo sentimos, ha ocurrido un error. Por favor, inténtelo de nuevo más
          tarde.
        </h1>
        <h3>Nuestros tecnicos estan trabajando para solucionar el problema</h3>
      </div>
    </div>
  );
}

export default Disconnection;
