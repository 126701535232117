import React from "react";
import PropTypes from "prop-types";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Value from "../../common/Value/Value";
import { EditElement } from "../EditElement2";
import MermaidEditor from "../../common/MermaidEditor/MermaidEditor";

function PrimitiveElement({ element, data, path, ...props }) {
  const {
    mustache,
    config,
    onChangeValue,
    setupDragAndDrop,
    view,
    frame,
    setView,
    dc,
    item,
  } = props;

  let name = element.variable;
  if (path) {
    name = `${path}`;
  }

  const newElement = { ...element, variable: `{{${element.variable}}}` };
  const label =
    element.label && element.label.includes("{")
      ? mustache.replaceMustache(element.label, item)
      : element.label;

  const value = data ?? mustache.replaceMustache(newElement.variable, item);
  const link =
    element.link && element.link.includes("{")
      ? mustache.replaceMustache(element.link, item)
      : element.link;

  if (newElement.required !== "no" || (newElement.required === "no" && value)) {
    if (config.mode === "edit") {
      switch (element.type) {
        case "boolean": {
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Input
                type="checkbox"
                name={name}
                onBlur={onChangeValue}
                placeholder={element.default}
                defaultValue={value}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
              />
            </div>
          );
        }
        case "enum": {
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Select
                height="34px"
                name={name}
                options={element.enum ?? []}
                placeholder={element.default}
                onChange={(e) => onChangeValue(e, name)}
                value={
                  element.enum
                    ? element.enum.find((opt) => opt.value === String(value))
                    : value
                    ? { label: String(value), value: String(value) }
                    : null
                }
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                activeLabel={true}
                isMulti={element.config?.isMulti ?? false}
              />
            </div>
          );
        }
        case "mermaid": {
          const val = data.includes("{{")
            ? mustache.replaceMustache(data, item)
            : value;
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <MermaidEditor
                label={label}
                graph={val ?? ""}
                onChange={(value) => onChangeValue(value, name)}
              />
            </div>
          );
        }
        default: {
          let showValue = value || "";
          if (
            (element.type === "datetime-local" || element.type === "date") &&
            showValue
          ) {
            const validDate = Date.parse(showValue);
            if (isNaN(validDate) && typeof showValue === "string") {
              let splitChar = "";
              if (showValue.includes("/")) splitChar = "/";
              else if (showValue.includes("-")) splitChar = "-";

              const trozos = showValue.split(splitChar);
              const newDate = `${trozos[2]}/${trozos[1]}/${trozos[0]}`;
              showValue = Date.parse(newDate);
            }
          }
          if (showValue.length >= 75 && element.type !== "url") {
            return (
              <div
                className="row-element"
                draggable
                data-dragdrop-object="item"
                data-dragdrop-index={element.variable}
                onDragStart={setupDragAndDrop}
              >
                <EditElement
                  element={element}
                  view={view}
                  frame={frame}
                  setView={setView}
                  config={{ duplicateElement: true }}
                  dataCode={dc}
                />
                <TextArea
                  name={name}
                  onBlur={onChangeValue}
                  placeholder={element.default}
                  defaultValue={showValue}
                  label={
                    element.link ? (
                      <a href={link} target="_blank" rel="noreferrer">
                        {label}
                      </a>
                    ) : (
                      label
                    )
                  }
                />
              </div>
            );
          }
          return (
            <div
              className="row-element"
              draggable
              data-dragdrop-object="item"
              data-dragdrop-index={element.variable}
              onDragStart={setupDragAndDrop}
            >
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                config={{ duplicateElement: true }}
                dataCode={dc}
              />
              <Input
                type={element.type === "string" ? "text" : element.type}
                name={name}
                onBlur={(e) => onChangeValue(e, undefined, path, name)}
                placeholder={element.default}
                defaultValue={showValue}
                label={
                  element.link ? (
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>
                  ) : (
                    label
                  )
                }
                style={{ height: "30px" }}
              />
            </div>
          );
        }
      }
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "baseline",
          }}
          draggable
          data-dragdrop-object="item"
          data-dragdrop-index={element.variable}
          onDragStart={setupDragAndDrop}
        >
          <EditElement
            element={element}
            view={view}
            frame={frame}
            setView={setView}
            config={{ duplicateElement: true }}
            dataCode={dc}
          />
          <h3 style={{ margin: 0 }}>{label}:</h3>
          <Value
            type={element.type === "string" ? "text" : element.type}
            content={value}
            options={element.options || []}
          />
        </div>
      );
    }
  }
}

PrimitiveElement.propTypes = {
  element: PropTypes.object,
  data: PropTypes.any,
  path: PropTypes.string,
  mustache: PropTypes.object,
  config: PropTypes.object,
  onChangeValue: PropTypes.func,
  setupDragAndDrop: PropTypes.func,
  view: PropTypes.object,
  frame: PropTypes.object,
  setView: PropTypes.func,
  dc: PropTypes.string,
  deleteItemInArray: PropTypes.func,
  item: PropTypes.object,
  buttonsStyle: PropTypes.object,
};

export default React.memo(PrimitiveElement);
