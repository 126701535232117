import React from "react";
import { useDragDrop } from "./KanbanContext";
import Column from "./Column";
import EditColumn from "./EditColumn";
import EditItem from "./EditItem";
import "./KanbanBoard.css";
import Button from "src/modules/components/common/Button/Button";

export default function KanbanBoard() {
  const {
    columns,
    actualizarMenuConJerarquia,
    addNewColumn,
    showColumnEdit,
    showItemEdit,
  } = useDragDrop();

  // Añadir nueva columna
  const newColumn = () => {
    addNewColumn();
  };

  return (
    <section className="edit-menu-section">
      {showColumnEdit ? <EditColumn /> : <></>}
      {showItemEdit ? <EditItem /> : <></>}
      <div className="edit-menu-buttons">
        <Button
          content="GUARDAR"
          disabled={false}
          onClick={actualizarMenuConJerarquia}
        />
      </div>
      {Object.entries(columns).map(([name, items]) => {
        // Filtrar los nombres que no se quieren renderizar
        const excludedNames = ["logout", "login", "register"];
        if (!excludedNames.includes(name)) {
          return <Column key={name} name={name} items={items} />;
        }
        return null; // Retornar null si el nombre está excluido o no es visible
      })}
      <div
        className="edit-menu-column-add"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          icon="add"
          disabled={false}
          onClick={newColumn}
          style={{ minWidth: "0px" }}
        />
      </div>
    </section>
  );
}
