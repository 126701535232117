import React from "react";
import Input from "src/modules/components/common/Input/Input";
// import "./InputsVCFrame.css";
import { useViewCreateContext } from "../ViewCreateContext.js";
import Button from "src/modules/components/common/Button/Button";
import "./InputsVCElement.css";

const InputsVCElement = () => {
  const {
    singleElementData,
    setSingleElementData,
    targetFrameName,
    targetElementName,
    handlePutElement,
  } = useViewCreateContext();

  // Controla los inputs de Element
  const handleInputChangeElement = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSingleElementData({
      ...singleElementData,
      [name]: value,
    });
  };

  // Función para actualizar el contenido de un Element.
  const handleChangeElement = () => {
    handlePutElement(
      targetFrameName.name,
      targetElementName,
      singleElementData
    );
  };

  return (
    <section className="div3-vc">
      <div className="inputs-element">
        <div className="inputs-element-son">
          {" "}
          <Input
            name="name"
            type="text"
            value={singleElementData.name}
            onChange={handleInputChangeElement}
            label="Name"
            activeLabel={true}
            placeholder="Name"
          />
          <Input
            name="variable"
            type="text"
            value={singleElementData.variable}
            onChange={handleInputChangeElement}
            label="Variable"
            activeLabel={true}
            placeholder="{{scope.header.name}}"
          />
          <Input
            name="label"
            type="text"
            value={singleElementData.label}
            onChange={handleInputChangeElement}
            label="Label"
            activeLabel={true}
            placeholder="Nombre del expediente"
          />
          <Input
            name="icon"
            type="text"
            value={singleElementData.icon}
            onChange={handleInputChangeElement}
            label="Icon"
            activeLabel={true}
            placeholder="sumarize / receipt_long / picture_as_pdf"
          />
          <Input
            name="link"
            type="text"
            placeholder="/view/dmovements?dc={{dossier.header.code}}"
            value={singleElementData.link}
            onChange={handleInputChangeElement}
            label="Link"
            activeLabel={true}
          />
        </div>
        <div className="inputs-element-son">
          <Input
            name="type"
            type="text"
            placeholder="String"
            value={singleElementData.type}
            onChange={handleInputChangeElement}
            label="Type"
            activeLabel={true}
          />
          <Input
            name="align"
            type="text"
            placeholder="String"
            value={singleElementData.align}
            onChange={handleInputChangeElement}
            label="Align"
            activeLabel={true}
          />
          <Input
            name="format"
            type="text"
            placeholder="String"
            value={singleElementData.format}
            onChange={handleInputChangeElement}
            label="Format"
            activeLabel={true}
          />
          <Input
            name="options"
            type="text"
            placeholder='{"filter":true}'
            value={singleElementData.options}
            onChange={handleInputChangeElement}
            label="Options"
            activeLabel={true}
          />

          <div className="inputs-element-button-bar">
          <Button
        content="  Guardar cambios en Element"
        disabled={false}
        onClick={handleChangeElement}
      />
      
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputsVCElement;
