// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 32px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 15px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--font-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 4px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 4px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 28px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }`, "",{"version":3,"sources":["webpack://./src/modules/components/common/LoadingSpinner/LoadingSpinner.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,6BAA6B;IAC7B,mDAAmD;EACrD;EACA;IACE,SAAS;IACT,sCAAsC;EACxC;EACA;IACE,SAAS;IACT,sCAAsC;EACxC;EACA;IACE,UAAU;IACV,sCAAsC;EACxC;EACA;IACE,UAAU;IACV,sCAAsC;EACxC;;EAEA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;EACA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;EACA;IACE;MACE,0BAA0B;IAC5B;IACA;MACE,6BAA6B;IAC/B;EACF","sourcesContent":[".lds-ellipsis {\r\n    display: inline-block;\r\n    position: relative;\r\n    width: 44px;\r\n    height: 32px;\r\n  }\r\n  .lds-ellipsis div {\r\n    position: absolute;\r\n    top: 15px;\r\n    width: 7px;\r\n    height: 7px;\r\n    border-radius: 50%;\r\n    background: var(--font-color);\r\n    animation-timing-function: cubic-bezier(0, 1, 1, 0);\r\n  }\r\n  .lds-ellipsis div:nth-child(1) {\r\n    left: 4px;\r\n    animation: lds-ellipsis1 0.6s infinite;\r\n  }\r\n  .lds-ellipsis div:nth-child(2) {\r\n    left: 4px;\r\n    animation: lds-ellipsis2 0.6s infinite;\r\n  }\r\n  .lds-ellipsis div:nth-child(3) {\r\n    left: 16px;\r\n    animation: lds-ellipsis2 0.6s infinite;\r\n  }\r\n  .lds-ellipsis div:nth-child(4) {\r\n    left: 28px;\r\n    animation: lds-ellipsis3 0.6s infinite;\r\n  }\r\n\r\n  @keyframes lds-ellipsis1 {\r\n    0% {\r\n      transform: scale(0);\r\n    }\r\n    100% {\r\n      transform: scale(1);\r\n    }\r\n  }\r\n  @keyframes lds-ellipsis3 {\r\n    0% {\r\n      transform: scale(1);\r\n    }\r\n    100% {\r\n      transform: scale(0);\r\n    }\r\n  }\r\n  @keyframes lds-ellipsis2 {\r\n    0% {\r\n      transform: translate(0, 0);\r\n    }\r\n    100% {\r\n      transform: translate(12px, 0);\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
