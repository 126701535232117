import React, { useCallback, useEffect, useState, useRef } from "react";
import { createRoot } from "react-dom/client";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { View as Views, Dossier, Doc, Resume } from "src/lib/api.js";
import Graph from "src/modules/components/common/Graph/Graph";
import Icon from "@mui/material/Icon";
// Componentes de Frames
import ListFrame from "src/modules/components/frame/ListFrame/ListFrame";
import PageFrame from "src/modules/components/frame/PageFrame/PageFrame";
import ShowFrame from "src/modules/components/frame/ShowFrame/ShowFrame";
import TemplateFrame from "src/modules/components/frame/TemplateFrame/TemplateFrame";
import DropDownBox from "src/modules/components/common/DropDownBox/DropDownBox";
import Input from "src/modules/components/common/Input/Input";
import { EditFrame } from "src/modules/components/frame/EditFrame";
import PropTypes from "prop-types";

function View() {
  const [showDropDown, setShowDropDown] = useState(false);
  let { vid } = useParams();
  const location = useLocation();
  const { role, publicSetup, setNavBarTitle } = useAppContext();

  if (!vid) {
    vid = role ? publicSetup?.loggeddefaultview : publicSetup?.defaultview;
  }

  const [viewdata, setViewdata] = useState({});
  const [viewcontent, setviewcontent] = useState(
    <>
      <p>Procesando view {vid} ...</p>
    </>
  );
  const [subviewcontent, setsubviewcontent] = useState([]);

  // Referencia para renderData para evitar ciclo de dependencias
  const renderDataRef = useRef();

  const renderData = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);
    const data = await Views.read(vid);
    setViewdata(data);
    if (data) {
      if (data.title) setNavBarTitle(data.title);
      const { components, graphs } = await premotor(
        data,
        searchParams,
        renderDataRef.current
      );
      setviewcontent(components);
      setsubviewcontent(graphs);
    } else {
      setviewcontent(<p>View &quot;{vid}&quot; no encontrado.</p>);
    }
  }, [vid, location.search, setNavBarTitle]);

  // Actualizar la referencia
  useEffect(() => {
    renderDataRef.current = renderData;
  }, [renderData]);

  useEffect(() => {
    renderData();
  }, [vid, location, renderData]);

  useEffect(() => {
    subviewcontent.forEach((graph, index) => {
      const element = document.getElementById(`viaGraph-${index + 1}`);
      if (element) {
        const newContent = <Graph chart={graph} />;
        const root = createRoot(element);
        root.render(newContent);
      }
    });
  }, [subviewcontent]);

  const updateView = async () => {
    await Views.update(viewdata);
    setNavBarTitle(viewdata.title);
  };

  return (
    <div className="content-container">
      <div className="groupSetup">
        {publicSetup.developmode &&
          (role === "developer" || role === "superadmin") && (
            <div
              className="editColumnsModal"
              style={{
                position: "fixed",
                top: "5px",
                left: "150px",
                zIndex: 1000,
              }}
              title="Haga click para editar la vista."
            >
              <Icon
                style={{
                  color: "orange",
                  border: "2px solid blue",
                  borderRadius: "50%",
                }}
                onClick={(e) => {
                  setShowDropDown(true);
                }}
              >
                edit
              </Icon>
              {showDropDown && (
                <EditColumnsModal
                  data={viewdata}
                  setData={setViewdata}
                  showDropDown={showDropDown}
                  setShowDropDown={setShowDropDown}
                  updateView={updateView}
                />
              )}
            </div>
          )}
        <div className="setup-container" style={{ rowGap: 0 }}>
          {viewcontent}
        </div>
      </div>
    </div>
  );
}

function createHtml(html) {
  const graphs = [];
  const re = /<pre lang="mermaid">([^]*?)<\/pre>/g;
  html = html.replace(re, function (match, p1) {
    graphs.push(p1);
    return `<div id="viaGraph-${graphs.length}">Graph ${graphs.length}</div>`;
  });
  return {
    component: <div dangerouslySetInnerHTML={{ __html: html }} />,
    graphs,
  };
}

async function premotor(view, params, setView) {
  let components = [],
    graphs = [];
  for (const frame of view.frames) {
    const ogDatacode = frame.datacode
      ? JSON.parse(JSON.stringify(frame.datacode))
      : "";
    components.push(
      <EditFrame
        key={`editf_${frame._id}`}
        frame={frame}
        setView={setView}
        view={view}
        ogDatacode={ogDatacode}
      />
    );
    frame.datacode = frame.datacode
      ? mustache(frame.datacode, null, params)
      : "";
    frame.templatecode = frame.templatecode
      ? mustache(frame.templatecode, null, params)
      : "";
    if (frame.config) {
      try {
        frame.config = JSON.parse(frame.config);
      } catch (e) {
        console.log("Error parsing config", e);
      }
    } else {
      frame.config = {};
    }
    switch (frame.type) {
      case "list":
        components.push(
          <ListFrame
            key={`list_${frame._id}`}
            frame={frame}
            viewId={view.viewcode}
          />
        );
        break;
      case "page":
        components.push(
          <PageFrame
            key={`page_${frame._id}`}
            config={frame.config}
            url={frame.datacode}
          />
        );
        break;
      case "show":
        components.push(
          <ShowFrame
            key={`show_${frame._id}`}
            frame={frame}
            viewId={view.viewcode}
            view={view}
            ogDatacode={ogDatacode}
          />
        );
        break;
      case "template":
        components.push(
          <TemplateFrame key={`template_${frame._id}`} frame={frame} />
        );
        break;
      default:
        components.push(
          <p key={`ns_${frame.type}`}>
            Frame &quot;{frame.type}&quot; no soportado.
          </p>
        );
    }

    if (frame.type === "template") {
      // Comentado código para manejo de templates
    } else if (frame.type === "graph") {
      if (frame.scope === "doc") {
        const data = await Doc.graph(frame.datacode);
        if (data) {
          components.push(<Graph chart={data} />);
        } else {
          components.push(
            <p>Doc Graph &quot;{frame.datacode}&quot; no encontrado.</p>
          );
        }
      } else if (frame.scope === "resume") {
        const data = await Resume.graph(frame.datacode);
        if (data) {
          components.push(<Graph chart={data} />);
        } else {
          components.push(
            <p>Resume Graph &quot;{frame.datacode}&quot; no encontrado.</p>
          );
        }
      } else {
        components.push(<p>Graph</p>);
        const graphDefinition = "graph LR\nA-->B";
        components.push(<Graph chart={graphDefinition} />);
      }
    } else if (frame.type === "explain") {
      const data = await Dossier.explain(frame.datacode);
      if (data) {
        components.push(<h1>Flujos de Trabajo</h1>);
        components.push(<Graph chart={data.flowgraph.toString()} />);
        components.push(<h1>Gráfico de tiempo</h1>);
        components.push(<Graph chart={data.timegraph.toString()} />);
        components.push(<h1>Estructura del dossier</h1>);
        components.push(<Graph chart={data.structuregraph.toString()} />);
        components.push(<h1>Diagrama de datos</h1>);
        components.push(<Graph chart={data.datagraph.toString()} />);
        const { component, graphs: subgraphs } = createHtml(
          data.content.toString()
        );
        graphs = graphs.concat(subgraphs);
        components.push(component);
      } else {
        components.push(
          <p>Explain del dossier &quot;{frame.datacode}&quot; no encontrado.</p>
        );
      }
    }
  }

  return { components, graphs };
}

function mustache(text, variables, params) {
  if (!text) return "";
  const re = /{{([^}]+)}}/g;
  return text.replace(re, function (match, p1) {
    const subvars = p1.split(".");
    if (subvars[0] === "params") {
      return params.get(subvars[1]);
    }
    return variables ? variables[p1] : "";
  });
}

const EditColumnsModal = ({
  data,
  setData,
  showDropDown,
  setShowDropDown,
  updateView,
  ...props
}) => {
  const { created, updated, frames, who, ...view } = data;
  const navigate = useNavigate()
  const onChangeInput = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    const newData = {
      ...data,
      [name]: value,
    };
    setData(newData);
  };
  const deleteView = async (viewcode) => {
    await Views.delete(viewcode);
    navigate(`/`);
  };
  return (
    <DropDownBox setOpen={setShowDropDown} onClosed={updateView} title="Editor de vista">
      <div className="css-175oi2r">
        <div className="edit-viewcode-title-container">
          <div className="edit-viewcode-title">{data.viewcode}</div>
          <div className="edit-viewcode-buttons">
            <div className="edit-viewcode-button-edit">
              <Icon
                title="Haz click para editar la vista"
                onClick={(e) => {
                  navigate(`/view/edit?viewcode=${data.viewcode}`);
                }}
              >
                edit
              </Icon>
            </div>
            <div className="edit-viewcode-button-delete">
              <Icon
                title="Haz click para eliminar la vista"
                onClick={() => {
                  deleteView(data.viewcode);
                }}
              >
                delete
              </Icon>
            </div>
          </div>
        </div>
        <>
          <Input
            label={"Nombre"}
            name={"name"}
            value={view["name"]}
            onChange={(e) => {
              onChangeInput(e);
            }}
            activeLabel={true}
            placeholder="Lista de clientes"
          />
          <Input
            label={"Titulo"}
            name={"title"}
            value={view["title"]}
            onChange={(e) => {
              onChangeInput(e);
            }}
            activeLabel={true}
            placeholder="Clientes"
          />
          <Input
            label={"Publico"}
            name={"public"}
            value={view["public"]}
            onChange={(e) => {
              onChangeInput(e);
            }}
            type="checkbox"
          />
          <Input
            label={"System"}
            name={"system"}
            value={view["system"]}
            onChange={(e) => {
              onChangeInput(e);
            }}
            type="checkbox"
          />
          <Input
            label={"Activo"}
            name={"active"}
            value={view["active"]}
            onChange={(e) => {
              onChangeInput(e);
            }}
            type="checkbox"
          />
        </>
      </div>
    </DropDownBox>
  );
};

// Agregar las PropTypes
EditColumnsModal.propTypes = {
  data: PropTypes.shape({
    created: PropTypes.string,
    updated: PropTypes.string,
    frames: PropTypes.array,
    who: PropTypes.string,
    viewcode: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    public: PropTypes.bool,
    system: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
  setData: PropTypes.func.isRequired,
  showDropDown: PropTypes.bool.isRequired,
  setShowDropDown: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
};

export default View;
