import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

let observer = null;
let observerCallbacks = new Map();

const getObserver = (root, rootMargin, threshold) => {
  if (!observer || observer.root !== root) {
    observer = new IntersectionObserver(handleIntersections, {
      root: root ?? undefined,
      rootMargin,
      threshold,
    });
  }
  return observer;
};

const handleIntersections = (entries) => {
  entries.forEach((entry) => {
    const callback = observerCallbacks.get(entry.target);
    if (callback) {
      callback(entry);
    }
  });
};

const LazyLoad = ({ children, rootMargin = "200px 0px 200px 0px", threshold = 0.1, root }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleIntersection = useCallback(
    (entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
        observerCallbacks.delete(entry.target);
      }
    },
    [setIsVisible]
  );

  useEffect(() => {
    const currentObserver = getObserver(root, rootMargin, threshold);
    const currentRef = ref.current;

    if (currentRef) {
      observerCallbacks.set(currentRef, handleIntersection);
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
        observerCallbacks.delete(currentRef);
      }
    };
  }, [handleIntersection, rootMargin, threshold, root]);

  return <div ref={ref}>{isVisible ? children : null}</div>;
};

LazyLoad.propTypes = {
  children: PropTypes.node.isRequired,
  rootMargin: PropTypes.string,
  threshold: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  root: PropTypes.object,
};

export default LazyLoad;
