import React from 'react';
import './Notifications.css';
import PropTypes from "prop-types"
function Notifications({ notifications }) {
  return (
    <div className="user-activity-dashboard__notifications--hidden">
      <div className="notifications__header">
        <h2 className="user-activity-dashboard__notifications-title">Notificaciones</h2>
      </div>
      <div className="user-activity-dashboard__notifications-items">
        {notifications.map((notification, index) => (
          <div key={index} className="notification__item">
            {notification}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Notifications;

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired
}