import React from "react";
import PropTypes from "prop-types";
import Collapsible from "../../common/Collapsible/Collapsible";
import { EditElement } from "../EditElement2";
import ElementRenderer from "./ElementRenderer";
import Button from "src/modules/components/common/Button/Button";

function ObjectElement({ element, data, path, ...props }) {
  const {
    mustache,
    setupDragAndDrop,
    view,
    frame,
    setView,
    dc,
    deleteItemInArray,
  } = props;

  const link =
    element.link && element.link.includes("{")
      ? mustache.replaceMustache(element.link, data)
      : element.link;

  const label =
    element.label && element.label.includes("{")
      ? mustache.replaceMustache(element.label, data)
      : element.label;

  return (
    <div
      style={{ display: "flex", gap: "10px" }}
      draggable
      data-dragdrop-object="item"
      data-dragdrop-index={element.variable}
      onDragStart={setupDragAndDrop}
    >
      <EditElement
        element={element}
        view={view}
        frame={frame}
        setView={setView}
        dataCode={dc}
      />
      <Collapsible
        headerStyle={{ padding: "2px 20px 2px 10px" }}
        textStyle={{ padding: "unset" }}
        open={element.isOpen}
        header={
          <div className="array-header">
            <div className="row-element">
              {element.link ? (
                <a href={link} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ) : (
                <span>{`${label}`}</span>
              )}
            </div>
            <Button
              icon="delete"
              onClick={() => deleteItemInArray(path)}
              name={path}
              buttonStyle={props.buttonsStyle}
            />
          </div>
        }
      >
        {element.elements?.map((el, elIndex) => (
          <ElementRenderer
            key={`${path}_${elIndex}`}
            element={el}
            data={data ? data[el.variable.split(".").pop()] : null}
            path={path  + "." + el.variable.split('.').pop()}
            {...props}
          />
        ))}
      </Collapsible>
    </div>
  );
}

ObjectElement.propTypes = {
  element: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  path: PropTypes.string,
  mustache: PropTypes.object,
  setupDragAndDrop: PropTypes.func,
  view: PropTypes.object,
  frame: PropTypes.object,
  setView: PropTypes.func,
  dc: PropTypes.string,
  deleteItemInArray: PropTypes.func,
  buttonsStyle: PropTypes.object,
};

export default React.memo(ObjectElement);
