import React, { useEffect, useState } from "react";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Test from "src/routes/Checks/Test/Test";
import { Check as CheckAPI } from "src/lib/api";
import Title from "src/modules/components/common/Title/Title";
import PropTypes from "prop-types";
import "../Checks.css";

const TestCheck = ({ check }) => {
  const [scopecode, setScopecode] = useState(null);
  const [result, setResult] = useState({});
  const [passedTests, setPassedTests] = useState([]);
  const [notPassedTests, setNotPassedTests] = useState([]);
  const [usedVariables, setUsedVariables] = useState([]);

  const testCheck = async () => {
    if (!scopecode) return alert("Introduzca un scopeCode");

    const responseData = await CheckAPI.test(scopecode, check);
    setResult(responseData.result);
    // Actualizar la segunda columna con los datos recibidos
    setPassedTests(
      responseData.result.tests.filter((test) => test.result === true)
    );
    setNotPassedTests(
      responseData.result.tests.filter((test) => {
        if (test.result === false || test.error) {
          return test;
        }
        return null;
      })
    );
    setUsedVariables(responseData.result.vars.map((variable) => variable.name));
  };

  useEffect(() => {
    setResult({});
    setPassedTests([]);
    setNotPassedTests([]);
    setUsedVariables([]);
  }, []);

  const calculateTotalDelay = (tests) => {
    let totalDelay = 0;

    for (const test of tests) {
      totalDelay += test.delay;
    }

    return totalDelay;
  };

  return (
    <div className="group-container">
      <div>
        <Title text="Test" />
        <div className="actions-buttons">
          <Input
            name="scopecode"
            type="text"
            placeholder="ScopeCode"
            defaultValue={scopecode}
            onBlur={(e) => setScopecode(e.target.value)}
            label="Código de Item"
            activeLabel={true}
          />
          <Button
            onClick={testCheck}
            icon="science"
            
          />
        </div>
        {result.name && (
          <div style={{ paddingTop: "20px" }}>
            <Title text="Resultados:" />
            <p>
              <strong>Name:</strong> {result.name}
            </p>
            <p>
              <strong>Check Code:</strong> {result.testcode}
            </p>
            <p>
              <strong>Delay:</strong> {result.delay}
            </p>
            <p>
              <strong>Total tests:</strong> {result.tests.length}
            </p>
            <p>
              <strong>Structure:</strong> : {JSON.stringify(result.structure)}
            </p>
            <ul
              style={{
                listStyle: "inside",
                margin: "0",
                padding: "0",
                color: "white",
              }}>
              <p>
                <strong>Variables usadas:</strong>
              </p>{" "}
              {usedVariables.map((variable, index) => (
                <li
                  style={{ marginLeft: "20px" }}
                  key={index}>
                  {variable}
                </li>
              ))}
            </ul>
            <div className="test-section actions-buttons-container">
              <div className="columnCheck">
                <h3 style={{ color: "white" }}>Passed {passedTests.length}</h3>
                <div style={{ color: "white" }}>
                  Delay: {calculateTotalDelay(passedTests)}
                </div>
                <br />
                {passedTests.map((test, index) => (
                  <Test
                    element={test}
                    key={index}
                  />
                ))}
              </div>
              <div className="columnCheck">
                <h3 style={{ color: "white" }}>
                  Not Passed {notPassedTests.length}
                </h3>
                <div style={{ color: "white" }}>
                  Delay: {calculateTotalDelay(notPassedTests)}
                </div>
                <br />
                {notPassedTests.map((test, index) => (
                  <Test
                    element={test}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TestCheck.propTypes = {
  check: PropTypes.instanceOf({
    name: PropTypes.string,
  }),
};

export default TestCheck;
