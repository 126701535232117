import React from 'react';
import PropTypes from 'prop-types';
import './BoxFloat.css';
import Button from '../Button/Button';

/**
 * BoxFloat component renders a floating box overlay with optional title, footer, and close button.
 * 
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the floating box.
 * @param {Function} props.onClickExit - The function to be called when the overlay is clicked.
 * @param {string} [props.title] - The title to be displayed at the top of the floating box.
 * @param {Function} [props.onClose] - The function to be called when the close button is clicked.
 * @param {React.ReactNode} [props.footer] - The content to be displayed in the footer of the floating box.
 * 
 * @returns {JSX.Element} The rendered BoxFloat component.
 */
const BoxFloat = ({ children, onClickExit, title = "", onClose = undefined, footer = null, ...props }) => {
    return (
        <div className="box-float-overlay" onClick={onClickExit}>
            <div className="box-float" onClick={(e) => e.stopPropagation()}>
                {title && <div className="box-float-title">{title}</div>}
                {onClose !== undefined && (
                    <div className="boxfloat-deleteButton">
                        <Button
                            icon="close"
                            style={{ minWidth: "0px", minHeight: "0px" }}
                            onClick={onClose}
                        />
                    </div>
                )}
                <div className="box-float-content">
                    {children}
                </div>
                <div className="box-float-footer">
                    {footer}
                </div>
            </div>
        </div>
    );
};

BoxFloat.propTypes = {
    children: PropTypes.node.isRequired,
    onClickExit: PropTypes.func.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func,
    footer: PropTypes.node,
};

export default BoxFloat;
