/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";
export class Dev {
    static async fillmenu() {
        const response = await HttpMethods.request_get("/v1/dev/fillmenu");
        if (!response) alert("Error al resetear los menus");
        return response;
    }
    static async action(data) {
        const response = await HttpMethods.request_post("/v1/dev/action", data);
        if (!response) alert("Error al enviar la acción");
        return response;
    }
}