import React from "react";
import PropTypes from "prop-types";
import './List.css';

const TableRow = ({ isHeader = false, children }) => {
  return (
    <span className={`lf-table-row ${isHeader ? "lf-header" : ""}`}>
      {children}
    </span>
  );
};

TableRow.propTypes = {
  isHeader: PropTypes.bool,
  children: PropTypes.node.isRequired, 
};

export default TableRow;
