import React, { useEffect, useState } from "react";
import Table from "src/modules/components/common/Table/Table";
import { Cartridge, Dossier } from "src/lib/api";
import Button from "src/modules/components/common/Button/Button";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Flujos = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [data, setData] = useState(
    { ...setupInfo }.flows?.map((flow, index) => {
      const id = index;
      return { ...flow, id };
    })
  );
  const [cartridgeOptions, setCartridgeOptions] = useState([]);
  const [showAddFlows, setShowAddFlows] = useState(false);
  const [showEditFlow, setShowEditFlow] = useState(false);
  const [rowValues, setRowValues] = useState({});
  const [fileTypeOptions, setFileTypeOptions] = useState([]);

  useEffect(() => {
    function fetch() {
      Cartridge.list()
        .then((res) => {
          return res.map((cartridge) => {
            return { value: cartridge.cartridgecode, label: cartridge.name };
          });
        })
        .then((res) => setCartridgeOptions(res));
      Dossier.filetypes().then((res) => {
        const typesOptions = res.map((filetype) => {
          return { value: filetype.name, label: filetype.name };
        });
        setFileTypeOptions(typesOptions);
      });
    }

    fetch();
  }, [setCartridgeOptions, setFileTypeOptions]);

  useEffect(() => {
    setSetupInfo((prev) => {
      return {
        ...prev,
        flows: data,
      };
    });
  }, [data, setSetupInfo]);

  const deleteRow = (e) => {
    const idElement = e.id;
    const newData = data.filter((item) => item.id !== idElement);
    setData(newData);
  };
  const editRow = (e) => {
    setShowEditFlow(true);
    setRowValues(e);
  };
  const setNewDataValues = (e) => {
    setData((prev) => {
      return [
        ...prev,
        {
          ...e,
          id: prev.length,
        },
      ];
    });
    setShowAddFlows(false);
  };
  const updateDataValues = (e) => {
    const newData = data.map((item) => {
      if (item.id === rowValues.id) {
        return { ...e, id: item.id };
      }
      return item;
    });
    setData(newData);
    setShowEditFlow(false);
  };
  const columns = [
    {
      label: "Nombre",
      key: "name",
      type: "text",
    },
    {
      label: "Filetype",
      key: "filetype",
      type: "text",
    },
    {
      label: "Sujeto",
      key: "subject",
      type: "string",
    },
    {
      label: "Cartucho",
      key: "cartridge",
      type: "string",
    },

    {
      label: "Por defecto",
      key: "default",
      type: "string",
    },
    {
      label: "Logica",
      key: "logic",
      type: "string",
    },

    {
      label: "Acciones",
      key: "table_actions",
      type: "actions",
      buttons: [
        {
          icon: "EditIcon",
          onClick: (e) => {
            editRow(e);
          },
        },
        {
          icon: "DeleteIcon",
          onClick: (e) => {
            deleteRow(e);
          },
        },
        // {
        //   icon: "keyboard_arrow_up",
        //   onClick: (e) => {
        //     // reLevel(e, "up");
        //   },
        // },
        // {
        //   icon: "keyboard_arrow_down",
        //   onClick: (e) => {
        //     // reLevel(e, "down");
        //   },
        // },
      ],
    },
  ];

  const elements = [
    {
      name: "name",
      component: "input",
      type: "text",
      label: "Nombre",
      key: "name",
      placeholder: "tramitación",
    },
    {
      name: "filetype",
      component: "select",
      type: "text",
      label: "Filetype",
      key: "filetype",
      placeholder: "expediente",
      options: fileTypeOptions,
    },
    {
      name: "subject",
      component: "input",
      type: "text",
      label: "Sujeto",
      key: "subject",
      placeholder: "dossier.file.clientes.fullname",
    },
    {
      name: "cartridge",
      component: "input",
      type: "text",
      label: "Cartucho",
      key: "cartridge",
      placeholder: "dossier.file.contrato.tramites",
    },
    {
      name: "default",
      component: "select",
      type: "text",
      label: "Por defecto",
      key: "default",
      placeholder: "tramitedefecto",
      options: cartridgeOptions,
    },

    {
      name: "logic",
      component: "input",
      type: "text",
      label: "Logica",
      key: "logic",
    },
  ];
  return (
    <div className="flujos-container">
      <div>
        {showAddFlows && (
          <BoxFloat
            title="Añadir Flujo"
            onClickExit={() => setShowAddFlows(false)}
          >
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={setNewDataValues}
            />
          </BoxFloat>
        )}
        {showEditFlow && (
          <BoxFloat
            title="Editar Flujo"
            onClickExit={() => setShowEditFlow(false)}
          >
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={updateDataValues}
              initialValues={rowValues}
            />
          </BoxFloat>
        )}
        <div
          className="buttons-flows"
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <Button content="Añadir" onClick={() => setShowAddFlows(true)} />
        </div>
        <Table columns={columns} data={data} />
      </div>
    </div>
  );
};

export default Flujos;
