import React from "react";
import PropTypes from "prop-types";
const MenuCard = ({ data, setupDragAndDrop, index, listId }) => (
  <div
    className="menu-card__container"
    draggable
    data-dragdrop-index={index} // Añadimos el índice para identificar la tarjeta
    data-dragdrop-object={`${listId}-${data.id}`} // Identificamos la tarjeta
    onDragStart={() => setupDragAndDrop()}
  >
    {data.title}
  </div>
);

export default MenuCard;

MenuCard.propTypes = {
  data: PropTypes.object.isRequired,
  setupDragAndDrop: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  listId: PropTypes.string.isRequired,
};
