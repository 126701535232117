import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import {
  User as UserAPI,
  Setup as SetupAPI,
  Auth as AuthAPI,
} from "src/lib/api.js";
import DoubleContainer from "src/modules/components/Containers/DoubleContainer/DoubleContainer";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Select, {
  onSelectChangeMulti,
} from "src/modules/components/common/Select/Select";
import Table from "src/modules/components/common/Table/Table";
import "./Users.css";

const initialInputValues = {
  username: "",
  email: "",
  password: "",
  policies: [],
  role: "",
  active: "",
  devicecheck: "",
  devices: [],
};

const User = () => {
  const { setupData, setSetupData } = useAppContext();
  const navigate = useNavigate()
  const location = useLocation();
  const [roles, setRoles] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [inputValues, setInputValues] = useState(initialInputValues);
  const [queryParameterExists, setQueryParameterExists] = useState(false);

  const fetchSetupData = useCallback(async () => {
    const newSetup = await SetupAPI.read();
    setSetupData(newSetup);
  }, [setSetupData]);

  const fetchPolicies = useCallback(async () => {
    let newPolicies = await AuthAPI.getPolicies();
    newPolicies = newPolicies.map((el) => {
      return {
        value: el.policy,
        label: el.policy,
        description: el.description,
      };
    });
    setPolicies(newPolicies);
  }, [setPolicies]);

  const fetchRoles = useCallback(() => {
    const newRoles = setupData.roles?.map((rol) => ({
      label: rol.name,
      value: rol.name,
      policies: rol.policies, // Almacenar las políticas del rol
    }));
    setRoles(newRoles);
  }, [setupData.roles]);

  const readUser = useCallback(async (email) => {
    const user = await UserAPI.read(email);
    setInputValues((prevState) => ({
      ...prevState,
      username: user.name,
      email: user.email,
      policies: user.policies,
      role: user.role ?? "",
      active: user.active ?? "",
      devicecheck: user.devicecheck ?? "",
      devices: user.devices ?? [],
    }));
  }, []);

  useEffect(() => {
    fetchPolicies();
  }, [fetchPolicies]);

  useEffect(() => {
    fetchSetupData();
  }, [fetchSetupData]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    if (email) {
      readUser(email);
      setQueryParameterExists(true);
    }
  }, [location.search, readUser]);

  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateInputFilled = () => {
    const requiredFields = ["username", "email", "policies", "role"];
    for (let field of requiredFields) {
      if (!inputValues[field]) {
        alert(`Falta ${field}`);
        return false;
      }
    }
    return true;
  };

  const handleCreateUser = () => {
    if (!validateInputFilled()) return;
    if (!inputValues.password) {
      alert("Falta la contraseña");
      return;
    }
    UserAPI.create(inputValues);
  };

  const handleUpdateUser = () => {
    const data = { ...inputValues };
    if (!inputValues.password) delete data.password;
    UserAPI.update(data);
  };

  const handleDeleteUser = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar al usuario: ${inputValues.email}?`
    );
    if (confirmation) {
      UserAPI.delete(inputValues.email);
    }
  };

  const redirectToUsers = () => {
    navigate("/view/users");
  };

  const editDevice = (id, permited) => {
    const updatedDevices = inputValues.devices.map((device) =>
      device.id === id ? { ...device, permited } : device
    );
    setInputValues((prevState) => ({ ...prevState, devices: updatedDevices }));
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.value;
    const rolePolicies =
      roles.find((role) => role.value === selectedRole)?.policies || [];
    setInputValues((prevState) => ({
      ...prevState,
      role: selectedRole,
      policies: rolePolicies,
    }));
  };

  return (
    <DoubleContainer>
      <FormContainer>
        <Input
          name="username"
          type="text"
          placeholder="Nombre de usuario"
          value={inputValues.username}
          onChange={handleInputChange}
          label="Nombre de usuario"
          activeLabel
        />
        <Input
          name="email"
          type="text"
          placeholder="Email"
          value={inputValues.email}
          onChange={handleInputChange}
          label="Correo Electrónico"
          activeLabel
        />
        <Input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={inputValues.password}
          onChange={handleInputChange}
          label="Contraseña"
          activeLabel
        />
        <Select
          isSearchable
          isClearable={false}
          name="policies"
          options={policies}
          placeholder="Policies..."
          onChange={(event) =>
            onSelectChangeMulti(event, setInputValues, "policies")
          }
          value={policies.filter((policy) =>
            inputValues.policies.includes(policy.value)
          )}
          label="Policies"
          activeLabel
          isMulti
        />
        <Select
          isSearchable
          isClearable={false}
          name="role"
          options={roles}
          placeholder="Roles..."
          onChange={handleRoleChange}
          value={roles?.find((role) => role.value === inputValues.role)}
          label="Rol"
          activeLabel
        />
        <Input
          name="active"
          type="checkbox"
          checked={inputValues.active}
          onChange={handleInputChange}
          label="Active"
          activeLabel
        />
        <Input
          name="devicecheck"
          type="checkbox"
          checked={inputValues.devicecheck}
          onChange={handleInputChange}
          label="Validación de dispositivos"
          activeLabel
        />
        <div className="css-175oi2c" style={{ alignItems: "center" }}>
          <Button
            id="usuarios"
            onClick={redirectToUsers}
            content="<<"
            buttonStyle={{ maxWidth: "200px" }}
          />
          {!queryParameterExists && (
            <Button
              id="createUser"
              onClick={handleCreateUser}
              content="Crear Usuario"
              buttonStyle={{ maxWidth: "200px" }}
            />
          )}
          {queryParameterExists && (
            <>
              <Button
                id="updateUser"
                onClick={handleUpdateUser}
                icon="save"
                buttonStyle={{ maxWidth: "200px" }}
              />
              <Button
                id="deleteUser"
                onClick={handleDeleteUser}
                content="Eliminar Usuario"
                buttonStyle={{ maxWidth: "200px" }}
              />
            </>
          )}
        </div>
      </FormContainer>
      <FormContainer
        style={{
          minHeight: "500px",
          justifyContent: "normal",
          paddingTop: "28px",
        }}
      >
        <DevicesTable
          inputValues={inputValues.devices}
          editDevice={editDevice}
        />
      </FormContainer>
    </DoubleContainer>
  );
};

const DevicesTable = ({ inputValues, editDevice }) => {
  const columns = [
    {
      label: "Permitido",
      key: "permited",
      type: "checkbox",
      style_header_cell: { maxWidth: "100px" },
      style_body_cell: { maxWidth: "100px" },
    },
    {
      label: "Primer inicio de sesión",
      key: "first",
      type: "string",
      style_header_cell: { minWidth: "200px" },
      style_body_cell: { minWidth: "200px" },
    },
    {
      label: "Último inicio de sesión",
      key: "last",
      type: "string",
      style_header_cell: { minWidth: "200px" },
      style_body_cell: { minWidth: "200px" },
    },
    {
      label: "ID",
      key: "id",
      type: "string",
      style_header_cell: { minWidth: "100px" },
      style_body_cell: { minWidth: "100px" },
    },
    {
      label: "Sistema Operativo",
      key: "OS",
      type: "string",
      style_header_cell: { minWidth: "100px" },
      style_body_cell: { minWidth: "100px" },
    },
    {
      label: "Navegador",
      key: "browser",
      type: "string",
      style_header_cell: { minWidth: "100px" },
      style_body_cell: { minWidth: "100px" },
    },
    {
      label: "Versión del navegador",
      key: "version",
      type: "string",
      style_header_cell: { minWidth: "100px" },
      style_body_cell: { minWidth: "100px" },
    },
    {
      label: "Dispositivo",
      key: "platform",
      type: "string",
      style_header_cell: { minWidth: "100px" },
      style_body_cell: { minWidth: "100px" },
    },
    {
      label: "Sistema Operativo Versión",
      key: "OSversion",
      type: "string",
      style_header_cell: { minWidth: "300px" },
      style_body_cell: { minWidth: "300px" },
    },
    {
      label: "Versión del dispositivo",
      key: "pversion",
      type: "string",
      style_header_cell: { minWidth: "300px" },
      style_body_cell: { minWidth: "300px" },
    },
    {
      label: "Acciones",
      key: "table_actions",
      type: "actions",
      style_header_cell: { minWidth: "100px" },
      style_body_cell: { minWidth: "100px" },
      buttons: [
        { icon: "EditIcon", onClick: (e) => {} },
        { icon: "DeleteIcon", onClick: (e) => {} },
      ],
    },
  ];

  const fakeData = [
    {
      first: "2022-01-01T00:00:00Z",
      last: "2022-01-01T00:00:00Z",
      id: "abc123",
      name: "Mi dispositivo",
      ip: "192.168.1.1",
      OS: "Windows",
      OSversion: "10.0",
      browser: "Chrome",
      version: "96.0.4664.110",
      platform: "Desktop",
      pversion: "10.0.0",
      permited: true,
    },
    {
      first: "2022-01-01T00:00:00Z",
      last: "2022-01-01T00:00:00Z",
      id: "abc123",
      name: "Mi dispositivo",
      ip: "192.168.1.1",
      OS: "Windows",
      OSversion: "10.0",
      browser: "Chrome",
      version: "96.0.4664.110",
      platform: "Desktop",
      pversion: "10.0.0",
      permited: true,
    },
    {
      first: "2022-01-01T00:00:00Z",
      last: "2022-01-01T00:00:00Z",
      id: "abc123",
      name: "Mi dispositivo",
      ip: "192.168.1.1",
      OS: "Windows",
      OSversion: "10.0",
      browser: "Chrome",
      version: "96.0.4664.110",
      platform: "Desktop",
      pversion: "10.0.0",
      permited: true,
    },
  ];

  const tableStyle = {
    maxWidth: "800px",
    minHeight: "492px",
    maxHeight: "492px",
  };

  return (
    <>
      <div style={{ fontSize: 30, textAlign: "center", fontWeight: "bold" }}>
        Dispositivos
      </div>
      <Table columns={columns} data={fakeData} style={tableStyle} />
    </>
  );
};

// Validación de props
DevicesTable.propTypes = {
  inputValues: PropTypes.arrayOf(PropTypes.shape({
      first: PropTypes.string,
      last: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      ip: PropTypes.string,
      OS: PropTypes.string,
      OSversion: PropTypes.string,
      browser: PropTypes.string,
      version: PropTypes.string,
      platform: PropTypes.string,
      pversion: PropTypes.string,
      permited: PropTypes.boolean,
  })),
  editDevice: PropTypes.func.isRequired,
};

export default User;
