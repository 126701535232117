const alertControlledByDebugMode = () => {
    // Guarda la referencia a la función alert original
    const originalAlert = window.alert;

    /**xcddasd
     * 
     * @param {string} message Mensaje a mostrar en la alerta
     */
    window.alert = function (message) {
        originalAlert(message)
    };
}

export default alertControlledByDebugMode;