// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-container__jornada-info {
    display: inline-block;
    background-color: var(--detail-color);
    border-radius: 20px;
    padding: 8px 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .jornada-info__day {
    /* color: #6c757d; */
    margin-right: 8px;
  }
  
  .jornada-info__duration {
    font-weight: 600;
    /* color: #6c757d; */
  }
  `, "",{"version":3,"sources":["webpack://./src/routes/UserActivity/JornadaInfo/JornadaInfo.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,qCAAqC;IACrC,mBAAmB;IACnB,iBAAiB;IACjB,wCAAwC;EAC1C;;EAEA;IACE,oBAAoB;IACpB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,oBAAoB;EACtB","sourcesContent":[".time-container__jornada-info {\r\n    display: inline-block;\r\n    background-color: var(--detail-color);\r\n    border-radius: 20px;\r\n    padding: 8px 16px;\r\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  }\r\n  \r\n  .jornada-info__day {\r\n    /* color: #6c757d; */\r\n    margin-right: 8px;\r\n  }\r\n  \r\n  .jornada-info__duration {\r\n    font-weight: 600;\r\n    /* color: #6c757d; */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
