import React from "react";
import PropTypes from "prop-types";
import ArrayElement from "./ArrayElement";
import ObjectElement from "./ObjectElement";
import PrimitiveElement from "./PrimitiveElement";
import LazyLoad from "src/routes/Structure/LazyLoad/LazyLoad";

const ElementRenderer = React.memo(function ElementRenderer({
  element,
  data,
  path,
  rootRef,
  ...props
}) {
  switch (element.type) {
    case "array":
      return (
        <ArrayElement element={element} data={data} path={path} {...props} />
      );
    case "object":
      return (
        <ObjectElement element={element} data={data} path={path} {...props} />
      );
    default:
      return (
        <LazyLoad root={rootRef ? rootRef.current : null}>
          <PrimitiveElement
            element={element}
            data={data}
            path={path}
            {...props}
          />
        </LazyLoad>
      );
  }
});

ElementRenderer.propTypes = {
  element: PropTypes.object,
  data: PropTypes.any,
  path: PropTypes.string,
  deleteItemInArray: PropTypes.func,
  rootRef: PropTypes.shape({ current: PropTypes.any }),
};

ElementRenderer.displayName = "ElementRenderer";

export default ElementRenderer;
