import React from "react";
import PropTypes from 'prop-types'; // Añadir PropTypes
import "./StatusGeneralInfo.css";

const StatusGeneralInfo = ({ service }) => {
  const { name, version, description } = service;
  return (
    <div className="status-service-generalInfo">
      <div className="status-service-generalInfo-label status-service-generalInfo-name">
        {name ? name : "N/A"}
      </div>
      <div className="status-service-generalInfo-label status-service-generalInfo-version">
        <div className="status-service-generalInfo-label-name">Version: </div>
        {version ? version : "N/A"}
      </div>
      <div className="status-service-generalInfo-label status-service-generalInfo-description">
        Descripcion: {description ? description : "N/A"}
      </div>
    </div>
  );
};

// Validación de props
StatusGeneralInfo.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    version: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default StatusGeneralInfo;
