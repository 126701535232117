import React from "react";
import "./Form.css";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import estructura from "./estructura.json";
function Form() {
  return (
    <div className="formulario">
      <div className="form-one">
        <FormGenerator json={estructura} />
      </div>
      <div className="form-two">
        <FormGenerator json={estructura} />
      </div>
    </div>
  );
}

export default Form;
