import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  User as UserApi,
  Dossier as DossierApi,
  Filetype as FiletypeApi,
} from "src/lib/api";
import "./Assign.css";
import { SvgIcon, Tooltip } from "@mui/material";
import { Delete, KeyboardDoubleArrowDown } from "@mui/icons-material";
import Button from "src/modules/components/common/Button/Button";

function Assign() {
  const { filetype, ownerEmail } = useParams();
  const [users, setUsers] = useState([]);
  const [filetypes, setFiletypes] = useState([]);
  const [selectedFiletype, setSelectedFiletype] = useState(filetype || "");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedNewOwner, setSelectedNewOwner] = useState("");
  const [newOwners, setNewOwners] = useState([]);
  const [filetypeError, setFiletypeError] = useState("");
  const [ownerError, setOwnerError] = useState("");
  const [newOwnersError, setNewOwnersError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const loadData = useCallback(async () => {
    const fetchedData = await DossierApi.analysis();
    return fetchedData;
  }, []);

  const loadUsers = useCallback(async () => {
    const fetchedData = await UserApi.list();
    return fetchedData;
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = await loadData();
      const users = await loadUsers();

      setUsers(users);
      setFiletypes(data);

      if (ownerEmail) {
        const matchedFiletype = data.find((ft) => ft.name === selectedFiletype);
        if (matchedFiletype) {
          const matchedOwner = matchedFiletype.owners.find(
            (owner) => owner.email === ownerEmail
          );
          if (matchedOwner) {
            setSelectedOwner(ownerEmail);
          }
        }
      }
    }
    fetchData();
  }, [loadUsers, loadData, ownerEmail, selectedFiletype]);

  const handleFiletypeChange = (event) => {
    setSelectedFiletype(event.target.value);
    if (event.target.value) {
      setFiletypeError("");
    }
  };

  const handleOwnerChange = (event) => {
    setSelectedOwner(event.target.value);
    if (event.target.value) {
      setOwnerError("");
    }
  };

  const handleNewOwnerChange = (event) => {
    const newOwnerEmail = event.target.value;
    setSelectedNewOwner(newOwnerEmail);
    if (newOwnerEmail) {
      setNewOwnersError("");
      if (!newOwners.includes(newOwnerEmail)) {
        setNewOwners([...newOwners, newOwnerEmail]);
        setSelectedNewOwner("");
      }
    }
  };

  const removeNewOwner = (owner) => {
    setNewOwners(newOwners.filter((o) => o !== owner));
  };

  const showUpdatedMessage = () => {
    setShowSnackbar(true);
    setTimeout(() => {
      setShowSnackbar(false);
      setSnackbarMessage("");
    }, 2000);
  };

  const resetForm = () => {
    setSelectedFiletype("");
    setSelectedOwner("");
    setSelectedNewOwner("");
    setNewOwners([]);
    setFiletypeError("");
    setOwnerError("");
    setNewOwnersError("");
    setGeneralError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;
    if (!selectedFiletype) {
      setFiletypeError("Por favor, seleccione un filetype.");
      valid = false;
    }
    if (!selectedOwner) {
      setOwnerError("Por favor, seleccione un usuario a reasignar.");
      valid = false;
    }
    if (newOwners.length === 0) {
      setNewOwnersError(
        "Por favor, seleccione al menos un nuevo usuario para reasignar."
      );
      valid = false;
    }

    if (!valid) return;

    try {
      setGeneralError("");
      const result = await FiletypeApi.assign(
        `${selectedFiletype}/${selectedOwner.split()}/${newOwners.join(",")}`
      );
      console.log(result);
      setSnackbarMessage("Asignación completada con éxito.");
      showUpdatedMessage();
      resetForm();
    } catch (err) {
      setGeneralError("Error al asignar tareas. Inténtalo de nuevo.");
      console.error(err);
    }
  };

  const availableUsers = users.filter(
    (user) => !newOwners.includes(user.email)
  );

  return (
    <div className="assign">
      <form onSubmit={handleSubmit} className="assign__form">
        <h1 className="assign__title">Reasignar propietarios de dossier</h1>
        {generalError && <p className="assign__error">{generalError}</p>}
        <div className="assign__form-group">
          <label htmlFor="filetype-select" className="assign__label">
            Seleccione un Filetype:
          </label>
          <select
            id="filetype-select"
            className="assign__select"
            value={selectedFiletype}
            onChange={handleFiletypeChange}
          >
            <option value="">Elige un filetype</option>
            {filetypes.map((filetype) => (
              <option
                key={filetype.name}
                value={filetype.name}
                className="assign__option"
              >
                {filetype.name}
              </option>
            ))}
          </select>
          {filetypeError && <p className="assign__error">{filetypeError}</p>}
        </div>
        <div className="assign__form-group">
          <label htmlFor="owner-select" className="assign__label">
            Seleccione el usuario propietario del dossier:
          </label>
          <select
            id="owner-select"
            className="assign__select"
            value={selectedOwner}
            onChange={handleOwnerChange}
          >
            <option value="">Elige un usuario</option>
            {filetypes
              .find((ft) => ft.name === selectedFiletype)
              ?.owners.map((owner, index) => {
                return (
                  <option
                    key={index}
                    value={owner.name}
                    className="assign__option"
                  >
                    {`${owner.name} (${owner.count})`}
                  </option>
                );
              })}
          </select>
          {ownerError && <p className="assign__error">{ownerError}</p>}
        </div>
        <div className="assign__arrow-container">
          <SvgIcon
            component={KeyboardDoubleArrowDown}
            className="assign__arrow-down"
          />
        </div>
        <div className="assign__form-group">
          <label htmlFor="new-owner-select" className="assign__label">
            Seleccione nuevos usuarios para reasignar los dossiers:
          </label>
          <select
            id="new-owner-select"
            className="assign__select"
            value={selectedNewOwner}
            onChange={handleNewOwnerChange}
          >
            <option value="">Elige nuevos usuarios</option>
            {availableUsers.map((user, index) => (
              <option key={index} value={user.email} className="assign__option">
                {user.name}
              </option>
            ))}
          </select>
          {newOwnersError && <p className="assign__error">{newOwnersError}</p>}
          {newOwners.length > 0 && (
            <div className="assign__new-owners-list">
              <h3>Usuarios seleccionados para reasignar</h3>
              <ul>
                {newOwners.map((owner, index) => {
                  const user = users.find((user) => user.email === owner) || {
                    name: "foo",
                    email: owner,
                  };
                  return (
                    <li key={index} className="assign__new-owners-item">
                      {user.name}
                      <Tooltip title="Borrar">
                        <SvgIcon
                          component={Delete}
                          className="assign__button--remove"
                          onClick={() => removeNewOwner(owner)}
                        />
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="assign__button-container">
          <Button type="submit" icon="save" className="assign__button--submit">
            Guardar cambios
          </Button>
        </div>
      </form>
      {showSnackbar && (
        <span className="assign-updated__message">{snackbarMessage}</span>
      )}
    </div>
  );
}

export default Assign;
