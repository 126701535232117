import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth as AuthAPI } from "../../lib/api";
import { Setup as SetupAPI } from "../../lib/api";
import { User } from "src/lib/api";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const OAuth2 = () => {
  const { setRole, setToken } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const scope = params.get("scope");
    const state = params.get("state");

    const authenticate = async () => {
      try {
        const response = await AuthAPI.oauth2({ code, scope, state });

        const bearer = `Bearer ${response.token}`;
        setToken(bearer);
        setRole(await User.get().then((user) => user.role));
        navigate("/");

        // localStorage.setItem("refresh_token", refresh_token);

        // Leer el setup e iniciar la aplicación
        await fetchSetupAndInitialize();

        navigate("/");
      } catch (error) {
        console.error("Authentication failed:", error);
      }
    };

    if (code && scope) {
      authenticate();
    }
  }, [location, navigate]);

  const fetchSetupAndInitialize = async () => {
    // Hacer una solicitud para obtener el setup usando el token de acceso
    const response = await SetupAPI.read();
    if (response) {
      return <div>Se ha conseguido leer el setup</div>;
    } else {
      return (
        <div>Ha habido un problema con la autentificación del servicio</div>
      );
    }
  };
};

export default OAuth2;
