import React from "react";
import MenuCard from "../MenuCard/MenuCard";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const MenuList = ({ data, setupDragAndDrop, deleteMenuList, editMenuList ,addMenuCard}) => {
  return (
    <div className="menu-list__container">
      <div className="menu-list-header">
        <div className="menu-list-header__title">
          {data.title || "Nuevo Menú"}
        </div>
        <div className="menu-list-header__actions">
          <Button
            content={<EditIcon />}
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
            onClick={() => editMenuList(data)}

          />
          <Button
            content={<DeleteIcon />}
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
            onClick={() => deleteMenuList(data)}
          />
        </div>
      </div>
      <div className="menu-list-cards" data-droppable>
        {data.submenus.map((submenu, index) => (
          <MenuCard
            key={submenu.id + index}
            data={submenu}
            setupDragAndDrop={setupDragAndDrop}
            index={index}
            listId={data.id}
          />
        ))}
        <div className="menu-card__container"
          onClick={() => addMenuCard(data.id)}
        >+</div>

      </div>
    </div>
  );
};

export default MenuList;

MenuList.propTypes = {
  data: PropTypes.object.isRequired,
  setupDragAndDrop: PropTypes.func.isRequired,
  deleteMenuList: PropTypes.func.isRequired,
  editMenuList: PropTypes.func.isRequired,
  addMenuCard: PropTypes.func.isRequired,
};
