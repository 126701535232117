import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Filetype } from "src/lib/api";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Collapsible from "src/modules/components/common/Collapsible/Collapsible";
import Input from "src/modules/components/common/Input/Input";
import Title from "src/modules/components/common/Title/Title";

const FiletypeEditor = () => {
  const { filetype } = useParams();
  const [filetypeStructure, setFiletypeStructure] = useState({
    name: "",
    title: "",
    help: "",
    structure: [
      {
        varname: "varname",
        fullvarname: "dossier.file.varname",
        title: "Titulo",
        description: "Descripción",
        help: "Ayuda",
        datatype: "string",
        enum: ["uno", "dos", "tres"],
        structure: ["string"],
      },
    ],
  });

  const onBlur = (name) => (event) => {
    setFiletypeStructure(prev => ({...prev, [name]: event.target.name}))
  }

  useEffect(() => {
    if(!filetype) return
    const fetchData = async() => {
      const data = Filetype.read(filetype)
      setFiletypeStructure(data)
    }
    fetchData()
  }, [filetype]);

  return (
    <FormContainer style={{ gap: "1rem", margin: '10px' }}>
      <Title text={`FiletypeEditor: ${filetype}`} />

      <Input
        name="name"
        label="Nombre"
        defaultValue={filetypeStructure.name}
        onBlur={onBlur('name')}
        placeholder="expediente"
      />
      <Input
        name="title"
        label="Título"
        defaultValue={filetypeStructure.title}
        placeholder="Expediente"
        onBlur={onBlur('title')}
      />
      <Input
        name="help"
        label="Ayuda"
        defaultValue={filetypeStructure.help}
        placeholder="Expedientes de valeria"
        onBlur={onBlur('help')}
      />
      <Collapsible header="Estructura">

      </Collapsible>
    </FormContainer>
  );
};

export default FiletypeEditor;
