import React, { useEffect, useState } from "react";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { Cartridge as CartridgeAPI, Global, View as ViewAPI } from "src/lib/api";
import { validateProperties } from "src/lib/validateProperties";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Planner from "src/modules/components/common/Planner/Planner";
import Tabs from "src/modules/components/header/Tabs/Tabs";
import { getIncomers } from '@xyflow/react';
import TextArea from "src/modules/components/common/Text Area/TextArea";
import InputWithImport from "src/modules/components/common/InputWithImport/InputWithImport";
import { ViewContextProvider } from "src/modules/contexts/ViewContextProvider";
import View2 from "../View/View2";

function Cartridge() {
  const [queryParameterExists, setQueryParameterExists] = useState(false);
  const navigate = useNavigate()
  const [cartridge, setCartridge] = useState({
    cartridgecode: "",
    name: "",
    description: "",
    group: "",
    imgurl: "",
    logic: "",
    videourl: "",
    public: false,
    active: false,
    planner: [],
  });

  const [viewData, setViewData] = useState({ scope: "check", elements: [] });

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const parametroCartridgeCode = queryParams.get("cartridgecode");
    if (parametroCartridgeCode) {
      getCartridge(parametroCartridgeCode);
      setQueryParameterExists(true);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const parametroCartridgeCode = queryParams.get("cartridgecode");

    ViewAPI.read("dchecks").then((data) => {
      data.filter = JSON.stringify({
        cartridge: parametroCartridgeCode,
      });
      data.frames[0].filter = JSON.stringify({
        cartridge: parametroCartridgeCode,
      });
      setViewData(data.frames[0]);
    });
  }, []);

  const getCartridge = async (input) => {
    const cartridge = await CartridgeAPI.read(input);
    if (!cartridge) return;
    setCartridge({
      cartridgecode: cartridge.cartridgecode,
      name: cartridge.name,
      description: cartridge.description,
      group: cartridge.group,
      imgurl: cartridge.imgurl,
      logic: cartridge.logic,
      videourl: cartridge.videourl,
      public: cartridge.public,
      active: cartridge.active,
      planner: cartridge.planner
    });
  };

  const validateCartridge = () => {
    const propertiesToValidate = {
      cartridgecode: cartridge.cartridgecode,
      name: cartridge.name
    };
    const errorMessage = validateProperties(propertiesToValidate);
    return errorMessage === ""
      ? { validated: true }
      : { validated: false, errorMessage };
  };

  const saveCartridge = () => {
    const validation = validateCartridge();
    if (!validation.validated) return alert(validation.errorMessage);
    const cartridgeToUpload = cartridge;
    cartridgeToUpload.planner = buildPlanner()
    CartridgeAPI.crupdate(cartridgeToUpload);
    setCartridge(cartridgeToUpload)
  };

  const deleteCartridge = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar el cartucho: ${cartridge.cartridgecode}?`
    );

    if (confirmation) {
      CartridgeAPI.delete(cartridge.cartridgecode);
    }
  };
  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if((name === 'imgurl' || name === 'videourl') && event.target.file){
      const uploadFile = event.target.files ? event.target.files[0] : event.dataTransfer.files[0]
      const formData = {
        file: uploadFile,
        target: 'public',
        path: 'cartridge',
      };
      Global.upload(formData).then((result) => {
        console.log(result)
      }).catch((error) => {
        console.log(error)
      })
    }
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }
    setCartridge({ ...cartridge, [name]: value });
  };

  const redirectCartridges = () => {
    navigate("/view/dcartridges");
  };

  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState(null);
  const onChangeNodes = (nodesPlanner, edgesPlanner) => {
    setNodes(nodesPlanner);
    setEdges(edgesPlanner);
  };

  const buildPlanner = () => {
    const nuevosNodos = [];
    if(!nodes || !edges) return cartridge.planner
    nodes.forEach((node) => {
      let databytype = {}
      switch(node.data.type) {
        case 'document': {
          databytype = {
            filename: node.data.filename ?? '',
            filetype: node.data.filetype ?? '',
            requestlimit: node.data.requestlimit ?? '',
            documentlimit: node.data.documentlimit ?? '',
            must: node.data.must ?? false,
            who: node.data.who ?? { label: '', value: ''},
          }
          break;
        }
        case 'action': {
          databytype = {
            conductorname: node.data.conductorname,
          }
          break;
        }
        case 'event': {
          databytype = {
            fixdays: node.data.fixdays,
            eventtype: node.data.eventtype,
            alerttext: node.data.alerttext,
            alertdays: node.data.alertdays,
          }
          break;
        }
        case 'variable': {
          databytype = [...node.data.variables]
          break;
        }
        case 'ai': {
          databytype = {
            agent: node.data.agentype,
            prompt: node.data.prompt,
            variable: node.data.variable,
          }
          break;
        }
        default: {
          databytype = node.data
          break;
        }
      }
      const name = node.data.type !== 'variable' ? node.data.type : 'info'
      const nuevoNodo = {
        id: node.id,
        name: node.data.name ?? "",
        type: node.data.type ?? "",
        logic: node.data.logic ?? '',
        pos: { x: node.position.x, y: node.position.y },
        depends: getIncomers(node, nodes, edges).map(inc => inc.id),
        [name]: databytype
      };

      nuevosNodos.push(nuevoNodo);
    });

    return nuevosNodos;
  };
  //-------------------------------------------------------------------------------

  //------------------------------------------------------------
  const tabsData = [
    {
      name: "Cartucho",
      id: "cartridge",
      component: (
        <div
          className="css-175oi2r"
          style={{ marginTop: "10px" }}>
          <Input
            name="cartridgecode"
            type="text"
            placeholder="CDC0001"
            value={cartridge.cartridgecode}
            onChange={handleInputChange}
            label="Código:"
            activeLabel={true}
          />
          <Input
            name="name"
            type="text"
            placeholder="Nacionalidad española por residencias"
            value={cartridge.name}
            onChange={handleInputChange}
            label="Nombre:"
            activeLabel={true}
          />
          <TextArea
            name="description"
            type="text"
            placeholder="Description"
            value={cartridge.description}
            onChange={handleInputChange}
            label="Descripción:"
            activeLabel={true}
          />
          <Input
            name="group"
            type="text"
            placeholder="Extranjeria"
            value={cartridge.group}
            onChange={handleInputChange}
            label="Grupo:"
            activeLabel={true}
          />
          <InputWithImport name='imgurl' label='Imagen' onChange={handleInputChange} url={cartridge.imgurl}/>
          <InputWithImport name='videourl' label='Video'  onChange={handleInputChange} url={cartridge.videourl}/>
          <TextArea
            name="logic"
            type="text"
            placeholder="Lógica"
            value={cartridge.logic}
            onChange={handleInputChange}
            label="Logic:"
            activeLabel={true}
          />
          <Input
            name="public"
            type="checkbox"
            placeholder="public"
            value={cartridge.public}
            onChange={handleInputChange}
            label="Público:"
            activeLabel={true}
          />
          <Input
            name="active"
            type="checkbox"
            placeholder="active"
            value={cartridge.active}
            onChange={handleInputChange}
            label="Activo:"
            activeLabel={true}
          />
        </div>
      ),
    },
    {
      name: "Checks",
      component: (
        <div style={{ marginTop: "10px" }}>
          <ViewContextProvider>
            <View2 vid='dchecks'/>
          </ViewContextProvider>
        </div>
      ),
      id: "checks",
    },
    {
      name: "Planificador",
      component: (
        <div style={{ marginTop: "10px" }}>
          <Planner getInfo={onChangeNodes} initialNodes={cartridge?.planner} />
        </div>
      ),
      id: "planner",
    },
  ];
  
  return (
    <FormContainer style={{ margin: "10px", padding: '24px' }}>
      {viewData && <Tabs tabsData={tabsData} />}
      <div
        className="css-175oi2c"
        style={{ alignItems: "center" }}>
        <Button
          id="cartuchos"
          onClick={redirectCartridges}
          icon="arrow back"
        />
          <Button
            id="createCartridge"
            onClick={saveCartridge}
            icon='save'
          />
        {queryParameterExists && (
          <Button
            id="deleteCartridge"
            onClick={deleteCartridge}
            icon="delete"
          />
        )}
      </div>
    </FormContainer>
  );
}

export default Cartridge;
