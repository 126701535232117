// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-group {
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.test-group p {
  margin: 0;
}

/* Estilos para la tabla de variables */
.vars {
  margin-top: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}
td {
  color: white;
}

th {
  background-color: #646464;
  font-weight: bold;
}

/* Estilos para el botón "Mostrar Vars" */
.toggle-vars {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 5px;
}

.toggle-vars[data-state="shown"] {
  background-color: #3659f4;
}`, "",{"version":3,"sources":["webpack://./src/routes/Checks/Test/Test.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA,uCAAuC;AACvC;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA,yCAAyC;AACzC;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".test-group {\r\n  margin-bottom: 10px;\r\n  background: rgba(0, 0, 0, 0.3);\r\n  padding: 10px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  row-gap: 1rem;\r\n}\r\n\r\n.test-group p {\r\n  margin: 0;\r\n}\r\n\r\n/* Estilos para la tabla de variables */\r\n.vars {\r\n  margin-top: 10px;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\nth,\r\ntd {\r\n  border: 1px solid #dddddd;\r\n  padding: 8px;\r\n  text-align: left;\r\n}\r\ntd {\r\n  color: white;\r\n}\r\n\r\nth {\r\n  background-color: #646464;\r\n  font-weight: bold;\r\n}\r\n\r\n/* Estilos para el botón \"Mostrar Vars\" */\r\n.toggle-vars {\r\n  background-color: #4caf50;\r\n  color: white;\r\n  border: none;\r\n  padding: 5px 10px;\r\n  cursor: pointer;\r\n  border-radius: 4px;\r\n  margin-top: 5px;\r\n}\r\n\r\n.toggle-vars[data-state=\"shown\"] {\r\n  background-color: #3659f4;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
