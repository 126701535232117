// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-activity-dashboard__calendar {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  grid-area: user-activity-dashboard__calendar;
}


.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.calendar__title {
  color: #ff8c00;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.calendar__toggle-btn {
  background-color: #ff8c00;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calendar__toggle-btn:hover {
  background-color: #e07800;
}

.calendar__widget {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.calendar__grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/routes/UserActivity/Calendar/Calendar.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,4CAA4C;AAC9C;;;AAGA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,QAAQ;EACR,kBAAkB;AACpB","sourcesContent":[".user-activity-dashboard__calendar {\r\n  background-color: white;\r\n  border-radius: 8px;\r\n  padding: 16px;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  grid-area: user-activity-dashboard__calendar;\r\n}\r\n\r\n\r\n.calendar__header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 16px;\r\n}\r\n\r\n.calendar__title {\r\n  color: #ff8c00;\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  margin: 0;\r\n}\r\n\r\n.calendar__toggle-btn {\r\n  background-color: #ff8c00;\r\n  color: white;\r\n  padding: 8px 12px;\r\n  border: none;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n  transition: background-color 0.3s ease;\r\n}\r\n\r\n.calendar__toggle-btn:hover {\r\n  background-color: #e07800;\r\n}\r\n\r\n.calendar__widget {\r\n  background-color: white;\r\n  border-radius: 8px;\r\n  padding: 16px;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.calendar__grid {\r\n  display: grid;\r\n  grid-template-columns: repeat(7, 1fr);\r\n  gap: 8px;\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
