/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Error  {
    static async list(framecode, first = 0, pagination = 100) {
      const response = await HttpMethods.request_get(`/v1/error/list?format=flat`);
      if (!response) alert("Error al obtener los errores");
      return response;
    }

    static async dossier(dossiercode) {
      const response = await HttpMethods.request_get(`/v1/error/dossier/${dossiercode}`);
      if (!response) alert("Error al obtener los errores");
      return response;
    }
  }