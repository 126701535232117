import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./DropDownBox.css";

const DropDownBox = ({
  children,
  setOpen,
  onClosed = () => {}, 
  title = "", 
  ...props
}) => {
  const dropDownRef = useRef(null);
  const [initialized, setInitialized] = useState(false); // Estado para controlar si el componente se ha inicializado

  useEffect(() => {
    if (initialized) {
      const handleClickOutside = (event) => {
        if (
          dropDownRef.current &&
          !dropDownRef.current.contains(event.target)
        ) {
          onClosed && onClosed();
          setOpen(false);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    } else {
      setInitialized(true);
    }
  }, [initialized, setOpen, onClosed]);

  return (
    <div ref={dropDownRef} className="dropDownBox" {...props}>
      <div className="cabeceraDropDown">{title || "Editor de vista"}</div>
      {children}
    </div>
  );
};

DropDownBox.propTypes = {
  children: PropTypes.node.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  title: PropTypes.string,
};

export default DropDownBox;
