import React, { useCallback } from "react";
import { SvgIcon, Tooltip } from "@mui/material";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import PropTypes from "prop-types"; // Importa PropTypes

const StructureActions = React.memo(
  ({ structure, currentPath, pathKey, handleAddNewProperty, dispatch, editedItems }) => {

    const handleDelete = useCallback(
      (e) => {
        e.stopPropagation();
    
        const deepCopyStructures = (structures) => {
          return structures.map((structure) => {
            return {
              ...structure,
              structure: structure.structure ? deepCopyStructures(structure.structure) : [],
            };
          });
        };
    
        const updatedStructures = deepCopyStructures(editedItems.filetype.structure);
    
        const deleteNestedStructure = (structureToDelete, path) => {
          if (path.length === 1) {
            structureToDelete.splice(path[0], 1);
          } else {
            const nextPathIndex = path[0];
            if (structureToDelete[nextPathIndex] && structureToDelete[nextPathIndex].structure) {
              structureToDelete[nextPathIndex].structure = deleteNestedStructure(
                structureToDelete[nextPathIndex].structure,
                path.slice(1)
              );
            }
          }
          return structureToDelete;
        };
    
        const newStructure = deleteNestedStructure(updatedStructures, currentPath);
    
        dispatch({
          type: "UPDATE_STRUCTURE",
          payload: newStructure,
        });
      },
      [editedItems, dispatch, currentPath]
    );
    

    const handleAddProperty = useCallback(
      (e, position) => {
        e.stopPropagation();
        handleAddNewProperty(pathKey, position);
      },
      [handleAddNewProperty, pathKey]
    );

    return (
      <div className="structure-actions">
        {structure.datatype === "array" || structure.datatype === "object" ? (
          <Tooltip title="Agregar dentro">
            <SvgIcon
              component={AddCircleOutline}
              className="structure__icon"
              onClick={(e) => handleAddProperty(e, "inside")}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Agregar encima">
            <SvgIcon
              component={AddCircleOutline}
              className="structure__icon"
              onClick={(e) => handleAddProperty(e, "previous")}
            />
          </Tooltip>
        )}
        <Tooltip title="Borrar">
          <SvgIcon
            component={Delete}
            className="structure__icon"
            onClick={handleDelete}
          />
        </Tooltip>
      </div>
    );
  }
);

// Agrega un displayName
StructureActions.displayName = "StructureActions";

// Validación de props
StructureActions.propTypes = {
  structure: PropTypes.shape({
    datatype: PropTypes.string,
    structure: PropTypes.array,
  }).isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.number).isRequired,
  pathKey: PropTypes.number.isRequired,
  handleAddNewProperty: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      structure: PropTypes.array,
    }),
  }).isRequired,
};

export default StructureActions;
