const userActivityActionTypes = {
    SET_CURRENT_TIME: "SET_CURRENT_TIME",
    ACTIVITY_ENTRADA: "ACTIVITY_ENTRADA",
    ACTIVITY_PAUSA: "ACTIVITY_PAUSA",
    ACTIVITY_VOLVER: "ACTIVITY_VOLVER",
    ACTIVITY_SALIDA: "ACTIVITY_SALIDA",
    FETCH_LAST_ACTION: "FETCH_LAST_ACTION",
    FETCH_ACTION_ERROR: "FETCH_ACTION_ERROR",
    SET_DURATION: "SET_DURATION"
};

export default userActivityActionTypes