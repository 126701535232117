// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div2-vc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4vh;

}
.inputs-frame {
  width: 90%;
  border-radius: 5px;
  padding: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.252);
}
.inputs-frame-son {
  width: 47%;
  display: block;
}
.inputs-frame-button-bar {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0.7vw 0vw 0.2vw 0.2vw;
}

@media (max-width: 1000px) {
  .inputs-frame-son {
    width: 100%;
  }
  .inputs-frame-button-bar {
    padding: 0.6vw 0vw 0.2vw 0.2vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/ViewCreate/ComponentesViewCreate/InputsVCFrame.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;;AAErB;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;EAC9B,sCAAsC;AACxC;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".div2-vc {\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  padding-bottom: 4vh;\r\n\r\n}\r\n.inputs-frame {\r\n  width: 90%;\r\n  border-radius: 5px;\r\n  padding: 2vw;\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex-wrap: wrap;\r\n  justify-content: space-between;\r\n  background-color: rgba(0, 0, 0, 0.252);\r\n}\r\n.inputs-frame-son {\r\n  width: 47%;\r\n  display: block;\r\n}\r\n.inputs-frame-button-bar {\r\n  display: flex;\r\n  justify-content: end;\r\n  align-items: center;\r\n  padding: 0.7vw 0vw 0.2vw 0.2vw;\r\n}\r\n\r\n@media (max-width: 1000px) {\r\n  .inputs-frame-son {\r\n    width: 100%;\r\n  }\r\n  .inputs-frame-button-bar {\r\n    padding: 0.6vw 0vw 0.2vw 0.2vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
