import structureActionsTypes from "../actionTypes/structureActionsTypes";

const initialState = {
    editedItems: null,
    showSnackbar: false,
    snackbarMessage: "",
    isLoading: false,
    isModalOpen: false,
    selectedStructure: null,
    modalPath: [],
    selectOptions: [],
    selectedMenuItem: "Flows",
    selectedOrigins: [
        { value: "local", label: "Local" },
        { value: "public", label: "Public" },
    ],
    error: null,
};

const structureReducer = (state, action) => {
    switch (action.type) {
        case structureActionsTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case structureActionsTypes.LOAD_STRUCTURE_DATA:
            return {
                ...state,
                editedItems: action.payload,
                isLoading: false,
            };
        case structureActionsTypes.SHOW_SNACKBAR:
            return {
                ...state,
                showSnackbar: true,
                snackbarMessage: action.payload,
            };
        case structureActionsTypes.HIDE_SNACKBAR:
            return {
                ...state,
                showSnackbar: false,
                snackbarMessage: "",
            };
        case structureActionsTypes.SET_MODAL_STATE:
            return {
                ...state,
                isModalOpen: action.payload.isModalOpen,
                selectedStructure: action.payload.structure,
                modalPath: action.payload.path,
            };
        case structureActionsTypes.SET_SELECT_OPTIONS:
            return {
                ...state,
                selectOptions: action.payload,
            };
        case structureActionsTypes.SET_SELECTED_MENU_ITEM:
            return {
                ...state,
                selectedMenuItem: action.payload,
            };
        case structureActionsTypes.UPDATE_HEADER:
            return {
                ...state,
                editedItems: {
                    ...state.editedItems,
                    filetype: {
                        ...state.editedItems.filetype,
                        header: action.payload,
                    },
                },
            };
        case structureActionsTypes.UPDATE_STRUCTURE:
            return {
                ...state,
                editedItems: {
                    ...state.editedItems,
                    filetype: {
                        ...state.editedItems.filetype,
                        structure: action.payload,
                    },
                },
            };
        case structureActionsTypes.SET_SELECTED_ORIGINS:
            return {
                ...state,
                selectedOrigins: action.payload,
            };
        case structureActionsTypes.SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case structureActionsTypes.UPDATE_STRUCTURE_ITEM: {
            const { updatedStructure, modalPath } = action.payload;
            const updatedStructures = [...state.editedItems.filetype.structure];

            const updateNestedStructure = (structure, path) => {
                if (path.length === 1) {
                    structure[path[0]] = updatedStructure;
                } else {
                    structure[path[0]] = {
                        ...structure[path[0]],
                        structure: updateNestedStructure(
                            structure[path[0]].structure,
                            path.slice(1)
                        ),
                    };
                }
                return structure;
            };

            updateNestedStructure(updatedStructures, modalPath);

            return {
                ...state,
                editedItems: {
                    ...state.editedItems,
                    filetype: { ...state.editedItems.filetype, structure: updatedStructures },
                },
            };
        }
        case structureActionsTypes.CREATE_NEW_STRUCTURE: {
            const { newStructure, path, buildFullVarname } = action.payload;
            const updatedStructures = [...state.editedItems.filetype.structure];

            const parentVarname = path.length > 0
                ? buildFullVarname(path.slice(0, -1), updatedStructures)
                : "file";

            const fullvarname = `${parentVarname}.${newStructure.varname}`;
            const structure = { ...newStructure, fullvarname };

            let parentStructure = updatedStructures;
            for (let i = 0; i < path.length - 1; i++) {
                parentStructure = parentStructure[path[i]].structure;
            }

            const insertIndex = path[path.length - 1];
            parentStructure.splice(insertIndex, 0, structure);

            return {
                ...state,
                editedItems: {
                    ...state.editedItems,
                    filetype: {
                        ...state.editedItems.filetype,
                        structure: updatedStructures,
                    },
                },
            };
        }
        default:
            return state;
    }
};

export { structureReducer, initialState };
