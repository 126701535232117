import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/modules/contexts/AppContextProvider';

const Logout = () => {
  const navigate = useNavigate();
  const { setRole } = useAppContext()
  useEffect(() => {
    sessionStorage.clear();

    // Esperar un breve tiempo antes de redirigir para dar la sensación de transición suave
    const timer = setTimeout(() => {
      setRole('guest')
      navigate("/login", { replace: true });
    }, 300);

    return () => clearTimeout(timer);
  }, [navigate, setRole]);

  return null;
};

export default Logout;