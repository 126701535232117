// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-activity-dashboard__notifications {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    grid-area:user-activity-dashboard__notifications;
  }
  .notifications__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .user-activity-dashboard__notifications-title {
    color: #ff8c00;
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
  }
  
  .user-activity-dashboard__notifications-items {
    height: 300px;
    overflow-y: auto;
    padding-right: 8px;
  }
  
  .user-activity-dashboard__notifications-items::-webkit-scrollbar {
    width: 8px;
  }
  
  .user-activity-dashboard__notifications-items::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .user-activity-dashboard__notifications-items::-webkit-scrollbar-thumb {
    background: #cce5ff;
    border-radius: 4px;
  }
  
  .notification__item {
    background-color: #ffe4b3;
    color: #ff8c00;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  `, "",{"version":3,"sources":["webpack://./src/routes/UserActivity/Notifications/Notifications.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,wCAAwC;IACxC,gDAAgD;EAClD;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;EACA;IACE,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".user-activity-dashboard__notifications {\r\n    background-color: white;\r\n    border-radius: 8px;\r\n    padding: 16px;\r\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n    grid-area:user-activity-dashboard__notifications;\r\n  }\r\n  .notifications__header {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin-bottom: 16px;\r\n  }\r\n  .user-activity-dashboard__notifications-title {\r\n    color: #ff8c00;\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    margin: 0px;\r\n  }\r\n  \r\n  .user-activity-dashboard__notifications-items {\r\n    height: 300px;\r\n    overflow-y: auto;\r\n    padding-right: 8px;\r\n  }\r\n  \r\n  .user-activity-dashboard__notifications-items::-webkit-scrollbar {\r\n    width: 8px;\r\n  }\r\n  \r\n  .user-activity-dashboard__notifications-items::-webkit-scrollbar-track {\r\n    background: #f1f1f1;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .user-activity-dashboard__notifications-items::-webkit-scrollbar-thumb {\r\n    background: #cce5ff;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .notification__item {\r\n    background-color: #ffe4b3;\r\n    color: #ff8c00;\r\n    padding: 8px;\r\n    border-radius: 4px;\r\n    margin-bottom: 8px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
