import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import "./Collapsible.css";

const Collapsible = React.memo(function Collapsible({
  open = false,
  children,
  header,
  contentStyle = {},
  headerStyle = {},
  cardStyle = {},
  textStyle = {}
}) {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState(open ? undefined : 0);
  const ref = useRef(null);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <div className="collapsible-card" style={cardStyle}>
      <div>
        <div className="collapsible-header" style={headerStyle}>
          <div className="collapsible-title-text" style={textStyle}>{header}</div>
          <button
            type="button"
            className="collapsible-icon-button"
            onClick={handleFilterOpening}>
            <i
              className={`fas fa-chevron-down ${
                isOpen ? "rotate-center down" : "rotate-center up"
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className="collapsible-content"
        style={{ height }}>
        <div ref={ref}>
          <div className="collapsible-content-padding" style={contentStyle}>{children}</div>
        </div>
      </div>
    </div>
  );
});

Collapsible.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  contentStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  cardStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

export default Collapsible;
