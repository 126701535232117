import React, { useEffect, useState } from "react";
import Input from "src/modules/components/common/Input/Input";
// import "./InputsVCFrame.css";
import { useViewCreateContext } from "../ViewCreateContext.js";
import Button from "src/modules/components/common/Button/Button";
import TableVCElement from "./TablaVCElements.jsx";
import "./InputsVCFrame.css";
import constants from "src/definitions/constants";
import Select from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const InputsVCFrame = () => {
  const {
    singleFrameData,
    setSingleFrameData,
    targetFrameName,
    handlePutFrame,
  } = useViewCreateContext();
  const { publicSetup } = useAppContext();
  const [, setItemStyle] = useState({
    backgroundColor: "rgba(245,234,234)",
  });
  useEffect(() => {
    // Actualiza el estilo cada vez que cambia el valor de publicSetup.backcolor
    setItemStyle({
      backgroundColor: publicSetup.backcolor,
    });
    console.log("COLOR: " + publicSetup.backcolor);
  }, [publicSetup.backcolor]); // Escuchando cambios en publicSetup.backcolor

  // Controla los inputs de Frame
  const handleInputChangeFrame = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSingleFrameData({
      ...singleFrameData,
      [name]: value,
    });
  };

  // Función para manejar los selects de Frame
  const onSelectChangeFrame = (selectedOption, name) => {
    console.log(`Actualizando ${name}:`, selectedOption);
    setSingleFrameData({
      ...singleFrameData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  // Función para actualizar el contenido de un Frame.
  const handleChangeFrame = () => {
    handlePutFrame(targetFrameName.name, singleFrameData);
  };

  // Constantes a mapear por los select
  const scopes = Object.entries(constants.scopes).map(([key, value]) => ({
    value: value.value,
    label: value.label,
  }));
  const types = Object.entries(constants.frameTypes).map(([key, value]) => ({
    value: value.value,
    label: value.label,
  }));

  return (
    <section
      className="div2-vc"
      // style={itemStyle}
    >
      <div className="inputs-frame">
        <div className="inputs-frame-son">
          {" "}
          <Input
            name="name"
            type="text"
            value={singleFrameData.name}
            onChange={handleInputChangeFrame}
            label="Name"
            activeLabel={true}
            placeholder="Name"
          />
          <Select
            isSearchable={true}
            isClearable={false}
            name="type"
            options={types}
            placeholder="Tipo..."
            onChange={(selectedOption) =>
              onSelectChangeFrame(selectedOption, "type")
            }
            value={types.find((type) => type.value === singleFrameData.type)}
            label="Tipo"
            activeLabel={true}
          />
          <Input
            name="config"
            type="text"
            value={singleFrameData.config}
            onChange={handleInputChangeFrame}
            label="Config"
            activeLabel={true}
            placeholder="{#######}"
          />
          { singleFrameData.type === 'template' &&
            <Input
              name="templatecode"
              type="text"
              value={singleFrameData.templatecode}
              onChange={handleInputChangeFrame}
              label="Código de template"
              activeLabel={true}
              placeholder="thome"
            />
          }
          <Select
            isSearchable={true}
            isClearable={false}
            name="scope"
            options={scopes}
            placeholder="Scope..."
            onChange={(selectedOption) =>
              onSelectChangeFrame(selectedOption, "scope")
            }
            value={scopes.find(
              (scope) => scope.value === singleFrameData.scope
            )}
            label="Scope"
            activeLabel={true}
          />
        </div>
        <div className="inputs-frame-son">
          {" "}
          <Input
            name="pagesize"
            type="text"
            placeholder="1000"
            value={singleFrameData.pagesize}
            onChange={handleInputChangeFrame}
            label="Tamaño de la página"
            activeLabel={true}
          />
          <Input
            name="datacode"
            type="text"
            placeholder=""
            value={singleFrameData.datacode}
            onChange={handleInputChangeFrame}
            label="datacode"
            activeLabel={true}
          />
          <Input
            name="filter"
            type="text"
            placeholder="{ header.name: `expediente` }"
            value={singleFrameData.filter}
            onChange={handleInputChangeFrame}
            label="Filter"
            activeLabel={true}
          />{" "}
          <div className="inputs-frame-button-bar">
            <Button
              content="  Guardar cambios en Frame"
              disabled={false}
              onClick={handleChangeFrame}
            />
          </div>
        </div>
        <TableVCElement />
      </div>
    </section>
  );
};

export default InputsVCFrame;
