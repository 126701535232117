import React from "react";
import PropTypes from "prop-types";
import KanbanBoard from "./components/KanbanBoard.jsx";
import "./KanbanMenu.css";
import { DragDropProvider } from "./components/KanbanContext.js";

export default function KanbanMenu({ setSetupData }) {
  return (
    <DragDropProvider setSetupData={setSetupData}>
      <KanbanBoard />
    </DragDropProvider>
  );
}

// Validación de prop-types
KanbanMenu.propTypes = {
  setSetupData: PropTypes.func.isRequired,
};
