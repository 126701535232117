import React, { useContext } from 'react';
import './JornadaInfo.css';
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider';

function JornadaInfo() {
    const { state } = useContext(UserActivityContext)
    return (
        <div className="time-container__jornada-info">
            <span className="jornada-info__day">hoy</span>
            <span className="jornada-info__duration">{state.duration}</span>
        </div>
    );
}

export default JornadaInfo;