// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-row-tittles {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor:grab;
}
.border-bottom-light-grey {
  border-bottom: solid 1px rgb(204, 204, 204);
}
.frame-row-c1 {
  width: 25%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.frame-row-c2 {
  width: 25%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.frame-row-c3 {
  width: 25%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.frame-row-c4 {
  width: 25%;
  display: flex;
  justify-content: center;
  gap: 1vw;
  text-align: center;
  align-items: center;
}
.expanded {
  grid-template-rows: 1fr !important;
}
.expandableSection-f {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-in-out;
}
.expandableSection-inner {
  overflow: hidden;
}
.dragging {
  opacity: 0.1;
  transition: opacity 0.1s ease-out;
}

.drag-over {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ViewCreate/ComponentesViewCreate/FrameRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,2CAA2C;AAC7C;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,+CAA+C;AACjD;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".frame-row-tittles {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  font-size: 1vw;\r\n  padding-top: 1vh;\r\n  padding-bottom: 1vh;\r\n  cursor:grab;\r\n}\r\n.border-bottom-light-grey {\r\n  border-bottom: solid 1px rgb(204, 204, 204);\r\n}\r\n.frame-row-c1 {\r\n  width: 25%;\r\n  display: flex;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n}\r\n.frame-row-c2 {\r\n  width: 25%;\r\n  display: flex;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n}\r\n.frame-row-c3 {\r\n  width: 25%;\r\n  display: flex;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n}\r\n.frame-row-c4 {\r\n  width: 25%;\r\n  display: flex;\r\n  justify-content: center;\r\n  gap: 1vw;\r\n  text-align: center;\r\n  align-items: center;\r\n}\r\n.expanded {\r\n  grid-template-rows: 1fr !important;\r\n}\r\n.expandableSection-f {\r\n  display: grid;\r\n  grid-template-rows: 0fr;\r\n  transition: grid-template-rows 0.5s ease-in-out;\r\n}\r\n.expandableSection-inner {\r\n  overflow: hidden;\r\n}\r\n.dragging {\r\n  opacity: 0.1;\r\n  transition: opacity 0.1s ease-out;\r\n}\r\n\r\n.drag-over {\r\n  opacity: 0.5;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
