// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-green {
  background-color: green;
}
.bg-orange {
  background-color: rgb(128, 47, 0);
}

.padre {
  display: flex;
  flex-direction: column;
  background-color: aqua;
  width: 60vw;
  height: 60vh;
}
.cabecera {
  width: 100%;
  height: 20vh;
  background-color: bisque;
}
.cuerpo {
  width: 100%;
  height: auto;
  max-height: 0px;
  overflow: hidden;
  background-color: rgba(165, 42, 42, 0.274);
  transition: max-height 0.5s ease-in-out;
}
.expandido {
  max-height: none !important;
}

.cuerpo {
  width: 100%;

  height: 0px;
  overflow: hidden;
  background-color: rgba(165, 42, 42, 0.274);
  transition: height 0.5s ease-in-out;
}
.expandido {
  height: auto !important;
}
/* https://www.cssscript.com/expandify-css-grid/ */

.expanded {
  grid-template-rows: 1fr !important;
}
.expandableSection {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-in-out;
}
.expandableSection-inner {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/KanbanMenu/KanbanMenu.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,0CAA0C;EAC1C,uCAAuC;AACzC;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;;EAEX,WAAW;EACX,gBAAgB;EAChB,0CAA0C;EAC1C,mCAAmC;AACrC;AACA;EACE,uBAAuB;AACzB;AACA,kDAAkD;;AAElD;EACE,kCAAkC;AACpC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,+CAA+C;AACjD;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bg-green {\r\n  background-color: green;\r\n}\r\n.bg-orange {\r\n  background-color: rgb(128, 47, 0);\r\n}\r\n\r\n.padre {\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: aqua;\r\n  width: 60vw;\r\n  height: 60vh;\r\n}\r\n.cabecera {\r\n  width: 100%;\r\n  height: 20vh;\r\n  background-color: bisque;\r\n}\r\n.cuerpo {\r\n  width: 100%;\r\n  height: auto;\r\n  max-height: 0px;\r\n  overflow: hidden;\r\n  background-color: rgba(165, 42, 42, 0.274);\r\n  transition: max-height 0.5s ease-in-out;\r\n}\r\n.expandido {\r\n  max-height: none !important;\r\n}\r\n\r\n.cuerpo {\r\n  width: 100%;\r\n\r\n  height: 0px;\r\n  overflow: hidden;\r\n  background-color: rgba(165, 42, 42, 0.274);\r\n  transition: height 0.5s ease-in-out;\r\n}\r\n.expandido {\r\n  height: auto !important;\r\n}\r\n/* https://www.cssscript.com/expandify-css-grid/ */\r\n\r\n.expanded {\r\n  grid-template-rows: 1fr !important;\r\n}\r\n.expandableSection {\r\n  display: grid;\r\n  grid-template-rows: 0fr;\r\n  transition: grid-template-rows 0.5s ease-in-out;\r\n}\r\n.expandableSection-inner {\r\n  overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
