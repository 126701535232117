import React, { useEffect, useState } from "react";
import "./Checks.css";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Title from "src/modules/components/common/Title/Title";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Action from "src/routes/Checks/Action/Action";
import {
  default as Select,
  onSelectChangeSingle,
} from "src/modules/components/common/Select/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { Check as CheckAPI } from "src/lib/api";
import { Cartridge as CartridgeAPI } from "src/lib/api";
import { validateProperties } from "src/lib/validateProperties";
import TestCheck from "./Test/TestCheck";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

const Check = () => {
  const location = useLocation();
  const [queryParameterExists, setQueryParameterExists] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    actions: [],
    active: false,
    checkcode: "",
    notes: "",
    logic: "",
    name: "",
    scope: "",
    cartridge: "",
    cartridgecode: queryParams.get("cartridgecode"),
    scopecode: "",
  });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  const [actions, setActions] = useState([]);
  const [cartridgeList, setCartridgeList] = useState([
    { label: "", value: "" },
  ]);
  useEffect(() => {
    if (check.cartridge) {
      const cartridge = cartridgeList.find(
        (cartridge) => cartridge.value === check.cartridgecode
      );
      setCheck({ ...check, cartridgecode: cartridge.value });
    }
  }, [check.cartridge]);

  useEffect(() => {
    setCheck({ ...check, actions });
  }, [actions]);

  const readCheck = async (value) => {
    const checkRead = await CheckAPI.read(value);
    if (!checkRead) return;
    setCheck({ scopecode: check.scopecode, ...checkRead });
    setActions(checkRead.actions);
  };

  const saveCheck = () => {
    const propertiesToValidate = {
      checkcode: check.checkcode,
      cartridgecode: check.cartridgecode,
    };
    const errorMessage = validateProperties(propertiesToValidate);
    if (errorMessage !== "") return alert(errorMessage);
    CheckAPI.crupdate(check);
  };

  const deleteCheck = (checkCode) => {
    CheckAPI.delete(checkCode);
  };

  const confirmDelete = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar el check: ${check.checkcode}?`
    );

    if (confirmation) {
      deleteCheck(check.checkcode);
    }
  };

  const handleAddAccion = () => {
    if(saveButtonDisabled) setSaveButtonDisabled(false)
    const newAction = {
      name: "",
      type: "",
      problem: "",
      solution: "",
      link: "",
      helplink: "",
      for: "",
    };
    const updatedCheck = { ...check };

    updatedCheck.actions.push(newAction);
    setCheck(updatedCheck);
  };

  const handleDeleteAccion = (accionToDelete) => {
    const updatedAcciones = actions.filter(
      (action) => action !== accionToDelete
    );
    setActions(updatedAcciones);
  };

  const redirectToChecks = () => {
    navigate('/view/dchecksall')
  };


  const onChange = (e) => {
    if(saveButtonDisabled) setSaveButtonDisabled(false)
    if (e.target.name === "active") {
      setCheck({ ...check, [e.target.name]: e.target.checked });
      return;
    }
    setCheck({ ...check, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const fetch = async () => {
      CartridgeAPI.list().then((res) => {
        setCartridgeList(
          res.map((cartridge) => ({
            value: cartridge.cartridgecode,
            label: cartridge.name,
          }))
        );
      });
    };
    const parametroCheckCode = queryParams.get("checkcode");
    if (parametroCheckCode) {
      onSelectCheckChange({ value: parametroCheckCode });
      setQueryParameterExists(true);
    }
    fetch();
  }, []);
  const onSelectCheckChange = (value) => {
    if (!value || !value.value) {
      setCheck({
        scope: "Dossier",
        active: false,
        checkcode: "",
        name: "",
        notes: "",
        scopecode: "",
        logic: "",
        cartridge: "",
        cartridgecode: "",
      });
      setActions([]);

      return;
    }
    readCheck(value.value);
  };
  return (
    <div className="checks-container">

    <PanelGroup direction="horizontal" >
      <Panel>
      <div className="group-container">
        <Title text="Check" />
        <div className="actions-buttons">
          <Button
            id="backToChecks"
            onClick={redirectToChecks}
            icon="arrow back"
          />

          <Button
            id="updateCheck"
            onClick={saveCheck}
            icon="save"
            disabled={saveButtonDisabled}
          />

          {queryParameterExists && (
            <Button
              onClick={confirmDelete}
              icon="delete"
            />
          )}
        </div>
        <Input
          name="checkcode"
          type="text"
          placeholder="checkcode"
          onChange={onChange}
          value={check.checkcode}
          label="Código de Check"
          activeLabel={true}
        />
        {!queryParams.get("cartridgecode") && (
          <Select
            isSearchable={true}
            isClearable={false}
            name="cartridge"
            options={cartridgeList}
            placeholder="Cartridge"
            onChange={(event) => {
              onSelectChangeSingle(event, setCheck, "cartridge");
            }}
            value={cartridgeList.find(
              (option) => option.value === check.cartridgecode
            )}
            label="Cartucho"
            activeLabel={true}
          />
        )}
        <Input
          name="name"
          type="text"
          placeholder="name"
          onChange={onChange}
          value={check.name}
          label="Nombre"
          activeLabel={true}
        />
        <TextArea
          name="notes"
          type="text"
          placeholder="notes"
          onChange={onChange}
          value={check.notes}
          label="Notas"
        />
        <TextArea
          id="logic"
          value={check.logic}
          name="logic"
          placeholder="Logic"
          onChange={onChange}
          label="Lógica"
        />
        <Input
          type="checkbox"
          name="active"
          onChange={onChange}
          checked={check.active}
          label="Activar"
          activeLabel={true}
        />
        <div className="actions-container">
          <span className="actions-text">
            <h3>Actions:</h3>
            <Button
              onClick={handleAddAccion}
              icon="add"
            />
          </span>
          {actions.map((action, index) => (
            <Action
              key={index}
              action={action}
              onDelete={handleDeleteAccion}
              onChange={(updatedAction) => {
                const updatedCheck = { ...check };
                updatedCheck.actions[index] = updatedAction;
                setCheck(updatedCheck);
              }}
            />
          ))}
        </div>
      </div>
      </Panel>
      <PanelResizeHandle className="resizer" />
      <Panel>
      <TestCheck check={check} />
      </Panel>
    </PanelGroup>


    </div>
  );
};

export default Check;
