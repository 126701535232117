import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";

const StructureMenuComms = ({
  editedItems,
  dispatch,
  selectOptions,
}) => {
  const handleCheckBoxChange = (key, checked) => {
    dispatch({
      type: "UPDATE_HEADER",
      payload: {
        ...editedItems.filetype.header,
        [key]: checked,
      },
    });
  };

  const handleInputChange = (key, value) => {
    dispatch({
      type: "UPDATE_HEADER",
      payload: {
        ...editedItems.filetype.header,
        [key]: value,
      },
    });
  };

  const emailvars = editedItems.filetype.header.emailvars || [];
  const phonevars = editedItems.filetype.header.phonevars || [];

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Comunicaciones</h3>
      <p>Indica si el filetype se relaciona con las comunicaciones.</p>
      <div className="structure-header__value">
        <span className="structure-header-key">Activado:</span>
        <Input
          type="checkbox"
          value={editedItems.filetype.header["comms"] || false}
          onChange={(e) => handleCheckBoxChange("comms", e.target.checked)}
          autoFocus
        />
      </div>
      <div className="structure-header__value">
        <span className="structure-header-key">emailvars:</span>
        <Select
          options={selectOptions}
          value={selectOptions.filter((option) =>
            emailvars.includes(option.value)
          )}
          onChange={(options) =>
            handleInputChange(
              "emailvars",
              options ? options.map((option) => option.value) : []
            )
          }
          isClearable
          isSearchable
          isMulti
        />
      </div>
      <div className="structure-header__value">
        <span className="structure-header-key">phonevars:</span>
        <Select
          options={selectOptions}
          value={selectOptions.filter((option) =>
            phonevars.includes(option.value)
          )}
          onChange={(options) =>
            handleInputChange(
              "phonevars",
              options ? options.map((option) => option.value) : []
            )
          }
          isClearable
          isSearchable
          isMulti
        />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuComms.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        comms: PropTypes.bool,
        emailvars: PropTypes.arrayOf(PropTypes.string),
        phonevars: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StructureMenuComms;
