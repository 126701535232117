/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";


export class Doc {
    static async list(framecode, first = 0, pagination = 100) {
        let response = await HttpMethods.request_get(`/v1/doc/list?format=flat`);
        if (!response) {
            alert("Error al obtener los documentos");
            response = [];
        }
        return response;
    }
    static async listflat(framecode, first = 0, pagination = 100) {
        const response = await HttpMethods.request_get(
            `/v1/doc/list/-/${framecode}/${first}/${pagination}?format=flat`
        );
        if (!response) alert("Error al obtener los documentos");
        return response;
    }
    static async graph(dossiercode) {
        const response = await HttpMethods.request_get(`/v1/doc/graph/${dossiercode}`, "text");
        return response;
    }
    
    static async short(short, framecode, skip, limit, dossierCode, format) {
        let response = await HttpMethods.request_get(`/v1/doc/list?format=flat`);
        if (!response) {
            alert("Error al obtener los documentos");
            response = [];
        }
        return response;
    }
}