import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Button from "src/modules/components/common/Button/Button";
import { useViewCreateContext } from "../ViewCreateContext.js";
import "./ElementRow.css";
import InputsVCElement from "./InputsVCElement.jsx";

const ElementRow = ({ element, index }) => {
  const {
    removeElement,
    targetFrameName,
    setTargetElementName,
    setSingleElementData,
    singleElementData,
    onElementDragStart,
    onElementDragOver,
    onElementDrop,
    onDragEndElement
  } = useViewCreateContext();

  const handleRemove = () => {
    removeElement(targetFrameName.name, element.name);
  };

  const handleEditElement = () => {
    setTargetElementName(element.name);
    setSingleElementData(element);
    toggleShow();
  };

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (singleElementData._id !== element._id) {
      setShow(false);
    }
  }, [singleElementData]);

  return (
    <div>
      <div
        draggable
        onDragStart={(e) => onElementDragStart(e, element._id)}
        onDragOver={(e) => onElementDragOver(e)}
        onDrop={(e) => onElementDrop(e, element._id)}
        onDragEnd={onDragEndElement}
        className={`bg-black element-row-tittles ${
          show ? "" : "border-bottom-light-grey"
        }`}
      >
        <div className="element-row-child">{element.name}</div>
        <div className="element-row-child">{element.variable}</div>
        <div className="element-row-child">{element.icon}</div>
        <div className="element-row-child">{element.tipo}</div>
        <div className="element-row-child-btns">
          <Button
            icon="edit"
            disabled={false}
            onClick={handleEditElement}
            style={{ minWidth: "0px" }}
          />
          <Button
            icon="delete"
            disabled={false}
            onClick={handleRemove}
            style={{ minWidth: "0px" }}
          />{" "}
        </div>
      </div>
      <div className={`expandableSection-e ${show ? "expanded" : ""}`}>
        <div className="expandableSection-inner">
          <InputsVCElement />
        </div>
      </div>
    </div>
  );
};

// Validación de props
ElementRow.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    variable: PropTypes.string,
    icon: PropTypes.string,
    tipo: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ElementRow;
