// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  gap: 10px;
}

.status-service {
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);

  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 0px solid #000000;
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 600px) {
  .status {
    max-width: 100%;
    padding: 10px;
  }

  .status-service {
    flex-direction: column;
    padding: 10px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .status {
    max-width: 90%;
  }

  .status-service {
    padding: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/routes/Status/Status.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,sCAAsC;;EAEtC,kCAAkC;EAClC,uCAAuC;EACvC,0CAA0C;EAC1C,yBAAyB;EAIzB,kDAAkD;AACpD;;AAEA;EACE;IACE,eAAe;IACf,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,aAAa;EACf;AACF;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".status {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 0 auto;\r\n  max-width: 1000px;\r\n  gap: 10px;\r\n}\r\n\r\n.status-service {\r\n  margin-bottom: 10px;\r\n  padding: 20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  background-color: var(--primary-color);\r\n\r\n  border-radius: 20px 20px 20px 20px;\r\n  -moz-border-radius: 20px 20px 20px 20px;\r\n  -webkit-border-radius: 20px 20px 20px 20px;\r\n  border: 0px solid #000000;\r\n\r\n  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75);\r\n  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75);\r\n  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.75);\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .status {\r\n    max-width: 100%;\r\n    padding: 10px;\r\n  }\r\n\r\n  .status-service {\r\n    flex-direction: column;\r\n    padding: 10px;\r\n  }\r\n}\r\n\r\n@media (min-width: 601px) and (max-width: 1024px) {\r\n  .status {\r\n    max-width: 90%;\r\n  }\r\n\r\n  .status-service {\r\n    padding: 15px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
