// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divlink {
    cursor: pointer;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Bancos/BancosStyles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".divlink {\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
