import React, { useState } from "react";
import PropTypes from 'prop-types'; // Importa PropTypes
import Sidebar from "../Sidebar";
import { ReactFlow } from "@xyflow/react";
import Button from "../../Button/Button";

const NodeViewer = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onConnectStart,
  onConnectEnd,
  onNodeClick,
  hasSidebar,
  renderSidebar,
  canCreateNodes,
  createNewNode,
  onCloseEditor,
  nodeTypes,
  edgeTypes,
  onNodesDelete,
}) => {
  const proOptions = { hideAttribution: true };
  const fitViewOptions = {
    padding: 3,
  };

  const [openEditor, setOpenEditor] = useState(false);

  const onClickNode = (event, node) => {
    setOpenEditor(true);
    onNodeClick(event, node);
  };

  const closeEditor = () => {
    setOpenEditor(false);
    onCloseEditor();
  };

  const onDelete = () => {
    setOpenEditor(false);
    onNodesDelete();
  };

  return (
    <>
      {canCreateNodes && (
        <div className="add-node-button-container">
          <Button
            icon="add"
            name="add-node"
            onClick={createNewNode}
          />
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        {openEditor && hasSidebar && <Sidebar onCloseEditor={closeEditor}>{renderSidebar()}</Sidebar>}
        <div className="wrapper">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            fitView
            fitViewOptions={fitViewOptions}
            proOptions={proOptions}
            onNodeClick={onClickNode}
            onNodesDelete={onDelete}
          />
        </div>
      </div>
    </>
  );
};

// Validación de propiedades
NodeViewer.propTypes = {
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  onNodesChange: PropTypes.func.isRequired,
  onEdgesChange: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  onConnectStart: PropTypes.func.isRequired,
  onConnectEnd: PropTypes.func.isRequired,
  onNodeClick: PropTypes.func.isRequired,
  hasSidebar: PropTypes.bool.isRequired,
  renderSidebar: PropTypes.func.isRequired,
  canCreateNodes: PropTypes.bool.isRequired,
  createNewNode: PropTypes.func.isRequired,
  onCloseEditor: PropTypes.func.isRequired,
  nodeTypes: PropTypes.object.isRequired,
  edgeTypes: PropTypes.object.isRequired,
  onNodesDelete: PropTypes.func.isRequired,
};

export default NodeViewer;
