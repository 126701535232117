export function getDeepValue(object, path, defaultValue) {
    if(!path || !object) return null
    const pathTokens = path.split('.');
    let v = object;
    for (const p of pathTokens) {
      try {
        v = Object.prototype.hasOwnProperty.call(v, p) ? v[p] : defaultValue;
      } catch (error) {
        return defaultValue;
      }
    }
    return v;
  }
  
  export function buscarElementoPorVariable(array, variable) {
    for (const elemento of array) {
      if (elemento.variable === variable) {
        return elemento;
      }
      if (elemento.elements && elemento.elements.length > 0) {
        const resultadoRecursivo = buscarElementoPorVariable(
          elemento.elements,
          variable
        );
        if (resultadoRecursivo) {
          return resultadoRecursivo;
        }
      }
    }
    return null;
  }
  
  export function obtenerObjetoDeElemento(elemento) {
    const objeto = {};
    if (elemento?.elements && elemento.elements.length > 0) {
      elemento.elements.forEach((subElemento) => {
        const defaultValue = defaultData(subElemento.type);
        objeto[subElemento.variable.split('.').pop()] = defaultValue;
      });
    }
    return objeto;
  }
  
  export function defaultData(type) {
    if (type === 'array') {
      return [];
    } else if (type === 'object') {
      return {};
    } else {
      return '';
    }
  }
  
  export function findPathByVariable(arr, id, path = []) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].variable === id) {
        return path.concat(i);
      } else if (arr[i].elements && arr[i].elements.length > 0) {
        const result = findPathByVariable(
          arr[i].elements,
          id,
          path.concat(i, 'elements')
        );
        if (result) return result;
      }
    }
    return null;
  }
  
  export function getArrayByPath(arr, path) {
    return path.reduce((acc, key) => acc[key], arr);
  }
  
  export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  
  export function reorderArray(arr, fromIndex, toIndex) {
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
  }
  
  export function moveElementToPosition(arr, id1, id2) {
    const clonedArray = deepClone(arr);
  
    const path1 = findPathByVariable(clonedArray, id1);
    const path2 = findPathByVariable(clonedArray, id2);
  
    if (!path1 || !path2) {
      return clonedArray;
    }
  
    let i = 0;
    while (i < path1.length && i < path2.length && path1[i] === path2[i]) {
      i++;
    }
  
    const commonPath = path1.slice(0, i);
    const arrayAtLevel = getArrayByPath(clonedArray, commonPath);
  
    const index1 = path1[i];
    const index2 = path2[i];
  
    if (index1 !== undefined && index2 !== undefined) {
      reorderArray(arrayAtLevel, index1, index2);
    }
  
    return clonedArray;
  }
  