// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.double-container {
  display: flex;
  height: 80vh;
}

.double-container-left {
  padding: 8px;
  border-radius: 4px;
  min-width: 50%;
  height: 100%;
  margin-right: 20px;
}
.double-container-right {
  padding: 8px;
  overflow: hidden;
  height: 100%;
  width: -webkit-fill-available;
  border-radius: 4px;
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/Containers/DoubleContainer/DoubleContainer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".double-container {\r\n  display: flex;\r\n  height: 80vh;\r\n}\r\n\r\n.double-container-left {\r\n  padding: 8px;\r\n  border-radius: 4px;\r\n  min-width: 50%;\r\n  height: 100%;\r\n  margin-right: 20px;\r\n}\r\n.double-container-right {\r\n  padding: 8px;\r\n  overflow: hidden;\r\n  height: 100%;\r\n  width: -webkit-fill-available;\r\n  border-radius: 4px;\r\n  margin-right: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
