/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Info {
  /**
   * 
   * @param {boolean} formatFlat Permite obtener lo datos con format=flat
   * @returns 
   */
  static async basics(formatFlat = false) {
    let url = '/v1/info/basics'
    if (formatFlat) url += "?format=flat"
    const response = await HttpMethods.request_get(url);
    if (!response) alert("Error al obtener la información básica");
    return response;
  }

  static async status(){
    const response = await HttpMethods.request_get('/v1/info/status');
    if (!response) alert("Error al obtener la información de estado");
    return response;
  }
}
