import React, { useEffect, useState } from "react";
import MenuList from "./MenuList/MenuList";
import PropTypes from "prop-types";
import { generateStructureMenu, plainStructureMenu } from "./helpers";
import "./Menu.css";
import dragAndDrop from "src/modules/helpers/dragAndDrop";
import Button from "src/modules/components/common/Button/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import MenuListBoxEdit from "./MenuListBoxEdit/MenuListBoxEdit";
import MenuCardBoxEdit from "./MenuCardBoxEdit/MenuCardBoxEdit";
const Menu = ({ setupData, setSetupData }) => {
  const VAR_EDIT_MENULIST = "editMenuList";
  const VAR_EDIT_MENUCARD = "editMenuCard";
  const [menuData, setMenuData] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [selectEditMenuList, setSelectEditMenuList] = useState({});
  useEffect(() => {
    const menu = generateStructureMenu(setupData.menu);
    setMenuData(menu);
  }, []);

  //Updating SetupData Each time menuData changes
  useEffect(() => {
    const plainedMenu = plainStructureMenu(menuData);
    setSetupData((prevSetupData) => ({
      ...prevSetupData,
      menu: plainedMenu,
    }));
  }, [menuData, setMenuData, setSetupData]);

  const setupDragAndDrop = async () => {
    try {
      const pos = await dragAndDrop();

      const dragIndex = pos.drag.index;
      const dropIndex = pos.drop.index;

      const dragList = pos.drag.object.split("-")[0];
      const dropList = pos.drop.object.split("-")[0];

      const cardId = pos.drag.object.split("-")[1];

      const updatedMenuData = [...menuData];

      const sourceList = updatedMenuData.find((list) => list.id === dragList);
      const targetList = updatedMenuData.find((list) => list.id === dropList);

      const draggedCard = sourceList.submenus.find(
        (submenu) => submenu.id === cardId
      );
      //Update the pathmenu of the dragged card
      draggedCard.pathmenu = targetList.pathmenu + "/" + draggedCard.id;

      sourceList.submenus.splice(dragIndex, 1);

      targetList.submenus.splice(dropIndex, 0, draggedCard);

      setMenuData(updatedMenuData);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMenuList = (data) => {
    setMenuData((prev) => {
      const prevWithoutOneList = prev.filter((el) => data.id !== el.id);
      return prevWithoutOneList;
    });
  };

  const addMenuList = () => {
    setShowModal(VAR_EDIT_MENULIST);
    //Setea el menulist en por defecto
    setSelectEditMenuList({});
  };
  const editMenuList = (data) => {
    setShowModal(VAR_EDIT_MENULIST);
    setSelectEditMenuList(data);
  };

  const saveNewValuesMenuList = (data) => {
    const indexMenuList = menuData.findIndex((el) => el.id === selectEditMenuList.id)
    setMenuData((prev) => {
      const newValues = [...prev]
      newValues[indexMenuList] = data
      return newValues
    })
  }
  const addMenuCard = (menuListId) => {
    //Implementar logica de lo que hara menucard
    // setShowModal(VAR_EDIT_MENULIST)
    // setSelectedEditMenuList(menuListId)

  }
  return (
    <div className="setup-menu__dashboard">
      <div className="setup-menu__buttons">
        <Button content={<RefreshIcon />} />
      </div>
      <div className="setup-menu__lists">
        {menuData.map((menu, index) => {
          const menuToIgnore = ["login", "register"];
          if (menuToIgnore.includes(menu.id)) return null;
          return (
            <MenuList
              key={menu.id + index}
              data={menu}
              setupDragAndDrop={setupDragAndDrop}
              deleteMenuList={deleteMenuList}
              editMenuList={editMenuList}
              addMenuCard={addMenuCard}
            />
          );
        })}
        <div className="menu-list__container">
          <div className="menu-list-add"
            onClick={() => addMenuList()}
          >
            +
          </div>

        </div>
      </div>
      {showModal === VAR_EDIT_MENULIST && (
        <MenuListBoxEdit
          setShowModal={setShowModal}
          data={selectEditMenuList}
          setData={saveNewValuesMenuList}
        />
      )}
      {showModal === VAR_EDIT_MENUCARD && (
        <MenuCardBoxEdit
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export default Menu;

Menu.propTypes = {
  setupData: PropTypes.object.isRequired,
  setSetupData: PropTypes.func.isRequired,
};
