/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";


export class Agent {
    static async question(agentid, messages, threadid) {
      const data = {
        agentid: agentid,
        messages: messages,
      }
      if (threadid) data.threadid = threadid
      const response = await HttpMethods.request_post("/v1/agent/question", data);
      return response;
    }
  }
  