// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-menu-item {
  margin: 0.2vw;
  padding: 0.2vw 0.2vw 0.2vw 0.5vw;
  border-radius: 6px;
  cursor: grab;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

.edit-menu-item:hover{
  opacity: 0.7;
}

.edit-menu-item-tittle{
  font-size: 1vw;
}

.item-container-buttons{
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  gap: 5px;
  display: flex;
  flex-direction: row;
}

.edit-menu-item:hover .item-container-buttons {
  opacity: 1;
}

.edit-menu-item-button:hover {
  background-color: rgb(44, 44, 44);
  border: 1px solid white;
}

.dragging-item {
  opacity: 0.5;
  border: 2px dashed #666;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/KanbanMenu/components/Item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,oCAAoC;EACpC,QAAQ;EACR,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".edit-menu-item {\r\n  margin: 0.2vw;\r\n  padding: 0.2vw 0.2vw 0.2vw 0.5vw;\r\n  border-radius: 6px;\r\n  cursor: grab;\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  transition: opacity 0.3s ease-in-out;\r\n}\r\n\r\n.edit-menu-item:hover{\r\n  opacity: 0.7;\r\n}\r\n\r\n.edit-menu-item-tittle{\r\n  font-size: 1vw;\r\n}\r\n\r\n.item-container-buttons{\r\n  opacity: 0;\r\n  transition: opacity 0.2s ease-in-out;\r\n  gap: 5px;\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n\r\n.edit-menu-item:hover .item-container-buttons {\r\n  opacity: 1;\r\n}\r\n\r\n.edit-menu-item-button:hover {\r\n  background-color: rgb(44, 44, 44);\r\n  border: 1px solid white;\r\n}\r\n\r\n.dragging-item {\r\n  opacity: 0.5;\r\n  border: 2px dashed #666;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
