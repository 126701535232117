/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Bank {
    /**
     * Description
     * @param {any} {countrycode} Country code from ISO 3166-1 alpha-2
     * @returns {any}
     */
    static async bankList({ country }) {
      const response = await HttpMethods.request_get(`/v1/bank/publiclist/${country}`);
      if (!response) alert("Error al obtener los bancos");
      return response;
    }
    static async bankRequisitions() {
      const response = await HttpMethods.request_get(`/v1/bank/requisitions`);
      if (!response) alert("Error al actualizar requisitions");
      return response;
    }
  
    static async bankRevalidation(requisitionid) {
      const response = await HttpMethods.request_get(`/v1/bank/revalidate/${requisitionid}`);
      if (!response) alert("Error al realizar la revalidación");
      return response;
  
    }
    static async bankDelete(requisitionid) {
      const response = await HttpMethods.request_delete(`/v1/bank/delete`, requisitionid);
      if (!response) alert("Error al eliminar la requisición");
      return response;
  
    }
  }
  