// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formulario{
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    justify-content: space-around;
    width: 100%;
}
.form-one{
    width: 50%;
    min-height: 800px;
    padding: 10px;
}
.form-two{
    width: 25%;
    min-height: 800px;
    padding: 10px;

}`, "",{"version":3,"sources":["webpack://./src/routes/Form/Form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,6BAA6B;IAC7B,6BAA6B;IAC7B,WAAW;AACf;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,aAAa;;AAEjB","sourcesContent":[".formulario{\r\n    display: flex;\r\n    /* align-items: center; */\r\n    /* justify-content: center; */\r\n    justify-content: space-around;\r\n    width: 100%;\r\n}\r\n.form-one{\r\n    width: 50%;\r\n    min-height: 800px;\r\n    padding: 10px;\r\n}\r\n.form-two{\r\n    width: 25%;\r\n    min-height: 800px;\r\n    padding: 10px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
