// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.element-row-tittles {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor: grab;
}

.element-row-child {
  width: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.element-row-child-btns{
  width: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap:1vw;
}
.expanded {
  grid-template-rows: 1fr !important;
}
.expandableSection-e {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-in-out;
}
.expandableSection-inner {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ViewCreate/ComponentesViewCreate/ElementRow.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,OAAO;AACT;AACA;EACE,kCAAkC;AACpC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,+CAA+C;AACjD;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".element-row-tittles {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  font-size: 1vw;\r\n  padding-top: 1vh;\r\n  padding-bottom: 1vh;\r\n  cursor: grab;\r\n}\r\n\r\n.element-row-child {\r\n  width: 20%;\r\n  display: flex;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n}\r\n.element-row-child-btns{\r\n  width: 20%;\r\n  display: flex;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n  gap:1vw;\r\n}\r\n.expanded {\r\n  grid-template-rows: 1fr !important;\r\n}\r\n.expandableSection-e {\r\n  display: grid;\r\n  grid-template-rows: 0fr;\r\n  transition: grid-template-rows 0.5s ease-in-out;\r\n}\r\n.expandableSection-inner {\r\n  overflow: hidden;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
