import React, { useEffect, useState } from "react";
import "./ApiTab.css";
import { Icon } from "@mui/material";
import Input from "src/modules/components/common/Input/Input";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const ApiTab = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();

  const [portainerValues, setPortainerValues] = useState({
    portainerfrontendupdate: setupInfo?.api?.portainerfrontendupdate || "",
    portainerbackendupdate: setupInfo?.api?.portainerbackendupdate || "",
    portainerconductorupdate: setupInfo?.api?.portainerconductorupdate || "",
    portainerfilesyncupdate: setupInfo?.api?.portainerfilesyncupdate || "",
    portainerdatabaseupdate: setupInfo?.api?.portainerdatabaseupdate || "",
  });
  const [goCardlessValues, setGoCardlessValues] = useState({
    secretid: setupInfo?.bank?.secretid || "",
    secretkey: setupInfo?.bank?.secretkey || "",
  });
  useEffect(() => {
    setSetupInfo((prev) => {
      return {
        ...prev,
        api: {
          ...portainerValues,
        },
        bank: {
          ...goCardlessValues,
        },
      };
    });
  }, [portainerValues, goCardlessValues, setSetupInfo]);

  const onChangeInputGoCard = (e) => {
    setGoCardlessValues({
      ...goCardlessValues,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeInputPortainer = (e) => {
    setPortainerValues({
      ...portainerValues,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="api-container">
      {/**API */}
      <div className="api-item-container">
        <div className="api-item-header">
          <div className="api-item-header-title">Portainer </div>

          <Icon
            className="info-icon"
            title="Mas infomación"
            onClick={() => {
              //FIXME: Cambiar la url cuando exista la pagina de API en la documentacion
              window.open(
                "https://valeria.moodrobotics.com/docs/app/update#como-obtener-claves-de-portainer",
                "_blank"
              );
            }}
          >
            info_icon
          </Icon>
        </div>
        <div className="api-item-content">
          <div className="api-item-descripcion">
            <div>Actualizar Frontend</div>
            <Input
              name="portainerfrontendupdate"
              type="text"
              placeholder="https://swarm.cliente.com:NaN/api/webhooks/xxxxx-xxxxx-xxxxxxxxx-xxx-x"
              onChange={onChangeInputPortainer}
              value={portainerValues.portainerfrontendupdate}
            />
          </div>
          <div className="api-item-descripcion">
            <div>Actualizar Backend</div>
            <Input
              name="portainerbackendupdate"
              type="text"
              placeholder="https://swarm.cliente.com:NaN/api/webhooks/xxxxx-xxxxx-xxxxxxxxx-xxx-x"
              onChange={onChangeInputPortainer}
              value={portainerValues.portainerbackendupdate}
            />
          </div>
          <div className="api-item-descripcion">
            <div>Actualizar Conductor</div>
            <Input
              name="portainerconductorupdate"
              type="text"
              placeholder="https://swarm.cliente.com:NaN/api/webhooks/xxxxx-xxxxx-xxxxxxxxx-xxx-x"
              onChange={onChangeInputPortainer}
              value={portainerValues.portainerconductorupdate}
            />
          </div>
          <div className="api-item-descripcion">
            <div>Actualizar FileSync</div>
            <Input
              name="portainerfilesyncupdate"
              type="text"
              placeholder="https://swarm.cliente.com/api/webhooks/fwwerwr-8359-4e14-9f46-234234112"
              onChange={onChangeInputPortainer}
              value={portainerValues.portainerfilesyncupdate}
            />
          </div>
          <div className="api-item-descripcion">
            <div>Actualizar Base de datos</div>
            <Input
              name="portainerdatabaseupdate"
              type="text"
              placeholder="https://swarm.cliente.com/api/webhooks/rtteretert-8359-4e14-9f46-234234112"
              onChange={onChangeInputPortainer}
              value={portainerValues.portainerdatabaseupdate}
            />
          </div>
        </div>
      </div>
      <div className="api-item-container">
        <div className="api-item-header">
          <div className="api-item-header-title">Bancos </div>
          <Icon
            className="info-icon"
            title="Mas infomación"
            onClick={() => {
              window.open(
                "https://valeria.moodrobotics.com/docs/movimientos/bancos",
                "_blank"
              );
            }}
          >
            info_icon
          </Icon>
        </div>
        {/**Bancos */}
        <div className="api-item-content">
          <div className="api-item-descripcion">
            <div>Secret ID</div>
            <Input
              name="secretid"
              type="text"
              placeholder="0a000000-a000-0000-a0aa-000000000aa"
              onChange={onChangeInputGoCard}
              value={goCardlessValues.secretid}
            />
          </div>
          <div className="api-item-descripcion">
            <div>Secret Key</div>
            <Input
              name="secretkey"
              type="text"
              placeholder="0a000000a000000a0aa000000000aa0a000000a000000a0aa000000000aa0a000000a000000a0aa000000000aa0a000000a000000a0aa000000000aa"
              onChange={onChangeInputGoCard}
              value={goCardlessValues.secretkey}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiTab;
