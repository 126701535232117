import React, {
  useEffect,
  lazy,
  Suspense,
  useRef,
  useReducer,
  useCallback
} from "react";
import { useParams } from "react-router-dom";
import Button from "src/modules/components/common/Button/Button";
import Collapsible from "src/modules/components/common/Collapsible/Collapsible";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import StructureList from "./StructureList/StructureList";
import dragAndDrop from "../../modules/helpers/dragAndDrop";
import "./Structure.css";
import StructureMenuComments from "./StructureMenu/StructureMenuComments";
import StructureMenuArchives from "./StructureMenu/StructureMenuArchives";
import StructureMenuLogs from "./StructureMenu/StructureMenuLogs";
import StructureMenuMovements from "./StructureMenu/StructureMenuMovements";
import StructureMenuDocs from "./StructureMenu/StructureMenuDocs";
import StructureMenuComms from "./StructureMenu/StructureMenuComms";
import StructureMenuChat from "./StructureMenu/StructureMenuChat";
import StructureMenu from "./StructureMenu/StructureMenu";
import RecyclingIcon from "@mui/icons-material/Recycling";
import StructureMenuFlows from "./StructureMenu/StructureMenuFlows";
import LoadingSpinner from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import {
  loadStructureData,
  saveStructureData,
  deleteStructure,
  setModalState,
  remakeStructure,
  loadRemotes,
  updateStructurePositions,
  showSnackbar,
} from "../../modules/actions/structureActions";
import {
  structureReducer,
  initialState,
} from "../../modules/reducers/structureReducer";
import { useNavigate } from "react-router-dom";

const StructureModal = lazy(() => import("./StructureModal/StructureModal"));

const Structure = () => {
  const { filetypeName } = useParams();
  const [state, dispatch] = useReducer(structureReducer, initialState);
  const {
    editedItems,
    showSnackbar: snackbarVisible,
    snackbarMessage,
    isLoading,
    isModalOpen,
    selectedStructure,
    modalPath,
    selectOptions,
    selectedMenuItem,
    selectedOrigins,
  } = state;
  const menuItemRefs = useRef({});
  const navigate = useNavigate();

  const extractKeys = useCallback((array) => {
    const result = [];
    array.forEach((element) => {
      if (element.structure) {
        result.push(...extractKeys(element.structure));
      }
      result.push({ value: element.fullvarname, label: element.varname });
    });
    return result;
  }, []);

  useEffect(() => {
    loadStructureData(filetypeName, dispatch).catch(() =>
      showSnackbar("Error al cargar la estructura", dispatch)
    );
    loadRemotes(dispatch).catch(() =>
      showSnackbar("Error al cargar remotos", dispatch)
    );
  }, [filetypeName]);

  useEffect(() => {
    if (editedItems) {
      const keys = extractKeys(editedItems.filetype.structure);
      dispatch({ type: "SET_SELECT_OPTIONS", payload: keys });
    }
  }, [editedItems, extractKeys]);

  const setupDragAndDrop = async () => {
    try {
      const pos = await dragAndDrop();
      updateStructurePositions(pos, editedItems, dispatch);
      showSnackbar("Elementos reordenados", dispatch);
    } catch (error) {
      dispatch({ type: "SET_ERROR", payload: error });
      showSnackbar("Error al reorganizar elementos", dispatch);
    }
  };

  const handleSaveChanges = () => {
    saveStructureData(editedItems, dispatch).then(() =>
      showSnackbar("Cambios guardados correctamente", dispatch)
    );
  };

  const handleDeleteStructure = () => {
    deleteStructure(filetypeName, dispatch).then(() => {
      showSnackbar("Estructura eliminada", dispatch);
      navigate("/view/dfiletypes");
    });
  };

  const handleRemakeStructure = () => {
    remakeStructure(filetypeName, dispatch).then(() =>
      showSnackbar("Estructura recargada", dispatch)
    );
  };

  const openModal = (structure, path) => {
    setModalState(true, structure, path, dispatch);
  };

  const handleAddNewProperty = (pathKey, position) => {
    const path = pathKey.split("-").map(Number);

    if (position === "inside") {
      path.push(editedItems.filetype.structure.length);
    }

    openModal(
      {
        varname: "",
        title: "",
        datatype: "",
        validation: "",
        required: false,
        editable: true,
        default: null,
        enums: null,
        helplink: "",
        helptext: "",
      },
      path
    );
  };

  const manageModal = (structure = null, path = []) => {
    setModalState(true, structure, path, dispatch);
  };

  const handleInputChange = (key, value) => {
    const newHeader = {
      ...editedItems.filetype.header,
      [key]: value,
    };
    dispatch({
      type: "UPDATE_HEADER",
      payload: newHeader,
    });
  };

  const transformKeys = (key) => {
    switch (key) {
      case "name":
        return "Nombre";
      case "description":
        return "Descripción";
      case "dossierarchivedirectory":
        return "Directorio de archivos";
      case "roles":
        return "Roles";
      default:
        return key;
    }
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case "Flows":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuFlows editedItems={editedItems} dispatch={dispatch} />
          </div>
        );
      case "Comentarios":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuComments
              editedItems={editedItems}
              dispatch={dispatch}
            />
          </div>
        );
      case "Archivos":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuArchives
              editedItems={editedItems}
              dispatch={dispatch}
              originOptions={selectedOrigins}
            />
          </div>
        );
      case "Logs":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuLogs editedItems={editedItems} dispatch={dispatch} />
          </div>
        );
      case "Movimientos":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuMovements
              editedItems={editedItems}
              dispatch={dispatch}
            />
          </div>
        );
      case "Documentos":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuDocs editedItems={editedItems} dispatch={dispatch} />
          </div>
        );
      case "Comunicaciones":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuComms
              editedItems={editedItems}
              dispatch={dispatch}
              selectOptions={selectOptions}
              handleInputChange={handleInputChange}
            />
          </div>
        );
      case "Chat":
        return (
          <div className="dossier-dashboard__section-container">
            <StructureMenuChat editedItems={editedItems} dispatch={dispatch} />
          </div>
        );
      default:
        return (
          <div className="dossier-dashboard__section-container">
            <h3>{selectedMenuItem}</h3>
            <p>Contenido de la sección: {selectedMenuItem}</p>
          </div>
        );
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <p>Cargando</p>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {editedItems ? (
            <>
              <div className="structure-header">
                <h1 className="structure-header__title">
                  Estructura de {editedItems.filetype.header.name}
                </h1>
                <div className="structure__actions">
                  <Button
                    className="structure__actions-button"
                    onClick={handleDeleteStructure}
                    icon="delete"
                    tooltip="Borrar estructura"
                  />

                  <Button
                    className="structure__actions-button"
                    onClick={handleRemakeStructure}
                    content={<RecyclingIcon />}
                    tooltip="Rehacer estructura"
                  />

                  <Button
                    className="structure__actions-button"
                    onClick={handleSaveChanges}
                    icon="save"
                    tooltip="Guardar cambios"
                  />
                </div>
              </div>

              <Collapsible header="Información general" open={true}>
                <section className="structure-header-list">
                  {editedItems.filetype.header &&
                    [
                      "name",
                      "description",
                      "roles",
                      "dossierarchivedirectory",
                      "codevar",
                    ].map((key, index) => (
                      <div key={index} className="structure-header__value">
                        <span className="structure-header-key">
                          {transformKeys(key)}:
                        </span>
                        {key === "codevar" ? (
                          <Select
                            options={selectOptions}
                            value={selectOptions.find(
                              (option) =>
                                option.value ===
                                editedItems.filetype.header[key]
                            )}
                            onChange={(option) =>
                              handleInputChange(key, option ? option.value : "")
                            }
                            isClearable
                            isSearchable
                          />
                        ) : (
                          <Input
                            value={editedItems.filetype.header[key]}
                            onChange={(e) =>
                              handleInputChange(key, e.target.value)
                            }
                            autoFocus
                          />
                        )}
                      </div>
                    ))}
                  <>
                    <StructureMenu
                      selectedMenuItem={selectedMenuItem}
                      dispatch={dispatch}
                      menuItemRefs={menuItemRefs}
                    />
                    {renderContent()}
                  </>
                </section>
              </Collapsible>
              <Collapsible header="Estructura">
                <section className="structure-list-container">
                  <StructureList
                    structures={editedItems.filetype.structure}
                    handleAddNewProperty={handleAddNewProperty}
                    dispatch={dispatch}
                    editedItems={editedItems}
                    manageModal={manageModal}
                    setupDragAndDrop={setupDragAndDrop}
                  />
                  <div className="structure-button"></div>
                </section>
              </Collapsible>

              {snackbarVisible && (
                <span className="structure-updated__message">
                  {snackbarMessage}
                </span>
              )}
            </>
          ) : (
            <div>
              <p>Cargando</p>
              <LoadingSpinner />
            </div>
          )}
        </>
      )}

      {isModalOpen && (
        <Suspense fallback={<LoadingSpinner />}>
          <StructureModal
            onClose={() => setModalState(false, null, [], dispatch)}
            structure={selectedStructure}
            path={modalPath}
            dispatch={dispatch}
          />
        </Suspense>
      )}
    </>
  );
};

export default Structure;
