import React, { useContext, useEffect, lazy, useState, Suspense } from "react";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import { fetchView, setSelectedFrame } from "src/modules/actions/viewActions";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ListFrame2 from "src/modules/components/frame/ListFrame/ListFrame2";
import "./View.css";
import MetaWindow from "../../modules/components/common/MetaWindow/MetaWindow";
import LoadingSpinner from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import ShowFrame2 from "src/modules/components/frame/ShowFrame/ShowFrame2";
import PropTypes from "prop-types";

const renderFrame = (frame) => {
  
  switch (frame.type) {
    case "list": {
      return <ListFrame2 key={frame._id} frame={frame} />;
    }
    case "show": {
      return (
        <ShowFrame2
          key={`show_${frame._id}`}
          frame={frame}
        />
      );
    }
    default: {
      return <h1>Frame tipo {frame.type} no soportado</h1>;
    }
  }
};

const DossierDashboard = lazy(() =>
  import(
    "../../modules/components/common/MetaWindow/DossierDashboard/DossierDashboard"
  )
);

const View2 = ({vid}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [metaWindowMenuPosition, setMetaWindowMenuPosition] = useState("Flows");
  const { state, dispatch } = useContext(ViewContext);

  useEffect(() => {
    if (state.view.id !== vid) {
      fetchView(vid, dispatch);
    }
  }, [vid, state.view.id, dispatch]);

  if (!state.view.frames)
    return (
      <div className="view-container__loader">
        <span className="view-spinner-container">
          Cargando frames <LoadingSpinner />
        </span>
      </div>
    );

  const framesCount = state.view.frames.length;

  //Seleccionar frame para metawindow
  const onClick = () => {
    if (!state.selectedFrame) {
      setSelectedFrame(state.data[state.view.frames[0]._id], dispatch);
    }
    setMetaWindowMenuPosition("Flows");
    setIsOpen(!isOpen);
  };

  const onClickError = () => {
    setMetaWindowMenuPosition("Errores");
    setIsOpen(!isOpen);
  };

  if (framesCount === 1) {
    return (
      <div className="container">
        {isOpen ? (
          <PanelGroup direction="horizontal">
            <Panel>{renderFrame(state.view.frames[0])}</Panel>
            <PanelResizeHandle className="panel-resize-handle-horizontal" />
            <Panel className="panel panel-scroll" style={{ width: "300px" }}>
              <span
                className="dashboard-floating-resize-actions-close"
                onClick={() => setIsOpen(false)}
              >
                &rarr;
              </span>
              <Suspense fallback={<div>Loading...</div>}>
                <DossierDashboard
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  scope={state.selectedFrame?.scope || ""}
                  datacode={state.selectedFrame?.datacode || ""}
                  item={state.selectedFrame}
                  menuPosition={metaWindowMenuPosition}
                />
              </Suspense>
            </Panel>
          </PanelGroup>
        ) : (
          <>
            {renderFrame(state.view.frames[0], state.view, )}
            <MetaWindow
              onClick={onClick}
              onClickError={onClickError}
              errors={
                state.selectedFrame?.metadata
                  ? state.selectedFrame.metadata?.nerrors
                  : 0
              }
            />
          </>
        )}
      </div>
    );
  }

  let topFrame = null;
  let remainingFrames = state.view.frames;

  if (framesCount % 2 !== 0) {
    topFrame = state.view.frames[0];
    remainingFrames = state.view.frames.slice(1);
  }

  const halfIndex = Math.ceil(remainingFrames.length / 2);
  const firstHalf = remainingFrames.slice(0, halfIndex);
  const secondHalf = remainingFrames.slice(halfIndex);

  return (
    <div className="container">
      <PanelGroup direction="vertical">
        {topFrame && (
          <>
            <Panel className="panel panel-scroll" style={{ height: "40%" }}>
              {renderFrame(topFrame)}
            </Panel>
            <PanelResizeHandle className="panel-resize-handle-vertical" />
          </>
        )}
        <Panel style={{ flex: 1 }}>
          <PanelGroup direction="horizontal">
            <Panel className="panel panel-scroll">
              <PanelGroup direction="vertical">
                {firstHalf.map((frame, index) => (
                  <React.Fragment key={frame._id}>
                    <Panel className="panel panel-scroll">
                      {renderFrame(frame)}
                    </Panel>
                    {index < firstHalf.length - 1 && (
                      <PanelResizeHandle className="panel-resize-handle-vertical" />
                    )}
                  </React.Fragment>
                ))}
              </PanelGroup>
            </Panel>
            <PanelResizeHandle className="panel-resize-handle-horizontal" />
            <Panel className="panel panel-scroll">
              <PanelGroup direction="vertical">
                {secondHalf.map((frame, index) => (
                  <React.Fragment key={frame._id}>
                    <Panel className="panel panel-scroll">
                      {renderFrame(frame)}
                    </Panel>
                    {index < secondHalf.length - 1 && (
                      <PanelResizeHandle className="panel-resize-handle-vertical" />
                    )}
                  </React.Fragment>
                ))}
              </PanelGroup>
            </Panel>
            {isOpen && (
              <>
                <PanelResizeHandle className="panel-resize-handle-horizontal" />
                <Panel
                  className="panel panel-scroll"
                  style={{ width: "300px" }}
                >
                  <Suspense fallback={<div>Cargando <LoadingSpinner /></div>}>
                    <DossierDashboard
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      scope={state.selectedFrame?.scope || ""}
                      datacode={state.selectedFrame?.datacode || ""}
                      item={state.selectedFrame}
                      menuPosition={metaWindowMenuPosition}
                    />
                  </Suspense>
                </Panel>
              </>
            )}
          </PanelGroup>
        </Panel>
      </PanelGroup>
      {!isOpen && (
        <MetaWindow
          onClick={onClick}
          onClickError={onClickError}
          errors={
            state.selectedFrame?.metadata
              ? state.selectedFrame.metadata?.nerrors
              : 0
          }
        />
      )}
    </div>
  );
};

View2.propTypes = {
  vid: PropTypes.string
};


export default View2;
