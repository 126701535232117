import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import PropTypes from "prop-types"; // Importamos PropTypes para la validación de props
import "./List.css";
import ColumnFilter from "./ColumnFilter";
import Pagination from "./Pagination";
// Common components
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import removeAccents from "src/modules/helpers/removeAccents";
import { EditElement } from "../EditElement";
import dragAndDrop from "src/modules/helpers/dragAndDrop";
import moveElementInArray from "src/modules/helpers/moveElementInArray";
import { useMustache } from "src/modules/hooks/useMustache";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import { editView, fetchListData } from "src/modules/actions/viewActions";
import { ColumnResume } from "./ColumnResume";
import { useLocation, useNavigate } from "react-router";
import LoadingSpinner from "../../common/LoadingSpinner/LoadingSpinner";
import TableRow from "./TableRow";
import TableCell from "./TableCell";

const ListFrame = ({
  frame,
  config = {
    searchbar: true,
    resetFilters: true,
  },
}) => {
  const limit = 10_000;
  const { state, dispatch } = useContext(ViewContext);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [activeFilters, setActiveFilters] = useState({});
  const [displayedData, setDisplayedData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [visibleRows, setVisibleRows] = useState(itemsPerPage);
  const [columnWidths, setColumnWidths] = useState([]);
  const [minColumnWidth, setMinColumnWidths] = useState([]);

  const mustache = useMustache();
  const navigate = useNavigate();
  const tableBodyRef = useRef(null); 
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const lastPartOfUrl = getLastPartOfUrl();
    if (selectedValues.length === 0 && searchText === "") {
      localStorage.removeItem(`data-${lastPartOfUrl}`);
      return;
    }
    const dataToStore = {
      selectedValues,
      lastPartOfUrl,
      searchText,
    };
    localStorage.setItem(`data-${lastPartOfUrl}`, JSON.stringify(dataToStore));
  }, [selectedValues, searchText]);

  useEffect(() => {
    const allParams = {}
    for (const [key, value] of queryParams.entries()) {
      allParams[key] = value;
    }
    fetchListData(frame, limit, dispatch, allParams);
    const lastPartOfUrl = getLastPartOfUrl();
    const storedData = localStorage.getItem(`data-${lastPartOfUrl}`);
    if (!storedData) {
      return;
    }
    const parsedData = JSON.parse(storedData);
    setSelectedValues(parsedData.selectedValues);
    if (parsedData.searchText) setSearchText(parsedData.searchText);

    const resultado = {};
    parsedData.selectedValues.forEach((elemento) => {
      resultado[elemento.name] = elemento.checks;
    });

    setActiveFilters(resultado);
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const getLastPartOfUrl = () => {
    const urlParts = window.location.pathname.split("/");
    return urlParts[urlParts.length - 1];
  };

  const setupDragAndDrop = async () => {
    try {
      const pos = await dragAndDrop();
      const result = moveElementInArray(
        frame.elements,
        Number(pos.drag.index),
        Number(pos.drop.index)
      );
      const updatedFrame = { ...frame, elements: result };
      const frameIndex = state.view.frames
        .map((el) => el._id)
        .indexOf(frame._id);
      const newView = { ...state.view };
      newView.frames[frameIndex] = updatedFrame;
      editView(newView, dispatch);
    } catch (error) {
      console.log(error);
    }
  };

  const setView = async (view) => {
    fetchListData(frame, limit, dispatch);
  };

  const clickButton = (url) => {
    navigate(url);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // const handleEdit = (rowIndex, colAccessor, newValue) => {
  //   if (!newValue) newValue = null;
  //   const updatedData = [...state.data[frame._id]];
  //   updatedData[rowIndex][colAccessor] = newValue;

  //   //Update the state.data with the new value
  //   //state.data[frame._id] = updatedData;
  // };

  useEffect(() => {
    sortedData();
  }, [sortOrder, sortColumn, searchText, state.data, activeFilters]);

  const handleResize = (index, newWidth) => {
    const result = columnWidths.map((width, i) =>
      i === index ? newWidth : width
    );
    setColumnWidths(result);
  };

  const sortedData = () => {
    const frameData = state.data[frame._id];
    if (!frameData) return;

    let dataToShow = JSON.parse(JSON.stringify(frameData));

    if (sortColumn) {
      dataToShow = sortData(dataToShow, sortColumn, sortOrder);
    }

    dataToShow = applyFilters(dataToShow, activeFilters, frame.elements);

    if (searchText.trim() !== "") {
      dataToShow = applySearch(dataToShow, searchText, frame.elements);
    }

    setDisplayedData(dataToShow || []);
  };

  const sortData = (data, column, order) => {
    return data.sort((a, b) => {
      const valueA = mustache.replaceMustache(column, a, queryParams);
      const valueB = mustache.replaceMustache(column, b, queryParams);

      const valueANumber = Number(valueA);
      const valueBNumber = Number(valueB);

      if (!isNaN(valueANumber) && !isNaN(valueBNumber)) {
        return order === "asc"
          ? valueANumber - valueBNumber
          : valueBNumber - valueANumber;
      }

      const normalizedValueA = removeAccents(String(valueA));
      const normalizedValueB = removeAccents(String(valueB));
      return order === "asc"
        ? normalizedValueA.localeCompare(normalizedValueB)
        : normalizedValueB.localeCompare(normalizedValueA);
    });
  };

  const applyFilters = (data, filters, elements) => {
    return Object.keys(filters).reduce((filteredData, accessor) => {
      if (filters[accessor].length > 0) {
        return filteredData.filter((item) => {
          const cellValue = mustache.replaceMustache(accessor, item, queryParams);
          const isDate = elements.some(
            (element) =>
              element.variable === accessor && element.type?.includes("date")
          );

          if (isDate) {
            return filters[accessor].some((filter) => {
              const filterString = filter.toString();
              const date = new Date(cellValue);
              const monthMatch = date
                .toLocaleString("es-ES", { month: "long" })
                .includes(filterString.toLowerCase());
              const yearMatch = date
                .getFullYear()
                .toString()
                .includes(filterString);

              return monthMatch || yearMatch;
            });
          }

          return filters[accessor].map(String).includes(String(cellValue));
        });
      }
      return filteredData;
    }, data);
  };

  const [searchCounter, setSearchCounter] = useState(0);

  const applySearch = (data, searchText, elements) => {
    const normalizedSearchText = removeAccents(searchText.trim()).toLowerCase();
    const counter = JSON.parse(JSON.stringify(searchCounter));
    const filteredData = [];
    for (const item of data) {
      if (counter !== searchCounter) {
        break;
      }
      const match = elements.some(({ type, variable }) => {
        if (type === "button") return false;

        const cellValue = mustache.replaceMustache(variable, item, queryParams);
        if (!cellValue) return false;

        const normalizedCellValue = removeAccents(
          cellValue.trim()
        ).toLowerCase();
        return normalizedCellValue.includes(normalizedSearchText);
      });
      if (match) filteredData.push(item);
    }
    return filteredData;
  };

  const handleScroll = () => {
    const scrollTop = tableBodyRef.current.scrollTop;
    const scrollHeight = tableBodyRef.current.scrollHeight;
    const clientHeight = tableBodyRef.current.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setVisibleRows((prev) => Math.min(prev + itemsPerPage, displayedData.length));
    }
  };

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => {
        tableBody.removeEventListener("scroll", handleScroll);
      };
    }
  }, [displayedData]);

  const setMinWidth = useCallback((index, value) => {
    setMinColumnWidths((prevMinWidths) => {
      // Si el array está vacío, lo inicializamos con 0s
      let cloneMinColumnWidth = prevMinWidths.length === 0
        ? new Array(frame.elements.filter(el => el.type!== 'button').length).fill(0)
        : [...prevMinWidths];
  
      // Actualizamos el valor en el índice específico
      cloneMinColumnWidth[index] = value;
  
      // Retornamos el nuevo array para actualizar el estado
      return cloneMinColumnWidth;
    });
  }, [frame.elements]);
  

  if (frame.elements.length > 0 && columnWidths.length === 0) {
    const totalWidth = window.innerWidth - 32; // Ancho disponible (puedes ajustarlo según el contenedor)
    const numColumns = frame.elements.filter(el => el.type !== 'button').length;
    const initialWidths = Array(numColumns).fill(totalWidth / numColumns);
    const minWidths = Array(numColumns).fill(0);
    setColumnWidths(initialWidths);
    setMinColumnWidths(minWidths)
  }

  const renderSortArrow = (column) => {
    if (column === sortColumn) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return null;
  };

  if (!state.data[frame._id]) {
    return (
      <div className="lf-no-data">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="list">
      <div className="top-line">
        {frame.elements
          .filter((el) => el.type === "button")
          .map((element, index) => (
            <div
              key={`button-${index}`}
              className="row-element"
              style={{ width: "unset" }}>
              <EditElement
                view={state.view}
                setView={setView}
                element={element}
                frame={frame}
                config={{ duplicateElement: true }}
              />
              <Button
                key={`button ${index}`}
                onClick={() => clickButton(mustache.replaceMustache(element.link, state.data[frame._id], queryParams))}
                icon={element.icon}
                content={element.label}
              />
            </div>
          ))}
        {config.searchbar && (
          <Input
            onChange={(e) => {
              setSearchCounter((counter) => counter++);
              handleSearchChange(e);
            }}
            value={searchText}
            placeholder="Buscar..."
            name="search"
            label=""
            type="search"
            activeLabel={true}
          />
        )}
        {config.resetFilters && (
          <Button
            icon="Filter Alt Off Icon"
            onClick={() => {
              setActiveFilters({});
              setSelectedValues([]);
              setSearchText("");
              const urlParts = window.location.pathname.split("/");
              const lastPartOfUrl = urlParts[urlParts.length - 1];
              localStorage.removeItem(`data-${lastPartOfUrl}`);
            }}
          />
        )}
        <Pagination
          currentPage={currentPage}
          totalItems={state.data[frame._id].length}
          itemsPerPage={config.itemsPerPage || displayedData.length}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
      <div className="lf-table-container">

      <span className="lf-table">
        <TableRow isHeader>
          {frame.elements
            .filter((el) => el.type !== "button")
            .map((element, index) => {
              let optionsObject;
              if (element.options && typeof element.options === "string") {
                try {
                  optionsObject = JSON.parse(element.options);
                } catch (error) {
                  console.error("Error al parsear options:", error);
                  optionsObject = null; // O manejar el error de otra manera
                }
              } else {
                optionsObject = element.options; // Ya es un objeto
              }
              return (
                <TableCell
                  onDragStart={() => setupDragAndDrop()}
                  dragdropObject="column"
                  dragdropIndex={frame.elements
                    .map((e) => e._id)
                    .indexOf(element._id)}
                  key={`Header-${index}`}
                  width={columnWidths[index]}
                  minWidth={minColumnWidth[index]}
                  setColumnWidths={setMinWidth}
                  onResize={(newWidth) => handleResize(index, newWidth)}
                  rowIndex={index}
                  isHeader
                  draggable={true}
                  >
                  <EditElement
                    view={state.view}
                    setView={setView}
                    element={element}
                    frame={frame}
                    align={
                      index >
                      frame.elements.filter((el) => el.type !== "button")
                        .length /
                        2
                        ? "right"
                        : "left"
                    }
                    config={{ duplicateElement: true }}
                  />
                  <div
                    className="cell-div-column-text"
                    onClick={() => handleSort(element.variable)}>
                    {element.label} {renderSortArrow(element.variable)}
                  </div>
                  {optionsObject?.filter && (
                    <ColumnFilter
                      column={{ ...element, options: optionsObject }}
                      data={displayedData}
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      idFilter={`filter-${element.variable}`}
                    />
                  )}
                  {optionsObject?.resume && (
                    <div className="column-resume-group-container">
                      <div className="column-resume-group">
                        <div className="column-resume-group-numbers">
                          <div className="column-resume">
                            <ColumnResume
                              resumeType={optionsObject.resume}
                              data={displayedData.map((element) =>
                                mustache.replaceMustache(
                                  element.variable,
                                  element, 
                                  queryParams
                                )
                              )}
                            />
                          </div>
                          {optionsObject?.resume_suffix && (
                            <div className="column-resume-suffix">
                              {optionsObject.resume_suffix}
                            </div>
                          )}
                        </div>
                        {optionsObject?.resume && (
                          <div className="column-resume-indicator">
                            {`(${optionsObject.resume})`}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </TableCell>
              );
            })}
        </TableRow>
        <div
          className="lf-body"
          ref={tableBodyRef}>
          {displayedData.slice(0, visibleRows).map((rowData, rowIndex) => (
            <TableRow key={`data-${rowIndex}`}>
              {frame.elements
                .filter((el) => el.type !== "button")
                .map((column, cellIndex) => (
                  <TableCell
                    rowIndex={rowIndex}
                    rowData={rowData}
                    minWidth={minColumnWidth[cellIndex]}
                    column={column}
                    key={`cell-${rowIndex}-${cellIndex}`}
                    width={columnWidths[cellIndex]}
                  />
                ))}
            </TableRow>
          ))}
        </div>
      </span>
      </div>
    </div>
  );
};

ListFrame.propTypes = {
  frame: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        type: PropTypes.string,
        variable: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      })
    ).isRequired,
  }).isRequired,
  config: PropTypes.shape({
    searchbar: PropTypes.bool,
    resetFilters: PropTypes.bool,
    itemsPerPage: PropTypes.number,
  }),
};

export default ListFrame;
