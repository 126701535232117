import React from 'react';
import PropTypes from 'prop-types';

function Iframe({
  src,
  title,
  width,
  height,
  frameBorder,
  allowFullScreen,
  className,
  ...props
}) {
  return (
    <iframe
      src={src}
      title={title}
      width={width}
      height={height}
      frameBorder={frameBorder}
      allowFullScreen={allowFullScreen}
      className={className}
      style={{ border: frameBorder }}
      {...props}
    />
  );
}

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  frameBorder: PropTypes.string,
  allowFullScreen: PropTypes.bool,
  className: PropTypes.string,
};

export default Iframe;
