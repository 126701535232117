// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-floating-resize {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 5px;
  height: 93vh;
  background: var(--font-color);
  color: var(--detail-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px var(--bg-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dashboard-floating-resize-actions-open {
  position: absolute;
  right: 50%;
  background: var(--font-color);
  color: var(--detail-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  top: 40%;
  font-weight: bold; 
}

.dashboard-floating-resize-actions-errors {
  position: absolute;
  right: -500%;
  top: 25%;
  background: red;
  color: var(--font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transform: rotate(90deg);
  transform-origin: center;
  font-weight: bold; 
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/common/MetaWindow/MetaWindow.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,6BAA6B;EAC7B,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,qCAAqC;EACrC,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,6BAA6B;EAC7B,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,8BAA8B;EAC9B,QAAQ;EACR,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;EACR,eAAe;EACf,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,+BAA+B;EAC/B,wBAAwB;EACxB,wBAAwB;EACxB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".dashboard-floating-resize {\r\n  position: fixed;\r\n  bottom: 0;\r\n  right: 0;\r\n  width: 5px;\r\n  height: 93vh;\r\n  background: var(--font-color);\r\n  color: var(--detail-color);\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  box-shadow: 0 4px 8px var(--bg-color);\r\n  cursor: pointer;\r\n  transition: all 0.3s ease;\r\n}\r\n\r\n.dashboard-floating-resize-actions-open {\r\n  position: absolute;\r\n  right: 50%;\r\n  background: var(--font-color);\r\n  color: var(--detail-color);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 20px;\r\n  height: 50px;\r\n  border-top-left-radius: 5px;\r\n  border-bottom-left-radius: 5px;\r\n  top: 40%;\r\n  font-weight: bold; \r\n}\r\n\r\n.dashboard-floating-resize-actions-errors {\r\n  position: absolute;\r\n  right: -500%;\r\n  top: 25%;\r\n  background: red;\r\n  color: var(--font-color);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 80px;\r\n  height: 20px;\r\n  border-bottom-left-radius: 5px;\r\n  border-bottom-right-radius: 5px;\r\n  transform: rotate(90deg);\r\n  transform-origin: center;\r\n  font-weight: bold; \r\n  white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
