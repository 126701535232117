// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragfile-box {
  border: 2px dashed #ccc;
  padding: 20px;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/DragFileBox/DragFileBox.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".dragfile-box {\r\n  border: 2px dashed #ccc;\r\n  padding: 20px;\r\n  min-height: 100px;\r\n  align-items: center;\r\n  justify-content: center;\r\n  display: flex;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
