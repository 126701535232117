// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.structure-list {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  list-style: none;
  width: 90vw;
}

.full-width ul {
  width: 90%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

ul, li {
  text-align: left;
  vertical-align: middle;
}

.full-width {
  width: 100%;
  margin: 1rem 0;
}

.structure-nested {
  padding: 0;
  border: none;
  list-style: none;
  padding-left: 2rem;
  padding-top: 1rem ;
}

.show-lines {
  padding: 0;
  border: none;
  position: relative;
}

.structure-nested, .structure-nested ul, .structure-nested li {
  position: relative;
}

.structure-nested li::before, .structure-nested li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.structure-nested li::before {
  border-top: 2px solid var(--font-color) ;
  top: 2.25rem;
  width: 1.4rem;
  height: 0;
}

.structure-nested li::after {
  border-left: 2px solid var(--font-color) ;
  height: 100%;
  width: 0px;
  top: -1rem;
}

.structure-nested > li:last-child::after {
  height: 3.3rem;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Structure/StructureList/StructureList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,wCAAwC;EACxC,YAAY;EACZ,aAAa;EACb,SAAS;AACX;;AAEA;EACE,yCAAyC;EACzC,YAAY;EACZ,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".structure-list {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding-left: 3rem;\r\n  list-style: none;\r\n  width: 90vw;\r\n}\r\n\r\n.full-width ul {\r\n  width: 90%;\r\n  margin: 0 auto;\r\n  padding: 0;\r\n  list-style: none;\r\n}\r\n\r\nul, li {\r\n  text-align: left;\r\n  vertical-align: middle;\r\n}\r\n\r\n.full-width {\r\n  width: 100%;\r\n  margin: 1rem 0;\r\n}\r\n\r\n.structure-nested {\r\n  padding: 0;\r\n  border: none;\r\n  list-style: none;\r\n  padding-left: 2rem;\r\n  padding-top: 1rem ;\r\n}\r\n\r\n.show-lines {\r\n  padding: 0;\r\n  border: none;\r\n  position: relative;\r\n}\r\n\r\n.structure-nested, .structure-nested ul, .structure-nested li {\r\n  position: relative;\r\n}\r\n\r\n.structure-nested li::before, .structure-nested li::after {\r\n  content: \"\";\r\n  position: absolute;\r\n  left: -12px;\r\n}\r\n\r\n.structure-nested li::before {\r\n  border-top: 2px solid var(--font-color) ;\r\n  top: 2.25rem;\r\n  width: 1.4rem;\r\n  height: 0;\r\n}\r\n\r\n.structure-nested li::after {\r\n  border-left: 2px solid var(--font-color) ;\r\n  height: 100%;\r\n  width: 0px;\r\n  top: -1rem;\r\n}\r\n\r\n.structure-nested > li:last-child::after {\r\n  height: 3.3rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
