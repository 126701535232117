// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  color: var(--font-color);
  font-size: 28px;
  font-family: 'Ping LCG Bold';
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Value/Value.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;EACf,4BAA4B;EAC5B,SAAS;AACX","sourcesContent":[".title {\r\n  color: var(--font-color);\r\n  font-size: 28px;\r\n  font-family: 'Ping LCG Bold';\r\n  margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
