import React from "react";
import PropTypes from "prop-types";
import "./DoubleContainer.css";

/**
 * A container component that displays two child components side by side.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be displayed.
 * @returns {JSX.Element} The rendered DoubleContainer component.
 */
const DoubleContainer = ({ children }) => {
  const [left, right] = React.Children.toArray(children).slice(0, 2);

  return (
    <div className="double-container">
      <div className="double-container-left">{left}</div>
      <div className="double-container-right">{right}</div>
    </div>
  );
};

DoubleContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DoubleContainer;
