import React, { useContext, useEffect } /**  ,{ useState }*/ from 'react';
import StatusBubbles from '../StatusBubbles/StatusBubbles';
import JornadaInfo from '../JornadaInfo/JornadaInfo';
import './TimeContainer.css';
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider';
import { setCurrentTime } from 'src/modules/actions/userActivityActions';

function TimeContainer() {
  const { state, dispatch } = useContext(UserActivityContext);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dispatch)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [dispatch])


  return (
    <div className="user-activity-dashboard__time-container">
      <div className="time-container__time">{state.time}</div>
      <StatusBubbles />
      <div className="time-container__status-text">Tu jornada ahora</div>
      <JornadaInfo />
    </div>
  );
}

export default TimeContainer;
