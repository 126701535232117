// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-column {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.445);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputs-edit-column {
  display: flex;
  flex-direction: column;
  align-items: end;
  background-color: black;
  border-radius: 8px;
  padding: 1vw;
  gap:1vh;
}

.inputs-edit-column-buttons {
  background-color: rgb(156, 156, 156);
  border-radius: 4px;
  border: none;
  margin: 0.2vw;
  color: white;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0);
  width: auto;
  font-size: 1.7vw;
  padding: 0.3vw 0.6vw 0.3vw 0.6vw;

}
.inputs-edit-column-buttons:hover {
  background-color: rgb(44, 44, 44);

  border: 1px solid white;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/KanbanMenu/components/EditColumn.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sCAAsC;EACtC,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,OAAO;AACT;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,eAAe;EACf,wCAAwC;EACxC,WAAW;EACX,gBAAgB;EAChB,gCAAgC;;AAElC;AACA;EACE,iCAAiC;;EAEjC,uBAAuB;AACzB","sourcesContent":[".edit-column {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: absolute;\r\n  background-color: rgba(0, 0, 0, 0.445);\r\n  z-index: 1;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.inputs-edit-column {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: end;\r\n  background-color: black;\r\n  border-radius: 8px;\r\n  padding: 1vw;\r\n  gap:1vh;\r\n}\r\n\r\n.inputs-edit-column-buttons {\r\n  background-color: rgb(156, 156, 156);\r\n  border-radius: 4px;\r\n  border: none;\r\n  margin: 0.2vw;\r\n  color: white;\r\n  cursor: pointer;\r\n  border: 1px solid rgba(255, 255, 255, 0);\r\n  width: auto;\r\n  font-size: 1.7vw;\r\n  padding: 0.3vw 0.6vw 0.3vw 0.6vw;\r\n\r\n}\r\n.inputs-edit-column-buttons:hover {\r\n  background-color: rgb(44, 44, 44);\r\n\r\n  border: 1px solid white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
