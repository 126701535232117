import React from "react";
import ColorPicker from "src/modules/components/common/ColorPicker/ColorPicker";
import Input from "src/modules/components/common/Input/Input";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Aspecto = () => {
  const {
    setupData: setupInfo,
    setSetupData: setSetupInfo,
    setPublicSetup,
  } = useAppContext();

  const handleColorChangeContext = (color, name) => {
    setPublicSetup((prevValues) => ({
      ...prevValues,
      app:{
        ...prevValues.app,
        [name]: color,
      }
    }));
  };
  const handleInputChangeApp = (event) => {
    const { name, value } = event.target;
    setSetupInfo((prevValues) => ({
      ...prevValues,
      app: {
        ...prevValues.app,
        [name]: value,
      },
    }));
  };
  return (
    <div className="tab-container">
      <ColorPicker
        name="primarycolor"
        type="color"
        value={setupInfo.app?.primarycolor}
        onChange={(value) => {
          setSetupInfo({
            ...setupInfo,
            app: {
              ...setupInfo.app,
              primarycolor: value,
            },
          });
          handleColorChangeContext(value, "primarycolor");
        }}
        label="Color primario:"
        activeLabel={true}
        variable="--primary-color"
      />
      <ColorPicker
        name="backcolor"
        type="color"
        value={setupInfo.app?.backcolor}
        onChange={(value) => {
          setSetupInfo({
            ...setupInfo,
            app: {
              ...setupInfo.app,
              backcolor: value,
            },
          });
          handleColorChangeContext(value, "backcolor");
        }}
        label="Color de fondo:"
        activeLabel={true}
        variable="--bg-color"
      />
      <ColorPicker
        name="detailcolor"
        type="color"
        value={setupInfo.app?.detailcolor}
        onChange={(value) => {
          setSetupInfo({
            ...setupInfo,
            app: {
              ...setupInfo.app,
              detailcolor: value,
            },
          });
          handleColorChangeContext(value, "detailcolor");
        }}
        label="Color de detalles:"
        activeLabel={true}
        variable="--detail-color"
      />
      <ColorPicker
        name="fontcolor"
        type="text"
        value={setupInfo.app?.fontcolor}
        onChange={(value) => {
          setSetupInfo({
            ...setupInfo,
            app: {
              ...setupInfo.app,
              fontcolor: value,
            },
          });
          handleColorChangeContext(value, "fontcolor");
        }}
        label="Color de la letra:"
        activeLabel={true}
      />
      <Input
        name="fonttype"
        type="text"
        placeholder="Seleccionable con distintos tipos"
        value={setupInfo.app?.fonttype}
        onChange={handleInputChangeApp}
        label="Fuente de letra:"
        activeLabel={true}
        regex="[0-9]"
        tooltip={true}
      />
    </div>
  );
};

export default Aspecto;
