// Componente tipo Frame diseñado para rendear vistas de manera agnostica como screens de ValerIA
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// 2. Define valores por defecto para las props en caso de que no se pasen
const Page = ({ url }) => {
  const [html, setHtml] = useState("Descargando ... " + url);

  useEffect(() => {
    const fetchHtml = async (url) => {
      return await fetch(url);
    };
    fetchHtml(url)
      .then((response) => {
        setHtml(response.text());
      })
      .catch((error) => {
        setHtml("Error en frame 'page' con url: " + url + " -> " + error);
      });
  }, [url]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

// 1.Comprueba que los tipos de las props sean correctos
Page.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Page;
