import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import {
  User as UserAPI,
  Setup as SetupAPI,
} from "src/lib/api.js";
import DoubleContainer from "src/modules/components/Containers/DoubleContainer/DoubleContainer";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Select, {
} from "src/modules/components/common/Select/Select";
import "./Users.css";
import DevicesTable from "./DevicesTable/DevicesTables";
import Policies from "src/modules/components/common/Policies/Policies";

const initialInputValues = {
  name: "",
  email: "",
  password: "",
  policies: [],
  role: "",
  active: "",
  devicecheck: "",
  devices: [],
};

const User = () => {
  const { setupData, setSetupData } = useAppContext();
  const navigate = useNavigate()
  const location = useLocation();
  const [roles, setRoles] = useState([]);
  const [inputValues, setInputValues] = useState(initialInputValues);
  const [queryParameterExists, setQueryParameterExists] = useState(false);

  const fetchSetupData = useCallback(async () => {
    const newSetup = await SetupAPI.read();
    setSetupData(newSetup);
  }, [setSetupData]);


  const fetchRoles = useCallback(() => {
    const newRoles = setupData.roles?.map((rol) => ({
      label: rol.name,
      value: rol.name,
      policies: rol.policies, // Almacenar las políticas del rol
    }));
    setRoles(newRoles);
  }, [setupData.roles]);

  const readUser = useCallback(async (email) => {
    const user = await UserAPI.read(email);
    setInputValues((prevState) => ({
      ...prevState,
      name: user.name,
      email: user.email,
      policies: user.policies,
      role: user.role ?? "",
      active: user.active ?? "",
      devicecheck: user.devicecheck ?? "",
      devices: user.devices ?? [],
    }));
  }, []);


  useEffect(() => {
    fetchSetupData();
  }, [fetchSetupData]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    if (email) {
      readUser(email);
      setQueryParameterExists(true);
    }
  }, [location.search, readUser]);

  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateInputFilled = () => {
    const requiredFields = ["name", "email", "policies", "role"];
    for (let field of requiredFields) {
      if (!inputValues[field]) {
        alert(`Falta ${field}`);
        return false;
      }
    }
    return true;
  };

  const handleCreateUser = () => {
    if (!validateInputFilled()) return;
    if (!inputValues.password) {
      alert("Falta la contraseña");
      return;
    }
    UserAPI.create(inputValues);
  };

  const handleUpdateUser = () => {
    const data = { ...inputValues };
    if (!inputValues.password) delete data.password;
    UserAPI.update(data);
  };

  const handleDeleteUser = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar al usuario: ${inputValues.email}?`
    );
    if (confirmation) {
      UserAPI.delete(inputValues.email);
    }
  };

  const redirectToUsers = () => {
    navigate("/view/users");
  };

  const editDevice = (id, permited) => {
    const updatedDevices = inputValues.devices.map((device) =>
      device.id === id ? { ...device, permited } : device
    );
    setInputValues((prevState) => ({ ...prevState, devices: updatedDevices }));
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.value;
    const rolePolicies =
      roles.find((role) => role.value === selectedRole)?.policies || [];
    setInputValues((prevState) => ({
      ...prevState,
      role: selectedRole,
      policies: rolePolicies,
    }));
  };

  const setPolicies = (event) => {
    setInputValues((prev) => {
      return {
        ...prev,
        policies: event
      }
    })
  }
  return (
    <DoubleContainer>
      <FormContainer>
        <div className="css-175oi2c" style={{ alignItems: "center" }}>
          <Button
            id="usuarios"
            onClick={redirectToUsers}
            icon="arrow_back"
          />
          {!queryParameterExists && (
            <Button
              id="createUser"
              onClick={handleCreateUser}
              content="Crear Usuario"
              buttonStyle={{ maxWidth: "200px" }}
            />
          )}
          {queryParameterExists && (
            <>
              <Button
                id="updateUser"
                onClick={handleUpdateUser}
                icon="save"
              />
              <Button
                id="deleteUser"
                onClick={handleDeleteUser}
                icon="delete"
              />
            </>
          )}
        </div>
        <Input
          name="name"
          type="text"
          placeholder="Nombre de usuario"
          value={inputValues.name}
          onChange={handleInputChange}
          label="Nombre de usuario"
          activeLabel
          autocomplete="off"
        />
        <Input
          name="email"
          type="text"
          placeholder="Email"
          value={inputValues.email}
          onChange={handleInputChange}
          label="Correo Electrónico"
          activeLabel
          autocomplete="off"
        />
        <Input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={inputValues.password}
          onChange={handleInputChange}
          label="Contraseña"
          activeLabel
          autocomplete="new-password"
        />
        <Select
          isSearchable
          isClearable={false}
          name="role"
          options={roles}
          placeholder="Roles..."
          onChange={handleRoleChange}
          value={roles?.find((role) => role.value === inputValues.role)}
          label="Rol"
          activeLabel
        />
        <Input
          name="active"
          type="checkbox"
          checked={inputValues.active}
          onChange={handleInputChange}
          label="Active"
          activeLabel
        />
        <Input
          name="devicecheck"
          type="checkbox"
          checked={inputValues.devicecheck}
          onChange={handleInputChange}
          label="Validación de dispositivos"
          activeLabel
        />
        <Policies policies={inputValues.policies} setPolicies={(event) => setPolicies(event)} />
      </FormContainer>
      <FormContainer
        style={{
          minHeight: "500px",
          justifyContent: "normal",
          paddingTop: "28px",
        }}
      >
        <DevicesTable
          inputValues={inputValues.devices}
          editDevice={editDevice}
        />
      </FormContainer>
    </DoubleContainer>
  );
};



export default User;
