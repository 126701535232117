import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Input from "src/modules/components/common/Input/Input";

const StructureMenuMovements = ({ editedItems, dispatch }) => {
  const handleCheckBoxChange = (key, checked) => {
    dispatch({
      type: "UPDATE_HEADER",
      payload: {
        ...editedItems.filetype.header,
        [key]: checked,
      },
    });
  };

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Movimientos</h3>
      <p>Indica si el filetype se relaciona con los movimientos.</p>
      <div className="structure-header__value">
        <span className="structure-header-key">Activado:</span>
        <Input
          type="checkbox"
          value={editedItems.filetype.header["movements"] || false}
          onChange={(e) => handleCheckBoxChange("movements", e.target.checked)}
          autoFocus
        />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuMovements.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        movements: PropTypes.bool,
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default StructureMenuMovements;
