import React, { useEffect, useState } from "react";
import "./update.css";
import Button from "src/modules/components/common/Button/Button";
import packageInfo from "../../../package.json";
import {
  Info as InfoAPI,
  Update as UpdateAPI,
  Setup as SetupAPI,
} from "src/lib/api";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Update = () => {
  const { setupData, setSetupData } = useAppContext();
  const [vFront, setVFront] = useState("");
  const [vApi, setVApi] = useState("");
  const [, setIsLoadingUpdateFront] = useState(false);
  const [, setIsLoadingUpdateApi] = useState(false);
  const [, setIsLoadingUpdateConductor] =
    useState(false);
  const [, setIsLoadingUpdateFileSync] = useState(false);
  const [, setIsLoadingUpdateDataBase] = useState(false);
  const [timerFront, setTimerFront] = useState(null);
  const [timerApi, setTimerApi] = useState(null);
  const [timerConductor, setTimerConductor] = useState(null);
  const [timerFileSync, setTimerFileSync] = useState(null);
  const [timerDataBase, setTimerDataBase] = useState(null);

  useEffect(() => {
    fetchData();
    if (JSON.stringify(setupData) === JSON.stringify({})) {
      SetupAPI.read().then((res) => {
        setSetupData(res);
      });
    }
  }, []);

  const handleTimer = (setTimerFunc) => {
    let timeLeft = 180; // Tiempo inicial en segundos
    setTimerFunc(timeLeft);
    const timerId = setInterval(() => {
      timeLeft -= 1;
      setTimerFunc(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(timerId);
        window.location.reload();
      }
    }, 1000);
  };

  const updateFront = async () => {
    setIsLoadingUpdateFront(true);
    try {
      await UpdateAPI.updateFront();
      // Suponiendo que la actualización fue exitosa si no se lanzan excepciones
      handleTimer(setTimerFront); // Iniciar temporizador
    } catch (error) {
      // El manejo de errores ya está encapsulado dentro de UpdateAPI.updateFront()
    } finally {
      setIsLoadingUpdateFront(false);
    }
  };

  const updateApi = async () => {
    setIsLoadingUpdateApi(true);
    try {
      await UpdateAPI.updateApi();
      // Suponiendo que la actualización fue exitosa si no se lanzan excepciones
      handleTimer(setTimerApi); // Iniciar temporizador
    } catch (error) {
      // El manejo de errores ya está encapsulado dentro de UpdateAPI.updateApi()
    } finally {
      setIsLoadingUpdateApi(false);
    }
  };

  const updateConductor = async () => {
    setIsLoadingUpdateConductor(true);
    try {
      await UpdateAPI.updateConductor();
      // Suponiendo que la actualización fue exitosa si no se lanzan excepciones
      handleTimer(setTimerConductor); // Iniciar temporizador
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingUpdateConductor(false);
    }
  };
  const updateFileSync = async () => {
    setIsLoadingUpdateFileSync(true);
    try {
      await UpdateAPI.updateFileSync();
      // Suponiendo que la actualización fue exitosa si no se lanzan excepciones
      handleTimer(setTimerFileSync); // Iniciar temporizador
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingUpdateFileSync(false);
    }
  };
  const updateDataBase = async () => {
    setIsLoadingUpdateDataBase(true);
    try {
      await UpdateAPI.updateDataBase();
      // Suponiendo que la actualización fue exitosa si no se lanzan excepciones
      handleTimer(setTimerDataBase); // Iniciar temporizador
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingUpdateDataBase(false);
    }
  };

  const fetchData = async () => {
    const data = await InfoAPI.basics(true);

    setVFront(packageInfo.version);
    setVApi(data.apiversion);
  };

  const timerFrontHtml = (
    <div className="html-btn-update">
      <span>Actualizando Frontend</span>
      <span className="timer-update">
        {timerFront ? `Quedan ${timerFront} segundos...` : ""}
      </span>
    </div>
  );

  const timerApiHtml = (
    <div className="html-btn-update">
      <span>Actualizando Backend</span>
      <span className="timer-update">
        {timerApi ? `Quedan ${timerApi} segundos...` : ""}
      </span>
    </div>
  );
  const timerConductorHtml = (
    <div className="html-btn-update">
      <span>Actualizando Conductor</span>
      <span className="timer-update">
        {timerConductor ? `Quedan ${timerConductor} segundos...` : ""}
      </span>
    </div>
  );
  const timerFileSyncHtml = (
    <div className="html-btn-update">
      <span>Actualizando FileSync</span>
      <span className="timer-update">
        {timerConductor ? `Quedan ${timerFileSync} segundos...` : ""}
      </span>
    </div>
  );
  const timerDataBaseHtml = (
    <div className="html-btn-update">
      <span>Actualizando Base de datis</span>
      <span className="timer-update">
        {timerConductor ? `Quedan ${timerDataBase} segundos...` : ""}
      </span>
    </div>
  );
  return (
    <main className="update-page">
      <h1>Actualizar versiones</h1>
      <section className="update-container">
        <Button
          onClick={updateFront}
          content={timerFront ? timerFrontHtml : "Actualizar Frontend"}
          className={"navbutton-save"}
        />
        <span className="span-version-info">Versión actual: {vFront}</span>
        <Button
          onClick={updateApi}
          content={timerApi ? timerApiHtml : "Actualizar Backend"}
          className={"navbutton-save"}
        />
        <span className="span-version-info">Versión actual: {vApi}</span>
        {setupData.api?.portainerconductorupdate && (
          <>
            <Button
              onClick={updateConductor}
              content={
                timerConductor ? timerConductorHtml : "Actualizar Conductor"
              }
              className={"navbutton-save"}
            />
            <span className="span-version-info">Versión actual: X</span>
          </>
        )}
        {setupData.api?.portainerfilesyncupdate && (
          <>
            <Button
              onClick={updateFileSync}
              content={
                timerFileSync ? timerFileSyncHtml : "Actualizar FileSync"
              }
              className={"navbutton-save"}
            />
            <span className="span-version-info">Versión actual: X</span>
          </>
        )}
        {setupData.api?.portainerdatabaseupdate && (
          <>
            <Button
              onClick={updateDataBase}
              content={
                timerFileSync ? timerDataBaseHtml : "Actualizar Base de datos"
              }
              className={"navbutton-save"}
            />
            <span className="span-version-info">Versión actual: X</span>
          </>
        )}
      </section>
    </main>
  );
};

export default Update;
