import React, { useState } from "react";
import PropTypes from "prop-types"; // Importar PropTypes para la validación
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import Tooltip from '@mui/material/Tooltip';

const Button = ({
  content,
  icon,
  tooltip,
  disabled = false,
  onClick,
  style,
  className,
  type = "button",
  ...props
}) => {
  const { publicSetup } = useAppContext();
  const newicon = icon?.toLowerCase().replace(/ /g, "_");
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const myStyle = {
    backgroundColor: className?.includes("--active")
      ? "rgba(255, 255, 255, 0.3)"
      : disabled
      ? "rgba(255, 255, 255, 0.05)"
      : isHover
      ? "rgba(15, 15, 15, 0.98)"
      : "rgba(62, 59, 68, 0.7)",

    minHeight: props.buttonStyle?.minHeight || "40px",
    minWidth: props.buttonStyle?.minWidth ? props.buttonStyle?.minWidth : content ? "144px" : "40px",
    maxWidth: props.buttonStyle?.maxWidth ? props.buttonStyle?.maxWidth: content ? "100%" : "40px",
    maxHeight: props.buttonStyle?.maxHeight ? props.buttonStyle?.maxHeight :  content ? "100%" : "40px",
    color: "white",
    fontSize: "1rem",
    fontWeight: "bold",
    fontFamily: "Ping LCG Thin",
    cursor: disabled ? "not-allowed" : "pointer",
    border: isHover
      ? `2px solid ${publicSetup.detailcolor}`
      : "2px solid transparent",
    borderRadius: "4px",
    ...style,
  };

  const buttonContent = (
    <button
      disabled={disabled}
      onClick={onClick}
      style={myStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
      name={props.name}
      type={props.type}
    >
      {newicon && <Icon>{newicon === "recycle" ? "recycling" : newicon}</Icon>}
      {content && content}
    </button>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      {buttonContent}
    </Tooltip>
  ) : (
    buttonContent
  );
};

// Definir propTypes para la validación de las props
Button.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Puede ser texto o nodo (elemento React)
  icon: PropTypes.string, // El icono debe ser una cadena de texto
  tooltip: PropTypes.string, // El tooltip debe ser una cadena de texto
  disabled: PropTypes.bool, // Propiedad booleana para deshabilitar el botón
  onClick: PropTypes.func.isRequired, // La función onClick es obligatoria
  style: PropTypes.object, // Estilo personalizado como objeto
  className: PropTypes.string, // Clases CSS como cadena de texto
  name: PropTypes.string, // Nombre del botón
  type: PropTypes.string, // Tipo de botón (submit, button, etc.)
  buttonStyle: PropTypes.shape({
    minHeight: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
  }), // Validación para las propiedades de estilo del botón
};

export default Button;
