import "./FileSync.css";
import { Editor } from "@monaco-editor/react";
import React, { useEffect, useState } from "react";
import { Setup as SetupAPI } from "src/lib/api";
import Button from "src/modules/components/common/Button/Button";
const FileSync = () => {
  const [fileSyncConfig, setFileSyncConfig] = useState("");

  useEffect(() => {
    const fetchFileSync = () => {
      SetupAPI.fileSync_read().then((res) => {
        setFileSyncConfig(res);
      });
    };
    fetchFileSync();
  }, [setFileSyncConfig]);

  const onClickSaveFileSync = async () => {
    const result = await SetupAPI.fileSync_save(fileSyncConfig);
    try {
      if (result) {
        const parsedResult = JSON.parse(result);
        alert(parsedResult.result);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="setup-filesync-monaco">
      <div className="setup-filesync-monco-buttons">
        <Button icon="save" onClick={onClickSaveFileSync} />
      </div>
      <Editor
        defaultLanguage="json"
        value={fileSyncConfig}
        onChange={(value) => setFileSyncConfig(value)}
        theme="vs-dark"
      />
    </div>
  );
};

export default FileSync;
