// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dev-element {
  display: flex;
  gap: 10px;
  padding: 10px;
}
.dossier-prop-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Espacio entre las celdas */
}

.dossier-prop,
.dossier-value {
  border: 1px solid #ccc;
  padding: 5px;
}

@media (max-width: 750px) {
  .dev-element {
    flex-direction: column;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Developer/Developer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,aAAa;AACf;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS,EAAE,6BAA6B;AAC1C;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,mBAAmB;EACrB;AACF;AACA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".dev-element {\r\n  display: flex;\r\n  gap: 10px;\r\n  padding: 10px;\r\n}\r\n.dossier-prop-container {\r\n  display: grid;\r\n  grid-template-columns: repeat(2, 1fr);\r\n  gap: 20px; /* Espacio entre las celdas */\r\n}\r\n\r\n.dossier-prop,\r\n.dossier-value {\r\n  border: 1px solid #ccc;\r\n  padding: 5px;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .dev-element {\r\n    flex-direction: column;\r\n  }\r\n}\r\n\r\n@keyframes lds-ellipsis1 {\r\n  0% {\r\n    transform: scale(0);\r\n  }\r\n  100% {\r\n    transform: scale(1);\r\n  }\r\n}\r\n@keyframes lds-ellipsis3 {\r\n  0% {\r\n    transform: scale(1);\r\n  }\r\n  100% {\r\n    transform: scale(0);\r\n  }\r\n}\r\n@keyframes lds-ellipsis2 {\r\n  0% {\r\n    transform: translate(0, 0);\r\n  }\r\n  100% {\r\n    transform: translate(12px, 0);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
