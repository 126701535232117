import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import Title from "src/modules/components/common/Title/Title";
import Menu from "./menu";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

// Seccion superior de la página, contiene el logo y el menú de navegación
const NavBar = () => {
  const navRef = useRef();
  const location = useLocation();
  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const { role, publicSetup, navBarTitle, setNavBarTitle } = useAppContext();

  const [menu, setMenu] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 1; // La distancia a partir de la cual se aplica el fondo
      if (scrollY > scrollThreshold && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollY <= scrollThreshold && isScrolled) {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  useEffect(() => {
    const title = publicSetup?.menu.find(
      (val) => val.url === location.pathname
    )?.title;
    setNavBarTitle(title);
  }, [location]);

  useEffect(() => {
    const groupedRoutes = [];
    for (const route of publicSetup.menu) {
      const pathSegments = route.pathmenu
        .split("/")
        .filter((segment) => segment !== "");
      let currentGroup = groupedRoutes;
      let currentPath = "";

      for (let i = 0; i < pathSegments.length; i++) {
        currentPath = "/" + pathSegments[i];

        let existingGroup = currentGroup.find((item) => {
          return item.pathmenu === currentPath;
        });

        if (!existingGroup) {
          existingGroup = {
            id: route.id,
            title: route.title,
            pathmenu: currentPath,
            roles: [],
            url: route.url,
            active: true,
            outwindow: route.outwindow || false,
          };

          if (i === pathSegments.length - 1) {
            existingGroup.roles = route.roles;
            existingGroup.active = route.active;
          }

          currentGroup.push(existingGroup);
        }

        if (!existingGroup.submenus) {
          existingGroup.submenus = [];
        }

        currentGroup = existingGroup.submenus;
      }
      if (route.pathmenu === "/") {
        groupedRoutes.push({ submenus: [], ...route });
      }
    }

    const userMenuIndex = groupedRoutes.findIndex(
      (route) => route.pathmenu === "/usermenu"
    );
    if (userMenuIndex !== -1) {
      const [userMenu] = groupedRoutes.splice(userMenuIndex, 1);
      groupedRoutes.push(userMenu);
    }

    setMenu(groupedRoutes);
  }, [publicSetup]);

  return (
    menu.length > 0 && (
      <header className={isScrolled ? "scrolled" : ""}>
        <i className="fas fa-bars nav-btn" onClick={showNavBar} />
        <Link to={publicSetup.linklogo}>
          <img
            id="logo"
            src={publicSetup.logo}
            alt="logo"
            className="resize_fit_center"
          />
        </Link>
        <Title text={navBarTitle} />

        <nav ref={navRef}>
          {menu.map((menuItem, index) => {
            if (
              role !== undefined &&
              menuItem.roles.includes(role) &&
              menuItem.active
            ) {
              return (
                <Menu
                  val={menuItem}
                  key={menuItem.id + index}
                  setTitle={setNavBarTitle}
                  isLastItem={index === menu.length - 1}
                />
              );
            }
            return null;
          })}
          <i className="fas fa-times nav-close-btn" onClick={showNavBar} />
        </nav>
      </header>
    )
  );
};
export default NavBar;
