import React, { useEffect, useState } from "react";
import "./Agentes.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import constants from "src/definitions/constants";
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const tiposProveedores = Object.entries(constants.loginProviders).map(
  ([key, value]) => ({
    value: value.value,
    label: value.label,
  })
);

const Asistentes = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [assistants, setAssistants] = useState(setupInfo?.assistants || []);
  // const [assistants, setAssistants] = useState(initialValues);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [editedAssistant, setEditedAssistant] = useState(null);
  const [newAssistant, setNewAssistant] = useState(false);

  useEffect(() => {
    console.log(assistants);
    setSetupInfo((prev) => {
      return { ...prev, assistants: assistants };
    });
  }, [setAssistants, setSetupInfo, assistants]);

  const handleAssistantClick = (index) => {
    setSelectedAssistant(assistants[index]);
    setEditedAssistant({ ...assistants[index] });
    setOpenBox(true);
  };

  const handleEditAssistant = (e) => {
    setEditedAssistant((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const agregarElemento = () => {
    const item = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
      email: "",
      avatar: "",
      provider: "",
      clientID: "",
      secretID: "",
      redirect_uri: "",
      scope: "",
      code: "",
      token: "",
      refreshToken: "",
    };
    setEditedAssistant(item);
    setSelectedAssistant(item);
    setNewAssistant(true);
    setOpenBox(true);
  };

  const handlSaveAssistant = () => {
    if (newAssistant) {
      setAssistants((prev) => {
        return [...prev, editedAssistant];
      });
    } else {
      const updatedassistants = assistants.map((assistant) =>
        assistant === selectedAssistant ? editedAssistant : assistant
      );
      setAssistants(updatedassistants);
    }
    setOpenBox(false);
    setSelectedAssistant(null);
    setEditedAssistant(null);
    setNewAssistant(false);
  };

  const handleDeleteAssistant = () => {
    const filteredassistants = assistants.filter(
      (loginType) => loginType !== selectedAssistant
    );
    setAssistants(filteredassistants);
    setOpenBox(false);
    setSelectedAssistant(null);
    setEditedAssistant(null);
  };

  const onClickExit = () => {
    setOpenBox(false);
    setEditedAssistant(null);
    setNewAssistant(false);
    setSelectedAssistant(null);
  };

  function onSelectChangeSingle(event, setValues, property) {
    const values = event?.value;
    setValues((prev) => {
      return { ...prev, [property]: values };
    });
  }

  const footerBoxFloat = (
    <div className="agente-container-buttons">
      <Button icon="save" onClick={handlSaveAssistant} />
      {!newAssistant && (
        <Button icon="delete" onClick={handleDeleteAssistant} />
      )}
    </div>
  );
  return (
    <div className="agentes-container">
      {openBox && (selectedAssistant || newAssistant) && (
        <BoxFloat
          title={selectedAssistant ? "Editar Login" : "Agregar Login"}
          onClickExit={onClickExit}
          onClose={selectedAssistant ? onClickExit : undefined}
          footer={footerBoxFloat}
        >
          <Input
            name="name"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["name"] : ""}
            label="Nombre"
          />
          <Input
            name="description"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["description"] : ""}
            label="Descripcion"
          />
          <Input
            name="email"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["email"] : ""}
            label="Email"
          />
          <Input
            name="avatar"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["avatar"] : ""}
            label="Avatar URL"
          />
          <Select
            name="provider"
            options={tiposProveedores}
            onChange={(event) =>
              onSelectChangeSingle(event, setEditedAssistant, "provider")
            }
            label="Proveedor"
            activeLabel={true}
            value={tiposProveedores.find(
              (tipo) => tipo.value === editedAssistant["provider"]
            )}
          />
          <Input
            name="clientID"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["clientID"] : ""}
            label="Client ID"
          />
          <Input
            name="secretID"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["secretID"] : ""}
            label="Secret ID"
          />
          <Input
            name="redirect_uri"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["redirect_uri"] : ""}
            label="Redirección URI"
          />
          <Input
            name="scope"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["scope"] : ""}
            label="Scope"
          />
          <Input
            name="code"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["code"] : ""}
            label="Codigo autorizacion"
          />
          <Input
            name="token"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["token"] : ""}
            label="Token"
          />
          <Input
            name="refreshToken"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["refreshToken"] : ""}
            label="Token de refresco"
          />
        </BoxFloat>
      )}
      <div className="display-agentes">
        {assistants?.map((el, index) => (
          <div
            key={index}
            className="agente-item"
            onClick={() => handleAssistantClick(index)}
          >
            <div className="agente-item-title">{el.name}</div>
            <div className="agente-item-description">{el.description}</div>
            <div
              className="assistant-item-chatIcon"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon>google-plus-box</Icon>
            </div>
            <a
              href={`${el.linkurl}`}
            >
              <img src={el.avatar} alt="avatar" />
            </a>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <p>Agregar más</p>
        </div>
      </div>
    </div>
  );
};

export default Asistentes;
