// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-area-style {
  --placeholderTextColor: rgba(255, 255, 255, 0.7);
  align-self: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-width: 0.5px;
  color: rgb(255, 255, 255);
  padding-left: 12px;
  font-family: "Ping LCG Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  border-color: rgba(255, 255, 255, 0.4);
  flex-basis: 328px;
  flex-grow: 1;
  flex-shrink: 1;
  resize: vertical;
  field-sizing: fixed;
}

.text-area-style:disabled {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 750px) {
  .text-area-style {
    flex-basis: auto;
    align-self: self-start;
    margin-bottom: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Text Area/TextArea.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,kBAAkB;EAClB,oCAAoC;EACpC,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,sCAAsC;EACtC,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":[".text-area-style {\r\n  --placeholderTextColor: rgba(255, 255, 255, 0.7);\r\n  align-self: center;\r\n  background-color: rgba(0, 0, 0, 0.2);\r\n  border-radius: 4px;\r\n  border-width: 0.5px;\r\n  color: rgb(255, 255, 255);\r\n  padding-left: 12px;\r\n  font-family: \"Ping LCG Regular\";\r\n  font-weight: 400;\r\n  font-style: normal;\r\n  font-size: 14px;\r\n  letter-spacing: 0px;\r\n  border-color: rgba(255, 255, 255, 0.4);\r\n  flex-basis: 328px;\r\n  flex-grow: 1;\r\n  flex-shrink: 1;\r\n  resize: vertical;\r\n  field-sizing: fixed;\r\n}\r\n\r\n.text-area-style:disabled {\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .text-area-style {\r\n    flex-basis: auto;\r\n    align-self: self-start;\r\n    margin-bottom: 5px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
