// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-service-running {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 30%;
  gap: 5px;
}

.status-service-running-info {
}

.status-service-running-label {
  display: flex;
  justify-self: space-between;
}

.status-service-running-text {
}

.status-service-running-icon {
}

.status-service-launchedAt {
}
.status-service-launchedAt-text {
}

.status-service-launchedAt-time {
  display: flex;
}

@media (max-width: 600px) {
  .status-service-running {
    width: -webkit-fill-available;
    padding: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Status/StatusRunning.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,QAAQ;AACV;;AAEA;AACA;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;AACA;AACA;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,6BAA6B;IAC7B,aAAa;EACf;AACF","sourcesContent":[".status-service-running {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-bottom: 10px;\r\n  width: 30%;\r\n  gap: 5px;\r\n}\r\n\r\n.status-service-running-info {\r\n}\r\n\r\n.status-service-running-label {\r\n  display: flex;\r\n  justify-self: space-between;\r\n}\r\n\r\n.status-service-running-text {\r\n}\r\n\r\n.status-service-running-icon {\r\n}\r\n\r\n.status-service-launchedAt {\r\n}\r\n.status-service-launchedAt-text {\r\n}\r\n\r\n.status-service-launchedAt-time {\r\n  display: flex;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .status-service-running {\r\n    width: -webkit-fill-available;\r\n    padding: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
