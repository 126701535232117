import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
import { useDragDrop } from "./KanbanContext";
import Item from "./Item";
import Placeholder from "./Placeholder";
import "./Column.css";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

export default function Column({ name, items }) {
  const {
    initialData,
    setSingleColumn,
    setOriginalSingleColumn,
    setShowColumnEdit,
    handleColumnDragOver,
    handleItemDragOver,
    draggedOver,
    addItemToColumn,
    handleDrop,
    handleColumnDragStart,
    deleteColumnAndItems,
  } = useDragDrop();

  const { publicSetup } = useAppContext();
  const [itemStyle, setItemStyle] = useState({
    backgroundColor: "rgba(245,234,234)",
  });

  useEffect(() => {
    // Actualiza el estilo cada vez que cambia el valor de publicSetup.primarycolor
    setItemStyle({
      backgroundColor: publicSetup.primarycolor,
    });
    console.log("COLOR: " + publicSetup.primarycolor);
  }, [publicSetup.primarycolor]); // Escuchando cambios en publicSetup.primarycolor

  const setColumnToEdit = () => {
    const columnToEdit = initialData.menu.find(
      (column) => column.pathmenu === `/${name}`
    );

    if (columnToEdit) {
      setSingleColumn(columnToEdit); // Establece la columna para edición
      setOriginalSingleColumn({ ...columnToEdit }); // Guarda una copia para tener referencia al estado original
      setShowColumnEdit(true);
    } else {
      console.log(`No se encontró la columna con pathmenu "/${name}"`);
    }
  };

  const handleAddItem = () => {
    addItemToColumn(name);
  };

  const handleDeleteColumn = (columnName) => {
    // Llamada a la función del contexto para borrar la columna y sus ítems
    deleteColumnAndItems(columnName);
  };

  const getColumnTitle = () => {
    const columnData = initialData.menu.find(
      (column) => column.pathmenu === `/${name}`
    );
    return columnData ? columnData.title : "Título no encontrado";
  };

  return (
    <div
      onDragOver={handleColumnDragOver}
      onDrop={(e) => handleDrop(e, name)}
      className={`edit-menu-column`}
    >
      <div
        className="edit-menu-column-header"
        draggable
        onDragStart={(e) => handleColumnDragStart(e, name)}
      >
        {" "}
        <h2 className="edit-menu-column-title">{getColumnTitle()}</h2>
        <div className="edit-menu-buttons-row">
          <Button
            onClick={setColumnToEdit}
            icon="edit"
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
          />
          <Button
            onClick={() => handleDeleteColumn(name)}
            icon="delete"
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
          />
        </div>
      </div>
      <div className="edit-menu-column-list">
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            {draggedOver.showPlaceholder &&
              draggedOver.column === name &&
              draggedOver.index === index && <Placeholder />}
            <Item
              key={item.id}
              content={item}
              originColumn={name}
              index={index}
              onItemDragOver={handleItemDragOver}
            />
          </React.Fragment>
        ))}
        {draggedOver.showPlaceholder &&
          draggedOver.column === name &&
          draggedOver.index === items.length && <Placeholder />}
        <button
          onClick={handleAddItem}
          className="edit-menu-add-item-button"
          style={itemStyle}
        >
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
  );
}

// Validación de prop-types
Column.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
