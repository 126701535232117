import React, { useState } from "react";
import PropTypes from "prop-types";
import { View } from "src/lib/api.js";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Icon } from "@mui/material";
import DropDownBox from "../common/DropDownBox/DropDownBox";
import LazyLoad from "src/routes/Structure/LazyLoad/LazyLoad";

export function EditFrame({
  setView,
  view,
  frame,
  ogDatacode,
  align = "left",
  config = { duplicateFrame: false, deleteFrame: true }
}) {
  const { role, publicSetup } = useAppContext();
  const [showDropdown, setShowDropdown] = useState(false);

  const updateView = async () => {
    if (Object.keys(view).length === 0) return;
    await View.update(view);
    setView(view);
  };

  const duplicateFrame = (frame) => {
    const newFrame = structuredClone(frame);
    delete newFrame._id;
    newFrame.name = "Nuevo frame";
    const newView = { ...view, frames: [...view.frames, newFrame] };
    view = newView;
    updateView();
    setShowDropdown(false);
  };

  const deleteFrame = (frame) => {
    const frames = view.frames.filter((fr) => fr._id !== frame._id);
    const newView = { ...view, frames };
    view = newView;
    updateView();
    setShowDropdown(false);
  };

  const typeOptions = [
    { label: "Lista", value: "list" },
    { label: "Editar", value: "show" },
    { label: "Plantilla", value: "template" },
    { label: "Gráfico", value: "graph" }
  ];

  const scopeOptions = [
    { label: "Dossier", value: "dossier" },
    { label: "Global", value: "global" },
    { label: "Plantillas", value: "templates" },
    { label: "Plantilla", value: "template" },
    { label: "Check", value: "check" },
    { label: "Flujo", value: "flow" },
    { label: "Usuario", value: "user" },
    { label: "Error", value: "error" },
    { label: "Evento", value: "event" },
    { label: "Eventos", value: "events" },
    { label: "Resumen", value: "resume" },
    { label: "Medidas", value: "measure" },
    { label: "Documento", value: "doc" },
    { label: "Cartucho", value: "cartridge" },
    { label: "Cartuchos", value: "cartridges" },
    { label: "Movimiento", value: "movement" },
    { label: "Vista", value: "view" }
  ];

  const onChangeInput = (e, prop) => {
    let name = "";
    let value = "";
    if (!e.target) {
      name = prop;
      value = e.value;
      e.target = { name, value };
    } else {
      name = e.target.name;
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }
    const newFrame = { ...frame, datacode: ogDatacode, [name]: value };
    const frames = view.frames.map((fr) => {
      if (fr._id === frame._id) return newFrame;
      return fr;
    });
    const newView = { ...view, frames };
    view = newView;
  };

  return (
    <LazyLoad>
      {publicSetup.developmode &&
        (role === "developer" || role === "superadmin") && (
          <div
            className="editColumnsModal"
            title="Haga click para editar el frame."
          >
            <Icon
              style={{
                color: "orange",
                border: "2px solid blue",
                borderRadius: "50%",
                scale: "70%",
                cursor: "pointer"
              }}
              onClick={() => setShowDropdown(true)}
            >
              edit
            </Icon>
            {showDropdown && (
              <DropDownBox
                title="Editor de Frame"
                setOpen={setShowDropdown}
                onClosed={updateView}
                style={{ right: align === "right" ? 0 : "unset" }}
              >
                <div className="css-175oi2r">
                  <div className="edit-viewcode-title-container">
                    <div className="edit-viewcode-buttons">
                      <div className="edit-viewcode-button-delete">
                        {config.deleteFrame && (
                          <Icon
                            title="Haz click para eliminar el frame"
                            onClick={() => deleteFrame(frame)}
                          >
                            delete
                          </Icon>
                        )}
                      </div>
                      {config.duplicateFrame && (
                        <div className="edit-viewcode-button-delete">
                          <Icon
                            title="Haz click para duplicar el frame"
                            onClick={() => duplicateFrame(frame)}
                          >
                            content_copy
                          </Icon>
                        </div>
                      )}
                    </div>
                  </div>
                  <>
                    <Input
                      label={"Nombre"}
                      name={"name"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="file.name"
                      defaultValue={frame.name}
                    />
                    <Select
                      onChange={(e) => onChangeInput(e, "type")}
                      options={typeOptions}
                      label={"Tipo"}
                      name={"type"}
                      value={typeOptions.find(
                        (opt) => frame.type === opt.value
                      )}
                    />
                    {frame.type === 'template' && (
                      <Input
                        label={"Código de template"}
                        name={"templatecode"}
                        onBlur={(e) => onChangeInput(e)}
                        placeholder="thome"
                        defaultValue={frame.templatecode}
                      />
                    )}
                    <Input
                      label={"Configuración"}
                      name={"config"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="{}"
                      defaultValue={frame.config}
                    />
                    <Select
                      onChange={(e) => onChangeInput(e, "scope")}
                      options={scopeOptions}
                      label={"Scope"}
                      name={"scope"}
                      value={scopeOptions.find(
                        (opt) => frame.scope === opt.value
                      )}
                    />
                    <Input
                      label={"Tamaño de la página"}
                      name={"pagesize"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="1000"
                      defaultValue={frame.pagesize}
                    />
                    <Input
                      label={"Código de datos"}
                      name={"datacode"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="{{params.dc}}"
                      defaultValue={ogDatacode}
                    />
                    <Input
                      label={"Filtro"}
                      name={"filter"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="header.owner: 'pablo@moodrob.com'"
                      defaultValue={frame.filter}
                    />
                    <Input
                      label={"Orden"}
                      name={"sort"}
                      onBlur={(e) => onChangeInput(e)}
                      placeholder="{ 'header.name': 1 }"
                      defaultValue={frame.sort}
                    />
                  </>
                </div>
              </DropDownBox>
            )}
          </div>
        )}
    </LazyLoad>
  );
}

// Agregar validación de PropTypes
EditFrame.propTypes = {
  setView: PropTypes.func.isRequired,
  view: PropTypes.shape({
    frames: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        templatecode: PropTypes.string,
        config: PropTypes.string,
        scope: PropTypes.string,
        pagesize: PropTypes.number,
        datacode: PropTypes.string,
        filter: PropTypes.string,
        sort: PropTypes.string
      })
    )
  }).isRequired,
  frame: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string,
    templatecode: PropTypes.string,
    config: PropTypes.string,
    scope: PropTypes.string,
    pagesize: PropTypes.number,
    datacode: PropTypes.string,
    filter: PropTypes.string,
    sort: PropTypes.string
  }).isRequired,
  ogDatacode: PropTypes.string.isRequired,
  align: PropTypes.string,
  config: PropTypes.shape({
    duplicateFrame: PropTypes.bool,
    deleteFrame: PropTypes.bool
  })
};

export default EditFrame;
