import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types"; // Importa PropTypes

// Esqueletos de datos iniciales
const initialViewDataSkeleton = {
  viewcode: "",
  name: "",
  title: "",
  active: true,
  public: false,
  frames: [],
};

const initialFrameDataSkeleton = {
  name: "",
  type: "",
  config: "",
  scope: "",
  filter: "{}",
  pagesize: 1000,
  elements: [],
};

const initialElementDataSkeleton = {
  name: "",
  variable: "",
  label: "",
  icon: "",
  link: "",
  type: "",
  align: "",
  format: "",
  options: "",
};

// Estado inicial
const initialState = {
  viewData: { ...initialViewDataSkeleton },
  singleFrameData: { ...initialFrameDataSkeleton },
  singleElementData: { ...initialElementDataSkeleton },
  isElementCreationVisible: false, // Estado para controlar la visibilidad de crear element
  targetFrameName: null, // Estado para almacenar el Name del frame objetivo
  targetElementName: null, // Estado para almacenar el Name del elemento objetivo
  showElementsTable: false, // Estado para manejar la tabla de elements
  elementsTableData: [], // Estado que guarda los elements de un frame para visualizarlos
  editMode: false, // Estado para manejar el mondo creación / edición
  editModeFrame: false, // Estado para manejar el modo de edición de frames
  editModeElement: false, // Estado para manejar el modo de edición de elementos
  showInputsFrame: false, // Estado para mostrar u ocultar inputs de frame
  showInputsElement: false, // Estado para mostrar u ocultar inputs de elemento
};

// Acciones
const actionTypes = {
  UPDATE_VIEW_DATA: "UPDATE_VIEW_DATA",
  ADD_FRAME_TO_VIEW: "ADD_FRAME_TO_VIEW",
  UPDATE_FRAME_IN_VIEW: "UPDATE_FRAME_IN_VIEW",
  ADD_ELEMENT_TO_FRAME: "ADD_ELEMENT_TO_FRAME",
  UPDATE_ELEMENT_IN_FRAME: "UPDATE_ELEMENT_IN_FRAME",
  SET_SINGLE_FRAME_DATA: "SET_SINGLE_FRAME_DATA",
  SET_SINGLE_ELEMENT_DATA: "SET_SINGLE_ELEMENT_DATA",
  REMOVE_FRAME_FROM_VIEW: "REMOVE_FRAME_FROM_VIEW",
  TOGGLE_ELEMENT_CREATION_VISIBILITY: "TOGGLE_ELEMENT_CREATION_VISIBILITY",
  SET_TARGET_FRAME_NAME: "SET_TARGET_FRAME_NAME",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  SET_SHOW_ELEMENTS_TABLE: "SET_SHOW_ELEMENTS_TABLE",
  SET_INPUTS_FRAME: "SET_INPUTS_FRAME",
  SET_INPUTS_ELEMENT: "SET_INPUTS_ELEMENT",
  SET_ELEMENTS_TABLE_DATA: "SET_ELEMENTS_TABLE_DATA",
  REMOVE_ELEMENT: "REMOVE_ELEMENT",
  SET_EDIT_MODE_FRAME: "SET_EDIT_MODE_FRAME",
  SET_EDIT_MODE_ELEMENT: "SET_EDIT_MODE_ELEMENT",
  UPDATE_FRAME_BY_NAME: "UPDATE_FRAME_BY_NAME",
  SET_TARGET_ELEMENT_NAME: "SET_TARGET_ELEMENT_NAME",
  UPDATE_ELEMENT_BY_NAME: "UPDATE_ELEMENT_BY_NAME",
  RESET_VIEW_DATA: "RESET_VIEW_DATA",
};

// Reductor
const stateReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEW_DATA:
      return { ...state, viewData: { ...state.viewData, ...action.payload } };
    case actionTypes.ADD_FRAME_TO_VIEW:
      return {
        ...state,
        viewData: {
          ...state.viewData,
          frames: [...state.viewData.frames, action.payload],
        },
      };
    case actionTypes.RESET_VIEW_DATA:
      return { ...state, viewData: { ...initialViewDataSkeleton } };
    case actionTypes.UPDATE_FRAME_IN_VIEW: {
      const updatedFrames = state.viewData.frames.map((frame) =>
        frame.name === action.payload.name ? action.payload : frame
      );
      return {
        ...state,
        viewData: { ...state.viewData, frames: updatedFrames },
      };
    }
    case actionTypes.SET_EDIT_MODE:
      return { ...state, editMode: action.payload };
    case actionTypes.SET_EDIT_MODE_FRAME:
      return { ...state, editModeFrame: action.payload };
    case actionTypes.SET_EDIT_MODE_ELEMENT:
      return { ...state, editModeElement: action.payload };
    case actionTypes.ADD_ELEMENT_TO_FRAME: {
      const updatedFrames2 = state.viewData.frames.map((frame) => {
        if (frame.name === action.frameName) {
          // Añade el elemento al frame específico
          return { ...frame, elements: [...frame.elements, action.payload] };
        }
        return frame;
      });

      // Decide si también necesitas añadir el elemento a elementsTableData
      let newElementsTableData2 = state.elementsTableData;
      if (state.targetFrameName === action.frameName) {
        // Añade el elemento a elementsTableData solo si el frame es el actualmente seleccionado
        newElementsTableData2 = [...state.elementsTableData, action.payload];
      }

      return {
        ...state,
        viewData: { ...state.viewData, frames: updatedFrames2 },
        elementsTableData: newElementsTableData2,
      };
    }
    case actionTypes.SET_TARGET_ELEMENT_NAME:
      return { ...state, targetElementName: action.payload };
    case actionTypes.REMOVE_FRAME_FROM_VIEW:
      return {
        ...state,
        viewData: {
          ...state.viewData,
          frames: state.viewData.frames.filter(
            (frame) => frame.name !== action.payload
          ),
        },
      };
    case actionTypes.UPDATE_FRAME_BY_NAME: {
      const changedFrames = state.viewData.frames.map((frame) => {
        if (frame.name === action.payload.targetFrameName) {
          return { ...action.payload.singleFrameData };
        }
        return frame;
      });

      return {
        ...state,
        viewData: { ...state.viewData, frames: changedFrames },
      };
    }
    case actionTypes.SET_ELEMENTS_TABLE_DATA:
      return { ...state, elementsTableData: action.payload };
    case actionTypes.TOGGLE_ELEMENT_CREATION_VISIBILITY:
      return {
        ...state,
        isElementCreationVisible: !state.isElementCreationVisible,
      };
    case actionTypes.SET_TARGET_FRAME_NAME:
      return {
        ...state,
        targetFrameName: action.payload,
      };
    case actionTypes.SET_SHOW_ELEMENTS_TABLE:
      return { ...state, showElementsTable: action.payload };
    case actionTypes.SET_INPUTS_FRAME:
      return { ...state, showInputsFrame: !state.showInputsFrame };
    case actionTypes.SET_INPUTS_ELEMENT:
      return { ...state, showInputsElement: !state.showInputsElement };
    case actionTypes.UPDATE_ELEMENT_IN_FRAME: {
      const framesWithUpdatedElement = state.viewData.frames.map((frame) => {
        if (frame.name === action.frameName) {
          const updatedElements = frame.elements.map((element) =>
            element.name === action.payload.name ? action.payload : element
          );
          return { ...frame, elements: updatedElements };
        }
        return frame;
      });
      return {
        ...state,
        viewData: { ...state.viewData, frames: framesWithUpdatedElement },
      };
    }
    case actionTypes.SET_SINGLE_FRAME_DATA:
      return { ...state, singleFrameData: action.payload };
    case actionTypes.SET_SINGLE_ELEMENT_DATA:
      return { ...state, singleElementData: action.payload };
    case actionTypes.REMOVE_ELEMENT: {
      const framesWithoutElement = state.viewData.frames.map((frame) => {
        if (frame.name === action.payload.frameName) {
          const updatedElements = frame.elements.filter(
            (element) => element.name !== action.payload.elementName
          );
          return { ...frame, elements: updatedElements };
        }
        return frame;
      });
      const updatedElementsTableData = state.elementsTableData.filter(
        (element) => element.name !== action.payload.elementName
      );
      return {
        ...state,
        viewData: { ...state.viewData, frames: framesWithoutElement },
        elementsTableData: updatedElementsTableData,
      };
    }
    case actionTypes.UPDATE_ELEMENT_BY_NAME: {
      const { targetFrameName, elementName, singleElementData } =
        action.payload;

      // Actualizar elementos dentro de los frames
      const framesUpdated = state.viewData.frames.map((frame) => {
        if (frame.name === targetFrameName) {
          const elementsUpdated = frame.elements.map((element) => {
            if (element.name === elementName) {
              return { ...element, ...singleElementData };
            }
            return element;
          });
          return { ...frame, elements: elementsUpdated };
        }
        return frame;
      });

      // Actualizar elementsTableData si el elemento actualizado es parte de este
      const elementsTableDataUpdated = state.elementsTableData.map(
        (element) => {
          if (element.name === elementName) {
            return { ...element, ...singleElementData };
          }
          return element;
        }
      );

      return {
        ...state,
        viewData: { ...state.viewData, frames: framesUpdated },
        elementsTableData: elementsTableDataUpdated,
      };
    }

    default:
      return state;
  }
};

// Creación del contexto
const DataContext = createContext();

// Proveedor del contexto
export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  // Funciones para actualizar los estados
  const updateViewData = (update) =>
    dispatch({ type: actionTypes.UPDATE_VIEW_DATA, payload: update });
  const addFrameToView = (frame) =>
    dispatch({ type: actionTypes.ADD_FRAME_TO_VIEW, payload: frame });
  const addElementToFrame = (frameName, element) =>
    dispatch({
      type: actionTypes.ADD_ELEMENT_TO_FRAME,
      frameName,
      payload: element,
    });
  const setSingleFrameData = (data) =>
    dispatch({ type: actionTypes.SET_SINGLE_FRAME_DATA, payload: data });
  const setSingleElementData = (data) =>
    dispatch({ type: actionTypes.SET_SINGLE_ELEMENT_DATA, payload: data });
  const removeFrameFromView = (frameName) => {
    dispatch({ type: actionTypes.REMOVE_FRAME_FROM_VIEW, payload: frameName });
  };
  const toggleElementCreationVisibility = () =>
    dispatch({ type: actionTypes.TOGGLE_ELEMENT_CREATION_VISIBILITY });
  const setTargetFrameName = (name) =>
    dispatch({ type: actionTypes.SET_TARGET_FRAME_NAME, payload: name });
  const findFrameIndex = () => {
    const index = state.viewData.frames.findIndex(
      (frame) => frame.name === state.targetFrameName?.name
    );
    return index + 1;
  };
  const setShowElementsTable = (value) => {
    dispatch({ type: actionTypes.SET_SHOW_ELEMENTS_TABLE, payload: value });
  };

  const setInputsFrame = () => dispatch({ type: actionTypes.SET_INPUTS_FRAME });
  const setInputsElement = () =>
    dispatch({ type: actionTypes.SET_INPUTS_ELEMENT });
  const setElementsTableData = (array) => {
    dispatch({ type: actionTypes.SET_ELEMENTS_TABLE_DATA, payload: array });
  };
  const removeElement = (frameName, elementName) => {
    dispatch({
      type: actionTypes.REMOVE_ELEMENT,
      payload: { frameName, elementName },
    });
  };
  const setEditMode = (editModeStatus) => {
    dispatch({ type: actionTypes.SET_EDIT_MODE, payload: editModeStatus });
  };

  const setEditModeFrame = (value) => {
    dispatch({ type: actionTypes.SET_EDIT_MODE_FRAME, payload: value });
  };

  const setEditModeElement = (value) => {
    dispatch({ type: actionTypes.SET_EDIT_MODE_ELEMENT, payload: value });
  };
  // Función para reiniciar singleFrameData
  const resetSingleFrameData = () => {
    dispatch({
      type: actionTypes.SET_SINGLE_FRAME_DATA,
      payload: { ...initialFrameDataSkeleton },
    });
  };
  // Función para reiniciar singleElementData
  const resetSingleElementData = () => {
    dispatch({
      type: actionTypes.SET_SINGLE_ELEMENT_DATA,
      payload: { ...initialElementDataSkeleton },
    });
  };
  const handlePutFrame = (targetFrameName, singleFrameData) => {
    dispatch({
      type: actionTypes.UPDATE_FRAME_BY_NAME,
      payload: { targetFrameName, singleFrameData },
    });
  };
  const handlePutElement = (
    targetFrameName,
    elementName,
    singleElementData
  ) => {
    if (!singleElementData) {
      console.error("singleElementData es undefined", singleElementData);
      return; // Detener la ejecución si singleElementData no es válido
    }
    dispatch({
      type: actionTypes.UPDATE_ELEMENT_BY_NAME,
      payload: { targetFrameName, elementName, singleElementData },
    });
  };

  const setTargetElementName = (name) => {
    dispatch({ type: actionTypes.SET_TARGET_ELEMENT_NAME, payload: name });
  };
  const findElementIndex = () => {
    // Encuentra el frame objetivo usando targetFrameName
    const targetFrame = state.viewData.frames.find(
      (frame) => frame.name === state.targetFrameName
    );
    if (!targetFrame) {
      return -1; // Retorna -1 si el frame no se encuentra
    }

    // Encuentra el índice del elemento objetivo dentro de los elementos del frame encontrado
    const elementIndex = targetFrame.elements.findIndex(
      (element) => element.name === state.targetElementName
    );
    return elementIndex;
  };
  const resetViewData = () => {
    dispatch({ type: actionTypes.RESET_VIEW_DATA });
  };
  const addEmptyFrameToView = () => {
    const newFrame = {
      ...initialFrameDataSkeleton,
      name: `Frame ${state.viewData.frames.length + 1}`,
    };
    dispatch({ type: actionTypes.ADD_FRAME_TO_VIEW, payload: newFrame });
  };
  const addEmptyElementToSelectedFrame = () => {
    // Asegúrate de que haya un frame seleccionado
    if (!state.targetFrameName) {
      console.log("No hay un frame seleccionado para añadir el elemento.");
      return;
    }
    // Encuentra el índice del frame seleccionado en el arreglo de frames
    const targetFrameIndex = state.viewData.frames.findIndex(
      (frame) => frame.name === state.targetFrameName.name
    );

    // Verifica si se encontró el frame seleccionado
    if (targetFrameIndex === -1) {
      console.log("El frame seleccionado no existe en la vista actual.");
      return;
    }
    // Crea un nuevo elemento vacío
    const newElement = {
      ...initialElementDataSkeleton,
      name: `Element ${Date.now()}`,
    };
    // Copia el arreglo actual de frames para modificarlo
    let newFrames = [...state.viewData.frames];
    // Añade el nuevo elemento al frame seleccionado
    newFrames[targetFrameIndex].elements = [
      ...newFrames[targetFrameIndex].elements,
      newElement,
    ];
    // Actualiza el estado con el nuevo arreglo de frames
    dispatch({
      type: actionTypes.UPDATE_VIEW_DATA,
      payload: { frames: newFrames },
    });
    // Recolecta todos los elementos de todos los frames
    const allElements = newFrames.reduce(
      (acc, frame) => [...acc, ...frame.elements],
      []
    );
    // Actualiza el estado con el nuevo conjunto de elementos para elementsTableData_
    setElementsTableData(allElements);
  };
  const setViewCodeOnly = (viewCode) => {
    dispatch({
      type: actionTypes.UPDATE_VIEW_DATA,
      payload: { viewCode },
    });
  };

  // ------------------------------ FUNCIONES SORTABLE FRAMES & ELEMENTS -------------------- //
  const onDragStartFrame = (e, id) => {
    e.dataTransfer.setData("frameId", id);
    e.target.classList.add("dragging");
    setTimeout(() => (e.target.style.opacity = "0.1"), 100);
  };

  const onDragOverFrame = (e) => {
    e.preventDefault();
  };
  const onDragEndFrame = (e) => {
    e.target.classList.remove("dragging");
    e.target.style.opacity = ""; // Restaura la opacidad original
  };

  const onDropFrame = (e, targetId) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("frameId");
    updateFramesOrder(draggedId, targetId);
  };
  const updateFramesOrder = (draggedId, targetId) => {
    // Encuentra los índices de los frames arrastrado y objetivo
    const draggedIndex = state.viewData.frames.findIndex(
      (frame) => frame._id === draggedId
    );
    const targetIndex = state.viewData.frames.findIndex(
      (frame) => frame._id === targetId
    );

    // Implementa la lógica para reordenar los frames como prefieras. Por ejemplo, intercambiándolos:
    let frames = [...state.viewData.frames];
    const [removedFrame] = frames.splice(draggedIndex, 1); // Quita el frame arrastrado
    frames.splice(targetIndex, 0, removedFrame); // Inserta el frame arrastrado en la posición del frame objetivo

    // Actualiza el estado con los frames reordenados
    dispatch({ type: actionTypes.UPDATE_VIEW_DATA, payload: { frames } });
  };

  const onElementDragStart = (e, id) => {
    e.dataTransfer.setData("elementId", id);
    e.target.classList.add("dragging");
    setTimeout(() => (e.target.style.opacity = "0.1"), 100);
  };

  const onElementDragOver = (e) => {
    e.preventDefault();
  };
  const onDragEndElement = (e) => {
    e.target.classList.remove("dragging");
    e.target.style.opacity = ""; // Restaura la opacidad original
  };

  const onElementDrop = (e, targetId) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("elementId");
    updateElementsOrder(draggedId, targetId);
  };

  const updateElementsOrder = (draggedId, targetId) => {
    // Encuentra el frame actualmente seleccionado
    const frameIndex = state.viewData.frames.findIndex(
      (frame) => frame._id === state.targetFrameName._id
    );
    if (frameIndex === -1) {
      console.log("Frame seleccionado no encontrado.");
      return; // Si no se encuentra el frame, termina la función
    }
    // Encuentra los índices de los elementos arrastrado y objetivo dentro del frame
    const draggedIndex = state.viewData.frames[frameIndex].elements.findIndex(
      (element) => element._id === draggedId
    );
    const targetIndex = state.viewData.frames[frameIndex].elements.findIndex(
      (element) => element._id === targetId
    );
    if (draggedIndex === -1 || targetIndex === -1) {
      console.log("Índice de elemento arrastrado o destino no encontrado.");
      return;
    }

    // Reordena los elementos dentro del frame
    let elements = [...state.viewData.frames[frameIndex].elements];
    const [removedElement] = elements.splice(draggedIndex, 1); // Quita el elemento arrastrado
    elements.splice(targetIndex, 0, removedElement); // Inserta el elemento arrastrado en la posición del elemento objetivo
    // Actualiza también elementsTableData si es necesario
    let elementsTableData = [...state.elementsTableData];
    const draggedElementTableDataIndex = elementsTableData.findIndex(
      (element) => element._id === draggedId
    );
    const [removedElementTableData] = elementsTableData.splice(
      draggedElementTableDataIndex,
      1
    );
    elementsTableData.splice(targetIndex, 0, removedElementTableData);

    // Crea una nueva copia de los frames para actualizar el estado
    let frames = [...state.viewData.frames];
    frames[frameIndex].elements = elements;

    // Actualiza el estado con los frames y elementsTableData reordenados
    dispatch({
      type: actionTypes.UPDATE_VIEW_DATA,
      payload: { frames },
    });
    dispatch({
      type: actionTypes.SET_ELEMENTS_TABLE_DATA,
      payload: elementsTableData,
    });
  };

  // Pasar el estado y las funciones a través del contexto
  const value = {
    ...state,
    updateViewData,
    addFrameToView,
    addElementToFrame,
    setSingleFrameData,
    setSingleElementData,
    removeFrameFromView,
    toggleElementCreationVisibility,
    setTargetFrameName,
    findFrameIndex,
    findElementIndex,
    setShowElementsTable,
    setElementsTableData,
    removeElement,
    setEditMode,
    setEditModeFrame,
    setEditModeElement,
    resetSingleFrameData,
    resetSingleElementData,
    handlePutFrame,
    handlePutElement,
    setTargetElementName,
    resetViewData,
    setInputsFrame,
    setInputsElement,
    addEmptyElementToSelectedFrame,
    addEmptyFrameToView,
    setViewCodeOnly,
    onDragStartFrame,
    onDragOverFrame,
    onDropFrame,
    updateFramesOrder,
    onElementDragStart,
    onElementDragOver,
    onElementDrop,
    updateElementsOrder,
    onDragEndFrame,
    onDragEndElement,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook personalizado para usar el contexto
export const useViewCreateContext = () => useContext(DataContext);
