import React, { createContext, useReducer } from "react";
import PropTypes from 'prop-types';
import { userActivityReducer } from "../reducers/userActivityReducer";

const initialState = {
    time: "00:00",
    activity: "none",
    duration: "00h 00min"
};

export const UserActivityContext = createContext(initialState);

export const UserActivityContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userActivityReducer, initialState);

    return (
        <UserActivityContext.Provider value={{ state, dispatch }}>
            {children}
        </UserActivityContext.Provider>
    );
};

UserActivityContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
