import React from "react";
import PropTypes from "prop-types";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import "./Action.css";
import Select from "src/modules/components/common/Select/Select";
import Collapsible from "src/modules/components/common/Collapsible/Collapsible";
import { Icon } from "@mui/material";
const Action2 = ({ action, onDelete, onChange }) => {
  const actionsType = [{ value: "error", label: "error", icon: "error" }];

  const handleDelete = () => {
    onDelete(action);
  };
  const handleInputChange = (event) => {
    const updatedAction = { ...action };
    updatedAction[event.target.name] = event.target.value;
    onChange(updatedAction);
  };

  const onSelectActionTypeChange = (event) => {
    const updatedAction = { ...action };
    updatedAction.type = event ? event.value : "";
    onChange(updatedAction);
  };
  return (
    <Collapsible header={
      <div className="array-header">
        <span style={{display: 'flex', alignItems: 'center', gap: '10px'}}>{action.name === '' ? 'Nueva Acción' : action.type ? <Icon>{actionsType.find(act => act.value === action.type).icon}</Icon> : ''}
        <span>{action.name}</span>
        </span>

      <Button
        icon="delete"
        onClick={handleDelete}
      />
    </div>
    }> <div className="action-container">
    <Input
            type="text"
            name="name"
            defaultValue={action.name}
            onBlur={handleInputChange}
            placeholder="Name"
            buttonStyle={{ pointerEvents: "none" }}
            label="Nombre"
            activeLabel={true}
          />
      <Select
        isSearchable={true}
        isClearable={true}
        name="selectActionType"
        options={actionsType}
        placeholder="Type"
        onChange={onSelectActionTypeChange}
        label="Tipo"
        value={actionsType.find((option) => {
          if(option.value === action.type) 
            return option
        })}
        activeLabel={true}
      />
      <Input
        type="text"
        name="problem"
        defaultValue={action.problem}
        onBlur={handleInputChange}
        placeholder="Problem"
        label="Problema"
        activeLabel={true}
      />
      <Input
        type="text"
        name="solution"
        defaultValue={action.solution}
        onBlur={handleInputChange}
        placeholder="Solution"
        label="Solución"
        activeLabel={true}
      />
      <Input
        type="text"
        name="link"
        defaultValue={action.link}
        onBlur={handleInputChange}
        placeholder="Link"
        label="Link"
        activeLabel={true}
      />
      <Input
        type="text"
        name="helpLink"
        defaultValue={action.helpLink}
        onBlur={handleInputChange}
        placeholder="Help Link"
        label="Link de ayuda"
        activeLabel={true}
      />
      <Input
        type="text"
        name="for"
        defaultValue={action.for}
        onBlur={handleInputChange}
        placeholder="For"
        label="Responsable"
        activeLabel={true}
      />
  </div></Collapsible>
  )
}

Action2.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    problem: PropTypes.string,
    solution: PropTypes.string,
    link: PropTypes.string,
    helpLink: PropTypes.string,
    for: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default Action2