import React from "react"; // Asegúrate de importar React
import { BaseEdge, getBezierPath } from "@xyflow/react";
import PropTypes from 'prop-types'; // Importa PropTypes

export function CustomEdge({ sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, ...props }) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        {...props}
      />

      <BaseEdge
        path={edgePath}
        {...props}
        style={{
          strokeWidth: "30px",
          opacity: 0,
        }}
      />
    </>
  );
}

// Validación de propiedades
CustomEdge.propTypes = {
  sourceX: PropTypes.number.isRequired, // Añade validación para sourceX
  sourceY: PropTypes.number.isRequired, // Añade validación para sourceY
  targetX: PropTypes.number.isRequired, // Añade validación para targetX
  targetY: PropTypes.number.isRequired, // Añade validación para targetY
  sourcePosition: PropTypes.string.isRequired, // Añade validación para sourcePosition
  targetPosition: PropTypes.string.isRequired, // Añade validación para targetPosition
};

export default CustomEdge;
