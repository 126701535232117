// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.groupInfo {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  padding-left: 80px;
  padding-right: 80px;
}

.info-container {
  border-radius: 8px;
  padding: 48px;
  background-color: rgba(221, 221, 221, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.info-title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

.info-value {
  margin-bottom: 10px;
}
/* Estilos adicionales si es necesario ajustar el tamaño de las columnas */
@media (max-width: 1200px) {
  .groupInfo {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .groupInfo {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .groupInfo {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/Info/Info.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,2CAA2C;EAC3C,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;AACA,0EAA0E;AAC1E;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".groupInfo {\r\n  display: grid;\r\n  grid-template-columns: repeat(6, 1fr);\r\n  gap: 20px;\r\n  padding-left: 80px;\r\n  padding-right: 80px;\r\n}\r\n\r\n.info-container {\r\n  border-radius: 8px;\r\n  padding: 48px;\r\n  background-color: rgba(221, 221, 221, 0.06);\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  text-align: center;\r\n}\r\n\r\n.info-title {\r\n  font-size: 25px;\r\n  font-weight: bold;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.info-value {\r\n  margin-bottom: 10px;\r\n}\r\n/* Estilos adicionales si es necesario ajustar el tamaño de las columnas */\r\n@media (max-width: 1200px) {\r\n  .groupInfo {\r\n    grid-template-columns: repeat(3, 1fr);\r\n  }\r\n}\r\n\r\n@media (max-width: 900px) {\r\n  .groupInfo {\r\n    grid-template-columns: repeat(2, 1fr);\r\n  }\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .groupInfo {\r\n    grid-template-columns: 1fr;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
