// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-service-graphic {
}

.status-service-graphic-label {
}

.status-service-graphic-label-name {
}

.status-service-graphic-label-value {
}

.status-service-graphic-paint {
}


@media (max-width: 600px) {
    .status-service-graphic {
      width: -webkit-fill-available;
      padding: 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/routes/Status/StatusGraphic.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;;AAEA;AACA;;;AAGA;IACI;MACE,6BAA6B;MAC7B,aAAa;IACf;EACF","sourcesContent":[".status-service-graphic {\r\n}\r\n\r\n.status-service-graphic-label {\r\n}\r\n\r\n.status-service-graphic-label-name {\r\n}\r\n\r\n.status-service-graphic-label-value {\r\n}\r\n\r\n.status-service-graphic-paint {\r\n}\r\n\r\n\r\n@media (max-width: 600px) {\r\n    .status-service-graphic {\r\n      width: -webkit-fill-available;\r\n      padding: 10px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
