import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes para la validación de propiedades
import "./Pagination.css";
import Button from "src/modules/components/common/Button/Button";

function Pagination({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
  showItemsPerPage = false,
}) {

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const lastItem = () => {
    const prediction = currentPage * itemsPerPage;
    return prediction > totalItems ? totalItems : prediction;
  };

  const firstItem = () => {
    const prediction = (currentPage - 1) * itemsPerPage + 1;
    return prediction >= 1 ? prediction : "0";
  };

  // Función para manejar el cambio de página
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  // Función para manejar el cambio de elementos por página
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    if (!isNaN(newItemsPerPage) && newItemsPerPage > 0) {
      onItemsPerPageChange(newItemsPerPage);
    }
  };

  const buttonStyle = {
    minWidth: "30px",
    maxWidth: "50px",
    maxHeight: "100px",
    minHeight: "30px",
  };

  return (
    <div className="pagination">
      <span className="page-info">
        {firstItem()}-{lastItem()} de {totalItems}
      </span>
      <div>
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          icon="Chevron Left"
          buttonStyle={buttonStyle}
        />
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          icon="Chevron Right"
          buttonStyle={buttonStyle}
        />
      </div>
      {showItemsPerPage && (
        <div>
          <label htmlFor="itemsPerPage">Elementos por página: </label>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
      )}
    </div>
  );
}

// Añade validación de tipos para las propiedades
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
  showItemsPerPage: PropTypes.bool,
};

export default Pagination;
