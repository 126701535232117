// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.update-page h1 {
  text-align: center;
  margin-top: 15vh;
}

.update-container {
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2vh;
  background-color: grey;
  padding: 5vw;
  border-radius: 10px;
}
.span-version-info {
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 4vh;
}

.loading-item-button {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
}
.loading-text-button {
  width: 100%;
  text-align: center;
}
.timer-update {
  text-align: center;
}
.html-btn-update{
  display: flex;
  flex-direction: column;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  gap: 0.3em;
}`, "",{"version":3,"sources":["webpack://./src/routes/Update/update.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,UAAU;AACZ","sourcesContent":[".update-page {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n.update-page h1 {\r\n  text-align: center;\r\n  margin-top: 15vh;\r\n}\r\n\r\n.update-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 30vw;\r\n  margin-right: auto;\r\n  margin-left: auto;\r\n  margin-top: 2vh;\r\n  background-color: grey;\r\n  padding: 5vw;\r\n  border-radius: 10px;\r\n}\r\n.span-version-info {\r\n  text-align: center;\r\n  margin-top: 2vh;\r\n  margin-bottom: 4vh;\r\n}\r\n\r\n.loading-item-button {\r\n  display: flex;\r\n  width: 100%;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding-top: 2vh;\r\n}\r\n.loading-text-button {\r\n  width: 100%;\r\n  text-align: center;\r\n}\r\n.timer-update {\r\n  text-align: center;\r\n}\r\n.html-btn-update{\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding-top: 0.6em;\r\n  padding-bottom: 0.6em;\r\n  gap: 0.3em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
