import viewActionTypes from "../actionTypes/viewActionTypes";

export const viewReducer = (state, action) => {
  switch (action.type) {
    case viewActionTypes.FETCH_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        view: action.payload,
        error: null,
      };
    case viewActionTypes.FETCH_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case viewActionTypes.FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.payload.frameId]: action.payload.data },
        error: null,
      };
    case viewActionTypes.FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case viewActionTypes.UPDATE_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.UPDATE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        view: action.payload,
        error: null,
      };
    case viewActionTypes.UPDATE_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case viewActionTypes.FETCH_SHOW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.payload.frameId]: action.payload.data },
        error: null,
      };
    case viewActionTypes.FETCH_SHOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case viewActionTypes.FETCH_PATCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_PATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedData: action.payload,
        error: null,
      };
    case viewActionTypes.FETCH_PATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case viewActionTypes.SET_SELECTED_FRAME:
      return {
        ...state,
        selectedData: action.payload
      };

    default:
      return state;
  }
};
