/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Resume {
    static async list(first = 0, pagination = 100) {
      let response = await HttpMethods.request_get(`/v1/resume/list?format=flat`);
      if (!response) {
        alert("Error al obtener los resumes");
        response = [];
      }
      return response;
    }
    static async graph(code) {
      const response = await HttpMethods.request_get(
        `/v1/resume/graph/${code}?format=text`,
        "text"
      );
      return response;
    }
  }
  