import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FormGenerator.css";
import Title from "src/modules/components/common/Title/Title";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import Button from "src/modules/components/common/Button/Button";
import TextArea from "src/modules/components/common/Text Area/TextArea";

function FormGenerator({ form, onSubmit, initialValues }) {
  const [values, setValues] = useState(initialValues || {});
  
  const onChangeInput = (e) => {
    let value = e.target.value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const onChangeSelect = (e) => {
    setValues({
      ...values,
      [e.name]: e.value,
    });
  };

  return (
    <>
      {form.elements.map((item, index) => (
        <ComponentType
          key={index}
          index={index}
          component={item.component}
          onChangeInput={onChangeInput}
          onChangeSelect={onChangeSelect}
          values={values}
          {...item}
        />
      ))}
      <Button
        icon="save"
        onClick={() => {
          onSubmit(values);
        }}
      />
    </>
  );
}

function ComponentType({ component, index, values, ...props }) {
  return (
    <div className={`formGenerator-component ${component}-form`} key={index}>
      {component === "title" ? (
        <Title text={props.text} />
      ) : component === "input" && props.type === "checkbox" ? (
        <Input
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChangeInput}
          checked={values[props.name] || false}
          label={props.label}
          disabled={props.disabled}
        />
      ) : component === "input" ? (
        <Input
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChangeInput}
          value={values[props.name] || props.defaultValue || ""}
          label={props.label}
          disabled={props.disabled}
        />
      ) : component === "textarea" ? (
        <TextArea
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChangeInput}
          label={props.label}
          disabled={props.disabled}
        />
      ) : component === "select" ? (
        typeSelect(props, values)
      ) : null}
    </div>
  );
}

function typeSelect(props, values) {
  let options = props.options.map((item) => {
    return { label: item.label, value: item.value, name: props.name };
  });
  return (
    <Select
      isSearchable={true}
      isClearable={true}
      name={props.name}
      label={props.label}
      options={options}
      placeholder={props.placeholder}
      onChange={(e) => {
        if (e) {
          props.onChangeSelect(e);
        } else {
          props.onChangeSelect({ name: props.name, label: "", value: "" });
        }
      }}
      value={options.find((element) => element.value === values[props.name])}
    />
  );
}

FormGenerator.propTypes = {
  form: PropTypes.shape({
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        component: PropTypes.string.isRequired,
        name: PropTypes.string,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

ComponentType.propTypes = {
  component: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChangeInput: PropTypes.func,
  onChangeSelect: PropTypes.func,
  text: PropTypes.string,
};

export default FormGenerator;
