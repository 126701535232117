import React, { useState } from "react";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import Title from "src/modules/components/common/Title/Title";
import { Global } from "src/lib/api";
import DragFileBox from "src/modules/components/common/DragFileBox/DragFileBox";
import { useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";
import LoadingSpinner  from "src/modules/components/common/LoadingSpinner/LoadingSpinner";

const FileUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileProps, setFileProps] = useState({
    filetype: "",
    replace: false,
    importfile: null,
  });
  const [responseUpload, setResponseUpload] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      updateFileProps(event.target.files);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      updateFileProps(event.dataTransfer.files);
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);
    const formData = {
      importfile: fileProps.importfile,
      filetype: fileProps.filetype,
      replace: fileProps.replace,
    };
    const result = await Global.import(formData);
    setIsLoading(false);
    if (result.result) {
      setResponseUpload({ message: result.result });
      navigate(`/createmenu/${fileProps.filetype}`);
    } else {
      setResponseUpload(result);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const updateFileProps = (files) => {
    const file1 = files[0];
    const filePropsCopy = { ...fileProps };
    setSelectedFile(file1);

    filePropsCopy.importfile = file1;
    filePropsCopy.filetype = normalizeName(file1.name);

    setFileProps((prev) => {
      return { ...filePropsCopy };
    });
  };
  return (
    <FormContainer style={{ margin: "10px", display: "flex" }}>
      <Title text="Subir Archivos" />
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Input
            name="filetype"
            type="text"
            placeholder="minificha"
            onChange={(e) =>
              setFileProps({ ...fileProps, filetype: e.target.value })
            }
            value={fileProps.filetype || ""}
            label="filetype"
          />
          <Input
            name="replace"
            type="checkbox"
            onChange={(e) =>
              setFileProps({ ...fileProps, replace: e.target.checked })
            }
            value={fileProps.replace || false}
            label="replace"
          />
        </div>
      </div>
      <DragFileBox
        handleFileChange={handleFileChange}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        selectedFile={selectedFile}
      />
      {selectedFile && (
        <>
          {!responseUpload.error && responseUpload.message && (
            <p style={{ fontWeight: 800 }}>
              Se ha subido correctamente el archivo:{" "}
              <span>{responseUpload.message}</span>
            </p>
          )}
        </>
      )}

      <div style={{ display: "flex" }}>
        {selectedFile && (
          <Button
            onClick={handleUpload}
            content={
              isLoading ? (
                <LoadingSpinner />
              ) : (
                <Icon>file_upload</Icon>
              )
            }
            disabled={isLoading ? true : false}
          />
        )}
      </div>
    </FormContainer>
  );
};
const normalizeName = (name) => {
  // Toma el texto hasta el primer punto
  const index = name.indexOf(".");
  if (index !== -1) {
    name = name.substring(0, index);
  }

  // Elimina todos los caracteres extraños
  name = name.replace(/[^a-zA-Z0-9 ]/g, "");

  // Reemplaza los espacios por "_"
  name = name.replace(/ /g, "_");

  // Convierte todo a minúsculas
  return name.toLowerCase();
};
export default FileUploader;
