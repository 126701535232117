const parseViewData = (view) => {
    const newView = { ...view }
    for (let frame of newView.frames) {
        let config = frame.config;
        let filter = frame.filter;
        if (typeof filter === "object") {
            filter = JSON.stringify(filter)
            frame.filter = filter;
        }
        if (typeof config === "object") {
            config = JSON.stringify(config)
            frame.config = config;
        }

        for (let element of frame.elements) {
            let options = element.options;
            if (typeof options === "object") {
                options = JSON.stringify(options)
                element.options = options;
            }
        }
    }
    return newView;
}

export { parseViewData };