import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'; // Importa PropTypes
import  {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "./planner.css";
import Input from "../Input/Input";
import TextArea from "../Text Area/TextArea";
import Select from "../Select/Select";
import constants from "src/definitions/constants";
import Variables from "./Variables";
import NodeViewer from "./NodeViewer/NodeViewer";
import { CustomEdge } from "./CustomEdge";
import CustomNode from "./PlannerNode";
const getId = () => {
  const caracteres =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const longitud = 20; // Puedes ajustar la longitud según tus necesidades
  let resultado = "";

  for (let i = 0; i < longitud; i++) {
    const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
    resultado += caracteres.charAt(indiceAleatorio);
  }

  return resultado;
};
const edgeTypes = {
  customEdge: CustomEdge,
};

const nodeTypes = {
  customNode: CustomNode,
};
const Planner = (props) => {
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const directionToCreate = useRef(null);
  const { screenToFlowPosition } = useReactFlow();
  const colorByType = {
    document: "rgb(0 49 161 / 65%)",
    action: "rgb(92 26 121 / 65%)",
    event: "rgb(0 109 7 / 65%)",
    variable: "rgb(142 115 0 / 65%)",
    ai: "rgb(142 0 0 / 65%)",
  };

  const nameByType = {
    document: "Documento",
    action: "Acción",
    event: "Evento",
    variable: "Info",
    ai: "IA",
  };

  useEffect(() => {
    if (props.initialNodes.length <= 0) return;
    const reconstructedNodes = [];
    const reconstructedEdges = [];

    props.initialNodes?.forEach((newNode) => {
      const reconstructedNode = {
        id: newNode.id,
        data: {
          label: newNode.name,
          type: newNode.type,
          name: newNode.name,
          logic: newNode.logic,
          ...reconstructNodeByType(newNode)
        },
        position: {
          x: newNode.pos.x,
          y: newNode.pos.y,
        },
        type: "customNode",
        className: newNode[newNode.type]?.logic
          ? "border-logic"
          : "base-border-node",
      };
      reconstructedNode.style = {
        backgroundColor: colorByType[reconstructedNode.data.type],
      };
      reconstructedNodes.push(reconstructedNode);

      // Reconstruir arcos
      newNode.depends.forEach((dependencyId) => {
        const reconstructedEdge = {
          id: getId(),
          source: dependencyId,
          target: newNode.id,
          data: {
            label: "",
            interactionWidth: 1000,
          },
          type: "customEdge",
        };

        reconstructedEdges.push(reconstructedEdge);
      });
    });

    // Actualizar el estado de nodos y arcos
    setNodes(reconstructedNodes);
    setEdges(reconstructedEdges);
  }, [props.initialNodes]);

  const reconstructNodeByType = (node) => {
    switch(node.type) {
      case 'document': {
        if(!node.document) return {
          filename: '',
          filetype: '',
          requestlimit:  '',
          documentlimit: '',
          must: false,
          who: { label: '', value: ''},
        }
        return {
          filename: node.document.filename ?? '',
          filetype: node.document.filetype ?? '',
          requestlimit: node.document.requestlimit ?? '',
          documentlimit: node.document.documentlimit ?? '',
          must: node.document.must ?? false,
          who: node.document.who ?? { label: '', value: ''},
        };
      }
      case 'action': {
        if(!node.action) return {
          conductorname: '',
        }
        return {
          conductorname: node.action.conductorname ?? '',
        }
      }
      case 'event': {
        if(!node.event) return {
          fixdays: '',
          eventtype: { label: '', value: ''},
          alerttext: '',
          alertdays: '',
        }
        return {
          fixdays: node.event.fixdays ?? '',
          eventtype: node.event.eventtype ?? { label: '', value: ''},
          alerttext: node.event.alerttext ?? '',
          alertdays: node.event.alertdays ?? '',
        }
      }
      case 'variable': {
        return {variables: node.info ?? []}
      }
      case 'ai': {
        if(!node.document) return {
          agent: { label: '', value: ''},
          prompt: '',
          variable:  '',
        }
        return {
          agent: node.document.agent ?? { label: '', value: ''},
          prompt: node.document.prompt ?? '',
          variable: node.document.variable ?? '',
        };
      }
      default: {
        return node
      }
    }
  }
  // Función para comparar dos arreglos de bordes
  const areEdgesEqual = (edgesA, edgesB) => {
    if (edgesA.length !== edgesB.length) {
      return false;
    }
  
    for (let i = 0; i < edgesA.length; i++) {
      // Eliminamos la propiedad 'selected' de la comparación
      const edgeAWithoutSelected = { ...edgesA[i] };
      const edgeBWithoutSelected = { ...edgesB[i] };
      delete edgeAWithoutSelected.selected;
      delete edgeBWithoutSelected.selected;
  
      if (!isEqual(edgeAWithoutSelected, edgeBWithoutSelected)) {
        return false;
      }
    }
  
    return true;
  };

  const checkEdgesStroke = (edgesToLoop) => {
    return edgesToLoop.map((edge) => {
      const targetNode = getNodeInfoById(edge.target);
      return {
        id: edge.id,
        source: edge.source,
        target: edge.target,
        data: edge.data,
        className: targetNode?.data.logic ? "stroke-array" : "",
        animated: targetNode?.data.logic ? true : false,
        type: "customEdge",
        sourceHandle: edge.sourceHandle,
        targetHandle: edge.targetHandle,
      };
    });
  };

  useEffect(() => {
    if (edges.length > 0 || selectedNode?.data.logic) {
      const newEdges = checkEdgesStroke(edges);
      if (!areEdgesEqual(newEdges, edges)) {
        setEdges(newEdges);
      }
    }
  }, [edges, selectedNode?.data.logic]);

  // Función para comparar dos objetos
  const isEqual = (objA, objB) => {
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);
  
    if (keysA.length !== keysB.length) {
      return false;
    }
  
    for (let key of keysA) {
      if (objA[key] !== objB[key]) {
        return false;
      }
    }
  
    return true;
  };

  const getNodeInfoById = (id, nodesToUse = nodes) => {
    return nodesToUse.find((node) => node.id === id);
  };

  const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds))
    },
    [],
  );
  const onConnectStart = useCallback((_, { nodeId }) => {
    directionToCreate.current = _.target.dataset.handlepos;
    connectingNodeId.current = nodeId;
  }, []);

  const createNewNode = (event, id) => {
    const nodeID = id ? id : getId();
    let position = {};
    if (event.type === "click") position = searchNodesPosition();
    else
      position= screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      })
    const newNodeType = 'document'
    const newNode = {
      id: nodeID,
      position,
      data: {
        label: nameByType[newNodeType],
        name: nameByType[newNodeType],
        variables: [],
        type: newNodeType,
        logic: "",
        filetype: "",
        filename: "",
        requestlimit: "",
        documentlimit: "",
        who: {label: '', value: ''},
        must: false
      },
      style: { backgroundColor: colorByType[newNodeType] },
      className: "base-border-node",
      type: "customNode",
      selected: true,
    };
    setSelectedNode(newNode);
    setNodes((nds) => nds.concat(newNode));
  };

  const searchNodesPosition = () => {
    const nodesX0 = nodes.filter((nodo) => nodo.position.x === 0);

    // Ordenar los nodos filtrados por la posición y de manera ascendente
    nodesX0.sort((a, b) => a.position.y - b.position.y);

    // Definir incremento en el eje y
    const incrementoY = 50;

    // Buscar la primera posición disponible
    let posX = 0;
    let posY = 0;

    for (let i = 0; i < nodesX0.length; i++) {
      if (nodesX0[i].position.y !== posY) {
        // Encontrada una posición disponible
        break;
      }
      posY += incrementoY;
    }

    return { x: posX, y: posY };
  };

  const createNewEdge = (id) => {
    const edgeDirections = {};
    if (
      directionToCreate.current === "right" ||
      directionToCreate.current === "bottom"
    ) {
      edgeDirections.source = connectingNodeId.current;
      edgeDirections.target = id;
      edgeDirections.sourceHandle = directionToCreate.current;
      edgeDirections.targetHandle = directionToCreate.current === 'right'? 'left' : 'top'
    } else {
      edgeDirections.source = id;
      edgeDirections.target = connectingNodeId.current;
      edgeDirections.targetHandle = directionToCreate.current;
      edgeDirections.sourceHandle = directionToCreate.current === 'left'? 'right' : 'bottom'
    }
    const newEdge = {
      id,
      source: edgeDirections.source,
      target: edgeDirections.target,
      sourceHandle: edgeDirections.sourceHandle || null,
      targetHandle: edgeDirections.targetHandle || null,
      interactionWidth: 1000,
      data: { label: "",  },
      type: 'customEdge'
    };
    setEdges((eds) =>
          eds.concat(newEdge),
        );
  };

  const onConnectEnd = (event) => {
    const targetIsPane = event.target.classList.contains("react-flow__pane");
    if (!targetIsPane) {
      return;
    }
    const id = getId();

    createNewNode(event, id);

    createNewEdge(id);
  };


  const onNodeClick = (event, node) => {
    setSelectedNode(node);
  };

  const closeEditor = () => {
    setSelectedNode(null);
  };

  const onNodesDelete = () => {
    closeEditor()
  }

  const onChange = (name) => (event) => {
    const targetNode = getNodeInfoById(selectedNode.id);
    const targetIndex = nodes.indexOf(targetNode);

    let updatedNode;
    let value;
    if(!event) value = {}
    else if (targetNode.data.type === "variable" && !event.type && !event.value)
      value = event;
    else if (event.target?.type === "checkbox") value = event.target.checked;
    else if (event.value) value = event.value;
    else value = event.target.value;

    if (
      (nameByType[targetNode.data.type] === targetNode.data.name ||
        targetNode.data.name === "") &&
      name !== "name" &&
      name === "type"
    ) {
      targetNode.data.name =
        nameByType[value] || nameByType[targetNode.data.type];
      targetNode.data.label = targetNode.data.name;
      updatedNode = {
        ...targetNode,
        data: {
          ...targetNode.data,
          [name]: value,
        },
      };
    } else {
      updatedNode = {
        ...targetNode,
        data: {
          ...targetNode.data,
          [name]: value,
        },
      };
    }
    updatedNode.data.label = updatedNode.data.name;
    updatedNode.style = { backgroundColor: colorByType[updatedNode.data.type] };
    const updatedNodes = [...nodes];
    updatedNodes[targetIndex] = updatedNode;
    updatedNode.data.logic
      ? (updatedNode.className = "border-logic")
      : (updatedNode.className = "base-border-node");
    setNodes(updatedNodes);
    setSelectedNode(updatedNode);
  };

  useEffect(() => {
    props.getInfo(nodes, edges);
  }, [nodes]);

  const whoOptions = [
    { label: "Sujeto", value: "subject" },
    { label: "Gestor", value: "gestor" },
    { label: "Otros", value: "other" },
  ];

  const eventtypeOptions = [
    { label: "Presencial", value: "onsite" },
    { label: "Videoconferencia", value: "videoconference" },
    { label: "Otros", value: "other" },
  ];

  const renderSidebar = () => {
    if (!selectedNode) return;
    const type = selectedNode.data.type;
    const defaultItems = (
      <>
        <Select
          onChange={onChange("type")}
          name="type"
          value={
            Object.values(constants.nodeTypes).find(
              (constant) => constant.value === selectedNode.data.type
            ) || null
          }
          placeholder="Tipo"
          options={Object.values(constants.nodeTypes)}
          label="Tipo"
        />
        <Input
          type="text"
          name="name"
          value={selectedNode.data.name}
          label="Nombre"
          onChange={onChange("name")}
        />
        <TextArea
          name="logic"
          value={selectedNode.data.logic}
          label="Lógica"
          onChange={onChange("logic")}
        />
        <hr
          style={{
            border: "1px dashed #fff",
            width: "-webkit-fill-available",
          }}
        />
      </>
    );
    switch (type) {
      case "document": {
        return (
            <>
            {defaultItems}
              <Input
                type="text"
                name="filetype"
                value={selectedNode.data.filetype}
                label="Tipo de documento"
                onChange={onChange("filetype")}
                placeholder="CN, DNI, CD, Pasaporte..."
              />
              <Input
                type="text"
                name="filename"
                value={selectedNode.data.filename}
                label="Nombre del archivo"
                onChange={onChange("filename")}
                placeholder="CN de {{dossier.file.name}}"
              />

              <Input
                type="number"
                name="requestlimit"
                value={selectedNode.data.requestlimit}
                label="Plazo Solicitud"
                onChange={onChange("requestlimit")}
                placeholder="1"
                unit="días"
              />
              <Input
                type="number"
                name="documentlimit"
                value={selectedNode.data.documentlimit}
                label="Plazo Documento"
                onChange={onChange("documentlimit")}
                unit="días"
              />
              <Select
                name="who"
                value={whoOptions.find(
                  (opt) => opt.value === selectedNode.data.who
                ) ?? null}
                label="Quién lo realiza"
                onChange={onChange("who")}
                options={whoOptions}
              />
              <Input
                type="checkbox"
                name="must"
                value={selectedNode.data.must}
                label="Obligatorio"
                onChange={onChange("must")}
              />
            </>
        );
      }
      case "event": {
        return (
            <>
            {defaultItems}
              <Input
                type="number"
                name="fixdays"
                value={selectedNode.data.fixdays}
                label="Plazo fijo"
                onChange={onChange("fixdays")}
                unit="días"
              />
              <Select
                name="eventtype"
                value={eventtypeOptions.find(
                  (opt) => opt.value === selectedNode.data.eventtype
                )}
                label="Tipo de Evento"
                onChange={onChange("eventtype")}
                options={eventtypeOptions}
              />
              <Input
                type="text"
                name="alerttext"
                value={selectedNode.data.alerttext}
                label="Mensaje de aviso"
                onChange={onChange("alerttext")}
              />
              <Input
                type="number"
                name="alertdays"
                value={selectedNode.data.alertdays}
                label="Días de aviso"
                onChange={onChange("alertdays")}
                unit="días"
              />
            </>
        );
      }
      case "action": {
        return (
            <>
            {defaultItems}
              <Input
                type="text"
                name="conductorname"
                value={selectedNode.data.conductorname}
                label="Id en conductor"
                onChange={onChange("conductorname")}
              />
            </>
        );
      }
      case "ai": {
        return (
            <>
              {defaultItems}
              <Select
                name="agent"
                value={eventtypeOptions.find(
                  (opt) => opt.value === selectedNode.data.agent
                )}
                label="Agente"
                onChange={onChange("agent")}
                options={eventtypeOptions}
              />
              <Input
                name="prompt"
                value={selectedNode.data.prompt || ''}
                label="Prompt"
                onChange={onChange("prompt")}
              />
              <Input
                name="variable"
                value={selectedNode.data.variable || ''}
                label="Variable"
                onChange={onChange("variable")}
              />
            </>
        );
      }
      case "variable": {
        return (
            <>
              {defaultItems}
              <Variables
                variables={selectedNode.data.variables}
                setVariables={onChange("variables")}
              />
            </>
        );
      }
      default: {
        return (
          <>
            {defaultItems}
          </>
      );
      }
    }
  };
  return (
    <NodeViewer
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onNodeClick={onNodeClick}
      hasSidebar={true}
      renderSidebar={renderSidebar}
      canCreateNodes={true}
      createNewNode={createNewNode}
      onCloseEditor={closeEditor}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesDelete={onNodesDelete}
    />
  );
};

Planner.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  getInfo: PropTypes.func.isRequired,
};

const PlannerWithProvider = (props) => (
  <ReactFlowProvider>
    <Planner {...props} />
  </ReactFlowProvider>
);

export default PlannerWithProvider
