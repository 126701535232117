// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cabeceraDropDown {
  display: flex;
  justify-content: center;
  font-size: x-large;
  padding-bottom: 10px;
  font-weight: bold;
}
.dropDownBox {
  background-color: var(--primary-color);
  width: max-content;
  position: absolute;
  margin-top: 10px;
  z-index: 2;
  padding: 20px;
  border-radius: 10px;
  min-width: 500px;
  .input-label {
    margin-right: 0px;
    flex-basis: 100px;
  }
  input {
    /* width: 100%; */
  }
  .edit-viewcode-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .edit-viewcode-title {
    font-size: 20px;
    font-weight: 600;
  }
  .edit-viewcode-buttons {
    display: flex;
    gap: 10px;
  }
  .edit-viewcode-button-edit {
    cursor: pointer;
  }
  .edit-viewcode-button-delete {
    cursor: pointer;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/common/DropDownBox/DropDownBox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB;IACE,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,SAAS;EACX;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".cabeceraDropDown {\r\n  display: flex;\r\n  justify-content: center;\r\n  font-size: x-large;\r\n  padding-bottom: 10px;\r\n  font-weight: bold;\r\n}\r\n.dropDownBox {\r\n  background-color: var(--primary-color);\r\n  width: max-content;\r\n  position: absolute;\r\n  margin-top: 10px;\r\n  z-index: 2;\r\n  padding: 20px;\r\n  border-radius: 10px;\r\n  min-width: 500px;\r\n  .input-label {\r\n    margin-right: 0px;\r\n    flex-basis: 100px;\r\n  }\r\n  input {\r\n    /* width: 100%; */\r\n  }\r\n  .edit-viewcode-title-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  .edit-viewcode-title {\r\n    font-size: 20px;\r\n    font-weight: 600;\r\n  }\r\n  .edit-viewcode-buttons {\r\n    display: flex;\r\n    gap: 10px;\r\n  }\r\n  .edit-viewcode-button-edit {\r\n    cursor: pointer;\r\n  }\r\n  .edit-viewcode-button-delete {\r\n    cursor: pointer;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
