// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pagination.css */
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  font-size: 16px;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.page-info {
  padding-right: 5px;
}

.pagination-button:hover {
  background-color: #ddd;
}

.pagination-button.active {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.pagination-button.disabled {
  pointer-events: none;
  color: #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/frame/ListFrame/Pagination.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;EACpB,WAAW;AACb","sourcesContent":["/* Pagination.css */\r\n.pagination {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  align-items: center;\r\n  padding-right: 5px;\r\n  font-size: 16px;\r\n}\r\n\r\n.pagination-button {\r\n  padding: 8px 16px;\r\n  margin: 0 4px;\r\n  border: 1px solid #ccc;\r\n  background-color: #f0f0f0;\r\n  cursor: pointer;\r\n  transition: background-color 0.3s;\r\n}\r\n\r\n.page-info {\r\n  padding-right: 5px;\r\n}\r\n\r\n.pagination-button:hover {\r\n  background-color: #ddd;\r\n}\r\n\r\n.pagination-button.active {\r\n  background-color: #007bff;\r\n  color: #fff;\r\n  font-weight: bold;\r\n}\r\n\r\n.pagination-button.disabled {\r\n  pointer-events: none;\r\n  color: #ccc;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
