import React from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import "./StructureCard.css";
import StructureList from "../StructureList/StructureList";
import StructureActions from "../StructureActions/StructureActions";
import LazyLoad from "../LazyLoad/LazyLoad";

const StructureCard = React.memo(
  ({
    structure,
    currentPath,
    pathKey,
    handleAddNewProperty,
    dispatch,
    editedItems,
    manageModal,
    setupDragAndDrop,
  }) => {
    const handleDragStart = (e) => {
      e.stopPropagation();
      setupDragAndDrop();
    };

    return (
      <li
        key={pathKey}
        className="structure-item"
        draggable
        onClick={(e) => {
          e.stopPropagation();
          manageModal(structure, currentPath);
        }}
        onDragStart={handleDragStart}
        data-dragdrop-object="structure"
        data-dragdrop-index={currentPath.join(",")}
      >
        <LazyLoad>
          <div className="structure-content">
            <span
              className={`structure-position ${
                structure.required !== "no" ? "required" : ""
              }`}
            >
              {currentPath.join(".")}
            </span>
            <span className="structure-varname">{structure.varname}</span>
            <span className="structure-title">{structure.title}</span>
            <span className="structure-datatype">{structure.datatype}</span>
            <StructureActions
              structure={structure}
              currentPath={currentPath}
              pathKey={pathKey}
              handleAddNewProperty={handleAddNewProperty}
              dispatch={dispatch}
              editedItems={editedItems}
            />
          </div>
        </LazyLoad>
        {(structure.datatype === "array" || structure.datatype === "object") &&
          structure.structure.length > 0 && (
            <StructureList
              structures={structure.structure}
              path={currentPath}
              handleAddNewProperty={handleAddNewProperty}
              dispatch={dispatch}
              editedItems={editedItems}
              manageModal={manageModal}
              setupDragAndDrop={setupDragAndDrop}
              nested={true}
            />
          )}
      </li>
    );
  }
);

// Agrega un displayName
StructureCard.displayName = "StructureCard";

// Validación de props
StructureCard.propTypes = {
  structure: PropTypes.shape({
    required: PropTypes.string,
    varname: PropTypes.string,
    title: PropTypes.string,
    datatype: PropTypes.string,
    structure: PropTypes.array,
  }).isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.number).isRequired,
  pathKey: PropTypes.number.isRequired,
  handleAddNewProperty: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      structure: PropTypes.array,
    }),
  }).isRequired,
  manageModal: PropTypes.func.isRequired,
  setupDragAndDrop: PropTypes.func.isRequired,
};

export default StructureCard;
