import React, { useContext, useEffect } from 'react';
import './StatusBubbles.css';
import Button from 'src/modules/components/common/Button/Button';
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider';
import { fetchActivity, fetchAction } from 'src/modules/actions/userActivityActions';


const activityTypes = {
  start: "start",
  pause: "pause",
  continue: "continue",
  stop: "stop"
}

const buttonStyle = {
  borderRadius: "50%",
  width: "60px",
  height: "60px",
  maxWidth: "none",
  maxHeight: "none"
}
function StatusBubbles() {
  const { state, dispatch } = useContext(UserActivityContext)
  const { activity } = state

  useEffect(() => {
    fetchActivity(dispatch)
  }, [dispatch])

  const handleOnClick = async (action) => {
    await fetchAction(action, dispatch)
  }

  return (
    <div className="time-container__status-bubbles">
      <Button
        icon="login"
        className="status-bubble"
        style={buttonStyle}
        tooltip="Entrada"
        onClick={() => handleOnClick(activityTypes.start)}
        disabled={activity === activityTypes.start || activity === activityTypes.pause || activity === activityTypes.continue}
      />
      <Button
        icon="pause"
        className="status-bubble"
        style={buttonStyle}
        tooltip="Pausa"
        onClick={() => handleOnClick(activityTypes.pause)}
        disabled={activity === activityTypes.pause || activity === activityTypes.stop}
      />
      <Button
        icon="play_arrow"
        className="status-bubble"
        style={buttonStyle}
        tooltip="Volver"
        onClick={() => handleOnClick(activityTypes.continue)}
        disabled={activity === activityTypes.continue || activity === activityTypes.start || activity === activityTypes.stop}
      />
      <Button
        icon="logout"
        className="status-bubble"
        style={buttonStyle}
        tooltip="Salida"
        onClick={() => handleOnClick(activityTypes.stop)}
        disabled={activity === activityTypes.stop}
      />
    </div>
  );
}

export default StatusBubbles;
