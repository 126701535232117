// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  align-items: stretch;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  padding: 48px;
  background-color: rgba(221, 221, 221, 0.06);
  row-gap: 2rem;
  display: flex;
  margin: auto;
  width: auto;
  margin-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/components/Containers/FormContainer/FormContainer.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,2CAA2C;EAC3C,aAAa;EACb,aAAa;EACb,YAAY;EACZ,WAAW;EACX,eAAe;AACjB","sourcesContent":[".form-container {\r\n  align-items: stretch;\r\n  border-radius: 8px;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  padding: 48px;\r\n  background-color: rgba(221, 221, 221, 0.06);\r\n  row-gap: 2rem;\r\n  display: flex;\r\n  margin: auto;\r\n  width: auto;\r\n  margin-top: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
