import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Setup } from 'src/lib/api';
import { useSessionStorage } from "src/modules/hooks/useSessionStorage";
import setupDefault from 'src/_publicfiles/setup.json';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [role, setRole] = useSessionStorage('role', "guest");
  const [name, setName] = useSessionStorage('name', "");
  const [email, setEmail] = useSessionStorage('email', "");
  const [publicSetup, setPublicSetup] = useState({});
  const [setupData, setSetupData] = useState({});
  const [isServerUp, setIsServerUp] = useState(true);
  const [token, setToken] = useSessionStorage("token", null);
  const [navBarTitle, setNavBarTitle] = useState("titulo");

  // Lee el public setup del servidor
  useEffect(() => {
    async function fetchSetup() {
      try {
        let response = await Setup.public();
        // Si detecta que faltan valores del public setup en el servidor, establece los de por defecto
        response = fillUndefinedProps(response, setupDefault);
        // Gestiona el menú por defecto, en caso de no existir permite crear los menús
        setPublicSetup(response);
      } catch (error) {
        setIsServerUp(false);
        alert('Error al conectar al servidor');
      }
    }
    fetchSetup();
  }, []);

  const refreshSetup = async () => {
    try {
      let response = await Setup.public();
      // Si detecta que faltan valores del public setup en el servidor, establece los de por defecto
      response = fillUndefinedProps(response, setupDefault);
      // FIXME: Eliminar cuando el API sea quien gestione los menús por default
      setPublicSetup(response);
      setIsServerUp(true); // Asumiendo que quieres marcar el servidor como 'up' si la llamada tiene éxito
    } catch (error) {
      setIsServerUp(false);
      alert('Error al conectar al servidor');
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ name, setName, email, setEmail, role, setRole, publicSetup, setPublicSetup, isServerUp, setIsServerUp, token, setToken, navBarTitle, setNavBarTitle, setupData, setSetupData, refreshSetup }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function fillUndefinedProps(response, setupDefault) {
  const itemToReturn = {};
  for (let key in setupDefault) {
    if (response[key]) {
      itemToReturn[key] = response[key];
    } else {
      itemToReturn[key] = setupDefault[key];
    }
  }
  for (let key in response) {
    if (!itemToReturn[key]) itemToReturn[key] = response[key];
  }
  return itemToReturn;
}

export const useAppContext = () => useContext(AppContext);
