import React from "react";
import PropTypes from "prop-types"; // Asegúrate de tener PropTypes importado
import "./Title.css";

const Title = ({ color = "white", fontSize = "28px", style = {}, text }) => {
  return (
    <div className="title-container">
      <h1
        className="title"
        style={{ ...style, color, fontSize }} // Corregido el uso de color y fontSize en el estilo
      >
        {text}
      </h1>
    </div>
  );
};

Title.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string, // Asumí que el texto es obligatorio
};

export default Title;
