import { React, useEffect } from "react";
import { useNavigate } from "react-router";
import { Bank as BankAPI } from "src/lib/api";
import LoadingSpinner  from "src/modules/components/common/LoadingSpinner/LoadingSpinner";

const BankRevalidate = () => {
  const navigate = useNavigate()
  useEffect(() => {
    BankAPI.bankRequisitions().then((res) => {
      console.log(res);
      navigate(("/setup"));
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        fontSize: "2rem",
        gap:"10px"
      }}
    >
      Actualizando cuentas
      <LoadingSpinner />
    </div>
  );
};

export default BankRevalidate;
