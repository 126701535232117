import React, { useRef } from "react";
import PropTypes from 'prop-types';
import './DragFileBox.css';

const DragFileBox = ({
  handleFileChange,
  handleDragOver,
  handleDrop,
  selectedFile = null, 
  style = {} 
}) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
      <div
        onClick={handleClick}
        className="dragfile-box"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={style}
      >
        <p style={{ wordBreak: 'break-all' }}>
          {selectedFile ? `Archivo Seleccionado: ${selectedFile.name}` : 'Arrastra el fichero a importar o haz click aquí.'}
        </p>
      </div>
    </>
  );
};

DragFileBox.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  handleDragOver: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  style: PropTypes.object
};

export default DragFileBox;
