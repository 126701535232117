const removeAccents = (str) => {
    let value = str;
    if (typeof value !== String) {
        value = String(str);
    }
    if (value === "") {
        value = "undefined";
    }
    if (!value || value === "") return "";
    return value
        .normalize("NFD")
        .replace(/[\u0300-\u0302\u0308\u030B\u0327]/g, "");
};

export default removeAccents;