/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Dossier {
    static async structure(dossierCode) {
        const response = await HttpMethods.request_get(
            `/v1/dossier/structure/${dossierCode}?format=flat`
        );
        if (!response) alert("No se encontró el dossier");
        return response;
    }

    static async explain(dossierCode) {
        const response = await HttpMethods.request_get(
            `/v1/dossier/explain/${dossierCode}?format=json`
        );
        if (!response) alert("No se encontró el dossier");
        return response;
    }

    static async render(dossierCode) {
        const response = await HttpMethods.request_get(`/v1/dossier/render/${dossierCode}`);
        if (!response) alert("No se encontró el dossier");
        return response;
    }

    static async list(framecode, first = 0, pagination = 100) {
        const response = await HttpMethods.request_get(
            `/v1/dossier/list/-/${framecode}/${first}/${pagination}?format=flat`
        );
        if (!response) alert("Error al obtener los dossiers");
        return response;
    }

    static async read(dossierCode) {
        const response = await HttpMethods.request_get(`/v1/dossier/read/${dossierCode}`);
        if (!response) alert("Error al leer el dossier");
        return response?.dossier;
    }

    static async analysis() {
        const response = await HttpMethods.request_get(`/v1/dossier/analysis/`);
        if (!response) alert("Error al leer el dossier");
        return response;
    }

    static async crupdate(data) {
        const response = await HttpMethods.request_post("/v1/dossier/crupdate", data);
        if (!response) alert("Error al guardar el dossier");
        return response;
    }

    static async filetypes() {
        const response = await HttpMethods.request_get(`/v1/dossier/filetypes`);
        if (!response) alert("Error al obtener los tipos de archivo");
        return response;
    }

    static async modify(code, modifications) {
      const response = await HttpMethods.request_post(`/v1/dossier/modify/${code}`, modifications)
      if (!response) alert("Error al obtener los tipos de archivo");
      return response;
    }
}
