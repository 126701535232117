import React from 'react';
import './Calendar.css';
import PropTypes from "prop-types"

function Calendar({ title, children }) {
    return (
        <div className={"user-activity-dashboard__calendar--hidden"}>
            <div className="calendar__header">
                <h2 className="calendar__title">{title}</h2>
            </div>
            <div className="calendar__widget">
                {children}
            </div>
        </div>
    );
}

export default Calendar;


Calendar.propTypes = {
    title: PropTypes.array.isRequired,
    children: PropTypes.node
}