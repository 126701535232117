import React from "react";
import PropTypes from "prop-types";
import "./Test.css";
import Collapsible from "src/modules/components/common/Collapsible/Collapsible";
import TableRow  from "src/modules/components/frame/ListFrame/TableRow";
import TableCell from "src/modules/components/frame/ListFrame/TableCell";
import useFormatters from "src/modules/hooks/useFormatters";
// import SortingTable from "../Table/SortingTable";

const Test = ({ element }) => {
  const formatter = useFormatters()
  return (
    <div className="test-group">
      <span className="test-title">
        <span>
          <strong>Comprobación: </strong> 
          {element.check.replaceAll("'", " ").replaceAll("  ", " ").trim()}
        </span>
        <span>
          <strong>Delay:</strong> {formatter.roundToDecimals(element.delay, 3)}ms
        </span>
      </span>
      { element.vars?.length > 0 ?(
                  <Collapsible header='Variables'>
                  <div className="lf-table-container" style={{height: 'auto'}}>
                    <span className="lf-table" style={{height: 'auto'}}>
                      <TableRow isHeader>
                        <TableCell width={350} minWidth={350} draggable={false}>
                          Nombre de la variable
                        </TableCell>
                        <TableCell width={350} minWidth={350} draggable={false}>
                          Valor
                        </TableCell>
                        <TableCell width={120} minWidth={120} draggable={false}>
                          Tipo
                        </TableCell>
                      </TableRow>
                      <div className="lf-body">

                      {element.vars.map((varItem) => {
                            return (
                      <TableRow key={varItem.name}>
                              <TableCell width={350} minWidth={350} draggable={false}>
                              {varItem.name}
                            </TableCell>
                            <TableCell width={350} minWidth={350} draggable={false}>
                              {String.raw`${varItem.value}`}
                            </TableCell>
                            <TableCell width={120} minWidth={120} draggable={false}>
                              {varItem.type}
                            </TableCell>
                      </TableRow>
                            );
                          })}
                      </div>
                    </span>
                  </div>
                    </Collapsible>
      )
      :(
        <h3>No se utilizaron variables</h3>
      )
      }
    </div>
  );
};

Test.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
    check: PropTypes.string.isRequired,
    delay: PropTypes.string.isRequired,
    vars: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Test;
