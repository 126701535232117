/*eslint no-undef: "error"*/
import { HttpMethods } from "./HttpMethods.js";

export class Movement  {
    static async list(framecode, datacode, first = 0, pagination = 100) {
        if (datacode && datacode !== "null") {
            datacode = `&dossiercode=${datacode}`;
        } else {
            datacode = "";
        }
        let response = await HttpMethods.request_get(
            `/v1/movement/list/-/${framecode}/${first}/${pagination}?format=flat${datacode}`
        );
        if (!response) {
            alert("Error al obtener los movements");
            response = [];
        }
        return response;
    }
}