import React from "react";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
import Collapsible from "../../common/Collapsible/Collapsible";
import { EditElement } from "../EditElement2";
import ElementRenderer from "./ElementRenderer";

function ArrayElement({ element, data, path, ...props }) {
  const {
    mustache,
    addItemToArray,
    buttonsStyle,
    setupDragAndDrop,
    view,
    frame,
    setView,
    dc,
  } = props;

  if (!Array.isArray(data) && data) data = [data];

  const link =
    element.link && element.link.includes("{")
      ? mustache.replaceMustache(element.link, data)
      : element.link;

  const label =
    element.label && element.label.includes("{")
      ? mustache.replaceMustache(element.label, data)
      : element.label;

  return (
    <div
      draggable
      data-dragdrop-object="item"
      data-dragdrop-index={element.variable}
      onDragStart={setupDragAndDrop}
    >
      <Collapsible
        open={element.isOpen}
        headerStyle={{ padding: "2px 20px 2px 10px" }}
        textStyle={{ padding: "unset" }}
        header={
          <div className="array-header">
            <div className="row-element">
              <EditElement
                element={element}
                view={view}
                frame={frame}
                setView={setView}
                dataCode={dc}
              />
              {element.link ? (
                <a href={link} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ) : (
                <span>{`${label}: ${data ? data.length : 0}`}</span>
              )}
            </div>
            <Button
              icon="add-circle"
              onClick={() => addItemToArray(element.variable, path)}
              name={element.variable}
              buttonStyle={buttonsStyle}
            />
          </div>
        }
      >
        {data?.map((dataItem, index) => {
          const currentPath = path
            ? `${path}[${index}]`
            : `${element.variable}[${index}]`;

          return (
            <ElementRenderer
              key={`element_${element.variable}_${index}`}
              element={element.elements[0]}
              data={dataItem}
              path={currentPath}
              {...props}
            />
          );
        })}
      </Collapsible>
    </div>
  );
}

ArrayElement.propTypes = {
  element: PropTypes.object,
  data: PropTypes.any,
  path: PropTypes.string,
  mustache: PropTypes.object,
  addItemToArray: PropTypes.func,
  deleteItemInArray: PropTypes.func,
  buttonsStyle: PropTypes.object,
  setupDragAndDrop: PropTypes.func,
  view: PropTypes.object,
  frame: PropTypes.object,
  setView: PropTypes.func,
  dc: PropTypes.string,
};

export default React.memo(ArrayElement);
