import React from 'react';
import './ClockIns.css';
import PropTypes from "prop-types"
function ClockIns({ clockIns, onRequest }) {
    return (
        <div className="user-activity-dashboard__clock-ins--hidden">
            <div className="clock-ins__header">
                <h2 className="clock-ins__title">Fichajes</h2>
                <button className="clock-ins__request-btn" onClick={onRequest}>Solicitar fichaje</button>
            </div>
            <div className="clock-ins__items">
                {clockIns.map((clockIn, index) => (
                    <div key={index} className="clock-in">
                        <span className="clock-in__time">{clockIn.time}</span>
                        <div className={`clock-in__circle clock-in__circle--${clockIn.color}`}></div>
                        <span className="clock-in__duration">{clockIn.duration}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ClockIns;

ClockIns.propTypes = {
    clockIns: PropTypes.array.isRequired,
    onRequest: PropTypes.func
}