import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./Navbar.css";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import PersonIcon from '@mui/icons-material/Person';
import { SvgIcon } from "@mui/material";

const Menu = ({ val, isLastItem }) => {
  const { role } = useAppContext();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const navigate = useNavigate()
  return (
    <div
      onMouseEnter={() => {
        if (val.submenus) {
          setShowSubmenu(true);
        }
      }}
      onMouseLeave={() => setShowSubmenu(false)}
      className="submenu-container"
    >
      <NavLink
        id={window.location.pathname === val.url ? "active" : ""}
        to={val.url}
        target={val.outwindow ? "_blank" : "_self"}
        onClick={() => {
          if (val.url === "/logout") {
            sessionStorage.clear();
            navigate("/login");
          }
        }}
      >
        <div id="title">
          {val.title === "usermenu" ? (
              <SvgIcon
                component={PersonIcon}
              />
          ) : (
            val.title
          )}
        </div>
      </NavLink>
      {showSubmenu && (
        <li className={`submenu ${isLastItem ? "lastitem" : ""}`}>
          {val.submenus.map((submenu) => {
            if (
              role !== undefined &&
              submenu.roles.includes(role) &&
              submenu.active
            ) {
              return (
                <ul key={submenu.id}>
                  <NavLink
                    id={
                      window.location.pathname === submenu.url ? "active" : ""
                    }
                    target={submenu.outwindow ? "_blank" : "_self"}
                    to={submenu.url}
                    onClick={() => {
                      if (submenu.url === "/logout") {
                        sessionStorage.clear();
                        navigate("/login");
                      }
                    }}
                  >
                    {submenu.title}
                  </NavLink>
                </ul>
              );
            }
            return null;
          })}
        </li>
      )}
    </div>
  );
};

Menu.propTypes = {
  val: PropTypes.shape({
    url: PropTypes.string.isRequired,
    outwindow: PropTypes.bool,
    title: PropTypes.string.isRequired,
    submenus: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        outwindow: PropTypes.bool,
        title: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(PropTypes.string),
        active: PropTypes.bool,
      })
    ),
  }).isRequired,
  isLastItem: PropTypes.bool,
};

export default Menu;
