import React from "react";
import "./Item.css";

export default function Placeholder() {
  return (
    <div
      style={{
        height: "5vh",
        backgroundColor: "lightgrey",
        margin: "0.2vw",
        borderRadius: "8px",
      }}></div>
  );
}
