// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .formGenerator{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    box-sizing: border-box;
    background-color: rgba(221, 221, 221, 0.06);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
    padding-bottom: 20px;
}
.formGenerator-component{
    padding: 10px;
    display: grid;
}
.formGenerator-component label{
    padding-bottom: 8px;
}
.title-form{
    text-align: center;
margin-top: 20px;
padding-bottom:20px ;
}
f
.input-form{

}
.select-form{
    
}
.input-form {
} */`, "",{"version":3,"sources":["webpack://./src/modules/components/FormGenerator/FormGenerator.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA+BG","sourcesContent":["/* .formGenerator{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    padding: 0 1rem;\r\n    box-sizing: border-box;\r\n    background-color: rgba(221, 221, 221, 0.06);\r\n    border-radius: 0.5rem;\r\n    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);\r\n    padding-bottom: 20px;\r\n}\r\n.formGenerator-component{\r\n    padding: 10px;\r\n    display: grid;\r\n}\r\n.formGenerator-component label{\r\n    padding-bottom: 8px;\r\n}\r\n.title-form{\r\n    text-align: center;\r\nmargin-top: 20px;\r\npadding-bottom:20px ;\r\n}\r\nf\r\n.input-form{\r\n\r\n}\r\n.select-form{\r\n    \r\n}\r\n.input-form {\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
