const structureActionsTypes = {
    SET_LOADING: "SET_LOADING",
    LOAD_STRUCTURE_DATA: "LOAD_STRUCTURE_DATA",
    SHOW_SNACKBAR: "SHOW_SNACKBAR",
    HIDE_SNACKBAR: "HIDE_SNACKBAR",
    SET_MODAL_STATE: "SET_MODAL_STATE",
    SET_SELECT_OPTIONS: "SET_SELECT_OPTIONS",
    SET_SELECTED_MENU_ITEM: "SET_SELECTED_MENU_ITEM",
    UPDATE_HEADER: "UPDATE_HEADER",
    UPDATE_STRUCTURE: "UPDATE_STRUCTURE",
    SET_SELECTED_ORIGINS: "SET_SELECTED_ORIGINS",
    SET_ERROR: "SET_ERROR",
    UPDATE_STRUCTURE_ITEM: "UPDATE_STRUCTURE_ITEM",
    CREATE_NEW_STRUCTURE: "CREATE_NEW_STRUCTURE",
    REMAKE_STRUCTURE: "REMAKE_STRUCTURE",
};

export default structureActionsTypes;